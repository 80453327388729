import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function categoryReducer(state = initialState.forums, action) {
  switch (action.type) {
    case types.LOAD_FORUM_DATA_SUCCESS:
        return action.forums;
    default:
        return state;
  }  
}
