import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import * as forumActions from "../../actions/forumActions";
import { useForm } from "react-hook-form";

const ReportUser = (props) => {
  const { userdata, actions, categoryUserReducer } = props;
  const { register, errors, handleSubmit } = useForm();
  const [getLoader, setLoader] = useState(false);
  const { userInfo } = useSelector((state) => state);
  useEffect(() => {
    actions.loadReportCategoryDataUser();
  }, [actions]);
  const onSubmit = (data) => {
    setLoader(true);
    const request = {
      category_id: data.category,
      reported_by: userInfo.id,
      reported_to:
        userInfo.role_id == "Mentees"
          ? userdata.item.mentor_id
          : userdata.item.mentee_id,
      description: data.description,
      reported_from: userdata.from,
    };
    actions.saveForumReportUser(request, props, setLoader);
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h3>
            <b>Report User</b>
            <p></p>
            <p>
              We will review & take action for removing objectionable
              comments/user within 24 hours
            </p>
          </h3>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mentor_detail align-items-center">
              <Col sm={12}>
                <div className="form-group">
                  <label>Category*</label>
                  <Form.Control
                    as="select"
                    className="mb-3"
                    name="category"
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value="" key="">
                      Select Option
                    </option>

                    {categoryUserReducer &&
                      categoryUserReducer.map((item, key) => (
                        <option value={item.id} key={key}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Control>
                  {errors.category && errors.category.type === "required" && (
                    <label className="customeValidationText text-left">
                      Please select category
                    </label>
                  )}
                </div>
              </Col>
              <Col sm={12}>
                <label>Reason (Optional)</label>
                <Form.Control
                  as="textarea"
                  className={"form-control"}
                  name="description"
                  defaultValue=""
                  aria-invalid={"false"}
                  ref={register({
                    maxLength: 6000,
                  })}
                />
                {/* {errors.description &&
                  errors.description.type === "required" && (
                    <label className="customeValidationText text-left">
                      Please enter description
                    </label>
                  )}
                {errors.description &&
                  errors.description.type === "maxLength" && (
                    <label className="customeValidationText text-left">
                      Description should not more then 6000 characters
                    </label>
                  )} */}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="form-group mt-3 text-left">
                  <Button
                    className="btn nextbtn ml-2"
                    type="submit"
                    disabled={getLoader}
                  >
                    {getLoader ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    categoryUserReducer: state.categoryUserReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(forumActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportUser);
