import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import * as ResourcesHubActions from "../../actions/ResourcesHubActions";

function Index(props) {
  const { actions,NewsletterData} = props; 

  const slug = props.match.params.slug;

  const [getViewData, setViewData]=useState({});

  useEffect(() => {

    getNewsletterData();

  }, [actions]);


  useEffect(() => {
    
    document.title = 'vLookUp | Newsletter';
    
  });

    const getNewsletterData = () => {
        agent
        .get(API.NEWSLETTER_DETAIL_DATA + "/" + slug, {})
        .then((res) => {
            setViewData(res.data.data);
        })
        .catch((err) => {
            console.log(err);
        });
    };

    const prepareHtml = (description) => {
        if (description) {
          return { __html: description || "" };
        }
        return { __html: "" };
    };

//   console.log('getViewData',getViewData);

  return (
    <div>
      <section className="">
         
        <div className="page_content newsletter-html">
          <Container>  
                      
            <div
              dangerouslySetInnerHTML={prepareHtml(
                getViewData.description
              )}
            ></div>

          </Container>
        </div>

      </section>
    </div>
  );
}


function mapStateToProps(state) {
  return {
    NewsletterData: state.NewsletterData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(ResourcesHubActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);

