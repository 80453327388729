import React from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Row, Col } from "react-bootstrap";
const Details = (props) => {
  const { userdata } = props;
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="mentor_detail">
            <Col md={4}>
              <div className="mentor_pic">
                <img src={userdata.image} alt="user" />
                <div className="mentor_name">
                  <h3>{userdata.full_name}</h3>
                  <h5>
                    <span>{userdata.position}</span>
                    {userdata.organization}
                  </h5>
                  <h5>
                    <span></span>
                    {userdata.main_area}
                  </h5>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <h2>About me</h2>
              <p  dangerouslySetInnerHTML={prepareHtml(userdata.about)}></p>
              <div className="profile_btn">
                <Link
                  to={{ pathname: userdata.linkedin }}
                  className="link_btn btn m-0"
                 
                  target="_blank"
                >
                  <img
                    src={
                      process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                    }
                    className="mr-2 bg-white"
                    alt="asdasd"
                  />
                  Linkedin Profile
                </Link>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Details;
