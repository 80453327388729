import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import * as API from "../utils/apiPath";
import { showToaster } from "../utils/helpers";

export function loadSettingDataSuccess(setting) {
  return { type: types.LOADED_SETTING_INFO_SUCCESS, setting };
}

export function loadBrandDataSuccess(brand) {
  return { type: types.LOADED_BRAND_DATA_SUCCESS, brand };
}

export const getSettingData = () => async (dispatch) => {
  try {
    const response = await agent.get(API.SETTING);
    dispatch(loadSettingDataSuccess(response.data.data));
  } catch (error) {
    showToaster(error.message);
  }
};

export const getBrandData = () => async (dispatch) => {
  try {
    const response = await agent.get(API.BRAND);
    dispatch(loadBrandDataSuccess(response.data.data));
  } catch (error) {
    showToaster(error.message);
  }
};
