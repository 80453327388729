import React from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import { useForm } from "react-hook-form";
import { setToaster } from "../../utils/helpers";

const Relationship = (props) => {
  const { endrelationship } = props;
  const { register, errors, handleSubmit } = useForm();
  const history = useHistory();
  const onSubmit = (data) => {
    const request = {};
    request.mentee_id = endrelationship.mentee_id;
    request.mentor_id = endrelationship.mentor_id;
    request.actionid = endrelationship.actionid;

    request.reason = data.reason;
    request.user_role = "Mentee";
    //actions.updateReason(request);
    setToaster(
      "Your have successfully sent request to admin for terminate relationship."
    );
    props.onHide();
    agent
      .post(API.LOAD_CUSTOMER + "/updateReason", request)
      .then((res) => {})
      .catch((err) => {
        setToaster("Something went wrong.Please try again.");
      });
    history.push("/my-mentors");
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Reason*</Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mentor_detail align-items-center">
              <Col sm={12}>
                {/* <label>Reason*</label> */}
                <Form.Control
                  as="textarea"
                  className={
                    errors.reason ? "form-control is-invalid" : "form-control"
                  }
                  name="reason"
                  defaultValue={endrelationship.mentor_reason}
                  aria-invalid={errors.reason ? "true" : "false"}
                  ref={register({
                    // maxLength: 600,
                    // required: true,
                    // pattern: /^ *$/,
                    required: {
                      value: true,
                      message: "Please enter reason",
                    },
                    // pattern: {
                    //   value: /^\s*\S.{0,10000}\S\s*$/,
                    //   message: "Please enter reason",
                    // },
                    validate: (value) => {
                      // var text = /^\s*\S.{0,10000}\S\s*$/;
                      // console.log(value.trim() == "", "value");
                      if (value.trim() == "") {
                        return "Please enter reason";
                      } else {
                        return true;
                      }
                    },
                  })}
                />
                {errors?.reason && errors?.reason?.message && (
                  <label className="customeValidationText text-left">
                    {errors?.reason?.message}
                  </label>
                )}
                {/* {errors.reason && errors.reason.type === "required" && (
                  <label className="customeValidationText text-left">
                    Please enter reason
                  </label>
                )}
                {errors.reason && errors.reason.type === "maxLength" && (
                  <label className="customeValidationText text-left">
                    reason should not more then 600 characters
                  </label>
                )} */}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="form-group mt-3 text-left">
                  <Button
                    className="btn loginsubmit nextbtn ml-2"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Relationship;
