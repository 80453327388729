import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import * as settingActions from "../../actions/settingActions";
import * as eventActions from "../../actions/eventActions";
import Slider from "react-slick";
import moment from "moment";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import { useLocation, useHistory, Link } from "react-router-dom";
import { showToasterWithOk } from "../../utils/helpers";
import { useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import { decode } from "html-entities";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

const Index = (props) => {
  const { actions, isAuth, userInfo } = props;
  const slug = props.match.params.slug;
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [getLoader, setLoader] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const [showOrganization, setShowOrganization] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //console.log(leaders);
  const [eventDetail, setEventDetail] = useState([]);
  const [likecount, setlikecount] = useState([]);

  useEffect(() => {
    //window.scrollTo(0, 0);
    document.title = eventDetail.title;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", eventDetail.title);
  });
  const getEventDetailsData = (page = 1) => {
    agent
      .get(API.EVENT_DETAIL + "/" + slug, {})
      .then((res) => {
        setEventDetail(res.data.data);
        setlikecount(res.data.data.likes_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEventDetailsData();
  }, [actions]);

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const PostResourceHub = async (e) => {
    e.preventDefault();
    if (isAuth === false) {
      showToasterWithOk(
        "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
      );
      history.push(`/login`);
    } else {
      window.location = "/post-resource-hub";
    }
  };
  const Like = async (e, resource_id, status) => {
    e.preventDefault();
    if (isAuth === false) {
      showToasterWithOk(
        "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
      );
      history.push(`/login`);
    }

    agent
      .get(
        API.LOAD_RESOURCES +
          "/resourceLike/" +
          resource_id +
          "/" +
          status +
          "/" +
          userInfo.id
      )
      .then((res) => {
        if (res.data.data === true) {
          // showToasterWithOk(res.data.message);
          setlikecount(res.data.message);
        }
        if (res.data.data === false) {
          showToasterWithOk(res.data.message);
        }
        //history.push("/community-details/" + community_slug);
        //setUserCurrentRelationship(res.data.data);
        //return res.data.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    ReactTooltip.show("Copied!");
  }

  const onSubmit = (data) => {
    setLoader(true);
    const request = {};
    if (!!data.full_name && !!data.email) {
      request.full_name = data.full_name;
      request.email = data.email;
      request.slug = data.slug;
      request.user_type = data.user_type;
      if (data?.organization) {
        request.organisation = data?.organization;
      }
      // setFormError(false);
      actions.handleSubmitRSVP(request, setShow, setLoader);
    } else {
      // setFormError(true);
    }
  };

  return (
    <Fragment>
      <section className="outer_main resourcehubouter">
        <Container>
          <div className="page_content_inner">
            <div className="page_heading px-4 mb-0">
              <h1>{eventDetail.title}</h1>
            </div>

            <div className="d-md-flex py-4 px-4">
              <div className="d-flex align-items-center">
                <div className="resource_name">
                  {eventDetail.publish_date && (
                    <small className="d-block date">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_IMAGE_PATH + "time.svg"
                        }
                      />{" "}
                      {/* {moment(new Date(eventDetail.publish_date)).format(
                        "Do MMM YY"
                      )} */}
                      {`${moment(new Date(eventDetail?.publish_date)).format(
                        "Do MMM YY"
                      )} ${eventDetail?.calender_start_date?.split("+")[1]} ${
                        eventDetail?.calender_start_date?.split("+")[2]
                      } - ${eventDetail?.calender_end_date?.split("+")[1]} ${
                        eventDetail?.calender_end_date?.split("+")[2]
                      } SGT`}
                    </small>
                  )}
                  {eventDetail.address && (
                    <small className="d-block date pt-2">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_IMAGE_PATH +
                          "location.svg"
                        }
                      />{" "}
                      {eventDetail.address}
                    </small>
                  )}
                </div>
              </div>

              <div className="ml-auto pt-2 pt-md-0 flex-wrap d-flex  align-items-center">
                {/* {userInfo.id!==eventDetail.user_id && (
              <a href="#" className="like_link"  onClick={(e) => {
                            Like(e, eventDetail.id,1);
                          }}><i className="far fa-thumbs-up pr-2"></i> {likecount} likes</a>
             )} */}

                <ul className="social_links d-flex px-2 px-sm-5">
                  <li>
                    <FacebookShareButton
                      url={window.location.href}
                      quote={eventDetail.title}
                    >
                      <i className="fab fa-facebook-square"></i>
                    </FacebookShareButton>
                  </li>
                  <li>
                    <TwitterShareButton
                      url={window.location.href}
                      title={eventDetail.title}
                    >
                      <i className="fab fa-twitter"></i>
                    </TwitterShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton
                      url={window.location.href}
                      title={eventDetail.title}
                    >
                      <i className="fab fa-linkedin"></i>
                    </LinkedinShareButton>
                  </li>
                  <li>
                    <EmailShareButton url={window.location.href}>
                      <i className="fas fa-envelope-square"></i>
                    </EmailShareButton>
                  </li>
                  <li>
                    <i
                      className="fas fa-link"
                      style={{ cursor: `pointer` }}
                      data-tip="Copied!"
                      data-event="click focus"
                    ></i>
                    <ReactTooltip
                      place="right"
                      type="success"
                      effect="float"
                      globalEventOff={"click"}
                      afterShow={copy}
                      backgroundColor="#ef4343"
                      textColor="#fff"
                    />
                  </li>
                </ul>

                {eventDetail?.publish_date ? (
                  moment(eventDetail?.publish_date).isBefore(moment(), "day") ==
                  false ? (
                    <>
                      <div title="Add to Calendar" className="addeventatc">
                        <i class="fa fa-plus"></i> Calendar
                        <span className="start">{eventDetail.start_date}</span>
                        <span className="end">
                          {!!eventDetail.end_date
                            ? eventDetail.end_date
                            : eventDetail.start_date}
                        </span>
                        <span className="timezone">{eventDetail.timezone}</span>
                        <span className="title">{eventDetail.title}</span>
                        <span className="description">
                          {decode(eventDetail.addevent_description, {
                            level: "html5",
                          })}
                        </span>
                        {/* <span className="description">{ decode( eventDetail.short_description + '<br><br>For more details view this ' + window.origin + "/event-detail/" + eventDetail.slug , {level: 'html5'}) }</span> */}
                        <span className="location">{eventDetail.address}</span>
                      </div>
                      <span
                        className="btn btn_login mt-3 ml-4 mt-sm-0"
                       
                        onClick={handleShow}
                      >
                        RSVP
                      </span>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>

            <img
              src={eventDetail.image}
              className="resources_detail_img"
              alt=""
            />

            <p className="m-0 pb-3 pb-md-5">
              <div
                dangerouslySetInnerHTML={prepareHtml(eventDetail.description)}
              ></div>
            </p>

            <div className="d-md-flex py-4 ">
              <div className="d-flex align-items-center">
                {/* {userInfo.id!==eventDetail.user_id && (
              <a href="#" style={{margin:`12px`}} className="like_link"  onClick={(e) => {
                            Like(e, eventDetail.id,1);
                          }}><i className="far fa-thumbs-up pr-2"></i> {likecount} likes</a>
             )} */}
                <ul className="social_links d-flex " style={{ padding: `0` }}>
                  <li>
                    <FacebookShareButton
                      url={window.location.href}
                      quote={eventDetail.title}
                    >
                      <i className="fab fa-facebook-square"></i>
                    </FacebookShareButton>
                  </li>
                  <li>
                    <TwitterShareButton
                      url={window.location.href}
                      title={eventDetail.title}
                    >
                      <i className="fab fa-twitter"></i>
                    </TwitterShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton
                      url={window.location.href}
                      title={eventDetail.title}
                    >
                      <i className="fab fa-linkedin"></i>
                    </LinkedinShareButton>
                  </li>
                  <li>
                    <EmailShareButton url={window.location.href}>
                      <i className="fas fa-envelope-square"></i>
                    </EmailShareButton>
                  </li>
                  <li>
                    <i
                      className="fas fa-link"
                      style={{ cursor: `pointer` }}
                      data-tip="Copied!"
                      data-event="click focus"
                    ></i>
                    <ReactTooltip
                      place="right"
                      type="success"
                      effect="float"
                      globalEventOff={"click"}
                      afterShow={copy}
                      backgroundColor="#ef4343"
                      textColor="#fff"
                    />
                  </li>
                </ul>
                {/* <div className="resource_user">
            <img src={eventDetail.user_image}  alt="" />
            </div>

            <div className="resource_name pl-3">
            <span className="d-block name">{eventDetail.user_name}</span>
            <small className="d-block date pt-1">{eventDetail.date}</small>
            </div>    */}
              </div>

              <div className="ml-auto pt-2 pt-md-0 flex-wrap d-flex  align-items-center">
                {/* <a href="#" className="like_link"><i className="far fa-thumbs-up pr-2"></i> 256 likes</a> */}

                {eventDetail.previous_slug && (
                  <a
                    className="btn btn_login mt-3 mt-sm-0"
                    href={"/event-detail/" + eventDetail.previous_slug}
                  >
                    Previous
                  </a>
                )}

                {eventDetail.next_slug && (
                  <a
                    className="btn btn_login mt-3 mt-sm-0 ml-5"
                    href={"/event-detail/" + eventDetail.next_slug}
                  >
                    Next
                  </a>
                )}

                {/* <a className="btn btn_login mt-3 mt-sm-0" href=""  onClick={(e) => {
                        PostResourceHub(e);
                      }}>Submit your write up</a> */}
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            className="rsvp_modal"
            centered
          >
            <Modal.Header closeButton>RSVP </Modal.Header>
            <Modal.Body className="arrow_icon thumbnail">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="rsvp_full_name" className="mb-3">
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    ref={register({
                      minLength: 3,
                      required: true,
                    })}
                    name="full_name"
                  />
                </Form.Group>
                {errors.full_name &&
                errors.full_name.type === "required" && (
                  <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                    Please enter Name
                  </label>
                )}
              {errors.full_name &&
                errors.full_name.type === "minLength" && (
                  <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                    Minimum length should be 3
                  </label>
                )}
              {errors.full_name && errors.full_name.type === "maxLength" && (
                <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                  Your name should contain max 50 characters
                </span>
              )}

                <Form.Group controlId="rsvp_email" className="mb-3">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control
                  type="text"
                  className="form-control"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                    maxLength: 50,
                  })}
                  name="email"
                />
                </Form.Group>
                {errors.email && errors.email.type === "required" && (
                <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                  Please enter your email address
                </span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                  Invalid email address
                </span>
              )}
              {errors.email && errors.email.type === "maxLength" && (
                <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                  Your email should contain max 50 characters
                </span>
              )}
                
                <Form.Group controlId="rsvp_user_type" className="mb-3">
                  <Form.Label>Are you a vLookUp user?*</Form.Label>
                  <Form.Control
                    as="select"
                    className="mb-3"
                    name="user_type"
                    ref={register({
                      required: true,
                    })}
                    onChange={(e) => {
                      e.target.value === 'None' ? setShowOrganization(true) : setShowOrganization(false);
                    }}
                  >
                  <option value="">Please select the category</option>
                    <option value="None">No</option>
                    <option value="Mentee">Mentee</option>
                    <option value="Mentor">Mentor</option>
                  </Form.Control>
                  {errors.user_type && errors.user_type.type === "required" && (
                  <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                    Please select category
                  </span>
                )}

                  {showOrganization && <Form.Group controlId="rsvp_organization" className="mb-3">
                  <Form.Label>Organization*</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    maxLength={150}
                    ref={register({
                      minLength: 3,
                      required: true,
                      maxLength: 150,
                    })}
                    name="organization"
                  />
                </Form.Group>}

                
                {errors.organization &&
                  errors.organization.type === "required" && (
                    <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                      Please enter Organization
                    </label>
                  )}
                {errors.organization &&
                  errors.organization.type === "minLength" && (
                    <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                      Minimum length should be 3
                    </label>
                  )}
                {errors.organization && errors.organization.type === "maxLength" && (
                  <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                    Organization name should contain max 50 characters
                  </span>
                )}

                  <Form.Control
                    type="hidden"
                    defaultValue={eventDetail.slug}
                    ref={register({})}
                    name="slug"
                  />
                </Form.Group>
                <Button
                  type="submit"
                  className="btn btn_submit ml-2"
                  align="center"
                  disabled={getLoader}
                >
                  {getLoader ? "Submitting.." : "Submit"}
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>
      </section>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    isAuth: state.isAuth,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(settingActions, eventActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
