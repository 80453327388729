import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as menteesRequestAction from "../../actions/menteesRequestAction";
import * as baseActions from "../../actions/baseActions";
import { Col } from "react-bootstrap";
import UserSidebar from "../../Component/UserSidebar";
import Slider from "react-slick";
import firebase from "../../Firebase";
import Moment from "moment";
import Details from "./details";
import { UCWords } from "../../utils/helpers";
import Relationship from "./relationship";
import { makeStyles } from "@material-ui/core/styles";
import DToolkit from "./dtoolkit";
import MentoringSession from "../../Component/MentoringSession";
import Feedback from "../MenteesLog/Feedback";
import ReportUser from "../Forum/reportUser";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import config from "../../config";
import NoImage from '../../assets/images/noImage.png'
import rainbow from '../../assets/images/rainbow.png'
import ScheduleMeetingModal from "./scheduleMeetingModal";
const queryString = require("query-string");

const Index = (props) => {
  const { title, actions, menteeRequestData, userInfo, pagination } = props;
  const [room, setRoom] = useState({ roomname: "" });
  const usersDatabase = firebase.database().ref("users/");
  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightBold,
    },
    cust_accordion: {
      borderLeft: "#0078ff 5px solid",
      borderTop: "#0078ff 1px solid",
    },
    description: {
      fontStyle: "italic",
      marginBottom: "20px",
      textAlign: "justify",
    },
  }));
  const classes = useStyles();

  var Mentors_details = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    unslick: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          unslick: false,
        },
      },
    ],
  };

  const [userData, setUserData] = useState({});
  const [endRelationship, setEndRelationship] = useState({});

  const writeReview = (e, mentee_id, mentor_id, review) => {
    e.preventDefault();
    setModalShow(true);
    setUserData({
      mentee_id: mentee_id,
      mentor_id: mentor_id,
      mentor_review: review,
    });
  };

  const endRelationshipModal = (e, mentee_id, mentor_id, review, actionid) => {
    e.preventDefault();
    setRelationModalShow(true);
    setEndRelationship({
      mentee_id: mentee_id,
      mentor_id: mentor_id,
      mentor_review: review,
      actionid: actionid,
    });
  };
  const [modalShow, setModalShow] = useState(false);
  const [relationModalShow, setRelationModalShow] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [sessionModel, setSessionModel] = useState(false);

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    const request = {};
    if (params.get("sort")) {
      var sort = queryStringParsed["sort"];
      var direction = queryStringParsed["direction"];
      request.sort = sort;
      request.direction = direction;
    }
    if (params.get("page")) {
      var page = queryStringParsed["page"];
      request.page = page;
    }
    if (params.get("keyword")) {
      var keyword = queryStringParsed["keyword"];
      request.keyword = keyword;
    }
    document.title = title;
    actions.loadData(request, 4);
  }, [title, actions, location]);

  const downloadResume = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (room.roomname) {
      history.push("/chat?roomKey=" + room.roomname);
    }
  }, [room, history]);

  const handleclickChat = (item) => {
    if (item.mentor_id > item.mentee_id) {
      var roomUser = item.mentor_id + "_" + item.mentee_id;
    } else {
      var roomUser = item.mentee_id + "_" + item.mentor_id;
    }

    //const roomUser = item.mentor_id + "_" + item.mentee_id;
    let time = Moment.utc().valueOf();
    console.log(item);
    const message = {};
    message.mentor_name = item.mentor_name;
    message.mentor_image = userInfo.image;
    message.mentor_id = item.mentor_id;
    message.mentee_name = item.mentee_name;
    message.mentee_image = item.mentee_image;
    message.mentee_id = item.mentee_id;
    message.device_type = "Web";
    message.message = "";
    message.key = roomUser;
    message.role_id = userInfo.role_id;
    message.status = 0;
    message.time = Moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    message.epoch = -1 * time;

    setRoom({ ...room, roomname: roomUser });

    let mentorDatabaseKey = "users/" + item.mentor_id + "/" + roomUser;
    usersDatabase.equalTo(mentorDatabaseKey).once("value", (snapshot) => {
      if (snapshot.exists()) {
      } else {
        const newUser = firebase.database().ref(mentorDatabaseKey);
        newUser.set(message);
      }
    });

    let menteedatabaseKey = "users/" + item.mentee_id + "/" + roomUser;
    usersDatabase.equalTo(menteedatabaseKey).once("value", (snapshot) => {
      if (snapshot.exists()) {
      } else {
        const newUser = firebase.database().ref(menteedatabaseKey);
        newUser.set(message);
      }
    });
  };

  const [toolkitData, setToolkitData] = useState({});

  const viewToolkit = (
    e,
    mentee_id,
    mentor_id,
    diagnostics,
    diagnostics_sdate
  ) => {
    e.preventDefault();
    setToolkitModalShow(true);
    setToolkitData({
      mentee_id: mentee_id,
      mentor_id: mentor_id,
      mentee_diagnostics: diagnostics,
      diagnostics_sdate: diagnostics_sdate,
    });
  };

  const [toolkitShow, setToolkitModalShow] = useState(false);
  const [modeldata, setModelData] = useState("");
  const [model, setModel] = useState(false);
  const [userData2, setUserData2] = useState({});
  const [scheduleUser, setScheduleUser] = useState({})
  const [reportModalShow, setReportModalShow] = useState(false);
  const viewReportModal = (e, item) => {
    e.preventDefault();
    setReportModalShow(true);
    setUserData2({ item: item, from: "user" });
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </span>
  ));

  const handleSchedule = (e, item) => {
    e.preventDefault();
    setScheduleModal(true)
    setScheduleUser(item)
  }
  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <h2 className="dash_heading">Current Mentees</h2>
          <div className="box_wrap shadow-sm bg-white mentor_box">
            <Slider {...Mentors_details}>
              {menteeRequestData &&
                menteeRequestData.map((item, key) => (
                  <Fragment>
                    {item.mentor_termination_status !== 2 &&
                      item.mentee_termination_status !== 2 && (
                        <div
                          className="mentro_details mentro_with_object mb-2 mb-md-4 d-flex  flex-wrap"
                          key={key}
                        >
                          <div className="mentro_details_leftbar">
                            <div className="mentor_pic">
                              <img
                                src={item.mentee_image || NoImage}
                                alt="profilePicture"
                              />
                            </div>
                            <div className="profile_btn mt-3">
                              <Link
                                to={{ pathname: item.mentee_linkedin }}
                                className="link_btn btn m-0"
                                target="_blank"
                              >
                                <img
                                  src="/assets/images/linkedin.png"
                                  className="mr-2 bg-white"
                                  alt="linkedin"
                                />
                                Linkedin Profile
                              </Link>
                            </div>
                            {item.mentee_diagnostics_sdate !== "0" && (
                              <div className="profile_btn mt-3">
                                <Link
                                  to=""
                                  className="link_btn btn btn_red m-0"
                                  onClick={(e) => {
                                    viewToolkit(
                                      e,
                                      item.mentee_id,
                                      item.mentor_id,
                                      item.mentee_diagnostics,
                                      item.mentee_diagnostics_sdate
                                    );
                                  }}
                                >
                                  <img
                                    src="/assets/images/notification_icon.png"
                                    alt="review_icon"
                                    className="mr-2 bg-white"
                                  />
                                  {"Self Assessment"}
                                </Link>
                              </div>
                            )}
                            <div className="extar_info_link">
                              <ul className="px-0 pt-2 mentor_log">
                                <li>
                                  {item?.mn_open_to_talk == 1 && (
                                    <Tooltip
                                      size="4xl"
                                      title={<h5>{config?.mn_open_to_talk}</h5>}
                                      describeChild
                                      arrow
                                    >
                                      <img
                                        src={
                                          process.env
                                            .REACT_APP_STATIC_IMAGE_PATH +
                                          "usermind.svg"
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </li>
                                <li>
                                  {item?.mn_loking_for_coach == 1 && (
                                    <Tooltip
                                      size="4xl"
                                      title={
                                        <h5>{config?.mn_loking_for_coach}</h5>
                                      }
                                      describeChild
                                      arrow
                                    >
                                      <img
                                        src={
                                          process.env
                                            .REACT_APP_STATIC_IMAGE_PATH +
                                          "useraward.svg"
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </li>
                                <li>
                                  {item?.mentor_lgbtq_discussion == 1 && (
                                    <Tooltip
                                      size="4xl"
                                      title={
                                        <h5>{config?.LGBTQ_discussion}</h5>
                                      }
                                      describeChild
                                      arrow
                                    >
                                      <img
                                        src={rainbow}
                                      />
                                    </Tooltip>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="mentro_details_sidebar">
                            <div className="row ">
                              <Col xl={4}>
                                <div className="d-flex ">
                                  <div className="name_info main_area_Details">
                                    <h4 className="mb-0">{item.mentee_name}</h4>
                                    <h5>
                                      <span className="d-block">
                                        {item.mentee_organization}
                                      </span>
                                    </h5>
                                    <h5>{item.mentee_position}</h5>
                                    <h5>{UCWords(item.mantee_main_area)}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl={8} className="">
                                <div className="current-mentees  profile_btn d-sm-flex  justify-content-xl-end ">

                                  <span
                                    className="link_btn btn btn_red"
                                    onClick={() => {
                                      setSessionModel(true);
                                      setModelData(item);
                                    }}
                                  >
                                    Mentoring Session
                                  </span>

                                  {item.mentee_review !== "" ? (
                                    <span
                                      className="link_btn btn btn_red"
                                      onClick={() => {
                                        setModel(true);
                                        setModelData(item);
                                      }}
                                    >
                                      {/* <img
                                    src="/assets/images/msg.png"
                                    alt="msg_icon"
                                    className="mr-2 bg-white"
                                  /> */}
                                      Feedback
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <span
                                    className="link_btn btn btn_red"
                                    onClick={() => handleclickChat(item)}
                                  >
                                    <img
                                      src="/assets/images/msg.png"
                                      alt="msg_icon"
                                      className="mr-2 bg-white"
                                    />
                                    Send Message
                                  </span>
                                  <Link
                                    to=""
                                    className="link_btn btn"
                                    onClick={(e) => {
                                      writeReview(
                                        e,
                                        item.mentee_id,
                                        item.mentor_id,
                                        item.mentor_review
                                      );
                                    }}
                                  >
                                    <img
                                      src="/assets/images/review_blue.png"
                                      alt="review_icon"
                                      className="mr-2 bg-white"
                                    />
                                    {item.mentor_review === "" &&
                                      "Write A Review"}
                                    {item.mentor_review !== "" &&
                                      "Update Review"}
                                  </Link>
                                  {(item.mentor_termination_status === 0 ||
                                    item.mentor_termination_status === "") && (
                                      <>
                                        <Link
                                          to=""
                                          className="link_btn btn"
                                          onClick={(e) => {
                                            endRelationshipModal(
                                              e,
                                              item.mentee_id,
                                              item.mentor_id,
                                              item.mentor_review,
                                              item.id
                                            );
                                          }}
                                        >
                                          <img
                                            src="/assets/images/review_blue.png"
                                            alt="review_icon"
                                            className="mr-2 bg-white"
                                          />
                                          Terminate the Relationship
                                        </Link>
                                        <Link
                                          to=""
                                          className="link_btn btn"
                                          onClick={(e) => {
                                            handleSchedule(e, item);
                                          }}
                                        >
                                          <img
                                            src="/assets/images/review_blue.png"
                                            alt="review_icon"
                                            className="mr-2 bg-white"
                                          />
                                          Schedule Meeting
                                        </Link>
                                      </>
                                    )}
                                  {(item.mentor_termination_status === 1 ||
                                    item.mentor_termination_status === 2) && (
                                      <Link
                                        to=""
                                        className="link_btn btn disabledCursor"
                                        onClick={(event) =>
                                          event.preventDefault()
                                        }
                                      >
                                        <img
                                          src="/assets/images/review_blue.png"
                                          alt="review_icon"
                                          className="mr-2 bg-white"
                                        />
                                        Terminate the Relationship
                                      </Link>
                                    )}
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      style={{ marginTop: "10px" }}
                                      size="sm"
                                      title=""
                                    >
                                      {/* <Dropdown.Header>Options</Dropdown.Header> */}
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          viewReportModal(e, item);
                                        }}
                                      >
                                        Report
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </Col>
                            </div>

                            <ul className="date_list py-2 py-md-4">
                              <li className="d-flex align-items-center">
                                <div className="date_icon">
                                  <img
                                    src="/assets/images/calender.png"
                                    alt="calender_image"
                                  />
                                </div>
                                <div className="date_content pl-2">
                                  <span className="d-block">
                                    Session start date
                                  </span>
                                  <span className="d-block date">
                                    {item.session_start_date}
                                  </span>
                                </div>
                              </li>

                              <li className="d-flex align-items-center">
                                <div className="date_icon">
                                  <img
                                    src="/assets/images/calender.png"
                                    alt="calendericon"
                                  />
                                </div>
                                <div className="date_content pl-2">
                                  <span className="d-block">
                                    Session end date
                                  </span>
                                  <span className="d-block date">
                                    {item.session_end_date}
                                  </span>
                                </div>
                              </li>
                              {item.mentee_resume && (
                                <li className="d-sm-flex align-items-center">
                                  <div className="date_icon">
                                    <img
                                      src="/assets/images/pdf.png"
                                      alt="download-icon"
                                    />
                                  </div>

                                  <div className="date_content pl-2">
                                    <Link
                                      to=""
                                      download
                                      className="d-block date color_red"
                                      onClick={(e) =>
                                        downloadResume(e, item.mentee_resume)
                                      }
                                      target="_blank"
                                    >
                                      {"Download Resume"}
                                    </Link>
                                  </div>
                                </li>
                              )}

                              <li className="d-flex align-items-center">
                                &nbsp;
                              </li>
                            </ul>
                            <div className="mentro_text">
                              <h3 className="mb-1">{item.mentee_objective ? 'Objectives' : null}</h3>
                              <ul>
                                {item.mentee_objective &&
                                  item.mentee_objective.map((item, key) => (
                                    <li>{item}</li>
                                  ))}
                              </ul>
                            </div>
                            <h3 className="mb-1">{item.mentee_objective ? 'About me' : null}</h3>

                            <div className="">
                              <p>{item.mentee_about}</p>
                            </div>
                          </div>
                        </div>
                      )}
                  </Fragment>
                ))}
            </Slider>
            {(menteeRequestData.length === 0 || pagination?.total === 0) && (
              <div className="text-center">No current mentees found.</div>
            )}
          </div>
        </div>
      </div>
      {toolkitShow && (
        <DToolkit
          show={toolkitShow}
          userdata={toolkitData}
          onHide={() => setToolkitModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {sessionModel && (
        <MentoringSession
          show={sessionModel}
          userInfo={userInfo}
          userdata={modeldata}
          actions={actions}
          onHide={() => setSessionModel(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {reportModalShow && (
        <ReportUser
          show={reportModalShow}
          userdata={userData2}
          onHide={() => setReportModalShow(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {scheduleModal && (
        <ScheduleMeetingModal
          show={scheduleModal}
          scheduleUser={scheduleUser}
          onHide={() => setScheduleModal(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {model && (
        <Feedback
          show={model}
          userdata={modeldata}
          onHide={() => setModel(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {modalShow && (
        <Details
          show={modalShow}
          userdata={userData}
          onHide={() => setModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}

      {relationModalShow && (
        <Relationship
          show={relationModalShow}
          endrelationship={endRelationship}
          onHide={() => setRelationModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    menteeRequestData: state.menteeRequestData,
    isAuth: state.isAuth,
    userInfo: state.userInfo,
    pagination: state.pagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(menteesRequestAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
