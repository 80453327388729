import React, { useState, useCallback, Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import Cropper from 'react-easy-crop';
import { getCroppedImgDetails } from './canvasUtils'

const ImageCropper = (props) => {
  const { cropImg, fileToImage } = props;
  // console.log('cropImg',cropImg);

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedImage, setCroppedImage] = useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [rotation, setRotation] = useState(0)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImgDetails(
        cropImg,
        croppedAreaPixels,
        rotation
      )
      // console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)
      fileToImage(croppedImage)
      props.onHide()
    } catch (e) {
      console.error(e)
    }
  }, [cropImg, croppedAreaPixels, rotation])

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>

        <div className="crop-container">
          <Cropper
            image={cropImg}
            crop={crop}
            zoom={zoom}
            aspect={16 / 9}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="controls">
          <Button
            onClick={showCroppedImage}
            variant="contained"
            color="primary"
          >
            Crop & Set
          </Button>
        </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageCropper;
