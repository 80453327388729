import React, { useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import { Link, useLocation, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as scheduleListingAction from "../../actions/scheduleListingAction";
import * as baseActions from "../../actions/baseActions";
import UserSidebar from "../../Component/UserSidebar";
import { Tooltip, Button, OverlayTrigger, Table, Row, Col, Card, Badge } from "react-bootstrap";
import Search from '../../Component/Search'
import Paginate from '../../Component/Paginate'
import ScheduleMeetingModal from "../MentorDashboard1/scheduleMeetingModal";
const queryString = require("query-string");


const Index = (props) => {
  const { title, actions, scheduleListingData } = props;

  const location = useLocation();
  const history = useHistory();

  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  useEffect(() => {
    document.title = title;
    if (props?.userInfo?.id) {
      actions.loadData({ user_id: props?.userInfo?.id });
    }
  }, [title]);

  const resetPage = () => {
    const params = new URLSearchParams(location.search);
    let page = 1;
    params.set("page", page);
    history.push({ pathname: `/schedule-meeting`, search: "?" + `page=1` });
  };

  const searchData = (data) => {
    const params = new URLSearchParams(location.search);
    if (data.keyword) {
      params.set("keyword", data.keyword);
    }
    if (data.from) {
      params.set("from", data.from);
    }
    if (data.to) {
      params.set("to", data.to);
    }
    let page = 1;
    params.set("page", page);
    history.push({
      pathname: "/schedule-meeting",
      search: "?" + params,
    });
  };

  const handleFormClick = (item, action) => {
    actions.loadFormPop(action);
  };

  const handleChange = (event, item) => {
    let params = item;
    if (props?.userInfo?.id === item?.creator_id) {
      params.status = event.target.checked ? "Yes" : "No";
    } else {
      params.status = event.target.checked ? "Yes" : "No";
    }
    actions.updateJoinStatus(params);
  }

  const handleCancelled = (item, status) => {
    actions.updateMeetingStatus(item, status);
  }

  const handlePageClick = (data) => {
    let page = data + 1;
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    history.push({
      pathname: "/schedule-meeting",
      search: "?" + params,
    });
  };

  const [modalShow, setModalShow] = useState(false)
  const [scheduleData, setScheduleData] = useState(false)


  const handleEdit = (item, ModalStatus) => {
    console.log(item)
    setScheduleData(item)
    setModalShow(ModalStatus)
  }
  

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    const request = {};
    if (params.get("sort")) {
      var sort = queryStringParsed["sort"];
      var direction = queryStringParsed["direction"];
      request.sort = sort;
      request.direction = direction;
    }
    if (params.get("page")) {
      var page = queryStringParsed["page"];
      request.page = page;
    }
    if (params?.get("keyword")) {
      var keyword = queryStringParsed["keyword"];
      request.keyword = keyword;
    }

    if (params.get("from")) {
      var from = queryStringParsed["from"];
      request.from = from;
    }

    if (params.get("to")) {
      var to = queryStringParsed["to"];
      request.to = to;
    }

    document.title = title;
    if (props?.userInfo?.id) {
      actions.loadData(request);
    }
  }, [title, actions, location]);

  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <h1 className="dash_heading">Schedule Meeting</h1>
          <Row className="pt-5">
            <Search
              onClick={resetPage}
              onSearch={searchData}
              show={false}
              date={true}
              title={"Search by user"}
              handleFormClick={handleFormClick}
            />
          </Row>
          <Row className="mt-5">
            <Col>
              <Card>
                <Card.Body>
                  <Paginate onClick={handlePageClick} />
                  {scheduleListingData?.length > 0 ?
                    <Table striped responsive>
                      <thead >
                        <tr>
                          <th>Creator Name</th>
                          <th>Receiver Name</th>
                          <th>Session Start Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Join Status</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {scheduleListingData?.map((item, ind) =>
                          <tr key={ind}>
                            <td scope="row">
                              {item?.creator_name}
                            </td>
                            <td scope="row">
                              {item?.receiver_name}
                            </td>
                            <td scope="row">
                              {item?.meeting_start_datetime}
                            </td>
                            <td scope="row">
                              {item?.meeting_start_time}
                            </td>
                            <td scope="row">
                              {item?.meeting_end_time}
                            </td>
                            <td>
                              <Switch
                                checked={props?.userInfo?.id === item?.creator_id ? (item.join_status_creator == "No" || item.join_status_creator == "" ? false : true) : (item.join_status_receiver == "No" || item.join_status_receiver == "" ? false : true)}
                                onChange={(e) => { handleChange(e, item) }}
                                name="status"
                                color="primary"
                                inputProps={{ "aria-label": "secondary checkbox" }}
                              />
                            </td>
                            <td scope="row">
                              <h5>
                                {item.mStatus == "Created" && (
                                  <Badge variant="primary">
                                    {item?.mStatus}
                                  </Badge>
                                )}
                                {item.mStatus == "Accepted" && (
                                  <Badge variant="success">
                                    {item?.mStatus}
                                  </Badge>
                                )}
                                {item.mStatus == "Rejected" && (
                                  <Badge variant="danger">
                                    {item?.mStatus}
                                  </Badge>
                                )}
                                {item.mStatus == "Rescheduled" && (
                                  <Badge variant="warning">
                                    {item?.mStatus}
                                  </Badge>
                                )}
                                {item.mStatus == "Cancelled" && (
                                  <Badge variant="danger">
                                    {item?.mStatus}
                                  </Badge>
                                )}
                              </h5>
                            </td>
                            <td scope="row">
                              {props?.userInfo?.id == item?.creator_id ? (
                                <>
                                  <Button
                                    variant="primary py-1 px-2 mr-2"
                                    onClick={() => handleEdit(item, true)}
                                  >
                                    <OverlayTrigger overlay={<Tooltip>Rescheduled</Tooltip>}>
                                      <i className="fas fa-edit mr-0"></i>
                                    </OverlayTrigger>
                                  </Button>
                                  <Button
                                    variant="primary py-1 px-2"
                                    onClick={() => handleCancelled(item, "Cancelled")}
                                  >
                                    <OverlayTrigger overlay={<Tooltip>Cancelled</Tooltip>}>
                                      <i className="fa fa-ban mr-0"></i>
                                    </OverlayTrigger>
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant="success py-1 px-2 mr-2"
                                    onClick={() => handleCancelled(item, "Accepted")}
                                  >
                                    <OverlayTrigger overlay={<Tooltip>Accepted</Tooltip>}>
                                      <i className="fa fa-thumbs-up mr-0"></i>
                                    </OverlayTrigger>
                                  </Button>
                                  <Button
                                    variant="danger py-1 px-2"
                                    onClick={() => handleCancelled(item, "Rejected")}
                                  >
                                    <OverlayTrigger overlay={<Tooltip>Rejected</Tooltip>}>
                                      <i className="fa fa-thumbs-down mr-0"></i>
                                    </OverlayTrigger>
                                  </Button>
                                </>
                              )}
                            </td>
                          </tr>)}
                      </tbody>
                    </Table>
                    : 'No meeting found'}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {modalShow && (
            <ScheduleMeetingModal
              show={modalShow}
              scheduleData={scheduleData}
              type="edit"
              onHide={() => setModalShow(false)}
              centered
              size="xl"
              className="mentor_modal"
            />
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    scheduleListingData: state.scheduleListingData,
    userInfo: state.userInfo,
    isAuth: state.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(scheduleListingAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);