import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function categoryUserReducer(
  state = initialState.categoriesUser,
  action
) {
  switch (action.type) {
    case types.LOAD_REPORT_CATEGORY_DATA_USER_SUCCESS:
      return action.categoriesUser;
    default:
      return state;
  }
}
