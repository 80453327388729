import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as menteesRequestAction from "../../actions/menteesRequestAction";
import * as baseActions from "../../actions/baseActions";
import UserSidebar from "../../Component/UserSidebar";
import FormDiagnostics from "./form";

const Index = (props) => {
  const { title } = props;  

  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  useEffect(() => {
    document.title = title;
  }, [title]);

  
  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <h1 className="dash_heading">My Diagnostic Toolkit</h1>
          <div className="box_wrap shadow-sm bg-white mentor_box">
            <FormDiagnostics />
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    menteeRequestData: state.menteeRequestData,
    userInfo: state.userInfo,
    isAuth: state.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(menteesRequestAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
