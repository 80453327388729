import React, { useState, useEffect } from "react";
import { UCWords } from "../../utils/helpers";
import { useLocation, useHistory, Link } from "react-router-dom";
import { LOAD_AREAS_LIST } from "../../utils/apiPath";
import { agent } from "../../utils/agent";
import { Tooltip } from "@material-ui/core";
import config from "../../config";
import rainbow from '../../assets/images/rainbow.png'

const queryString = require("query-string");

const Filter = (props) => {
  const [searchDomain, setSearchDomain] = useState("");
  const [mentalHealth, setMentalHealth] = useState("");
  const [certifiedCoach, setCertifiedCoach] = useState("");

  const [areas, setAreas] = useState([]);

  const getAreasData = (page = 1) => {
    agent
      .get(LOAD_AREAS_LIST, {})
      .then((res) => {
        setAreas(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const location = useLocation();
  console.log("🚀 ~ Filter ~ location:", location?.pathname)

  useEffect(() => {
    getAreasData();
  }, []);

  const[lgBtqDiscussion,setlgBtqDiscussion]=useState(0)

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    if (params.get("domains")) {
      var keyword = queryStringParsed["domains"];
      setSearchDomain(keyword);
    }
    if (params.get("mf_mental_health")) {
      var mf_mental_health = queryStringParsed["mf_mental_health"];
      setMentalHealth(mf_mental_health);
    }
    if (params.get("mf_certified_coach")) {
      var mf_certified_coach = queryStringParsed["mf_certified_coach"];
      setCertifiedCoach(mf_certified_coach);
    }

    if (params.get("lgbtq_discussion")) {
      var mf_certified_coach = queryStringParsed["lgbtq_discussion"];
      setMentalHealth (mf_certified_coach);
      setlgBtqDiscussion(mf_certified_coach)
    }
  }, [location]);

  const history = useHistory();
  const resetPage = (e) => {
    //e.preventDefault();
    history.push(location?.pathname);
    window.location.reload(false);
  };

  const [selectedDomain, setSelectedDomain] = useState([]);
  const setSelectedDomainArray = (e) => {
    const val = e.target.value;
    let cloneSelected = [...selectedDomain];
    if (selectedDomain.find((item) => item === val)) {
      cloneSelected = selectedDomain.filter((item) => item !== val);
    } else {
      cloneSelected.push(val);
    }
    setSelectedDomain(cloneSelected);
    // Set parameter for searching data
    const params = new URLSearchParams(location.search);
    params.set("domains", cloneSelected);
    if (params.get("page") > 1) {
      params.set("page", 1);
    }
    history.push({
      pathname: location?.pathname,
      search: "?" + params,
    });
  };

  const setSelectedHealth = (e, type) => {
    const val = e.target.checked;
    const params = new URLSearchParams(location.search);
    if (type == "mf_mental_health") {
      if (val == true) {
        params.set("mf_mental_health", 1);
        setMentalHealth(1);
      } else {
        params.set("mf_mental_health", "");
        setMentalHealth("");
      }
    }
    if (type == "mf_certified_coach") {
      if (val == true) {
        params.set("mf_certified_coach", 1);
        setCertifiedCoach(1);
      } else {
        params.set("mf_certified_coach", "");
        setCertifiedCoach("");
      }
    }
    if (type == "lgbtq_discussion") {
      if (val == true) {
        params.set("lgbtq_discussion", 1);
        setlgBtqDiscussion(1);
      } else {
        params.set("lgbtq_discussion", '');
        setlgBtqDiscussion("");
      }
    }

    
    // Set parameter for searching data

    history.push({
      pathname: location?.pathname,
      search: "?" + params,
    });
  };
  return (
    <div className="sidebar">
      <div className="sidebar_heading seprator">
        <h3 className="d-flex align-items-center">
          Filters
          <Link to="" onClick={resetPage} className="link ml-auto">
            {"Reset Filters"}
          </Link>
        </h3>
      </div>
      <div className="sidebar_toggle">
        <div className="seprator">
          <h6>Select Domain</h6>
          <ul>
            {areas &&
              areas.map((item, key) => (
                <li key={key}>
                  <span className="custom_check">
                    {UCWords(item.name)} &nbsp;{" "}
                    <input
                      value={item.id}
                      type="checkbox"
                      onClick={(e) => setSelectedDomainArray(e)}
                      defaultChecked={
                        searchDomain.indexOf(item.id.toString()) > -1
                          ? true
                          : false
                      }
                    ></input>
                    <span className="check_indicator">&nbsp;</span>
                  </span>
                </li>
              ))}
          </ul>
        </div>
        <div className="seprator health_check">
          {/* <h6>Select Organisation</h6> */}
          <ul>
            <li>
              <span className="custom_check">
                &nbsp;{" "}
                <input
                  value={mentalHealth ? 1 : 0}
                  type="checkbox"
                  onClick={(e) => setSelectedHealth(e, "mf_mental_health")}
                  defaultChecked={mentalHealth ? true : false}
                ></input>
                <span className="check_indicator">&nbsp;</span>
              </span>
              <Tooltip
                size="4xl"
                title={<h5>{config?.mf_mental_health}</h5>}
                describeChild
                arrow
              >
                <img
                  src={process.env.REACT_APP_STATIC_IMAGE_PATH + "usermind.svg"}
                />
              </Tooltip>{" "}
            </li>
            <li>
              <span className="custom_check">
                &nbsp;{" "}
                <input
                  type="checkbox"
                  value={certifiedCoach ? true : false}
                  onClick={(e) => setSelectedHealth(e, "mf_certified_coach")}
                  defaultChecked={certifiedCoach ? true : false}
                ></input>
                <span className="check_indicator">&nbsp;</span>
              </span>
              <Tooltip
                size="4xl"
                title={<h5>{config?.mf_certified_coach}</h5>}
                describeChild
                arrow
              >
                <img
                  src={
                    process.env.REACT_APP_STATIC_IMAGE_PATH + "useraward.svg"
                  }
                />
              </Tooltip>{" "}
            </li>

            <li>
              <span className="custom_check">
                &nbsp;{" "}
                <input
                  type="checkbox"
                  value={lgBtqDiscussion}
                  checked={lgBtqDiscussion}
                  onClick={(e) => setSelectedHealth(e, "lgbtq_discussion")}
                ></input>
                <span className="check_indicator">&nbsp;</span>
              </span>
              <Tooltip
                size="4xl"
                title={<h5>{config?.LGBTQ_discussion}</h5>}
                describeChild
                arrow
              >
                <img
                  src={rainbow}
                />
              </Tooltip>{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Filter;
