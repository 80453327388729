import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";
import { showToaster } from "../utils/helpers";

export function loadDataSuccess(mentorsData) {
  return { type: types.LOAD_MENTORS_DATA_SUCCESS, mentorsData };
}

export function loadpaginationDataSuccess(pagination) {
  return { type: types.LOAD_PAGINATION_DATA_SUCCESS, pagination };
}

export function UpdateUserInfo(userInfo) {
  return { type: types.LOADED_USER_INFO_SUCCESS, userInfo };
}

export function loadNotFoundDataSuccess(isData) {
  return { type: types.LOADED_DATA_SUCCESS, isData };
}

export const loadData =
  (request, user_id, role_id, domains, programmes) => async (dispatch) => {
    try {
      dispatch(toggleNetworkRequestStatus(true));
      const response = await agent.get(
        API.LOAD_MENTORS +
          "/" +
          user_id +
          "/" +
          role_id +
          "?userDomains=" +
          domains +
          "&programmes=" +
          programmes,
        {
          params: request,
        }
      );

      if (response.data.data.data.length <= 0) {
        dispatch(loadNotFoundDataSuccess(false));
        dispatch(toggleNetworkRequestStatus(false));
      } else {
        dispatch(loadNotFoundDataSuccess(true));
        dispatch(toggleNetworkRequestStatus(false));
      }
      if (response.data.data.data) {
        dispatch(loadDataSuccess(response.data.data.data));
        dispatch(loadpaginationDataSuccess(response.data.data.pagination));
        dispatch(toggleNetworkRequestStatus(false));
      }
    } catch (error) {
      showToaster(error.message);
      dispatch(toggleNetworkRequestStatus(false));
      dispatch(loadNotFoundDataSuccess(false));
    }
  };

export const getFilterData = () => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.GET_FILTER);
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
  }
};

export const saveSelectMentor = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.SAVESELECTMENTOR, params);
    dispatch(toggleNetworkRequestStatus(false));
    //loadData({ type: true })(dispatch);
    showToaster(response.data.message);
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    showToaster(error.message);
  }
};

export const saveWaitlistMentor = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.SAVEWAITLISTMENTOR, params);
    dispatch(toggleNetworkRequestStatus(false));
    //loadData({ type: true })(dispatch);
    showToaster(response.data.message);
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    showToaster(error.message);
  }
};

export function updateUserData() {
  return async function (dispatch) {
    await agent
      .get(API.UPDATEUSER, {})
      .then((response) => {
        if (response.status === 200) {
          dispatch(UpdateUserInfo(response.data.data));
        }
      })
      .catch((error) => {
        showToaster(error.message);
      });
  };
}
