import React, { useState, useEffect } from "react";
import UserSidebar from "../../Component/UserSidebar";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
import { useForm } from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import countryList from "react-select-country-list";
import { LOAD_ORGANIZATION_LIST } from "../../utils/apiPath";
import { agent } from "../../utils/agent";
import { showErrorToaster } from "../../utils/helpers";
import Button from "../../Component/Button";

import {
  Modal,
  Container,
  Row,
  Col,
  input,
  Input,
  Form,
  select,
  Dropdown,
} from "react-bootstrap";

const DeleteProfile = (props) => {
  const { title, actions, updateProfileData, userInfo, isAuth } = props;

  useEffect(() => {
    document.title = title;
    actions.getUserProfile(userInfo.id);
  }, [title, actions, userInfo]);

  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }

  const { register, errors, handleSubmit } = useForm();
  const [getLoader, setLoader] = useState(false);
  const [check, setCheck] = useState("yes");
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append(
      "delete_reason",
      check == "no" ? "No Reason" : data.delete_reason
    );
    actions.DeleteRequest(formData, updateProfileData.id);
  };

  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>

          <h1 className="dash_heading mb-3">Delete Profile</h1>
          <div className="box shadow bg-white p-4">
            <Row className="mentor_detail align-items-center">
              <Col sm={12}>
                <div className="faqouter">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Control
                      type="hidden"
                      name="id"
                      defaultValue={updateProfileData.id}
                      ref={register}
                    />
                    <Row>
                      <Col md={12}>
                        <Row className="align-items-center">
                          <Col sm={9}>
                            <Row>
                              <Col sm={12}>
                                <label>
                                  Dear vLookUp Member
                                  <br /> <br />
                                  I am sad to know that you have decided to
                                  delete your vLookUp profile. Please share your
                                  reason for deleting your profile. I would like
                                  to hear about your experience and reason for
                                  your decision so that I can create a better
                                  experience for our members. Let's know if I
                                  can do anything to enable you change your
                                  decision
                                  <br /> <br /> Thanks
                                  <br /> Kanchana Gupta
                                  <br /> Founder - vLookUp
                                  <br />{" "}
                                  <label className="customeValidationText">
                                    (1500 characters) *
                                  </label>
                                </label>
                                <Col sm={12}>
                                  <div className="form-group">
                                    {/* <label className="d-block">
                                      I am currently employed*
                                    </label> */}
                                    <span className="ml-md-3">
                                      <span className="custom_radio ml-0">
                                        I would you like to give the reason for
                                        deletion &nbsp;{" "}
                                        <input
                                          type="radio"
                                          name="employee"
                                          checked={
                                            check === "yes" ? "checked" : ""
                                          }
                                          value="yes"
                                          onClick={(e) =>
                                            setCheck(e.target.value)
                                          }
                                        />
                                        <span className="radio_indicator">
                                          &nbsp;
                                        </span>
                                      </span>
                                      <span className="custom_radio">
                                        I would not like to give the reason for
                                        the deletion &nbsp;{" "}
                                        <input
                                          type="radio"
                                          name="employee"
                                          checked={
                                            check === "no" ? "checked" : ""
                                          }
                                          value="no"
                                          // ref={register({
                                          //   required: true,
                                          // })}
                                          onClick={(e) =>
                                            setCheck(e.target.value)
                                          }
                                        />
                                        <span className="radio_indicator">
                                          &nbsp;
                                        </span>
                                        {/* {errors.organization &&
                                          errors.organization.type ===
                                            "required" && (
                                            <label className="invalid-feedback text-left">
                                              Please enter your
                                              organization/institute address
                                            </label>
                                          )} */}
                                      </span>
                                    </span>
                                  </div>
                                </Col>
                                {check == "yes" && (
                                  <Form.Control
                                    as="textarea"
                                    defaultValue={
                                      updateProfileData.delete_reason
                                    }
                                    className={
                                      errors.delete_reason
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="delete_reason"
                                    aria-invalid={
                                      errors.delete_reason ? "true" : "false"
                                    }
                                    ref={register({
                                      required:
                                        check == "yes"
                                          ? "Please enter delete reason."
                                          : false,
                                      maxLength: 1500,
                                    })}
                                  />
                                )}
                                {errors.delete_reason &&
                                  errors.delete_reason.type === "required" && (
                                    <label className="customeValidationText text-left">
                                      Please enter delete reason
                                    </label>
                                  )}
                                {errors.delete_reason &&
                                  errors.delete_reason.type === "maxLength" && (
                                    <label className="customeValidationText text-left">
                                      Delete reason should not more then 1500
                                      characters
                                    </label>
                                  )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={12}>
                        <Row>
                          <Col md={12}>
                            <div className="form-group mt-3 text-left">
                              <Button
                                title={"Submit"}
                                className={"btn w-10 loginsubmit"}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isData: state.isData,
    updateProfileData: state.updateProfileData,
    isLoad: state.isLoad,
    userInfo: state.userInfo,
    isAuth: state.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProfile);
