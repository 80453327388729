import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import Button from "../../../Component/Button";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../actions/userActions";
import logo from "../../../assets/images/logo.svg";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import { agent } from "../../../utils/agent";
import * as API from "../../../utils/apiPath";
import { showToaster } from "../../../utils/helpers";
const queryString = require("query-string");
const Index = (props) => {
  const { title, superUserParams } = props;
  const { register, errors, handleSubmit, watch } = useForm({
    defaultValues: superUserParams,
  });
  useEffect(() => {
   // document.title = title;
  }, [title]);

  const location = useLocation();
  const queryStringParsed = queryString.parse(location.search);
  var forgotString = queryStringParsed["validating_string"];
  const history = useHistory();
  const onSubmit = (data) => {
    data.forgot_password_validate_string = forgotString;
    agent
      .post(API.RESETPASSWORD, data)
      .then((res) => {
        showToaster(res.data.message);
        history.push("/login");

      })
      .catch((err) => {
        showToaster(err.message);
      });
  };
  const newPassword = watch('password');
  const conNewPassword = watch('password_confirmation')
  const conditionCheck = newPassword != conNewPassword && !errors.password_confirmation
  return (
    <>
      <section className="loginouter">
        <Container>
          <Row className="align-items-center ">
            <Col md={12}>
              <div className="logininnerouter">
                <div>
                  <div className="loginlogo text-center mb-5">
                    <Link to="/" className="logo m-0">
                      <img src="/assets/images/logo.svg" alt="logo" />
                    </Link>
                  </div>
                  <div className="loginform change_reset_password">
                    <h1>Reset Password</h1>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={12} sm={12}>
                          <div className="form-group">
                            <label>Password*</label>
                            <Form.Control
                              type="password"
                              className={
                                errors.password
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              name="password"
                              aria-invalid={errors.name ? "true" : "false"}
                              ref={register({
                                required: true,
                                minLength: 6,
                                pattern: {
                                  value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!()@$%^&*-]).{6,}$/i,
                                },
                              })}
                            />
                            {errors.password &&
                              errors.password.type === "required" && (
                                <label className="invalid-feedback text-left">
                                  Please enter your password
                                </label>
                              )}
                            {errors.password &&
                              errors.password.type === "minLength" && (
                                <label className="invalid-feedback text-left">
                                  Your password should contain atleast 6
                                  characters
                                </label>
                              )}
                            {errors.password &&
                              errors.password.type === "pattern" && (
                                <label className="invalid-feedback text-left">
                                  Password must have a minimum of 6 characters. It must be a combination of numerals, alphabet and special characters. Special characters don't include comma, underscores, full stop etc.
                                </label>
                              )}
                          </div>
                        </Col>

                        <Col md={12} sm={12}>
                          <div className="form-group ">
                            <label>Confirm Password*</label>
                            <Form.Control
                              type="password"
                              className={
                                errors.password_confirmation
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              name="password_confirmation"
                              aria-invalid={
                                errors.password_confirmation ? "true" : "false"
                              }
                              ref={register({
                                required: true,
                                minLength: 6,
                                pattern: {
                                  value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!()@$%^&*-]).{6,}$/i,
                                },
                              })}
                            />
                            {errors.password_confirmation &&
                              errors.password_confirmation.type ===
                                "required" && (
                                <label className="invalid-feedback text-left">
                                  Please enter your confirmation password
                                </label>
                              )}
                            {errors.password_confirmation &&
                              errors.password_confirmation.type ===
                                "minLength" && (
                                <label className="invalid-feedback text-left">
                                  Your password should contain atleast 6
                                  characters
                                </label>
                              )}
                            {errors.password_confirmation &&
                              errors.password_confirmation.type ===
                                "pattern" && (
                                <label className="invalid-feedback text-left">
                                  Your password should contain at-least 1
                                  Uppercase, 1 Lowercase, 1 Numeric and 1
                                  special character
                                </label>
                              )}
                               {conditionCheck ?  <label className="text-left" style={{color:'#dc3545', fontSize:'0.875em', marginTop:'0.25rem'}}>
                              New password and confirm password does not match
                            </label> : null}
                          </div>
                        </Col>

                        <Col md={12} sm={12}>
                          <div className="form-group mt-3">
                            <Button
                              title={"Submit"}
                              className={"btn w-100 loginsubmit"}
                            />
                          </div>
                        </Col>
                        {/* <Col md={12} className="text-center">
                          <Form.Group>
                            Don’t have an account yet?&nbsp;
                            <Link to="/register" className="text-danger">
                              Register
                            </Link>
                          </Form.Group>
                        </Col> */}
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    superUserParams: state.superUserParams,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
