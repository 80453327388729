import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingActions from "../../actions/settingActions";
import * as homePageActions from "../../actions/homePageActions";
import { Container } from "react-bootstrap";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Index = (props) => {
  const { actions, brand, homePageData, leaders } = props;

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  useEffect(() => {
    const fetchData = () => {
      actions.getBrandData();
      actions.loadHomePageData("organisations");
      actions.loadLeaderData(10);
    };
    fetchData();
  }, [actions]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = homePageData.title;
  });

  var leadersSilder = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  var Brand = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <section className="about_content_mian">
        <Container>
          <div className="about_content">
            <h1 className="title pb-2">{homePageData.title}</h1>
            <div
              dangerouslySetInnerHTML={prepareHtml(homePageData.description)}
            ></div>
          </div>
        </Container>
      </section>

      <section className="leader_sec">
        <Container>
          <div className="title text-center space-bottom">
            Know Featured Leaders{" "}
          </div>
          <Slider {...leadersSilder}>
            {leaders &&
              leaders.length &&
              leaders.map((item, key) => (
                <div key={key}>
                  <div className="leader_box text-center">
                    <div className="leader_img">
                      <img src={item.image} alt="career consultants for women" />
                      <div className="leader_info text-left">
                        <div>
                          <span className="name">{item.name}</span>
                          <span className="profile">{item.position}</span>
                          <span className="experience">{item.about}</span>
                        </div>
                      </div>
                    </div>
                    <span className="leader_title">{item.name}</span>
                  </div>
                </div>
              ))}
          </Slider>
        </Container>
      </section>

      <section className="brand_sec">
        <Container>
          <div className="title text-center space-bottom">
            Partnering Organisations and Institutions
          </div>
          <Slider {...Brand}>
            {brand &&
              brand.length &&
              brand.map((item, key) => (
                <div key={key}>
                  <div className="brand">
                    <img src={item.image} alt="professional consultants for women" />
                  </div>
                </div>
              ))}
          </Slider>
        </Container>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    setting: state.setting,
    brand: state.brand,
    leaders: state.leaders,
    homePageData: state.homePageData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(settingActions, homePageActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
