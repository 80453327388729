import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function brandReducer(state = initialState.brand, action) {
  switch (action.type) {
    case types.LOADED_BRAND_DATA_SUCCESS:
      return action.brand;
    default:
      return state;
  }
}
