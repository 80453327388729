import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
const Details = (props) => {
  const { userdata } = props;
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="mentor_detail align-items-center">
            <Col md={4}>
              <div className="mentor_pic">
                {userdata.image && <img src={userdata.image} alt="mentor" />}
                {!userdata.image && (
                  <img src="assets/images/mentor_women_two.jpg" alt="mentor" />
                )}
                <div className="mentor_name">
                  <h3>{userdata.first_name}</h3>
                  <h5>
                    <span>{userdata.position}</span>
                    {userdata.organization === "undefined"
                      ? "N/A"
                      : userdata.organization}
                  </h5>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <h2>About me</h2>
              <p>{userdata.about}</p>
              <div className="profile_btn">
                <span className="link_btn">
                  <img
                    src={
                      process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                    }
                    className="mr-2 bg-white"
                    alt="linkedin"
                  />{" "}
                  Linkedin Profile
                </span>
                {/* <Link
                  to=""
                  onClick={(e) => {
                    getMentor(e, item.id);
                  }}
                >
                  Select as Mentor
                </Link> */}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Details;
