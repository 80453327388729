import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import { useForm } from "react-hook-form";
import { setToaster } from "../../utils/helpers";
import { useSelector, useDispatch } from "react-redux";
import toggleNetworkRequestStatus from "../../actions/toggleNetworkRequestStatus";
import { loadData } from "../../actions/scheduleListingAction";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  TimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const ScheduleMeetingModal = (props) => {
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  var todayDate = new Date();
  todayDate.setMonth(todayDate.getMonth());
  todayDate = todayDate.toLocaleDateString();
  const { scheduleUser, scheduleData, type, updatePage } = props;
  const [timezone, setTimezone] = useState([])
  const [endTimeError, setEndTimeError] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const history = useHistory();
  const onSubmit = (data) => {
    dispatch(toggleNetworkRequestStatus(true));
    const request = {};
    if (type != "edit") {
      data.relation_id = scheduleUser?.id;
      request.relation_id = data.relation_id;
    } else {
      request.id = scheduleData?.id;
      data.relation_id = scheduleData?.relation_id;
      request.relation_id = data.relation_id;
    }
    request.subject = data.subject;
    request.description = data.description;
    request.meeting_start_time = data.meeting_start_time;
    request.meeting_timezone = data.meeting_timezone;
    request.meeting_end_time = data.meeting_end_time;
    request.meeting_date = data.meeting_date;
    if (type == "edit") {
      agent.post(API.LOAD_CUSTOMER + "/web-save-meeting", request).then((res) => {
        dispatch(toggleNetworkRequestStatus(false));
        setToaster(res.data.message);
        props.onHide();
        dispatch(loadData({}))
      }).catch((err) => {
        dispatch(toggleNetworkRequestStatus(false));
        setToaster("Something went wrong.Please try again.");
        dispatch(loadData({}))
      });
      if (updatePage == "calendar") {
        history.push("/schedule-meeting-calendar");
      } else {
        history.push("/schedule-meeting");
      }
    } else {
      agent.post(API.LOAD_CUSTOMER + "/web-save-meeting", request).then((res) => {
        dispatch(toggleNetworkRequestStatus(false));
        setToaster(res.data.message);
        props.onHide();
      }).catch((err) => {
        dispatch(toggleNetworkRequestStatus(false));
        setToaster("Something went wrong.Please try again.");
      });
      userInfo?.id === scheduleUser?.mentor_id ? history.push("/current-mentees") : history.push("/my-mentors");
    }
  };
  const [fromDate, handleFromDateChange] = useState(type == "edit" ? scheduleData?.meeting_date : todayDate);
  const [formObject, setFormObject] = useState(false);
  const checkFromDate = (date) => {
    handleFromDateChange(moment(new Date(date)).format("YYYY-MM-DD"));
    if (moment(new Date(date)).isValid() === false) {
      setFormObject(true);
    } else if (moment(new Date(date)).isValid()) {
      setFormObject(false);
    }
  };
  const currentTime = new Date();
  const [customTime, setCustomTime] = useState(type == "edit" ? new Date(scheduleData?.meeting_start_datetime) : currentTime)

  const checkTime = (selectTime) => {
    setCustomTime(selectTime)
  }


  useEffect(async () => {
    const response = await agent.get(API.LOAD_CALENDAR_TIMEZONE);
    if (response.status === 200) {
      setTimezone(response?.data?.data)
    }
  }, [])

  const valueTime = scheduleData?.meeting_timezone ? scheduleData?.meeting_timezone : ""
  const defaultTimezone = "Asia/Calcutta" ? "Asia/Kolkata" : Intl.DateTimeFormat().resolvedOptions().timeZone
  return (
    <div className="">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="mb-4 close-btn-modal">Schedule Meeting</Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mentor_detail align-items-center">
              <Col sm={12} className="mb-4">
                <label>Topic *</label>
                <Form.Control
                  type="text"
                  className={
                    errors.subject ? "form-control is-invalid" : "form-control"
                  }
                  defaultValue={type == "edit" ? scheduleData?.subject : ""}
                  name="subject"
                  ref={register({
                    required: true
                  })}
                />
                {errors.subject && errors.subject.type === "required" && (
                  <label className="customeValidationText text-left">
                    Please enter subject.
                  </label>
                )}
              </Col>
            </Row>
            <Row className="mentor_detail align-items-center">
              <Col sm={12} className="mb-4">
                <label>Description *</label>
                <Form.Control
                  as="textarea"
                  className={
                    errors.description ? "form-control is-invalid" : "form-control"
                  }
                  defaultValue={type == "edit" ? scheduleData?.description : ""}
                  name="description"
                  placeholder="Please use this section to share details of the proposed meeting and meetings links / zoom / Teams etc."
                  aria-invalid={errors.description ? "true" : "false"}
                  ref={register({
                    required: {
                      value: true,
                      message: "Please enter description.",
                    },
                    validate: (value) => {
                      if (value.trim() == "") {
                        return "Please enter description.";
                      } else {
                        return true;
                      }
                    },
                  })}
                />
                {errors?.description && errors?.description?.message && (
                  <label className="customeValidationText text-left">
                    {errors?.description?.message}
                  </label>
                )}
              </Col>
            </Row>
            <Row className="mentor_detail align-items-center">
              <Col sm={12} className="mb-4">
                <label>Timezone *</label>
                <select
                  name="meeting_timezone"
                  className={
                    errors.meeting_timezone ? "form-control is-invalid" : "form-control"
                  }
                  defaultValue={type == "edit" ? scheduleData?.meeting_timezone : ""}
                  ref={register({
                    required: {
                      value: true,
                      message: "Please select timezone.",
                    },
                    validate: (value) => {
                      if (value.trim() == "") {
                        return "Please select timezone.";
                      } else {
                        return true;
                      }
                    },
                  })}
                >
                  <option value="">Select Meeting Type</option>
                  {timezone.map((timezoneObject, index) => {
                    const key = Object.keys(timezoneObject)[0];
                    const value = timezoneObject[key];
                    return (
                      type == "edit" ? <option key={index} value={key} selected={valueTime == key ? "selected" : ""}>{value}</option> : <option key={index} value={key} selected={defaultTimezone == key ? "selected" : ""}>{value}</option>
                    );
                  })}

                </select>
                {errors?.meeting_timezone && errors?.meeting_timezone?.message && (
                  <label className="customeValidationText text-left">
                    {errors?.meeting_timezone?.message}
                  </label>
                )}
              </Col>
            </Row>
            <Row className="mentor_detail align-items-center mb-4">
              <Col sm={4} className="datepicker-metting">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    format="yyyy-MM-dd"
                    id="date-picker-from"
                    label="Meeting Date"
                    minDate={new Date()}
                    className={errors.fromDate ? "w-100 is-invalid" : "w-100"}
                    name="meeting_date"
                    aria-invalid={errors.fromDate ? "true" : "false"}
                    value={fromDate}
                    inputRef={register}
                    onChange={(date) => checkFromDate(date)}
                  />
                </MuiPickersUtilsProvider>
              </Col>
              <Col sm={4} className="datepicker-metting">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    id="date-picker-time"
                    label="Start Time"
                    className={
                      errors?.customTime ? "w-100 is-invalid" : "w-100"
                    }
                    name="meeting_start_time"
                    aria-invalid={errors?.customTime ? "true" : "false"}
                    inputRef={register}
                    onChange={(selectTime) => checkTime(selectTime)}
                    value={customTime}
                  />
                </MuiPickersUtilsProvider>
              </Col>
              <Col sm={4}>
                <label>Duration (In Hour) *</label>
                <select
                  name="meeting_end_time"
                  className={
                    errors.meeting_end_time ? "form-control is-invalid" : "form-control"
                  }
                  defaultValue={type == "edit" ? scheduleData?.meeting_end_time : ""}
                  ref={register({
                    required: {
                      value: true,
                      message: "Please select duration.",
                    },
                    validate: (value) => {
                      if (value.trim() == "") {
                        return "Please select duration.";
                      } else {
                        return true;
                      }
                    },
                  })}
                >
                  <option value="">Select Duration</option>
                  <option value="1" selected={scheduleData?.meeting_end_time == "1" ? "selected" : ""}>1</option>
                  <option value="2" selected={scheduleData?.meeting_end_time == "2" ? "selected" : ""}>2</option>
                  <option value="3" selected={scheduleData?.meeting_end_time == "3" ? "selected" : ""}>3</option>
                  <option value="4" selected={scheduleData?.meeting_end_time == "4" ? "selected" : ""}>4</option>
                  <option value="5" selected={scheduleData?.meeting_end_time == "5" ? "selected" : ""}>5</option>
                  <option value="5+" selected={scheduleData?.meeting_end_time == "5+" ? "selected" : ""}>More than 5</option>
                </select>
                {errors?.meeting_end_time && errors?.meeting_end_time?.message && (
                  <label className="customeValidationText text-left">
                    Please select duration.
                  </label>
                )}
              </Col>
            </Row>
            <Row className="mentor_detail align-items-center">
              {type == "edit" ? <Col md={12}>
                {userInfo && scheduleData && userInfo?.id == scheduleData?.creator_id ? (
                  <>
                    <label><b>{scheduleData?.receiver_mentor_role_id}</b></label>
                    <div className="rounder-img">
                      <img src={scheduleData?.receiver_image_path} />
                      <span>{scheduleData?.receiver_name}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <label><b>{scheduleData?.creator_mentor_role_id}</b></label>
                    <div className="rounder-img">
                      <img src={scheduleData?.creator_image_path} />
                      <span>{scheduleData?.creator_name}</span>
                    </div>

                  </>
                )}
              </Col> : <Col md={12}>
                {userInfo && scheduleUser && userInfo?.id === scheduleUser?.mentor_id ? (
                  <>
                    <label><b>Mentee</b></label>
                    <div className="rounder-img">
                      <img src={scheduleUser?.mentee_image} />
                      <span>{scheduleUser?.mentee_name}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <label><b>Mentor</b></label>
                    <div className="rounder-img">
                      <img src={scheduleUser?.mentors_image} />
                      <span>{scheduleUser?.mentor_name}</span>
                    </div>
                  </>
                )}
              </Col>
              }
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group mt-3 text-left">
                  <>
                    <Button
                      className="btn loginsubmit nextbtn ml-2"
                      type="submit"
                    >
                      {type == "edit" ? "Update" : "Submit"}
                    </Button>
                    <Button
                      className="btn loginsubmit nextbtn ml-2 transparent-btn-cancel"
                      onClick={() => {
                        props.onHide();
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body >
      </Modal >
    </div>
  );
};

export default ScheduleMeetingModal;
