import React, { useState,Fragment } from "react";
import { Modal, Button, Row, Col, Accordion, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// import { agent } from "../../utils/agent";
// import * as API from "../../utils/apiPath";
// import { useForm } from "react-hook-form";
// import { setToaster } from "../../utils/helpers";

const Details = (props) => {
  const { userdata } = props;
  // const { register, errors, handleSubmit } = useForm();
  // const history = useHistory();
  const [activeId, setActiveId] = useState(1);

  // const onSubmit = (data) => {
  //   const request = {};
  //   request.mentee_id = userdata.mentee_id;
  //   request.mentor_id = userdata.mentor_id;
  //   request.review = data.diagnostics;
  //   request.user_role = "Mentor";
  //   agent
  //     .post(API.LOAD_CUSTOMER + "/updateReview", request)
  //     .then((res) => {
  //       setToaster(res.data.message);
  //       props.onHide();
  //     })
  //     .catch((err) => {
  //       setToaster("Something went wrong.Please try again.");
  //     });
  //   history.push("/dashboard");
  // };
  // console.log(userdata);

  return (
    <Fragment>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <label>Self Assessment</label>
        </Modal.Header>
        <Modal.Body>
          <Row className="mentor_detail align-items-center">
            <Col sm={12}>
              <div className="faqouter">
                {userdata.diagnostics_sdate!=0 && 
                userdata.mentee_diagnostics &&
                userdata.mentee_diagnostics.map((item, key) => (
                <Accordion defaultActiveKey={1} className="myaccourdin">
                    
                  <Card key={key + 1}>
                    <Card.Header>
                      <Accordion.Toggle
                          as={Button}
                          className={activeId !== key + 1 && "faq_open"}
                          variant="link"
                          eventKey={key + 1}
                          onClick={() => setActiveId(key + 1)}
                      >
                      {item.question}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={key + 1}>
                      <Card.Body>
                        <div><i><sup>{item.question_desc}</sup></i></div>
                        <p><b>Answer:</b> {item.answer}</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                </Accordion>
                ))}
                {userdata.diagnostics_sdate == 0 && (
                  <div align="center">
                    <b>
                    {"No Data Found"}
                    </b>  
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Details;
