import React, { useEffect, useState } from "react";
import UserHeader from "../../Component/UserHeader";
import UserSidebar from "../../Component/UserSidebar";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
import Switch from "@material-ui/core/Switch";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  input,
  Input,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";

import { useForm } from "react-hook-form";

const UpdateProfile2 = (props) => {
  const { title, actions, updateProfileData, userInfo } = props;
  const history = useHistory();

  /* Form Method Handling */
  const [privacy, setPrivacy] = useState({
    about_privacy: false,
  });
  const handleChange = (event) => {
    setPrivacy({ ...privacy, [event.target.name]: event.target.checked });
  };

  const [text, setText] = useState('');
  const countWords = (text) => {
      return text?.length;  
  };
  
  useEffect(() => {
    if (updateProfileData) {
      setPrivacy({
        about_privacy: updateProfileData.about_privacy,
      });
    }
  }, [updateProfileData]);

  useEffect(() => {
    document.title = title;
    actions.getUserProfile(userInfo.id);
  }, [title, actions, userInfo]);

  const { register, errors, handleSubmit } = useForm();

  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }
  const [selectedResume, setSelectedResume] = useState("");
  const onChangeResume = (e) => {
    if (e.target.files[0]) {
      const selectedResume = e.target.files[0];
      setSelectedResume(selectedResume);
    }
  };
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("about", data.about);
    formData.append("about_privacy", privacy.about_privacy);
    formData.append("id", data.id);
    if (selectedResume.name) {
      formData.append("resume", selectedResume, selectedResume.name);
    }
    actions.updateStep2(formData, updateProfileData.id);
    history.push("/manage-profile-step3");
  };
  const downloadResume = (e, url) => {
    e.preventDefault();
   // window.location.href = url;
    window.open(url, "_blank");
  };
  return (
    <div>
      <UserHeader />
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <h2 className="page_heading mb-3">Edit Profile</h2>

          <div className="box shadow bg-white p-md-5">
            <div className="studentformdashboard pt-md-4">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Control
                  type="hidden"
                  name="id"
                  defaultValue={updateProfileData.id}
                  ref={register}
                />
                <Col md={12}>
                  <Row className="">
                    <Col sm={10}>
                      <Row>
                        <Col sm={12} className="">
                          <div className="form-group">
                            <h4 className="mb-3">
                            Tell us a little more about yourself (Min 300 and Max 1,500 characters)*
                            </h4>
                            <Form.Control
                              as="textarea"
                              onChange={(e) => setText(e.target.value)}
                              defaultValue={updateProfileData.about}
                              className={
                                errors.about
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              name="about"
                              aria-invalid={errors.about ? "true" : "false"}
                              ref={register({
                                required: "Please enter yourself.",
                                maxLength: 1500,
                              })}
                            />
                            <p>Characters count: {countWords(text)}</p>
                            {errors.about &&
                              errors.about.type === "required" && (
                                <label className="customeValidationText text-left">
                                  Please enter about yourself
                                </label>
                              )}
                            {errors.about &&
                              errors.about.type === "maxLength" && (
                                <label className="customeValidationText text-left">
                                  About should not more then 1500 characters
                                </label>
                              )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col sm={2} className="mt-md-5">
                      <div className="dropdownouter">
                        <Switch
                          checked={privacy.about_privacy}
                          onChange={handleChange}
                          name="about_privacy"
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                    </Col> */}
                  </Row>
                </Col>
                <Col md={12} lg={12} xl={6}>
                  <Row className="align-items-center">
                    <Col sm={9}>
                      <Row>
                        <Col sm={12}>
                          {updateProfileData.role_id === "Mentor" && (
                            <div className="form-group inputfileupload">
                              <label>Resume</label>
                              <input
                                type="file"
                                className="form-control"
                                placeholder="Upload resume (optional)"
                                onChange={onChangeResume}
                              ></input>
                              <span className="uploadfile">
                                <input type="file"></input>
                                Upload
                              </span>
                            </div>
                          )}
                          {updateProfileData.role_id === "Mentees" && (
                            <div className="form-group inputfileupload">
                              <label>Resume*</label>
                              <input
                                type="file"
                                name="resume"
                                className="form-control"
                                placeholder="Upload resume (optional)"
                                onChange={onChangeResume}
                                //ref={register({ required: true })}
                              ></input>

                              <span className="uploadfile">
                                <input type="file"></input>
                                Upload
                              </span>
                              {errors.resume &&
                                errors.resume.type === "required" && (
                                  <label className="customeValidationText text-left">
                                    Please upload resume
                                  </label>
                                )}
                            </div>
                          )}
                          <Link
                            to=""
                            download
                            className="d-block date color_red"
                            onClick={(e) =>
                              downloadResume(e, updateProfileData.resume)
                            }
                            target="_blank"
                          >
                            {"Download Resume"}
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} className="mt-md-5">
                  <Row>
                    <Col md={12}>
                      <div className="form-group mt-3 text-left">
                        <Button
                          className="btn loginsubmit nextbtn outline mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/manage-profile");
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          className="btn loginsubmit nextbtn ml-2"
                          type="submit"
                        >
                          Next
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isData: state.isData,
    updateProfileData: state.updateProfileData,
    isLoad: state.isLoad,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile2);
