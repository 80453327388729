import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../../Component/Button";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../actions/userActions";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import { agent } from "../../../utils/agent";
import * as API from "../../../utils/apiPath";
import { showToaster } from "../../../utils/helpers";

const Index = (props) => {
  const { title, superUserParams, isFrogot, actions, isSubmitting } = props;
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({
    defaultValues: superUserParams,
  });
  useEffect(() => {
    document.title = 'vLookUp - Reset your Password';
    document.querySelector('meta[name="description"]').setAttribute('content', "Trouble Logging In or Forgot your Password? Enter your email registered with vLookUp.ai and we'll send you a link to get back into your account.");
    if (isFrogot && !isSubmitting) {
      history.push({
        pathname: "/reset-password",
      });
    }
    window.scrollTo(0, 0);
  }, [title, isFrogot, history, isSubmitting]);

  const onSubmit = (data) => {
    actions.forgotPassword(data);
    // agent
    //   .post(API.FORGOTPASSWORD, data)
    //   .then((res) => {
    //     showToaster(res.data.message);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  return (
    <>
      <section className="loginouter">
        <Container>
          <Row className="align-items-center ">
            <Col md={12}>
              <div className="logininnerouter">
                <div>
                  <div className="loginlogo text-center mb-5">
                    <Link to="/" className="logo m-0">
                      
                      <img src="/assets/images/logo.svg" alt="logo" />
                    </Link>
                  </div>
                  <div className="loginform forgotPassword">
                    <h1>Forgot Password</h1>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={12} sm={12}>
                          <Form.Group controlId="formBasicEmail">
                            <FormControl
                              type="email"
                              placeholder="Email Address"
                              className={errors.email ? "formError" : ""}
                              name="email"
                              aria-invalid={errors.email ? "true" : "false"}
                              ref={register({
                                required: true,
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                },
                                maxLength: 50,
                              })}
                            />
                            {errors.email &&
                              errors.email.type === "required" && (
                                <span role="alert">
                                  Please enter your email address
                                </span>
                              )}
                            {errors.email &&
                              errors.email.type === "pattern" && (
                                <span role="alert">Invalid email address</span>
                              )}
                            {errors.email &&
                              errors.email.type === "maxLength" && (
                                <span role="alert">
                                  Your email should contain max 50 characters
                                </span>
                              )}
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <div className="form-group mt-3">
                            <Button
                              title={"Submit"}
                              className={"btn w-100 loginsubmit"}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="text-center">
                          <Form.Group>
                            Already have an account?&nbsp;
                            <Link to="/login" className="text-danger">
                              Login
                            </Link>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    superUserParams: state.superUserParams,
    isFrogot: state.isFrogot,
    isSubmitting: state.isSubmitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
