import React, { useState, useEffect } from "react";
import UserSidebar from "../../Component/UserSidebar";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
import { useForm } from "react-hook-form";
import Button from "../../Component/Button";
import CKEditor from "ckeditor4-react"
import {Modal,
  Container,
  Row,
  Col,
  input,
  Input,
  Form,
} from "react-bootstrap";

const Refer = (props) => {
  const { title, actions, updateProfileData, userInfo, isAuth } = props;
  const { register, errors, handleSubmit,setValue } = useForm();
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  var roleId;
  
  if(userInfo.role_id==='Mentees'){
    roleId='Mentee';
  }
  else{
    roleId=userInfo.role_id;
  }

var description_text= "<p>Hey [RECEIVER_NAME],</p><p>I wanted to share something exciting with you – vLookUp, an online mentoring platform designed specifically for aspiring women leaders. The mission is simple: break access barriers by connecting mentees with industry leaders and mentors from various organizations, fostering cross-organizational growth.</p><p>vLookUp offers a unique blend of:</p><ul><li> A robust network of mentors</li><li> AI-driven, curated one-on-one connections</li><li> Informal and flexible interactions that truly make a difference</li></ul><p>As a proud vLookUp member, I'm thrilled to extend an invitation to you to join this growing community.</p><p>Feel free to explore the platform at your convenience by <a href='https://www.vlookup.ai/' target='_blank'>following this link</a>. The registration process is a breeze - it should take you less than 15 minutes. Pro tip: Have a photo and the link to your LinkedIn profile handy for a seamless experience!</p><p>Looking forward to seeing you on the platform and witnessing the impactful connections you'll make.</p><p>Regards,</p><p>[SENDER_NAME]</p>";

  useEffect(() => {
    document.title = title;
    actions.getUserProfile(userInfo.id);
    setSubject("Introduction to vLookUp, a cross organisational mentoring platform for women leaders");
    register("description");
    setValue("description", description_text)
  }, [title, actions, userInfo, description_text]);

  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }

const [getLoader, setLoader] = useState(false);

const onSubmit = (data) => 
{
    actions.Refer(data,updateProfileData.id);
};

// console.log('description_text',description_text);
  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>

          <h1 className="dash_heading mb-3">Invite a {roleId}</h1>

          <div className="box shadow bg-white p-4">
            
            <div className="studentformdashboard">
    
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Control
                  type="hidden"
                  name="id"
                  defaultValue={updateProfileData.id}
                  ref={register}
                />
                <Row>

                  <Col md={12}>
                    <Row className="align-items-center">
                      <Col sm={12}>
                        <Row>
                        <Col md={12} sm={12}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            placeholder="Email Address*"
                            className={errors.email ? "formError" : ""}
                            name="email"
                            // value={email ? email : ""}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            aria-invalid={errors.email ? "true" : "false"}
                            ref={register({
                              required: true,
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              },
                              maxLength: 50,
                            })}
                          />
                          {errors.email &&
                            errors.email.type === "required" && (
                              <span role="alert">
                                Please enter your email address
                              </span>
                            )}
                          {errors.email &&
                            errors.email.type === "pattern" && (
                              <span role="alert">Invalid email address</span>
                            )}
                          {errors.email &&
                            errors.email.type === "maxLength" && (
                              <span role="alert">
                                Your email should contain max 50 characters
                              </span>
                            )}
                        </Form.Group>
                      </Col>
                      <Col md={12} sm={12}>
                        <Form.Group controlId="formBasicSubject">
                          <Form.Control
                            type="subject" 
                            placeholder="Enter Subject Line*"
                            defaultValue={subject}
                          //  disabled={true}
                            className={errors.subject ? "formError" : ""}
                            name="subject"
                            value={subject ? subject : ""}
                            onChange={(e) => {
                              setSubject(e.target.value);
                            }}
                            aria-invalid={errors.subject ? "true" : "false"}
                            ref={register({
                              required: true,
                              
                              maxLength: 250,
                            })}
                          />
                          {errors.subject &&
                            errors.subject.type === "required" && (
                              <span role="alert">
                                Please enter your subject
                              </span>
                            )}
                          {errors.subject &&
                            errors.subject.type === "maxLength" && (
                              <span role="alert">
                                Your subject should contain max 250 characters
                              </span>
                            )}
                        </Form.Group>
                      </Col>
                          <Col sm={12}>
                            
                            <CKEditor
                              data={description_text}
                              config={{
                                allowedContent: true,
                                height: 150,
                              }}
                              onChange={(evt) =>
                                setValue("description", evt.editor.getData())
                              }
                            />
                            {errors.description && errors.description.type === "required" && (
                              <label className="invalid-feedback text-left">
                                Please enter your description
                              </label>
                            )}
                          </Col>
                          
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12}>
                    <div className="form-group mt-3">
                      <Button
                        title={"Submit"}
                        className={"btn w-10 loginsubmit"}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
              
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isData: state.isData,
    updateProfileData: state.updateProfileData,
    isLoad: state.isLoad,
    userInfo: state.userInfo,
    isAuth: state.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Refer);
