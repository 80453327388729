import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/database"; // for realtime database
import "firebase/firestore"; // for cloud firestore
import "firebase/messaging"; // for cloud messaging
import "firebase/functions"; // for cloud functions

const config = {
  apiKey: "AIzaSyCZjI5PgOoxA088PkBMvRlV7QfBziSiics",
  authDomain: "vlookup-f5ebb.firebaseapp.com",
  databaseURL: "https://vlookup-f5ebb-default-rtdb.firebaseio.com",
  projectId: "vlookup-f5ebb",
  storageBucket: "vlookup-f5ebb.appspot.com",
  messagingSenderId: "261015493728",
  appId: "1:261015493728:web:c9986b540e86df48c3b705",
  measurementId: "G-SH7LXZWQ1M",
};
firebase.initializeApp(config);

export default firebase;
