import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ResourcesHubActions from "../../actions/ResourcesHubActions";
import placeholder from "../../assets/images/userprofile.png";
import CKEditor from "ckeditor4-react"
import { Container, Row, Col, Form,FormControl } from "react-bootstrap";
import Button from "../../Component/Button";
import * as API from "../../utils/apiPath";
import { blobToFile, showErrorToaster } from "../../utils/helpers";
import ImageCropper from "../../Component/ImageCropper/resourceHub";

import TextField from "@material-ui/core/TextField";
import moment from "moment";
const queryString = require("query-string");

const Index = (props) => {
  
  const location = useLocation();
  const { title, superUserParams, isAuth,actions ,userInfo} = props;
  const getUserId = localStorage.getItem("userId");
  const { register, errors, handleSubmit,setValue } = useForm({
    defaultValues: superUserParams,
  });
  const [{ alt, src }, setImg] = useState({
    src: '',
    alt: "Image size should be less than 2 MB",
  });

  useEffect(() => {
    document.title = title;
    register("description");

  }, [title]);
  const history = useHistory();
 
  const onSubmit = (data) => 
  {
      const formData = new FormData();
      formData.append("category_id", data.category_id);
      formData.append("title", data.title);
      formData.append("user_id", userInfo.id);
      if(data.description){
        formData.append("description", data.description);
      }
      if (selectedFile.name) {
        formData.append("image", selectedFile, selectedFile.name);
      }
      actions.PostResurcesHubData(formData);
  };

  const [ cropImg, setCropImg ] = useState("");
  const [cropImgModalShow, setCropImgModalShow] = useState(false);
  

  /* File upload */
  const [selectedFile, setSelectedFile] = useState("");
  const onChangePicture = (e) => {
  

    if (e.target.files[0]) {

      var size = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2); 

      if(size > 2) {
        alert('Please select image size less than 2 MB');
        setImg({
          src: '',
          alt: "Image size should be less than 2 MB"
        });
          
      }
      else if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
        showErrorToaster('Please select valid image.');
        setImg({
          src: placeholder,
          alt: "Upload an Image"
        });
      }
      else{

        const selectedFile = e.target.files[0];
        // setImg({
        //   src: URL.createObjectURL(e.target.files[0]),
        //   alt: e.target.files[0].name,
        // });
        // setSelectedFile(selectedFile);
        setCropImg(URL.createObjectURL(e.target.files[0]));
        setCropImgModalShow(true);
      
      }
      
    }
    else{
      setImg({
        src: '',
        alt: "Image size should be less than 2 MB"
      });
      
    }
  };

  const fileToImage = (childdata) => {
    //   console.log('childdata',childdata);
    // console.log('blobToFile',blobToFile(childdata, getUserId + '.png'))
    setSelectedFile(blobToFile(childdata, getUserId + '.png'));
    setImg({
      src: URL.createObjectURL(childdata),
      alt: '',
    });
  }

  return (
    <>
      <section className="loginouter">
        <Container>
          <Row className="align-items-center ">
            <Col md={12}>
              <div className="logininnerouter">
                <div>
                  
                  <div className="loginform" style={{width:`100%`}}>
                    <h1>Post Resources</h1>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                   
                      <Row>
                        <Col md="12">
                        <div className="form-group">

                          <select
                            name="category_id"
                            ref={register({
                              required: "select one category",
                            })}
                            className={
                              errors.category_id
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            
                          >
                            <option value="">Please Select Category*</option>
                            <option value="1">People Stories</option>
                            <option value="2">Points of view</option>
                            <option value="3">Toolkits</option>
                          </select>
                          {errors.category_id && (
                            <label className="invalid-feedback text-left">
                              Please select one option
                            </label>
                          )}
                          </div>
                        </Col>
                                  
                        <Col md={12}>
                        <div className="form-group">
                            <Form.Control
                              type="text"
                              
                              placeholder="Title*"
                              className={
                                errors.title
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              name="title"
                              aria-invalid={errors.title ? "true" : "false"}
                              ref={register({
                                required: true,
                                minLength: 3,
                                maxLength: 250,
                              })}
                            />
                            {errors.title &&
                              errors.title.type === "required" && (
                                <label className="invalid-feedback text-left">
                                  Please enter title
                                </label>
                              )}
                            {errors.title &&
                              errors.title.type === "minLength" && (
                                <label className="invalid-feedback text-left">
                                  Your platform title should contain atleast
                                  3 characters
                                </label>
                              )}
                            {errors.title &&
                              errors.title.type === "maxLength" && (
                                <label className="invalid-feedback text-left">
                                  Your title should contain max 20
                                  characters
                                </label>
                              )}
                          </div>
                        </Col>
                        <Col md={12}>
                        <div className="form-group">
                        <div className="userprofile pt-3 pb-5">
                        
                         <div>
                         <span className="inputfileouter">
                         Upload image{" "}
                            <input
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={onChangePicture}
                              className="form-control"
                              name="image"
                              ref={register({ required: true})}
                            />
                          </span>
                          { src && (
                            <>
                            <br/>
                            <br/>
                            <img
                              className="img-fluid"
                              src={src}
                              alt={alt}
                            />
                            </>
                          )}
                          <span style={{padding:`12px` , color: `red`}}>{alt}</span>
                               
                  {errors.image &&
                  errors.image.type === "required" && (
                    <label className="customeValidationText text-center">
                      Please select image size less than 2 MB
                    </label>
                  )}
                  {errors.image &&
                  errors.image.type === "maxLength" && (
                    <label className="customeValidationText text-center">
                      Please upload 2 mb file
                    </label>
                  )}
                </div>
              </div>
              </div>
              </Col>
              <Col md={12}>
                
              <Form.Group controlId="formBasicdescription">
              <Form.Label style={{color:`red`}}>Note : If  you are copying and pasting your content, 
              you may like to enable the source code before so that you can format the content
               as you wish. Please disable the source code once done and then click the submit button.</Form.Label>

                    <CKEditor
            
                      config={{
                        allowedContent: true,
                        height: 150,
                        filebrowserUploadUrl: process.env.REACT_APP_API_BASE_URL+'ckeditor_uploader'
                      }}
                      onChange={(evt) =>
                        setValue("description", evt.editor.getData())
                      }
                    />
                      {errors.description && errors.description.type === "required" && (
                        <label className="invalid-feedback text-left">
                          Please enter your description
                        </label>
                      )}
                      </Form.Group>
                      </Col>
                        
                        <Col md={12}>
                          <div className="form-group mt-3">
                          <Button
                              title={"Submit"}
                              className={"btn loginsubmit nextbtn ml-2"}
                              
                            />
                          
                         
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
		  {cropImgModalShow && (
        <ImageCropper
          show={cropImgModalShow}
          cropImg={cropImg}
		      fileToImage={fileToImage}
          onHide={() => setCropImgModalShow(false)}
          centered
          size="xl"
          className="cropper-modal"
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    isAuth: state.isAuth,

    //ResourcesHubData: state.ResourcesHubData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(ResourcesHubActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
