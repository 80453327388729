import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  input,
  Form,
  select,
  Button,
  Dropdown,
  Accordion,
  Card,
  Modal,
} from "react-bootstrap";
import Slider from "react-slick";
import UserHeader from "../../Component/UserHeader";
import UserSidebar from "../../Component/UserSidebar";
const Index = (props) => {
  const { title } = props;

  useEffect(() => {
    document.title = title;
  }, [title]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  function SidebarRemoveClass() {
    console.log("tset");
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }

  var Mentors_details = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    unslick: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          unslick: false,
        },
      },
    ],
  };
  return (
    <div>
      <UserHeader />

      <div className="dashboard_outer">
        <UserSidebar />

        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to="#"
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <h2 className="dash_heading">Dashboard</h2>

          <div className="box_wrap shadow-sm bg-white mentor_box">
            <h3>My Mentees</h3>

            <Slider {...Mentors_details}>
              <div className="mentro_details mb-2 mb-md-4 d-flex  flex-wrap">
                <div className="mentro_details_leftbar">
                  <div className="mentor_pic">
                    <img src="assets/images/mentor_women.jpg" alt="dummy" />
                  </div>
                  <div className="profile_btn mt-3 d-none d-sm-block">
                    <Link to="#" className="link_btn btn m-0">
                      <img
                        src={
                      process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                    }
                        className="mr-2 bg-white"
                        alt="linkedinimage"
                      />
                      Linkedin Profile
                    </Link>
                  </div>
                </div>

                <div className="mentro_details_sidebar">
                  <div className="row ">
                    <Col xl={4}>
                      <div className="d-flex ">
                        <div className="name_info">
                          <h4 className="mb-0">Aparajita Rao</h4>
                          <h5>
                            <span className="d-block">
                              Presales Complex Bid Support Specialist
                            </span>
                            Vodafone
                          </h5>
                        </div>

                        <Dropdown className="profile_mobile ml-auto d-block d-sm-none">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="profile_view_btn"
                          >
                            <i className="fas fa-ellipsis-v"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="profile_btn profile_dropedown d-block">
                            <span className="link_btn btn m-0 w-100">
                              <img
                                src={
                                  process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                                }
                                className="mr-2 bg-white"
                                alt=''
                              />{" "}
                              Linkedin Profile
                            </span>

                            <span
                              className="link_btn btn btn_red m-0 my-2 w-100"
                            >
                              <img
                                src={
                                  process.env.REACT_APP_STATIC_IMAGE_PATH + "msg.png"
                                }
                                className="mr-2 bg-white"
                                alt=''
                              />{" "}
                              Send Message
                            </span>

                            <span className="link_btn btn m-0 w-100">
                              <img
                              src={
                                process.env.REACT_APP_STATIC_IMAGE_PATH + "review_blue.png"
                              }
                              alt=''
                                className="mr-2 bg-white"
                              />{" "}
                              Write a review
                            </span>
                            <div className="d-flex align-items-center pt-2">
                              <div className="date_icon">
                              src={
                                process.env.REACT_APP_STATIC_IMAGE_PATH + "pdf.png"
                              }
                                
                              </div>
                              <div className="date_content pl-2">
                                <span className="d-block date color_red">
                                  Download Resume
                                </span>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>

                    <Col xl={8} className="">
                      <div className="profile_btn d-sm-flex  justify-content-xl-end d-none d-sm-block">
                        <span className="link_btn btn btn_red">
                          <img
                            src={
                              process.env.REACT_APP_STATIC_IMAGE_PATH + "msg.png"
                            }
                            alt=''
                            className="mr-2 bg-white"
                          />{" "}
                          Send Message
                        </span>
                        <span className="link_btn btn">
                          <img
                          
                            src="assets/images/review_blue.png"
                            className="mr-2 bg-white"
                            alt=''
                          />{" "}
                          Write a review
                        </span>
                      </div>
                    </Col>
                  </div>

                  <ul className="date_list py-2 py-md-4">
                    <li className="d-flex align-items-center">
                      <div className="date_icon">
                        <img src="assets/images/calender.png"
                                alt='' />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block">Session start date</span>
                        <span className="d-block date">24 January 2021</span>
                      </div>
                    </li>

                    <li className="d-flex align-items-center">
                      <div className="date_icon">
                        <img src="assets/images/calender.png"
                                alt='' />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block">Session start date</span>
                        <span className="d-block date">24 January 2021</span>
                      </div>
                    </li>

                    <li className="d-sm-flex align-items-center d-none d-sm-block">
                      <div className="date_icon">
                        <img src="assets/images/pdf.png"
                                alt='' />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block date color_red">
                          Download Resume
                        </span>
                      </div>
                    </li>

                    <li className="d-flex align-items-center">
                      <a
                        href="javascript:void(0);"
                        onClick={handleShow}
                        className="btn  d-none d-sm-block"
                      >
                        Schedule a meeting
                      </a>
                    </li>
                  </ul>

                  <h3 className="mb-1">About me</h3>

                  <div className="d-none d-md-block">
                    <p>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Nam eu nulla. Donec lobortis purus ignis vel urna unc...{" "}
                      <span className="color_red">
                        read more
                      </span>
                    </p>
                  </div>

                  <Accordion defaultActiveKey="0" className="d-md-none my-2">
                    <Card className="border-0">
                      <Card.Header className="p-0 bg-white border-0 ">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="1"
                          className="p-0 bg-white border-0 color_red "
                        >
                          Read more
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="p-0">
                          <p className="m-0">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Nam eu nulla. Donec lobortis purus ignis vel
                            urna unc...{" "}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <a
                    href="javascript:void(0);"
                    onClick={handleShow}
                    className="btn w-100 d-block d-sm-none"
                  >
                    Schedule a meeting
                  </a>
                </div>
              </div>

              <div className="mentro_details mb-2 mb-md-4 d-flex  flex-wrap">
                <div className="mentro_details_leftbar">
                  <div className="mentor_pic">
                    <img src="assets/images/mentor_women_two.jpg" alt="dummy" />
                  </div>
                  <div className="profile_btn mt-3 d-none d-sm-block">
                    <span className="link_btn btn m-0">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                        }
                        alt=''
                        className="mr-2 bg-white"
                      />
                      Linkedin Profile
                    </span>
                  </div>
                </div>

                <div className="mentro_details_sidebar">
                  <div className="row ">
                    <Col xl={4}>
                      <div className="d-flex ">
                        <div className="name_info">
                          <h4 className="mb-0">Jennifer Homer</h4>
                          <h5>
                            <span className="d-block">
                              Senior Talent Acquisition Manager{" "}
                            </span>
                            Standard Chartered
                          </h5>
                        </div>

                        <Dropdown className="profile_mobile ml-auto d-block d-sm-none">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="profile_view_btn"
                          >
                            <i className="fas fa-ellipsis-v"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="profile_btn profile_dropedown d-block">
                            <span className="link_btn btn m-0 w-100">
                              <img
                                src={
                                  process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                                }
                                alt=''
                                className="mr-2 bg-white"
                              />{" "}
                              Linkedin Profile
                            </span>

                            <span
                              className="link_btn btn btn_red m-0 my-2 w-100"
                            >
                              <img
                                src="assets/images/msg.png"
                                className="mr-2 bg-white"
                                alt=''
                              />{" "}
                              Send Message
                            </span>

                            <span className="link_btn btn m-0 w-100">
                              <img
                                src="assets/images/review_blue.png"
                                className="mr-2 bg-white"
                                alt=''
                              />{" "}
                              Write a review
                            </span>
                            <div className="d-flex align-items-center pt-2">
                              <div className="date_icon">
                                <img src="assets/images/pdf.png" />
                              </div>
                              <div className="date_content pl-2">
                                <span className="d-block date color_red">
                                  Download Resume
                                </span>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>

                    <Col xl={8} className="">
                      <div className="profile_btn d-sm-flex  justify-content-xl-end d-none d-sm-block">
                        <span className="link_btn btn btn_red">
                          <img
                            src="assets/images/msg.png"
                            className="mr-2 bg-white"
                          />{" "}
                          Send Message
                        </span>
                        <span className="link_btn btn">
                          <img
                            src="assets/images/review_blue.png"
                            className="mr-2 bg-white"
                          />{" "}
                          Write a review
                        </span>
                      </div>
                    </Col>
                  </div>

                  <ul className="date_list py-2 py-md-4">
                    <li className="d-flex align-items-center">
                      <div className="date_icon">
                        <img src="assets/images/calender.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block">Session start date</span>
                        <span className="d-block date">24 January 2021</span>
                      </div>
                    </li>

                    <li className="d-flex align-items-center">
                      <div className="date_icon">
                        <img src="assets/images/calender.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block">Session start date</span>
                        <span className="d-block date">24 January 2021</span>
                      </div>
                    </li>

                    <li className="d-sm-flex align-items-center d-none d-sm-block">
                      <div className="date_icon">
                        <img src="assets/images/pdf.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block date color_red">
                          Download Resume
                        </span>
                      </div>
                    </li>

                    <li className="d-flex align-items-center">
                      <a
                        href="javascript:void(0);"
                        onClick={handleShow}
                        className="btn  d-none d-sm-block"
                      >
                        Schedule a meeting
                      </a>
                    </li>
                  </ul>

                  <h3 className="mb-1">About me</h3>

                  <div className="d-none d-md-block">
                    <p>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Nam eu nulla. Donec lobortis purus ignis vel urna unc...{" "}
                      <span className="color_red">
                        read more
                      </span>
                    </p>
                  </div>

                  <Accordion defaultActiveKey="0" className="d-md-none my-2">
                    <Card className="border-0">
                      <Card.Header className="p-0 bg-white border-0 ">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="1"
                          className="p-0 bg-white border-0 color_red "
                        >
                          Read more
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="p-0">
                          <p className="m-0">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Nam eu nulla. Donec lobortis purus ignis vel
                            urna unc...{" "}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <a
                    href="javascript:void(0);"
                    onClick={handleShow}
                    className="btn w-100 d-block d-sm-none"
                  >
                    Schedule a meeting
                  </a>
                </div>
              </div>

              <div className="mentro_details mb-2 mb-md-4 d-flex  flex-wrap">
                <div className="mentro_details_leftbar">
                  <div className="mentor_pic">
                    <img
                      src="assets/images/mentor_women_three.jpg"
                      alt="dummy"
                    />
                  </div>
                  <div className="profile_btn mt-3 d-none d-sm-block">
                    <span className="link_btn btn m-0">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                        }
                        className="mr-2 bg-white"
                      />
                      Linkedin Profile
                    </span>
                  </div>
                </div>

                <div className="mentro_details_sidebar">
                  <div className="row ">
                    <Col xl={4}>
                      <div className="d-flex ">
                        <div className="name_info">
                          <h4 className="mb-0">Chandini Manoharan</h4>
                          <h5>
                            <span className="d-block">
                              Business Specialist (Commercial Transformation)
                            </span>
                            Telstra
                          </h5>
                        </div>

                        <Dropdown className="profile_mobile ml-auto d-block d-sm-none">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="profile_view_btn"
                          >
                            <i className="fas fa-ellipsis-v"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="profile_btn profile_dropedown d-block">
                            <span className="link_btn btn m-0 w-100">
                              <img
                                src={
                                  process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                                }
                                className="mr-2 bg-white"
                              />{" "}
                              Linkedin Profile
                            </span>

                            <span
                              
                              className="link_btn btn btn_red m-0 my-2 w-100"
                            >
                              <img
                                src="assets/images/msg.png"
                                className="mr-2 bg-white"
                              />{" "}
                              Send Message
                            </span>

                            <span className="link_btn btn m-0 w-100">
                              <img
                                src="assets/images/review_blue.png"
                                className="mr-2 bg-white"
                              />{" "}
                              Write a review
                            </span>
                            <div className="d-flex align-items-center pt-2">
                              <div className="date_icon">
                                <img src="assets/images/pdf.png" />
                              </div>
                              <div className="date_content pl-2">
                                <span className="d-block date color_red">
                                  Download Resume
                                </span>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>

                    <Col xl={8} className="">
                      <div className="profile_btn d-sm-flex  justify-content-xl-end d-none d-sm-block">
                        <span className="link_btn btn btn_red">
                          <img
                            src="assets/images/msg.png"
                            className="mr-2 bg-white"
                          />{" "}
                          Send Message
                        </span>
                        <span className="link_btn btn">
                          <img
                            src="assets/images/review_blue.png"
                            className="mr-2 bg-white"
                          />{" "}
                          Write a review
                        </span>
                      </div>
                    </Col>
                  </div>

                  <ul className="date_list py-2 py-md-4">
                    <li className="d-flex align-items-center">
                      <div className="date_icon">
                        <img src="assets/images/calender.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block">Session start date</span>
                        <span className="d-block date">24 January 2021</span>
                      </div>
                    </li>

                    <li className="d-flex align-items-center">
                      <div className="date_icon">
                        <img src="assets/images/calender.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block">Session start date</span>
                        <span className="d-block date">24 January 2021</span>
                      </div>
                    </li>

                    <li className="d-sm-flex align-items-center d-none d-sm-block">
                      <div className="date_icon">
                        <img src="assets/images/pdf.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block date color_red">
                          Download Resume
                        </span>
                      </div>
                    </li>

                    <li className="d-flex align-items-center">
                      <a
                        href="javascript:void(0);"
                        onClick={handleShow}
                        className="btn  d-none d-sm-block"
                      >
                        Schedule a meeting
                      </a>
                    </li>
                  </ul>

                  <h3 className="mb-1">About me</h3>

                  <div className="d-none d-md-block">
                    <p>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Nam eu nulla. Donec lobortis purus ignis vel urna unc...{" "}
                      <span className="color_red">
                        read more
                      </span>
                    </p>
                  </div>

                  <Accordion defaultActiveKey="0" className="d-md-none my-2">
                    <Card className="border-0">
                      <Card.Header className="p-0 bg-white border-0 ">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="1"
                          className="p-0 bg-white border-0 color_red "
                        >
                          Read more
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="p-0">
                          <p className="m-0">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Nam eu nulla. Donec lobortis purus ignis vel
                            urna unc...{" "}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <a
                    href="javascript:void(0);"
                    onClick={handleShow}
                    className="btn w-100 d-block d-sm-none"
                  >
                    Schedule a meeting
                  </a>
                </div>
              </div>

              <div className="mentro_details mb-2 mb-md-4 d-flex  flex-wrap">
                <div className="mentro_details_leftbar">
                  <div className="mentor_pic">
                    <img
                      src="assets/images/mentor_women_four.jpg"
                      alt="dummy"
                    />
                  </div>
                  <div className="profile_btn mt-3 d-none d-sm-block">
                    <span className="link_btn btn m-0">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                        }
                        className="mr-2 bg-white"
                      />
                      Linkedin Profile
                    </span>
                  </div>
                </div>

                <div className="mentro_details_sidebar">
                  <div className="row ">
                    <Col xl={4}>
                      <div className="d-flex ">
                        <div className="name_info">
                          <h4 className="mb-0">Claire Josephine O'Callaghan</h4>
                          <h5>
                            <span className="d-block">
                              Manager - Management Consulting
                            </span>
                            Ernst & Young
                          </h5>
                        </div>

                        <Dropdown className="profile_mobile ml-auto d-block d-sm-none">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="profile_view_btn"
                          >
                            <i className="fas fa-ellipsis-v"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="profile_btn profile_dropedown d-block">
                            <span className="link_btn btn m-0 w-100">
                              <img
                                src={
                                  process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                                }
                                className="mr-2 bg-white"
                              />{" "}
                              Linkedin Profile
                            </span>

                            <span
                              className="link_btn btn btn_red m-0 my-2 w-100"
                            >
                              <img
                                src="assets/images/msg.png"
                                className="mr-2 bg-white"
                              />{" "}
                              Send Message
                            </span>

                            <span className="link_btn btn m-0 w-100">
                              <img
                                src="assets/images/review_blue.png"
                                className="mr-2 bg-white"
                              />{" "}
                              Write a review
                            </span>
                            <div className="d-flex align-items-center pt-2">
                              <div className="date_icon">
                                <img src="assets/images/pdf.png" />
                              </div>
                              <div className="date_content pl-2">
                                <span className="d-block date color_red">
                                  Download Resume
                                </span>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>

                    <Col xl={8} className="">
                      <div className="profile_btn d-sm-flex  justify-content-xl-end d-none d-sm-block">
                        <span className="link_btn btn btn_red">
                          <img
                            src="assets/images/msg.png"
                            className="mr-2 bg-white"
                          />{" "}
                          Send Message
                        </span>
                        <span className="link_btn btn">
                          <img
                            src="assets/images/review_blue.png"
                            className="mr-2 bg-white"
                          />{" "}
                          Write a review
                        </span>
                      </div>
                    </Col>
                  </div>

                  <ul className="date_list py-2 py-md-4">
                    <li className="d-flex align-items-center">
                      <div className="date_icon">
                        <img src="assets/images/calender.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block">Session start date</span>
                        <span className="d-block date">24 January 2021</span>
                      </div>
                    </li>

                    <li className="d-flex align-items-center">
                      <div className="date_icon">
                        <img src="assets/images/calender.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block">Session start date</span>
                        <span className="d-block date">24 January 2021</span>
                      </div>
                    </li>

                    <li className="d-sm-flex align-items-center d-none d-sm-block">
                      <div className="date_icon">
                        <img src="assets/images/pdf.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block date color_red">
                          Download Resume
                        </span>
                      </div>
                    </li>

                    <li className="d-flex align-items-center">
                      <a
                        href="javascript:void(0);"
                        onClick={handleShow}
                        className="btn  d-none d-sm-block"
                      >
                        Schedule a meeting
                      </a>
                    </li>
                  </ul>

                  <h3 className="mb-1">About me</h3>

                  <div className="d-none d-md-block">
                    <p>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Nam eu nulla. Donec lobortis purus ignis vel urna unc...{" "}
                      <span className="color_red">
                        read more
                      </span>
                    </p>
                  </div>

                  <Accordion defaultActiveKey="0" className="d-md-none my-2">
                    <Card className="border-0">
                      <Card.Header className="p-0 bg-white border-0 ">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="1"
                          className="p-0 bg-white border-0 color_red "
                        >
                          Read more
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="p-0">
                          <p className="m-0">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Nam eu nulla. Donec lobortis purus ignis vel
                            urna unc...{" "}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <a
                    href="javascript:void(0);"
                    onClick={handleShow}
                    className="btn w-100 d-block d-sm-none"
                  >
                    Schedule a meeting
                  </a>
                </div>
              </div>

              <div className="mentro_details mb-2 mb-md-4 d-flex  flex-wrap">
                <div className="mentro_details_leftbar">
                  <div className="mentor_pic">
                    <img
                      src="assets/images/mentor_women_five.jpg"
                      alt="dummy"
                    />
                  </div>
                  <div className="profile_btn mt-3 d-none d-sm-block">
                    <span className="link_btn btn m-0">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                        }
                        className="mr-2 bg-white"
                      />
                      Linkedin Profile
                    </span>
                  </div>
                </div>

                <div className="mentro_details_sidebar">
                  <div className="row ">
                    <Col xl={4}>
                      <div className="d-flex ">
                        <div className="name_info">
                          <h4 className="mb-0">Jessica Liao</h4>
                          <h5>
                            <span className="d-block">
                              Regional Talent Development Manager
                            </span>
                            Shanghai Tunnel Engineering Co.
                          </h5>
                        </div>

                        <Dropdown className="profile_mobile ml-auto d-block d-sm-none">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="profile_view_btn"
                          >
                            <i className="fas fa-ellipsis-v"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="profile_btn profile_dropedown d-block">
                            <span className="link_btn btn m-0 w-100">
                              <img
                                src={
                                  process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                                }
                                className="mr-2 bg-white"
                              />{" "}
                              Linkedin Profile
                            </span>

                            <span
                              
                              className="link_btn btn btn_red m-0 my-2 w-100"
                            >
                              <img
                                src="assets/images/msg.png"
                                className="mr-2 bg-white"
                              />{" "}
                              Send Message
                            </span>

                            <span className="link_btn btn m-0 w-100">
                              <img
                                src="assets/images/review_blue.png"
                                className="mr-2 bg-white"
                              />{" "}
                              Write a review
                            </span>
                            <div className="d-flex align-items-center pt-2">
                              <div className="date_icon">
                                <img src="assets/images/pdf.png" />
                              </div>
                              <div className="date_content pl-2">
                                <span className="d-block date color_red">
                                  Download Resume
                                </span>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>

                    <Col xl={8} className="">
                      <div className="profile_btn d-sm-flex  justify-content-xl-end d-none d-sm-block">
                        <span className="link_btn btn btn_red">
                          <img
                            src="assets/images/msg.png"
                            className="mr-2 bg-white"
                          />{" "}
                          Send Message
                        </span>
                        <span className="link_btn btn">
                          <img
                            src="assets/images/review_blue.png"
                            className="mr-2 bg-white"
                          />{" "}
                          Write a review
                        </span>
                      </div>
                    </Col>
                  </div>

                  <ul className="date_list py-2 py-md-4">
                    <li className="d-flex align-items-center">
                      <div className="date_icon">
                        <img src="assets/images/calender.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block">Session start date</span>
                        <span className="d-block date">24 January 2021</span>
                      </div>
                    </li>

                    <li className="d-flex align-items-center">
                      <div className="date_icon">
                        <img src="assets/images/calender.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block">Session start date</span>
                        <span className="d-block date">24 January 2021</span>
                      </div>
                    </li>

                    <li className="d-sm-flex align-items-center d-none d-sm-block">
                      <div className="date_icon">
                        <img src="assets/images/pdf.png" />
                      </div>
                      <div className="date_content pl-2">
                        <span className="d-block date color_red">
                          Download Resume
                        </span>
                      </div>
                    </li>

                    <li className="d-flex align-items-center">
                      <a
                        href="javascript:void(0);"
                        onClick={handleShow}
                        className="btn  d-none d-sm-block"
                      >
                        Schedule a meeting
                      </a>
                    </li>
                  </ul>

                  <h3 className="mb-1">About me</h3>

                  <div className="d-none d-md-block">
                    <p>
                      {" "}
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Nam eu nulla. Donec lobortis purus ignis vel urna unc...{" "}
                      <span className="color_red">
                        read more
                      </span>
                    </p>
                  </div>

                  <Accordion defaultActiveKey="0" className="d-md-none my-2">
                    <Card className="border-0">
                      <Card.Header className="p-0 bg-white border-0 ">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="1"
                          className="p-0 bg-white border-0 color_red "
                        >
                          Read more
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="p-0">
                          <p className="m-0">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Nam eu nulla. Donec lobortis purus ignis vel
                            urna unc...{" "}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <a
                    href="javascript:void(0);"
                    onClick={handleShow}
                    className="btn w-100 d-block d-sm-none"
                  >
                    Schedule a meeting
                  </a>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="xl"
        className="mentor_modal schedule_popup"
      >
        <Button className="close" onClick={handleClose}>
          <img src="assets/images/close.png" />
        </Button>
        <Modal.Body>
          <h2>Schedule a meeting</h2>

          <Row className="meeting_form pt-md-3">
            <Col sm={12}>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Topic</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter meeting topic"
                />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Time Zone</Form.Label>
                <select className="custom-select form-control">
                  <option>(GMT - 08:00) Pacific Time (US and Canada)</option>
                </select>
              </Form.Group>
            </Col>

            <Col sm={6} md={5}>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Thursday January 7, 2021"
                  className="color_black"
                />
                <div className="incrase_div">
                  <span className="calender_btn">
                    <img src="assets/images/calender_white.png" />
                  </span>
                </div>
              </Form.Group>
            </Col>

            <Col xs={6} sm={3}>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="3:00 PM"
                  className="color_black"
                />
                <div className="incrase_div">
                  <span className="incrase_btn up">
                    <img src="assets/images/custom_select.svg" />
                  </span>
                  <span className="incrase_btn down">
                    <img src="assets/images/custom_select.svg" />
                  </span>
                </div>
              </Form.Group>
            </Col>

            <Col xs={6} sm={3}>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>End Time</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="3:00 PM"
                  className="color_black"
                />

                <div className="incrase_div">
                  <span className="incrase_btn up">
                    <img src="assets/images/custom_select.svg" />
                  </span>
                  <span className="incrase_btn down">
                    <img src="assets/images/custom_select.svg" />
                  </span>
                </div>
              </Form.Group>
            </Col>

            <Col sm={12} className="py-2 py-md-4">
              <span className="meet_user_title d-block pb-3">Mentee</span>
              <div className="meet_user ">
                <img src="assets/images/mentor_women.jpg" alt="dummy" />
                Aparajita Rao
              </div>
            </Col>

            <Col sm={12}>
              <div className="d-flex py-2 pt-md-4 pb-md-3">
                <span className="btn mr-3">
                  Schedule
                </span>
                <span className="btn btn_cancel">
                  Cancel
                </span>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
    </div>
  );
};

export default Index;
