import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import { useForm } from "react-hook-form";
import { setToaster } from "../../utils/helpers";

const FeedbackMentor = (props) => {
  const { userdata } = props;
  const { register, errors, handleSubmit } = useForm();
  const history = useHistory();
  const onSubmit = (data) => {
    const request = {};
    request.mentee_id = userdata.mentee_id;
    request.mentor_id = userdata.mentor_id;
    request.review = data.review;
    request.user_role = "Mentor";
    agent
      .post(API.LOAD_CUSTOMER + "/updateReview", request)
      .then((res) => {
        setToaster(res.data.message);
        props.onHide();
      })
      .catch((err) => {
        setToaster("Something went wrong.Please try again.");
      });
    history.push("/mentees-logs");
  };
  console.log(userdata, "user");
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Feedback</Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mentor_detail align-items-center">
              <Col sm={12}>
                <p>{userdata.mentor_review}</p>
                {/* <Form.Control
                  as="textarea"
                  className={
                    errors.review ? "form-control is-invalid" : "form-control"
                  }
                  name="review"
                  defaultValue={userdata.mentor_review}
                  aria-invalid={errors.review ? "true" : "false"}
                  ref={register({
                    maxLength: 600,
                  })}
                />
                {errors.review && errors.review.type === "required" && (
                  <label className="customeValidationText text-left">
                    Please enter review
                  </label>
                )}
                {errors.review && errors.review.type === "maxLength" && (
                  <label className="customeValidationText text-left">
                    Review should not more then 600 characters
                  </label>
                )} */}
              </Col>
            </Row>

            {/* <Row>
              <Col md={12}>
                <div className="form-group mt-3 text-left">
                  <Button
                    className="btn loginsubmit nextbtn ml-2"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row> */}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FeedbackMentor;
