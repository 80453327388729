import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import submittingRequestStatus from "./submittingRequestStatusAction";
import * as API from "../utils/apiPath";
import { setToaster } from "../utils/helpers";
import { showToasterWithOk } from "../utils/helpers";

export function loadHomePageDataSuccess(homePageData) {
  return { type: types.LOAD_HOME_PAGE_DATA_SUCCESS, homePageData };
}

export function loadFaqDataSuccess(faqData) {
  return { type: types.LOAD_FAQ_DATA_SUCCESS, faqData };
}

export function loadLeaderDataSuccess(leaders) {
  return { type: types.LOAD_LEADER_DATA_SUCCESS, leaders };
}

export function loadTestimonialsDataSuccess(testimonials) {
  return { type: types.LOAD_TESTIMONIALS_DATA_SUCCESS, testimonials };
}

export function loadCommunitiesDataSuccess(community) {
  return { type: types.LOAD_COMMUNITY_DATA_SUCCESS, community };
}

export function loadTeamsDataSuccess(teams) {
  return { type: types.LOAD_TEAMS_DATA_SUCCESS, teams };
}

export function loadAdvisorsDataSuccess(advisors) {
  return { type: types.LOAD_ADVISORS_DATA_SUCCESS, advisors };
}

export function loadHomePageData(id) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(API.CMS_PAGE + id)
      .then((response) => {
        dispatch(toggleNetworkRequestStatus(false));
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadHomePageDataSuccess(response.data.data));
            loadAdvisorsData({ type: true })(dispatch);
            loadTeamsData({ type: true })(dispatch);
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function loadFaqData(params) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(API.FAQ_PAGE,{params:params})
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadFaqDataSuccess(response.data.data));
          }
          dispatch(toggleNetworkRequestStatus(false));
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function sendContactUs(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.CONTACT_US_PAGE, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        setToaster(response.data.message);
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function loadLeaderData(id) {
  return async function (dispatch) {
    await agent
      .get(API.LEADER_DATA, { params: { limit: id } })
      .then((response) => {
        dispatch(loadLeaderDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadTestimonialsData(id) {
  return async function (dispatch) {
    await agent
      .get(API.TESTIMONIALS_DATA, { params: { limit: id } })
      .then((response) => {
        dispatch(loadTestimonialsDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadCommunityData(id) {
  return async function (dispatch) {
    await agent
      .get(API.COMMUNITY_DATA, { params: { limit: id } })
      .then((response) => {
        dispatch(loadCommunitiesDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function sendActivationMail(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.SAVESUBSCRIBTIONEMAIL, params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(submittingRequestStatus(false));
          showToasterWithOk(response.data.message);
          // dispatch({
          //   type: "REDIRECT",
          //   payload: "/",
          // });
        }
      })
      .catch((error) => {});
  };
}

export function saveSubscribtionMail(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.SAVESUBSCRIBTIONEMAIL, params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(submittingRequestStatus(false));
          showToasterWithOk(response.data.message);
          // dispatch({
          //   type: "REDIRECT",
          //   payload: "/",
          // });
        }
      })
      .catch((error) => {});
  };
}

export function loadAdvisorsData() {
  return async function (dispatch) {
    await agent
      .get(API.ABOUTUS_DATA + "/Advisor" , { })
      .then((response) => {
        dispatch(loadAdvisorsDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadTeamsData() {
  return async function (dispatch) {
    await agent
      .get(API.ABOUTUS_DATA + "/Team" , { })
      .then((response) => {
        dispatch(loadTeamsDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}