import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function forumUserReducer(state = initialState.forumusers, action) {
  switch (action.type) {
    case types.LOAD_FORUM_USER_DATA_SUCCESS:
        return action.forumusers;
    default:
        return state;
  }  
}
