import React, { useEffect } from "react";
import { Link, useHistory, NavLink } from "react-router-dom";
import { Row, Col, Container, Navbar, Nav,NavDropdown } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as settingActions from "../../actions/settingActions";
import * as homePageActions from "../../actions/homePageActions";
import placeholder from "../../assets/images/userprofile.png";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
const Index = (props) => {
  

  const { title, actions, updateProfileData, userInfo, isAuth,community } = props;
  const history = useHistory();
  
  
  if (!userInfo.id) {
    history.push("/login");
  }
  function handleLogOut() {
    localStorage.setItem("authToken", "");
    localStorage.clear();
    let params = {};
    actions.userLogout(params);
    history.push("/login"); // whichever component you want it to route to
  }

  const checkAuthToken = localStorage.getItem("userToken");

  const joinGroupDetail = async (e, community_id, community_slug) => {
    e.preventDefault();
    window.location = "/community-details/" + community_slug
  };
  useEffect(() => { 
    document.title = title;
    actions.getUserProfile();
    actions.loadCommunityData(20);
  }, [title, actions, userInfo]);
  
  // const resoucesPages = async (e, name, value) => {
  //   e.preventDefault();
  //   window.location = "/resource-hub?category="+ value
  // };
  function SidebarRemoveClass() {
    
    document.getElementById("OnscrollBody").classList.remove("chatbodyclass");
    
  }

 
  
  

  return (
    <div className="dashboard_header shadow-sm bg-white">
       
            <Navbar expand="lg">
            <Container fluid>
              
              <Link to="/" className="logo m-0">
                <img
                  src={process.env.REACT_APP_STATIC_IMAGE_PATH + "logo.svg"}
                  alt="career mentoring services"
                />
              </Link>
              <div className="DashHeader_right">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-md-auto align-items-lg-center d-flex">
                  
                  <NavLink
                    className="nav-link"
                    to="/mentors"
                    // title="Home"
                    exact
                    activeClassName="active"
                  >
                    Mentors
                  </NavLink>
                  <NavDropdown title="Communities" id="basic-nav-dropdown"  className="nav-link">
                  {community &&
              community.length &&
              community.map((item, key) => (
                  <NavDropdown.Item href={item.slug}  onClick={(e) => {
                    joinGroupDetail(e, item.id, item.slug);
                          }}>{item.group_name}</NavDropdown.Item>
                  ))}
                 </NavDropdown>
                 
                 <NavLink
                    className="nav-link"
                    to="/resource-hub/all-posts"
                    exact
                    activeClassName="active"
                  >
                    Resources
                  </NavLink>
                  
                  {/* { (userInfo.id===191 || userInfo.id===137 || userInfo.id===236) && ( */}
                  <a
                    className="nav-link"
                    href="/partnerships"
                    exact
                    activeClassName="active"
                  >
                    Partnerships
                  </a>
                  <a
                    className="nav-link"
                    href="/event/upcoming"
                    exact
                    activeClassName="active"
                  >
                    Events
                  </a>
                  {/* ) } */}

                  <NavLink
                    className="nav-link"
                    to="/aboutus"
                    exact
                    activeClassName="active"
                  >
                    About Us
                  </NavLink>

                </Nav>

                <div className="header_login d-flex ">
                  {!userInfo.id && (
                    <Link to="/login" className="btn btn_login mr-2">
                      Login
                    </Link>
                  )}

                  {userInfo.id && (
                    <>
                      <Link to="/manage-profile" className="btn btn_join mr-2">
                        My Dashboard
                      </Link>
                      <Link to="/forum" className="btn btn_join mr-2" style={{ minWidth: 'auto' }}>
                        Forum
                      </Link>
                    </>
                  )}
                  
                </div>
              </Navbar.Collapse>
            
            <div className="user_sub-info d-flex">
            <div className="user">
                <img src={updateProfileData.image ?updateProfileData.image : placeholder} alt={updateProfileData.first_name + ' ' + updateProfileData.last_name} title={updateProfileData.first_name + ' ' + updateProfileData.last_name} />
                
              </div>
              <div className="mail_notification d-flex align-items-center">
                <Link to="/chat" onClick={SidebarRemoveClass}>
                  <img
                    src={
                      process.env.REACT_APP_STATIC_IMAGE_PATH + "message_icon.png"
                    }
                    alt="how to find a career mentor"
                  />
                  
                </Link>

              </div> 
            </div>
 

              </div>
            </Container>
          </Navbar>
             
              
            </div>
          
   
  );
};
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    updateProfileData: state.updateProfileData,
    community: state.community,
    isAuth: state.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions,settingActions, homePageActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
