import React from "react";
import { Modal, Button } from "react-bootstrap";
import ReactPlayer from "react-player";
const Video = (props) => {
  const { setting } = props;
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <ReactPlayer
              url={setting.video_link}
              width="100%"
              className="react-player"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Video;
