import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import submittingRequestStatus from "./submittingRequestStatusAction";
import * as API from "../utils/apiPath";
import { showErrorToaster } from "../utils/helpers";
import { showToasterWithOk } from "../utils/helpers";

export function loadEventDataSuccess(eventsData) {
	return { type: types.LOAD_EVENTS_DATA_SUCCESS, eventsData };
  }

export function loadEventData(type) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(API.LOAD_EVENT + "/" + type)
      .then((response) => {
        dispatch(toggleNetworkRequestStatus(false));
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadEventDataSuccess(response.data.data));
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

// export function loadCommunityData(id) {
//   return async function (dispatch) {
//     await agent
//       .get(API.COMMUNITY_DATA, { params: { limit: id } })
//       .then((response) => {
//         dispatch(loadCommunitiesDataSuccess(response.data.data));
//       })
//       .catch((error) => {
//         throw error;
//       });
//   };
// }

export function handleSubmitRSVP(params, setShow, setLoader) {
    return async function (dispatch) {
      
    //   console.log('rsvp_params',params);

      dispatch(toggleNetworkRequestStatus(true));
      await agent
        .post(API.SUBMIT_RSVP, params)
        .then((response) => {
            setLoader(false);
            // console.log(response);
            if(response.data.status===200){
                dispatch(toggleNetworkRequestStatus(false));
                showToasterWithOk(response.data.message);
                document.getElementById("rsvp_full_name").value = "";
                document.getElementById("rsvp_email").value = "";
                setShow(false);
            }
            else{
              dispatch(toggleNetworkRequestStatus(false));
                showErrorToaster(response.data.message);
            }
        })
        .catch((error) => {
          throw error;
        });
    };
}