import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function advisorsReducer(state = initialState.advisors, action) {
  switch (action.type) {
    case types.LOAD_ADVISORS_DATA_SUCCESS:
      return action.advisors;
    default:
      return state;
  }
}
