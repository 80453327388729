import * as types from "./actionsTypes";
import submittingRequestStatus from "./submittingRequestStatusAction";
import * as API from "../utils/apiPath";
import { agent } from "../utils/agent";
import { useHistory } from "react-router-dom";
import { showToaster, setToaster, showErrorToaster } from "../utils/helpers";
import { redirect } from "../ui";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import { notice, toast } from "react-interaction";

export function loginDataSuccess(userInfo) {
  return { type: types.LOADED_USER_INFO_SUCCESS, userInfo };
}

export function RegisterDataSuccess(userInfo) {
  return { type: types.LOADED_USER_REGISTER_SUCCESS, userInfo };
}

export function loadDataSuccess(customerData) {
  return { type: types.LOADED_CUSTOMER_DATA_SUCCESS, customerData };
}

export function loginUserLoginSuccess(isAuth) {
  return { type: types.LOADED_USER_AUTH_SUCCESS, isAuth };
}

export function ParamsDataSuccess(superUserParams) {
  return { type: types.LOADED_USER_PARAM_SUCCESS, superUserParams };
}

export function ForgotPasswordDataSuccess(isFrogot) {
  return { type: types.LOADED_USER_FORGOT_SUCCESS, isFrogot };
}

export function loadViewDataSuccess(updateProfileData) {
  return { type: types.LOADED_UPDATE_PROFILE_SUCCESS, updateProfileData };
}

export function loadExperienceDataSuccess(userExperience) {
  return { type: types.LOADED_EXPERIENCE_DATA_SUCCESS, userExperience };
}

export function loadProgrammesDataSuccess(programmes) {
  return { type: types.LOAD_PROGRAMMES_DATA_SUCCESS, programmes };
}

export function userLoginData(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.LOGIN, params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(submittingRequestStatus(false));
          dispatch(loginDataSuccess(response.data.data));
          localStorage.setItem("userToken", response.data.data.api_token);
          localStorage.setItem(
            "isConfirmed",
            response?.data?.data?.isConfirmed
          );
          dispatch(loginUserLoginSuccess(true));
          if (params.remember_me) {
            dispatch(ParamsDataSuccess(params));
          } else {
            dispatch(ParamsDataSuccess({}));
          }
          dispatch({
            type: "REDIRECT",
            payload: "/manage-profile",
          });
          if (params.avl_resume == "1") {
            showToaster("Your resume has been uploaded successfully.");
          }
        }
      })
      .catch((error) => {
        showToaster(error.message);
        dispatch(submittingRequestStatus(false));
      });
  };
}

export function userRegisterData(params, roleId) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.REGISTER, params)
      .then((response) => {
        // console.log(response);
        // return false;
        localStorage.setItem("userId", response.data.data);
        if (response.status === 503) {
          showToaster(response.data.message);
          dispatch(submittingRequestStatus(false));
        }
        if (response.status === 200) {
          if (response?.data?.status === 200) {
            dispatch(submittingRequestStatus(false));
            dispatch(RegisterDataSuccess(true));
            if (roleId === "Mentor") {
              dispatch({
                type: "REDIRECT",
                payload: "/mentor-signup-step-1",
              });
            }
            if (roleId === "Mentees") {
              dispatch({
                type: "REDIRECT",
                payload: "/mentee-signup-step-1",
              });
            }
          } else {
            showToaster(response.data.message);
            dispatch(submittingRequestStatus(false));
          }

          //showToaster(response.data.message);
        }
      })
      .catch((error) => {
        showToaster(error.message);
        dispatch(submittingRequestStatus(false));
      });
  };
}

export const getExperienceUser = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.EXPERIENCE);
    dispatch(loadExperienceDataSuccess(response.data?.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    showToaster(error.message);
    dispatch(toggleNetworkRequestStatus(false));
  }
};

export function forgotPassword(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.FORGOT_PASSWORD, params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(submittingRequestStatus(false));
          dispatch(ForgotPasswordDataSuccess(true));
          showToaster(response.data.message);
        }
      })
      .catch((error) => {
        showToaster(error.message);
        dispatch(submittingRequestStatus(false));
      });
  };
}

export function userLogout(params) {
  return async function (dispatch) {
    dispatch(loginUserLoginSuccess(false));
    dispatch(loginDataSuccess({}));
    localStorage.removeItem("userToken");
    localStorage.removeItem("profileImage");
    localStorage.removeItem("profile_img");
  };
}

export const updateProfileData = (params) => async (dispatch) => {
  try {
    dispatch(submittingRequestStatus(true));
    const response = await agent.post(API.UPDATE_PROFILE, params);
    dispatch(submittingRequestStatus(false));
    showToaster(response.data.message);
    dispatch(loadViewDataSuccess(response.data.data));
  } catch (error) {
    dispatch(submittingRequestStatus(false));
    showToaster(error.message);
  }
};

export const updatePasswordData = (params) => async (dispatch) => {
  try {
    dispatch(submittingRequestStatus(true));
    const response = await agent.put(
      API.UPDATE_PASSWORD + "/" + params.id,
      params
    );
    if (response.status === 200) {
      dispatch(submittingRequestStatus(false));
      showToaster(response.data.message);
      dispatch({
        type: "REDIRECT",
        payload: "/manage-profile",
      });
    } else {
      dispatch(submittingRequestStatus(false));
      showToaster(response.message);
    }
  } catch (error) {
    dispatch(submittingRequestStatus(false));
    showErrorToaster(error.message);
  }
};

export const getUserProfile = (request) => async (dispatch) => {
  try {
    const response = await agent.get(API.GETUSERPROFILE);
    dispatch(loadViewDataSuccess(response.data.data));
  } catch (error) {
    if(window.location.pathname == '/login'){
      showToaster(error.message);
    }
    dispatch(loginUserLoginSuccess(false));
    dispatch(loginDataSuccess({}));
    localStorage.removeItem("userToken");
    localStorage.setItem("authToken", "");
  }
};

export const updateCustomerData = (params, id, role_id) => async (dispatch) => {
  try {
    const response = await agent.post(
      API.LOAD_CUSTOMER + "/update/" + id,
      params
    );
    dispatch(loadDataSuccess(response.data.data.data));
    setToaster(response.data.message);
  } catch (error) {
    setToaster(error.message);
  }
};

export function updateStep1(params, id, setLoader) {
  return async function (dispatch) {
    await agent
      .post(API.LOAD_CUSTOMER + "/saveStep1/" + id, params)
      .then((response) => {
        dispatch(loadViewDataSuccess(response.data.data));
        dispatch(loadDataSuccess(response.data.data.data));
        dispatch(loginDataSuccess(response.data.data));
        if (response.status === 422) {
          setLoader(false);
          showErrorToaster(response.data.message);
        }
        if (response.status === 503) {
          setToaster(response.data.message);
        }
        if (response.status === 200) {
          setToaster(response.data.message);
          setTimeout(
            () =>
              dispatch({
                type: "REDIRECT",
                payload: "manage-profile-step3",
              }),
            1800
          );
          localStorage.setItem(
            "isConfirmed",
            response?.data?.data?.isConfirmed
          );
        }
      })
      .catch((error) => {
        if (error.status === 422) {
          setLoader(false);
          showErrorToaster(error.message);
        } else {
          setToaster(error.message);
        }
      });
  };
}

export const updateUserAvailability = (params, id) => async (dispatch) => {
  try {
    const response = await agent.post(
      API.LOAD_CUSTOMER + "/updateUserAvailability/" + id,
      params
    );
    dispatch(loadDataSuccess(response.data.data.data));
    setToaster(response.data.message);
  } catch (error) {
    setToaster(error.message);
  }
};

export const updateStep2 = (params, id) => async (dispatch) => {
  try {
    const response = await agent.post(
      API.LOAD_CUSTOMER + "/saveStep2/" + id,
      params
    );
    dispatch(loadDataSuccess(response.data.data.data));
    setToaster(response.data.message);
    dispatch(loadViewDataSuccess(response.data.data));
    localStorage.setItem("isConfirmed", response?.data?.data?.isConfirmed);
  } catch (error) {
    setToaster(error.message);
  }
};

export function updateStep3(params, id) {
  return async function (dispatch) {
    await agent
      .post(API.LOAD_CUSTOMER + "/saveStep3/" + id, params)
      .then((response) => {
        dispatch(loadDataSuccess(response.data.data.data));

        if (response.status === 503) {
          setToaster(response.data.message);
        }
        if (response.status === 200) {
          dispatch(loadViewDataSuccess(response.data.data));
          setToaster(response.data.message);
          setTimeout(
            () =>
              dispatch({
                type: "REDIRECT",
                payload: "manage-profile-step4",
              }),
            1800
          );
          localStorage.setItem(
            "isConfirmed",
            response?.data?.data?.isConfirmed
          );
        }
      })
      .catch((error) => {
        setToaster(error.message);
      });
  };
}

export const updateStep4 = (params, id) => async (dispatch) => {
  try {
    const response = await agent.post(
      API.LOAD_CUSTOMER + "/saveStep4/" + id,
      params
    );
    dispatch(loadDataSuccess(response.data.data.data));
    dispatch(loadViewDataSuccess(response.data.data));
    localStorage.setItem("isConfirmed", response?.data?.data?.isConfirmed);
    setToaster(response.data.message);
  } catch (error) {
    setToaster(error.message);
  }
};

export function DeleteRequest(params, id) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.LOAD_CUSTOMER + "/deleteRequest/" + id, params)
      .then((response) => {
        // console.log(response);
        // return false;

        if (response.status === 503) {
          showToaster(response.data.message);
          dispatch(submittingRequestStatus(false));
        }
        if (response.status === 200) {
          dispatch(submittingRequestStatus(false));
          dispatch(RegisterDataSuccess(true));
          showToaster(response.data.message);
          setTimeout(
            () =>
              dispatch({
                type: "REDIRECT",
                payload: "manage-profile",
              }),
            1800
          );
        }
      })
      .catch((error) => {
        showToaster(error.message);
        dispatch(submittingRequestStatus(false));
      });
  };
}

export function Refer(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.SEND_REFER, params)
      .then((response) => {
        // console.log(response);
        // return false;

        if (response.status === 503) {
          showToaster(response.data.message);
          dispatch(submittingRequestStatus(false));
        }
        if (response.status === 200) {
          dispatch(submittingRequestStatus(false));
          dispatch(RegisterDataSuccess(true));
          showToaster(response.data.message);
          setTimeout(
            () =>
              dispatch({
                type: "REDIRECT",
                payload: "manage-profile",
              }),
            1800
          );
        }
      })
      .catch((error) => {
        showToaster(error.message);
        dispatch(submittingRequestStatus(false));
      });
  };
}

export const getAllProgrammes = (request) => async (dispatch) => {
  try {
    const response = await agent.get(API.LOAD_PROGRAMMES + "/all");
    dispatch(loadProgrammesDataSuccess(response.data.data));
  } catch (error) {
    showToaster(error.message);
  }
};

export function preregisterationFrom(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.PRE_REGISTER, params)
      .then((response) => {
        if (response.status === 503) {
          showToaster(response.data.message);
          dispatch(submittingRequestStatus(false));
        }
        if (response.status === 200) {
          dispatch(submittingRequestStatus(false));
          // dispatch(RegisterDataSuccess(true));
          showToaster(response.data.message);
        }
      })
      .catch((error) => {
        showToaster(error.message);
        dispatch(submittingRequestStatus(false));
      });
  };
}
