import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Button from "../../Component/Button";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import { Container, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import career from "./career.json";
import LT from "../../assets/images/lt-image.png";
const queryString = require("query-string");
const Index = (props) => {
  const location = useLocation();
  const { actions } = props;
  const { register, errors, handleSubmit, reset } = useForm({});
  const [available, setAvailable] = useState(false);
  const [getAgeCondition, setAgeCondition] = useState(false);

  const [course, setCourse] = useState("Regular");
  const [graduation, setGraduation] = useState("2023");
  const [next, setNext] = useState("Join professional life");
  const [errinterest, setErrinterest] = useState("");
  const [showClass, setShowClass] = useState("none");
  const [reqclass, setreqclass] = useState(false);
  const handleChange = (item, action) => {
    if (item === "Other") {
      setShowClass("block");
      setreqclass(true);
    }
  };

  const onSubmit = (data) => {
    if (temp.length > 0) {
      if (available == true && getAgeCondition == true) {
        let params = data;
        params.course_type = course;
        params.graduation_year = graduation;
        params.next_plan = next;
        params.interest = temp;
        actions.preregisterationFrom(params);
        reset();
        setCourse("Regular");
        setGraduation("2023");
        setNext("Join professional life");
        setTemp([]);
      }
    } else {
      setErrinterest("Please select atleast one.");
    }
  };
  const [temp, setTemp] = useState([]);
  return (
    <>
      <section className="registration_form_wrapper">
        <Container>
          <Row className="align-items-center ">
            <Col md={12}>
              <div className="logininnerouter">
                <div>
                  <div className="text-center mb-5">
                    <div className="preregisterationFrom">
                      <div className="registration_head">
                        <h1 className="welcome-text">Welcome to the</h1>
                        {/* <Link to="/" className="logo m-0">
                          <img
                            src={
                              process.env.REACT_APP_STATIC_IMAGE_PATH +
                              "olay.svg"
                            }
                            alt="logo"
                          />
                        </Link> */}

                        <div className="semi-header">
                          <strong>STEM Mentoring Program</strong>
                          <div className="center_title">
                            {" "}
                            <h6>Championed by</h6>
                          </div>
                          <div className="champinoned-powered">
                            <div className="champinoned-grid">
                              {/* <span>
                                <img
                                  src={
                                    process.env.REACT_APP_STATIC_IMAGE_PATH +
                                    "pNg.svg"
                                  }
                                  alt="logo"
                                />
                              </span> */}

                              <span>
                                {" "}
                                <img
                                  src={
                                    process.env.REACT_APP_STATIC_IMAGE_PATH +
                                    "olay.svg"
                                  }
                                  alt="logo"
                                />
                              </span>
                            </div>
                            <span>&</span>
                            <div className="champinoned-grid company_logo">
                              <span>
                                <img src={LT} alt="logo"/>
                              </span>
                            </div>
                          </div>

                          <div className="champinoned-powered v-lookup-down">
                            <div className="champinoned-grid company_logo pre-register">
                              <h6>Powered by</h6>
                              <span>
                                <img
                                  src={
                                    process.env.REACT_APP_STATIC_IMAGE_PATH +
                                    "logo.svg"
                                  }
                                  className="mainImg"
                                  // style={{width:"500px",height:"500px",background:"red"}}
                                  alt="logo"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="loginform">
                        <p className="text-left mb-3">
                          Thank you for registering for the OLAY, Mindtree and
                          vLookUp STEM Mentoring programme. Upon selection, you
                          will be assigned an industry leader from Mindtree and
                          P&G as your mentor for 4 months. This is a great
                          opportunity for you to get guidance from industry
                          leaders and build relevant connections.
                        </p>
                        <h4 className="text-left font-weight-bold mb-3">
                          You will also get a certificate of mentoring at the
                          end of the programme.
                        </h4>
                        <p className="text-left">
                          Little information about you will help us assign a
                          suitable mentor to you
                        </p>
                        <h3 className="text-left">Let’s know you better</h3>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <div className="form-block">
                            <Row>
                              <Col sm={6} className="pr-sm-2">
                                <div className="form-group">
                                  <Form.Control
                                    type="text"
                                    placeholder={"Name*"}
                                    className={
                                      errors.name
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    name="name"
                                    aria-invalid={
                                      errors.name ? "true" : "false"
                                    }
                                    ref={register({
                                      required: true,
                                      minLength: 2,
                                      maxLength: 30,
                                      pattern: /^[A-Za-z ]*$/,
                                    })}
                                  />
                                  {errors.name &&
                                    errors.name.type === "required" && (
                                      <label className="invalid-feedback text-left">
                                        Please enter full name.
                                      </label>
                                    )}
                                  {errors.name &&
                                    errors.name.type === "minLength" && (
                                      <label className="invalid-feedback text-left">
                                        Full name should contain at least 2
                                        characters.
                                      </label>
                                    )}
                                  {errors.name &&
                                    errors.name.type === "maxLength" && (
                                      <label className="invalid-feedback text-left">
                                        Full name should not exceed 30
                                        characters.
                                      </label>
                                    )}
                                  {errors.name &&
                                    errors.name.type === "pattern" && (
                                      <label className="invalid-feedback text-left">
                                        Please enter valid full name.
                                      </label>
                                    )}
                                </div>
                              </Col>

                              <Col sm={6} className="pr-sm-2">
                                <div className="form-group">
                                  <Form.Control
                                    type="text"
                                    placeholder={"Email ID*"}
                                    name="email"
                                    className={
                                      errors.email
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    aria-invalid={
                                      errors.email ? "true" : "false"
                                    }
                                    ref={register({
                                      required: true,
                                      pattern:
                                        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                    })}
                                  />
                                  {errors.email &&
                                    errors.email.type === "required" && (
                                      <small className="form-text text-danger text-left">
                                        Please enter email.
                                      </small>
                                    )}
                                  {errors.email &&
                                    errors.email?.type === "pattern" && (
                                      <small className="form-text text-danger text-left">
                                        Invalid email.
                                      </small>
                                    )}
                                </div>
                              </Col>

                              <Col sm={12} className="pr-sm-2">
                                <div className="form-group">
                                  <Form.Control
                                    type="text"
                                    placeholder="College*"
                                    name="college"
                                    className={
                                      errors.college
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    aria-invalid={
                                      errors.college ? "true" : "false"
                                    }
                                    ref={register({
                                      required: true,
                                      minLength: 2,
                                      maxLength: 40,
                                      pattern: /^[A-Za-z ]*$/,
                                    })}
                                  />
                                  {errors.college &&
                                    errors.college?.type === "required" && (
                                      <small className="form-text text-danger text-left">
                                        Please Enter College.
                                      </small>
                                    )}
                                  {errors.college &&
                                    errors.college?.type === "minLength" && (
                                      <small className="form-text text-danger text-left">
                                        College should contain at least 2
                                        characters.
                                      </small>
                                    )}
                                  {errors.college &&
                                    errors.college?.type === "maxLength" && (
                                      <small className="form-text text-danger text-left">
                                        College should not exceed 40 characters.
                                      </small>
                                    )}
                                  {errors.college &&
                                    errors.college?.type === "pattern" && (
                                      <small className="form-text text-danger text-left">
                                        Please enter valid College.
                                      </small>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="form-block">
                            <h5 className="form_small_heading">
                              Tell us about your academic life and achievements{" "}
                            </h5>
                            <Row>
                              <Col sm={12} className="pr-sm-2">
                                <div className="form-group">
                                  <Form.Control
                                    as="select"
                                    placeholder="Highest Education Qualification*"
                                    name="education"
                                    className={
                                      errors.education
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    aria-invalid={
                                      errors.education ? "true" : "false"
                                    }
                                    onChange={(e) => {
                                      handleChange(e.target.value);
                                    }}
                                    ref={register({ required: true })}
                                  >
                                    <option value="">
                                      Highest Education Qualification*
                                    </option>
                                    <option value="B.E">B.E</option>
                                    <option value="B.Tech">B.Tech</option>
                                    <option value="M.E">M.E</option>
                                    <option value="M.Tech">M.Tech</option>
                                    <option value="MCA">MCA</option>
                                    <option value="Other">Other</option>
                                  </Form.Control>
                                  {errors.education &&
                                    errors.education?.type === "required" && (
                                      <small className="form-text text-danger text-left">
                                        Please Enter Education.
                                      </small>
                                    )}
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                className="pr-sm-2"
                                style={{ display: showClass }}
                              >
                                <div className="form-group">
                                  <Form.Control
                                    type="text"
                                    placeholder={"Other Education"}
                                    name="other_education"
                                    className={
                                      errors.other_education
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    aria-invalid={
                                      errors.other_education ? "true" : "false"
                                    }
                                    ref={register({
                                      required: reqclass,
                                    })}
                                  />
                                  {errors.other_education &&
                                    errors.other_education?.type ===
                                      "required" && (
                                      <small className="form-text text-danger text-left">
                                        Please Enter other education.
                                      </small>
                                    )}
                                </div>
                              </Col>

                              <Col sm={12} className="pr-sm-2">
                                <div className="form-group">
                                  <Form.Control
                                    type="text"
                                    placeholder={"Specialization, if any"}
                                    name="specialization"
                                    className={
                                      errors.specialization
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    aria-invalid={
                                      errors.specialization ? "true" : "false"
                                    }
                                    ref={register({ pattern: /^[A-Za-z ]*$/ })}
                                  />
                                  {errors.specialization &&
                                    errors.specialization?.type ===
                                      "pattern" && (
                                      <small className="form-text text-danger text-left">
                                        Please Enter Valid Specialization.
                                      </small>
                                    )}
                                </div>
                              </Col>

                              <Col sm={6} className="pr-sm-2">
                                <h5 className="form_small_heading">
                                  {" "}
                                  Course Type
                                </h5>
                                <div className="radio_group">
                                  <span className="custom_radio ml-0">
                                    Regular &nbsp;
                                    <input
                                      // checked
                                      checked={course === "Regular"}
                                      type="radio"
                                      name="course_type"
                                      value="Regular"
                                      onChange={(e) =>
                                        setCourse(e.target.value)
                                      }
                                    />
                                    <span className="radio_indicator">
                                      &nbsp;
                                    </span>
                                  </span>

                                  <span className="custom_radio ml-0">
                                    Part Time &nbsp;
                                    <input
                                      type="radio"
                                      checked={course === "Part Time"}
                                      name="course_type"
                                      value="Part Time"
                                      onChange={(e) =>
                                        setCourse(e.target.value)
                                      }
                                    />
                                    <span className="radio_indicator">
                                      &nbsp;
                                    </span>
                                  </span>
                                </div>
                              </Col>

                              <Col sm={6} className="pr-sm-2">
                                <h5 className="form_small_heading">
                                  {" "}
                                  Graduation year
                                </h5>
                                <div className="radio_group">
                                  <span className="custom_radio ml-0">
                                    2023 &nbsp;
                                    <input
                                      checked={graduation === "2023"}
                                      type="radio"
                                      name="graduation_year"
                                      value="2023"
                                      onChange={(e) =>
                                        setGraduation(e.target.value)
                                      }
                                    />
                                    <span className="radio_indicator">
                                      &nbsp;
                                    </span>
                                  </span>

                                  <span className="custom_radio ml-0">
                                    2024 &nbsp;
                                    <input
                                      type="radio"
                                      checked={graduation === "2024"}
                                      name="graduation_year"
                                      value="2024"
                                      onChange={(e) =>
                                        setGraduation(e.target.value)
                                      }
                                    />
                                    <span className="radio_indicator">
                                      &nbsp;
                                    </span>
                                  </span>
                                  <span className="custom_radio ml-0">
                                    2025 &nbsp;
                                    <input
                                      type="radio"
                                      checked={graduation === "2025"}
                                      name="graduation_year"
                                      value="2025"
                                      onChange={(e) =>
                                        setGraduation(e.target.value)
                                      }
                                    />
                                    <span className="radio_indicator">
                                      &nbsp;
                                    </span>
                                  </span>
                                  <span className="custom_radio ml-0">
                                    2026 &nbsp;
                                    <input
                                      type="radio"
                                      checked={graduation === "2026"}
                                      name="graduation_year"
                                      value="2026"
                                      onChange={(e) =>
                                        setGraduation(e.target.value)
                                      }
                                    />
                                    <span className="radio_indicator">
                                      &nbsp;
                                    </span>
                                  </span>
                                </div>
                              </Col>

                              <Col sm={12} className="pr-sm-2">
                                <div className="form-group">
                                  <Form.Control
                                    as="select"
                                    placeholder="Please select one of these options applicable to you*"
                                    name="applicable"
                                    className={
                                      errors.applicable
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    aria-invalid={
                                      errors.applicable ? "true" : "false"
                                    }
                                    ref={register({ required: true })}
                                  >
                                    <option value="">
                                      Please select one of these options
                                      applicable to you*
                                    </option>
                                    <option value="You have scored 60% and above consistently from class 10 till now">
                                      You have scored 60% and above consistently
                                      from class 10 till now
                                    </option>
                                    <option value="Your scores varied between less than and above 60%">
                                      Your scores varied between less than and
                                      above 60%
                                    </option>
                                    <option value="You have scored above 60% always but with few backlogs in between">
                                      You have scored above 60% always but with
                                      few backlogs in between
                                    </option>
                                  </Form.Control>
                                  {errors.applicable &&
                                    errors.applicable?.type === "required" && (
                                      <small className="form-text text-danger text-left">
                                        Please Select Any One.
                                      </small>
                                    )}
                                </div>
                              </Col>

                              <Col sm={12} className="pr-sm-2">
                                <h5 className="form_small_heading">
                                  Select 5 options from the list below, which
                                  interest you the most in terms of career
                                  options upon completing your education. This
                                  will help us assign a suitable mentor to you :{" "}
                                </h5>
                              </Col>

                              <Col sm={12} className="pr-sm-2">
                                <div className="form-group">
                                  {/* <Form.Control
                                  multiple
                                    as="select"
                                    name="interest"
                                    className={
                                      errors.interest
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    aria-invalid={
                                      errors.interest ? "true" : "false"
                                    }
                                    ref={register({ required: true })}
                                  >
                                    <option value="">
                                      Select 5 options from the list below,
                                      which interest you the most in terms of
                                      career options upon completing your
                                      education
                                    </option>
                                    {career.map((i) => (
                                      <option value={i.id}>{i.name}</option>
                                    ))}
                                  </Form.Control> */}
                                  <Select
                                    isMulti
                                    options={career}
                                    isOptionDisabled={() =>
                                      temp?.length > 4 ? true : false
                                    }
                                    onChange={(e) => {
                                      setTemp([]);
                                      let pop = e.map((res) => {
                                        return res.label;
                                      });
                                      setTemp(pop);
                                      if (pop.length > 0) {
                                        setErrinterest("");
                                      }
                                    }}
                                  />
                                  {errinterest && (
                                    <small className="form-text text-danger text-left">
                                      {errinterest}
                                    </small>
                                  )}

                                  {/* {errors.interest &&
                                    errors.interest?.type === "required" && (
                                      <small className="form-text text-danger text-left">
                                        Please Select Atleast One.
                                      </small>
                                    )} */}
                                </div>
                              </Col>

                              <Col sm={12} className="pr-sm-2">
                                <h5 className="form_small_heading mb-0">
                                  We would love to hear about your next plan in
                                  terms of joining professional life or pursuing
                                  higher studies
                                </h5>
                              </Col>

                              <Col sm={12} className="pr-sm-2">
                                <div className="radio_group sm-column">
                                  <span className="custom_radio ml-0">
                                    Join professional life &nbsp;
                                    <input
                                      checked={
                                        next === "Join professional life"
                                      }
                                      type="radio"
                                      name="next_plan"
                                      value="Join professional life"
                                      onChange={(e) => setNext(e.target.value)}
                                    />
                                    <span className="radio_indicator">
                                      &nbsp;
                                    </span>
                                  </span>

                                  <span className="custom_radio ml-0">
                                    Pursue higher studies &nbsp;
                                    <input
                                      checked={next === "Pursue higher studies"}
                                      type="radio"
                                      name="next_plan"
                                      value="Pursue higher studies"
                                      onChange={(e) => setNext(e.target.value)}
                                    />
                                    <span className="radio_indicator">
                                      &nbsp;
                                    </span>
                                  </span>
                                </div>
                              </Col>

                              <Col sm={12}>
                                <h5
                                  style={{ display: "flex" }}
                                  className="form_small_heading mb-0"
                                >
                                  Want to know more about vLookUp mentoring,
                                  head to our&nbsp;
                                  <a
                                    href="/faq"
                                    target="_blank"
                                    style={{ color: "#0078FF" }}
                                  >
                                    FAQ
                                  </a>
                                  &nbsp; page
                                </h5>
                              </Col>

                              <Col sm={12} className="pr-sm-2 mb-4">
                                <div className="remember_me text-left">
                                  <span className="custom_check mr-2 mb-2">
                                    {"I agree to vLookUp"}{" "}
                                    <input
                                      type="checkbox"
                                      name="availability"
                                      value={available}
                                      className={`form-check-input ${
                                        errors.language ? "is-invalid" : ""
                                      }`}
                                      onChange={() => {
                                        setAvailable(!available);
                                      }}
                                    />
                                    <span className="check_indicator">
                                      &nbsp;
                                    </span>
                                  </span>
                                  <a
                                    href="https://vlookup.ai/page/terms-and-conditions"
                                    target="_blank"
                                  >
                                    {" "}
                                    T&C
                                  </a>
                                  {" and "}
                                  {""}
                                  <a
                                    href="https://vlookup.ai/page/privacy-policy"
                                    target="_blank"
                                  >
                                    {" "}
                                    Privacy Statement
                                  </a>
                                </div>
                                {available === false && (
                                  <small className="form-text text-left text-danger text-left">
                                    Please Accept T&C and Privacy Statement.
                                  </small>
                                )}
                              </Col>
                              <Col sm={12} className="pr-sm-2 mb-4">
                                <div className="remember_me text-left">
                                  <span className="custom_check mr-2 mb-2">
                                    {"I am above 18 years old"}{" "}
                                    <input
                                      type="checkbox"
                                      name="getAgeCondition"
                                      value={getAgeCondition}
                                      className={`form-check-input ${
                                        errors.getAgeCondition
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      onChange={() => {
                                        setAgeCondition(!getAgeCondition);
                                      }}
                                    />
                                    <span className="check_indicator">
                                      &nbsp;
                                    </span>
                                  </span>
                                </div>
                                {getAgeCondition === false && (
                                  <small className="form-text text-left text-danger text-left">
                                    Please confirm your age.
                                  </small>
                                )}
                              </Col>

                              <Col md={12}>
                                <div className="form-group text-left">
                                  <Button
                                    title={"Submit"}
                                    className={"btn loginsubmit"}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userRegister: state.userRegister,
    programmes: state.programmes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
