import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as forumActions from "../../actions/forumActions";

const Question = (props) => {
  const { userdata, actions } = props;
  const { register, errors, handleSubmit } = useForm();
  const [getLoader, setLoader] = useState(false);
  
  const onSubmit = (data) => {
    setLoader(true);
    const request = {};
    request.user_id = userdata.user_id;
    request.question = data.question;
    request.category = data.category;
    actions.saveForumQuestion(request,props, setLoader);
    // actions.loadForumData(userdata.user_id);
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton><h3><b>Ask a Question</b></h3></Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mentor_detail align-items-center">
                <Col sm={12}>
                <div className="form-group">
                    <label>Category</label>
                    <Form.Control
                    as="select"
                    className="mb-3"
                    name="category"
                    ref={register({
                      required: true
                    })}
                    >
                    <option
                        value=""
                        key=""
                    >
                        Select Option
                    </option>
                    {userdata.categories &&
                        userdata.categories.map((item, key) => (
                        <option
                            value={item.id}
                            key={key}
                        >
                            {item.category}
                        </option>
                        ))}
                    </Form.Control>
                {errors.category && errors.category.type === "required" && (
                  <label className="customeValidationText text-left">
                    Please select category
                  </label>
                )}
                </div>
                </Col>
              <Col sm={12}>
                <label>Question</label>
                <Form.Control
                  as="textarea"
                  className={
                    errors.question ? "form-control is-invalid" : "form-control"
                  }
                  name="question"
                  defaultValue=''
                  aria-invalid={errors.question ? "true" : "false"}
                  ref={register({
                    maxLength: 250,
                    required: true
                  })}
                />
                {errors.question && errors.question.type === "required" && (
                  <label className="customeValidationText text-left">
                    Please enter question
                  </label>
                )}
                {errors.question && errors.question.type === "maxLength" && (
                  <label className="customeValidationText text-left">
                    Question should not more then 250 characters
                  </label>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="form-group mt-3 text-left">
                  <Button
                    className="btn nextbtn ml-2"
                    type="submit"
                    disabled={getLoader}
                  >
                  { getLoader ? 'Submitting...' : 'Submit Question' }
                    
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(forumActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Question);