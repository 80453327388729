import React, { useEffect, useState } from "react";
import { Link, useHistory, NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "../../Component/Button";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
import UserSidebar from "../../Component/UserSidebar";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

const Index = (props) => {
  var todayDate = new Date();
  todayDate.setMonth(todayDate.getMonth());
  todayDate = todayDate.toLocaleDateString();

  var d = new Date();
  d.setMonth(d.getMonth() + 3);
  const nextThreeMonthData = d.toLocaleDateString();

  const { title, actions, userInfo, updateProfileData } = props;
  const [fromDate, handleFromDateChange] = useState(todayDate);
  const [toDate, handleToDateChange] = useState(nextThreeMonthData);
  const [formObject, setFormObject] = useState(false);

  const { register, errors, handleSubmit } = useForm();
  const checkFromDate = (date) => {
    handleFromDateChange(moment(new Date(date)).format("YYYY-MM-DD"));
    if (moment(new Date(date)).isValid() === false) {
      setFormObject(true);
    } else if (moment(new Date(date)).isValid()) {
      setFormObject(false);
    }
  };
  const checkToDate = (date) => {
    handleToDateChange(moment(new Date(date)).format("YYYY-MM-DD"));
    if (moment(new Date(date)).isValid() === false) {
      setFormObject(true);
    } else if (moment(new Date(date)).isValid()) {
      setFormObject(false);
    }
  };
  useEffect(() => {
    document.title = title;
  }, [title, actions, userInfo]);

  useEffect(() => {
    if (updateProfileData.avail_start_time) {
      handleFromDateChange(updateProfileData.avail_start_time);
    }
    if (updateProfileData.avail_end_time) {
      handleToDateChange(updateProfileData.avail_end_time);
    }
  }, [updateProfileData]);

  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }

  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  const onSubmit = (data) => {
    actions.updateUserAvailability(data, updateProfileData.id);
  };
  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>

          <h2 className="page_heading mb-3">Availability</h2>

          <div className="box shadow bg-white p-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6} className="mt-4">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      format="yyyy-MM-dd"
                      id="date-picker-from"
                      label="From"
                      minDate={new Date()}
                      className={errors.fromDate ? "w-100 is-invalid" : "w-100"}
                      name="from_date"
                      aria-invalid={errors.fromDate ? "true" : "false"}
                      value={fromDate}
                      inputRef={register}
                      onChange={(date) => checkFromDate(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Col>

                <Col md={6} className="mt-4">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      format="yyyy-MM-dd"
                      id="date-picker-from"
                      label="To"
                      name="to_date"
                      className="w-100"
                      value={toDate}
                      inputRef={register}
                      onChange={(date) => checkToDate(date)}
                    />
                  </MuiPickersUtilsProvider>
                  {errors.fromDate && errors.fromDate.type === "required" && (
                    <label className="customeValidationText text-left">
                      Please upload fromDate
                    </label>
                  )}
                </Col>
                <Col md={3}>
                  <div className="form-group mt-3">
                    <Button
                      title={"Submit"}
                      className={"btn w-100 loginsubmit"}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>

      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    updateProfileData: state.updateProfileData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
