import { notice, check, toast } from "react-interaction";

export const setToaster = (value) => {
  toast(value, { time: 2000 });
};

export const checkConform = async (callback, message) => {
  try {
    const isConfirmed = await check(message, {
      okStyle: {
        backgroundColor: "#0078ff",
        color: "#fff",
      },
      contentClassName: "account-logout",
      contentStyle: {
        width: 600,
      },
      okText: "Yes",
      cancelClassName: "my-check-cancel",
      cancelStyle: {
        backgroundColor: "#ccc",
        color: "#fff",
      },
      cancelText: "No",
    });

    if (isConfirmed) {
      callback();
    }
  } catch (error) {
    console.log(error.message);
  }
};

const handleOkClick = () => {
  console.log("OK button clicked!");
};

export const showToaster = (value) => {
  notice(value, {
    time: 4000,
    okStyle: {
      backgroundColor: "#0e81e4",
      fontSize: 18,
      color: "#fff",
    },

    okText: "OK",
  });
};

export const showToasterWithOk = (value) => {
  notice(value, {
    time: 4000,
    okStyle: {
      backgroundColor: "#0e81e4",
      fontSize: 18,
      color: "#fff",
    },
    okText: "OK",
  });
};

export const showMessageBox = (value) => {
  notice(value, {
    okStyle: {
      backgroundColor: "#0e81e4",
      fontSize: 18,
      color: "#fff",
    },
    okText: "OK",
  });
};

export const renderTitle = (title) => {
  if (!title) {
    return "";
  } else {
    var str = title;
    var n = str.length;
    if (n === 0) {
      return "";
    } else {
      var res = str.slice(0, 30);
      var dot = "";
      if (n > 30) {
        dot = "...";
      }
      return res + dot;
    }
  }
};

export const catchError = (response) => {
  if (response instanceof Error) {
    throw new Error(response.response.data.message);
  }
};

export const UCWords = (title) => {
  if (!title) {
    return "";
  } else {
    // split string on spaces
    var str = title;
    var arrStr = str.split(" ");

    var strOut = [];

    for (var i = 0; i < arrStr.length; i++) {
      // split string
      var firstChar = arrStr[i].substring(0, 1);
      var remainChar = arrStr[i].substring(1);

      // convert case
      firstChar = firstChar.toUpperCase();
      remainChar = remainChar.toLowerCase();

      strOut += firstChar + remainChar + " ";
    }

    // return string, but drop the last space
    return strOut.substring(0, strOut.length - 1);
  }
};

export const showErrorToaster = (value) => {
  notice(value, {
      time: 4000,
      okStyle: {
      backgroundColor: "#ef4343",
      fontSize: 18,
      color: "#fff",
      },

      okText: "OK",
  });
};

export const blobToFile = (theBlob, fileName) => {       
  return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
}