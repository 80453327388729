import React, { useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as certificateAction from "../../actions/certificateAction";
import * as baseActions from "../../actions/baseActions";
import UserSidebar from "../../Component/UserSidebar";
import { Tooltip, Button, OverlayTrigger, Table, Row, Col, Card } from "react-bootstrap";
import Search from '../../Component/Search'
import Paginate from '../../Component/Paginate'
const queryString = require("query-string");
// import FormDiagnostics from "./form";

const Index = (props) => {
  const { title, actions, certificateData,updateProfileData } = props;  
  const location = useLocation();
  const history = useHistory();
   
  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  useEffect(() => {
    document.title = title;
    if(props?.userInfo?.id){
      actions.loadData({user_id:props?.userInfo?.id});
    }
  }, [title]);

  const resetPage = () => {
    const params = new URLSearchParams(location.search);
    let page = 1;
    params.set("page", page);
    history.push({pathname:`/certificate`, search: "?" + `page=1`});
  };

  const searchData = (data) => {
    const params = new URLSearchParams(location.search);
    if (data.keyword) {
      params.set("keyword", data.keyword);
    }
    if (data.from) {
      params.set("from", data.from);
    }
    if (data.to) {
      params.set("to", data.to);
    }
    let page = 1;
    params.set("page", page);
    history.push({
      pathname: "/certificate",
      search: "?" + params,
    });
  };

  const handleFormClick = (item, action) => {
    actions.loadFormPop(action);
  };

  const handlePageClick = (data) => {
    let page = data + 1;
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    history.push({
      pathname: "/certificate",
      search: "?" + params,
    });
  };
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    const request = {};
     request.user_id =props?.userInfo?.id
    if (params.get("sort")) {
      var sort = queryStringParsed["sort"];
      var direction = queryStringParsed["direction"];
      request.sort = sort;
      request.direction = direction;
    }
    if (params.get("page")) {
      var page = queryStringParsed["page"];
      request.page = page;
    }
    if (params?.get("keyword")) {
      var keyword = queryStringParsed["keyword"];
      request.keyword = keyword;
    }

    if (params.get("from")) {
      var from = queryStringParsed["from"];
      request.from = from;
    }

    if (params.get("to")) {
      var to = queryStringParsed["to"];
      request.to = to;
    }

    document.title = title;
    if(props?.userInfo?.id){
      actions.loadData(request);
    }
  }, [title, actions, location]);

  const download = e => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };


  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <h1 className="dash_heading">Certificate</h1>
          <Row className="pt-5">
              <Search
                onClick={resetPage}
                onSearch={searchData}
                show={false}
                date={true}
                // exportData={exportData}
                title={"Search by user"}
                handleFormClick={handleFormClick}
              />
            </Row>
            <Row className="mt-5">
              <Col>
                <Card>
                  <Card.Body>
                    <Paginate onClick={handlePageClick} />
          {/* <div className="box_wrap shadow-sm bg-white mentor_box mt-5"> */}
          {certificateData?.length>0 ?
            <Table striped responsive>
            <thead >
              <tr>
                {/* <th>User</th> */}
                <th>Engaged with</th>
                <th>Special certificate</th>
                <th>Accept date</th>
                <th>Session end</th>
                <th>Created at</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {certificateData?.map((item, ind)=> 
            <tr key={ind}>
              {/* <th scope="row">
                {item?.user_data?.full_name} 
              </th> */}
              <td scope="row">
              {item?.user_data?.role_id==='Mentees'? item?.mentor_data
                ?.full_name :item?.mentee_data?.full_name }
              </td>
              <td scope="row">
               {item.is_special_certificate ? 'Yes' : 'No'}
              </td>
              <td scope="row">
              {item.accept_date}
              </td>
              <td scope="row">
              {item.session_end_date}
              </td>
              <td scope="row">
              {item.created_at}
              </td>
          <td scope="row">
              <OverlayTrigger overlay={<Tooltip> Download</Tooltip>}>
                      <Button
                        variant="warning py-1 px-2"
                        href={item.cert_file} target="_blank"
                        // onClick={e => download(e)}
                      >
                        <i className="fas fa-download mr-0"></i>
                      </Button>
                    </OverlayTrigger>
              
              {/* <a href={item.cert_file} target="_blank">Download</a> */}
              </td>
            </tr>)}
            </tbody>
            </Table>
          : 'No certificate found'}
          {/* </div> */}
          </Card.Body>
          </Card>
          </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    certificateData: state.certificateData,
    userInfo: state.userInfo,
    isAuth: state.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(certificateAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);