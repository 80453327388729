import React, { useState, useEffect } from "react";
import { LOAD_ORGANIZATION_LIST } from "../../utils/apiPath";
import { LOAD_SUBAREA_LIST, LOAD_DOMAINS_LIST} from "../../utils/apiPath";
import { agent } from "../../utils/agent";
import countryList from "react-select-country-list";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

import {
  Row,
  Col,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
const queryString = require("query-string");

export default function Index(props) {
    
    const { show, date, title, role, exportData, resetFilter, resetPage, isDate=false, url } = props;
    
    const history = useHistory();
    const location = useLocation();
    const [keyword, setKeyword] = useState(null);
    const [fromDate, handleFromDateChange] = useState(null);
    const [toDate, handleToDateChange] = useState(null);

    const handleClickFilter = () => {
        isDate ? setKeyword(null) : setKeyword('');
        handleFromDateChange(null);
        handleToDateChange(null);
        props.onClick();
        // prop.resetPage();
      };

    useEffect(()=>{
    isDate ? setKeyword(null) : setKeyword('');
    handleFromDateChange(null)
    handleToDateChange(null)
    }, [role, url])

    const handleClickSearch = (e) => {
    
        e.preventDefault()
        const params = {};
        params.keyword = keyword;
        if (fromDate) {
          params.from = fromDate;
        }
        if (toDate) {
          params.to = toDate;
        }
        props.onSearch(params);
      }

    useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    if (params.get("keyword")) {
        var keyword = queryStringParsed["keyword"];
        setKeyword(keyword);
    }

    if (params.get("from")) {
        var from = queryStringParsed["from"];
        handleFromDateChange(from);
    }
    if (params.get("to")) {
        var to = queryStringParsed["to"];
        handleToDateChange(to);
    }
    }, [location]);

    const handleClickExport = () => {
    const params = {};
    params.keyword = keyword;


    if (fromDate) {
        params.from = fromDate;
    }
    if (toDate) {
        params.to = toDate;
    }
    props.exportData(params);
    };
    
  const today = new Date();
  const maxDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
return (
    <>
     <Col>
      <Card>
      <Card.Body>
          <Form>
            <Row className="align-items-center">
              {/* {window.location.pathname != "/admin/mantes-status-report" &&
                window.location.pathname != "/admin/mentor-status-report" &&
                window.location.pathname != "/admin/user-data-report" && (
                  <Col md={3}>
                    {isDate? <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        format="yyyy-MM-dd"
                        id="date-picker-from"
                        label={title}
                        className="w-100"
                        value={keyword}
                        onChange={(date) =>
                        setKeyword(
                            moment(new Date(date)).format("YYYY-MM-DD")
                        )
                        }
                    />
                    </MuiPickersUtilsProvider>: <TextField
                        className="w-100"
                        id="standard-basic"
                        label={title}
                        value={keyword}
                        onChange={(event) => setKeyword(event.target.value)}
                    />}
                  </Col>)} */}

                  {date && (
                <Col md={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disableFuture
                      format="yyyy-MM-dd"
                      id="date-picker-from"
                      label="From"
                      maxDate={maxDate}
                      className="w-100"
                      value={fromDate}
                      onChange={(date) =>
                        handleFromDateChange(
                          moment(new Date(date)).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Col>
                
              )}
              
              {date && (
                <Col md={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disableFuture
                      format="yyyy-MM-dd"
                      className="w-100"
                      id="date-picker-to"
                      minDate={fromDate ? fromDate : ''}
                      label="To"
                      value={toDate}
                      onChange={(date) =>
                        handleToDateChange(
                          moment(new Date(date)).format("YYYY-MM-DD")
                        )
                      }
                      
                    />
                  </MuiPickersUtilsProvider>
                </Col>
              )}

<Col md={3}>
                {window.location.pathname !== "/admin/mantes-status-report" && (
                  <OverlayTrigger overlay={<Tooltip>Search</Tooltip>}>
                    <Button
                      type='submit'
                      variant="primary py-2 px-2 mt-3 mr-3"
                      onClick={(e) => handleClickSearch(e)}
                    >
                      <i className="fas fa-search mr-0"></i>
                    </Button>
                  </OverlayTrigger>
                )}
                <OverlayTrigger overlay={<Tooltip>Reset</Tooltip>}>
                  <Button
                    variant="danger py-2 px-2 mt-3"
                    onClick={() => handleClickFilter()}
                  >
                    <i className="fas fa-redo-alt  mr-0"></i>
                  </Button>
                </OverlayTrigger>
                {/* {show && (
                  <Button
                      variant="success py-1 px-2 mt-3"
                      onClick={() => handleClickAdd()}
                    >
                  <OverlayTrigger overlay={<Tooltip>Add New</Tooltip>}>
                      <i className="fas fa-plus-circle mr-0"></i>
                  </OverlayTrigger>
                    </Button>
                )} */}

                {/* {exportData && (
                  <OverlayTrigger overlay={<Tooltip> Download</Tooltip>}>
                    <Button
                      variant="warning py-1 px-2 mt-3"
                      onClick={() => handleClickExport()}
                    >
                      <i className="fas fa-download mr-0"></i>
                    </Button>
                  </OverlayTrigger>
                )} */}
              </Col>

            </Row>
          </Form>
          </Card.Body>
      </Card>
     </Col>
    </>
)
}
