import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function eventsReducer(state = initialState.eventsData, action) {
  switch (action.type) {
    case types.LOAD_EVENTS_DATA_SUCCESS:
        return action.eventsData;
    default:
        return state;
  }  
}
