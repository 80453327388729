import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import * as settingActions from "../../actions/settingActions";
import * as homePageActions from "../../actions/homePageActions";
import Slider from "react-slick";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import {useLocation, useHistory, Link} from "react-router-dom";
import { showToasterWithOk } from "../../utils/helpers";
import firebase from "../../Firebase";
import Moment from "moment";
import ReactTooltip from 'react-tooltip';

import {
  
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
 
} from "react-share";

const Index = (props) => {
  const {actions, isAuth,userInfo } = props;
  const slug = props.match.params.slug;
  const history = useHistory();
  const location = useLocation();

  
  //console.log(leaders);
  const [ResourcesDetail, setResourcesDetail] = useState([]);  
  const [likecount, setlikecount] = useState([]);


  useEffect(() => {
    //window.scrollTo(0, 0);
    document.title = ResourcesDetail.title;
    document.querySelector('meta[name="description"]').setAttribute('content', ResourcesDetail.title);

    });
  const getResourcesDetailsData = (page = 1) => {
    agent
      .get(API.LOAD_RESOURCES + "/getResourcesDetails/" + slug, {})
      .then((res) => {
        setResourcesDetail(res.data.data);
        setlikecount(res.data.data.likes_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  useEffect(() => {
    getResourcesDetailsData();
    
  }, [actions]);

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const PostResourceHub = async (e) => {
    e.preventDefault();
    if (isAuth === false) {
      showToasterWithOk(
        "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
      );
      history.push(`/login`);
    }else{
      window.location = "/post-resource-hub" ;

    }

    
  };
  const Like = async (e, resource_id,status) => {
    e.preventDefault();
    if (isAuth === false) {
      showToasterWithOk(
        "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
      );
      history.push(`/login`);
    }

    agent
      .get(
        API.LOAD_RESOURCES+ "/resourceLike/" + resource_id + '/' +status + "/" + userInfo.id
      )
      .then((res) => {
        
        if (res.data.data === true) {
         // showToasterWithOk(res.data.message);
          setlikecount(res.data.message);

        }
        if (res.data.data === false) {
          showToasterWithOk(res.data.message);
        }
        //history.push("/community-details/" + community_slug);
        //setUserCurrentRelationship(res.data.data);
        //return res.data.data;
      })
      .catch((err) => {
        throw err;
      });
  };
  
  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    ReactTooltip.show('Copied!') ;
  }
  
  return (
    <Fragment>
      
      <section className="outer_main resourcehubouter">
        <Container>

          <div className="page_content_inner">

            <div className="page_heading px-4 mb-0">
              <h1>{ResourcesDetail.title}</h1>
            </div>
      
            <div className="d-md-flex py-4 px-4">

              <div className="d-flex align-items-center">
                <div className="resource_user">
                  <img src={ResourcesDetail.user_image} alt="" />
                </div>

                <div className="resource_name pl-3">
                  <span className="d-block name">{ResourcesDetail.user_name}</span>
                  <small className="d-block date pt-1">{ResourcesDetail.date}</small>
                </div>   
              </div>
              
              <div className="ml-auto pt-2 pt-md-0 flex-wrap d-flex  align-items-center">
                {userInfo.id!==ResourcesDetail.user_id && (
                  <span className="like_link"  onClick={(e) => {
                    Like(e, ResourcesDetail.id,1);
                  }}><i className="far fa-thumbs-up pr-2"></i> {likecount} likes</span>
                )}
              
                <ul className="social_links d-flex px-2 px-sm-5">

                  <li>
                    <FacebookShareButton url={window.location.href} quote={ResourcesDetail.title}>
                    <i className="fab fa-facebook-square"></i>
                    </FacebookShareButton>
                  </li>

                  <li>
                    <TwitterShareButton url={window.location.href} title={ResourcesDetail.title} >
                    <i className="fab fa-twitter"></i>
                    </TwitterShareButton>
                  </li>

                  <li>
                    <LinkedinShareButton url={window.location.href} title={ResourcesDetail.title} >
                    <i className="fab fa-linkedin"></i>
                    </LinkedinShareButton>
                  </li>

                  <li>
                    <EmailShareButton url={window.location.href}>
                    <i className="fas fa-envelope-square"></i>
                    </EmailShareButton>
                  </li>

                  <li>

                    <i className="fas fa-link"  style={{cursor:`pointer`}} data-tip="Copied!" data-event='click focus'></i>
                    <ReactTooltip place="right" type="success" effect="float" globalEventOff={"click"} afterShow={copy} backgroundColor="#ef4343" textColor="#fff"/>

                  </li>
                  
                </ul>

                <span className="btn btn_login mt-3 mt-sm-0" onClick={(e) => {
                  PostResourceHub(e);
                }}>Submit your write up</span>

              </div>
              
            </div>    
      
            <img src={ResourcesDetail.image} className="resources_detail_img"  alt="" />
            
            <p className="m-0 pb-3 pb-md-5">
              <div
                dangerouslySetInnerHTML={prepareHtml(
                    ResourcesDetail.description
                )}
              >
              </div>
            </p>
         
            <div className="d-md-flex py-4 ">
              
              <div className="d-flex align-items-center">
                {userInfo.id!==ResourcesDetail.user_id && (
                  <span style={{margin:`12px`}} className="like_link" onClick={(e) => {
                    Like(e, ResourcesDetail.id,1);
                  }}>
                    <i className="far fa-thumbs-up pr-2"></i> {likecount} likes
                  </span>
                )}
                <ul className="social_links d-flex " style={{padding:`0`}}>
                  <li>
                    <FacebookShareButton url={window.location.href} quote={ResourcesDetail.title}>
                    <i className="fab fa-facebook-square"></i>
                    </FacebookShareButton>
                  </li>
                  <li>
                    <TwitterShareButton url={window.location.href} title={ResourcesDetail.title}>
                    <i className="fab fa-twitter"></i>
                    </TwitterShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton url={window.location.href} title={ResourcesDetail.title} >
                    <i className="fab fa-linkedin"></i>
                    </LinkedinShareButton>
                  </li>
                  <li>
                    <EmailShareButton url={window.location.href}>
                    <i className="fas fa-envelope-square"></i>
                    </EmailShareButton>
                  </li>
                  <li>
                    <i className="fas fa-link"  style={{cursor:`pointer`}} data-tip="Copied!" data-event='click focus'></i>
                    <ReactTooltip place="right" type="success" effect="float" globalEventOff={"click"} afterShow={copy} backgroundColor="#ef4343" textColor="#fff"/>
                  </li>
                </ul>
                {/* <div className="resource_user">
                <img src={ResourcesDetail.user_image}  alt="" />
                </div>

                <div className="resource_name pl-3">
                <span className="d-block name">{ResourcesDetail.user_name}</span>
                <small className="d-block date pt-1">{ResourcesDetail.date}</small>
                </div>    */}
              </div>
        
              <div className="ml-auto pt-2 pt-md-0 flex-wrap d-flex  align-items-center">
                {/* <span className="like_link"><i className="far fa-thumbs-up pr-2"></i> 256 likes</span> */}
           
                <a className="btn btn_login mt-3 mt-sm-0" href={"/resources-details/"+ResourcesDetail.next_slug}>Next</a>

                {/* <a className="btn btn_login mt-3 mt-sm-0" href=""  onClick={(e) => { PostResourceHub(e); }}>Submit your write up</a> */}
              </div>
    
            </div>    
            
          </div>
      
        </Container>
      </section>

    </Fragment>
  );
  }

function mapStateToProps(state) {
  return {
    
    isAuth: state.isAuth, 
    userInfo: state.userInfo,
    

  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(settingActions, homePageActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
