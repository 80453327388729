let config = {
  defaultPath: "",
  basename: "/",
  LOGIN_PATH: "/login",
  APP_NAME: "Vlookup",
  BLANK_LINK: "#!",
  mf_mental_health:
    "I offer a safe space to talk about non-clinical mental health",
  mf_certified_coach: "I am a certified coach",
  mn_open_to_talk:
    "I am open to talk about non-clinical mental health with my Mentor",
  mn_loking_for_coach: "I am looking for a certified coach",
  LGBTQ_discussion: "I offer a safe space for LGBTQ discussions",
  total_ongoing_mentees: "Total Ongoing Mentees",
  total_completed_mentees: "Total Completed Mentees",
  languages: [
    { value: 'Afrikaans', label: 'Afrikaans' },
    { value: 'Albanian', label: 'Albanian' },
    { value: 'Amharic', label: 'Amharic' },
    { value: 'Arabic', label: 'Arabic' },
    { value: 'Bahasa', label: 'Bahasa' },
    { value: 'Basque', label: 'Basque' },
    { value: 'Belarusian', label: 'Belarusian' },
    { value: 'Bengali', label: 'Bengali' },
    { value: 'Bulgarian', label: 'Bulgarian' },
    { value: 'Burmese', label: 'Burmese' },
    { value: 'Cantonese', label: 'Cantonese' },
    { value: 'Catalan', label: 'Catalan' },
    { value: 'Chinese', label: 'Chinese' },
    { value: 'Cree', label: 'Cree' },
    { value: 'Croatian', label: 'Croatian' },
    { value: 'Czech', label: 'Czech' },
    { value: 'Danish', label: 'Danish' },
    { value: 'Dari', label: 'Dari' },
    { value: 'Persian', label: 'Persian' },
    { value: 'Dutch', label: 'Dutch' },
    { value: 'English', label: 'English' },
    { value: 'Estonian', label: 'Estonian' },
    { value: 'Faeroese', label: 'Faeroese' },
    { value: 'Farsi', label: 'Farsi' },
    { value: 'Finnish', label: 'Finnish' },
    { value: 'French', label: 'French' },
    { value: 'Gaelic', label: 'Gaelic' },
    { value: 'German', label: 'German' },
    { value: 'Greek', label: 'Greek' },
    { value: 'Gujarati', label: 'Gujarati' },
    { value: 'Hausa', label: 'Hausa' },
    { value: 'Hawaiian', label: 'Hawaiian' },
    { value: 'Hebrew', label: 'Hebrew' },
    { value: 'Hindi', label: 'Hindi' },
    { value: 'Hungarian', label: 'Hungarian' },
    { value: 'Icelandic', label: 'Icelandic' },
    { value: 'Igbo', label: 'Igbo' },
    { value: 'Indonesian', label: 'Indonesian' },
    { value: 'Irish', label: 'Irish' },
    { value: 'Italian', label: 'Italian' },
    { value: 'Japanese', label: 'Japanese' },
    { value: 'Javanese', label: 'Javanese' },
    { value: 'Kannada', label: 'Kannada' },
    { value: 'Korean', label: 'Korean' },
    { value: 'Kurdish', label: 'Kurdish' },
    { value: 'Latvian', label: 'Latvian' },
    { value: 'Lithuanian', label: 'Lithuanian' },
    { value: 'Macedonian', label: 'Macedonian' },
    { value: 'FYROM', label: 'FYROM' },
    { value: 'Malay', label: 'Malay' },
    { value: 'Malayalam', label: 'Malayalam' },
    { value: 'Malaysian', label: 'Malaysian' },
    { value: 'Maltese', label: 'Maltese' },
    { value: 'Mandarin', label: 'Mandarin' },
    { value: 'Marathi', label: 'Marathi' },
    { value: 'Mongolian', label: 'Mongolian' },
    { value: 'Ndebele', label: 'Ndebele' },
    { value: 'Nigerian', label: 'Nigerian' },
    { value: 'Pidgin', label: 'Pidgin' },
    { value: 'Norwegian', label: 'Norwegian' },
    { value: 'Oromo', label: 'Oromo' },
    { value: 'Pashto', label: 'Pashto' },
    { value: 'Polish', label: 'Polish' },
    { value: 'Portuguese', label: 'Portuguese' },
    { value: 'Punjabi', label: 'Punjabi' },
    { value: 'Rhaeto', label: 'Rhaeto' },
    { value: 'Romanic', label: 'Romanic' },
    { value: 'Romanian', label: 'Romanian' },
    { value: 'Romansh', label: 'Romansh' },
    { value: 'Russian', label: 'Russian' },
    { value: 'Serbian', label: 'Serbian' },
    { value: 'Shona', label: 'Shona' },
    { value: 'Slovak', label: 'Slovak' },
    { value: 'Slovenian', label: 'Slovenian' },
    { value: 'Somali', label: 'Somali' },
    { value: 'Sorbian', label: 'Sorbian' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Sundanese', label: 'Sundanese' },
    { value: 'Swahili', label: 'Swahili' },
    { value: 'Swazi', label: 'Swazi' },
    { value: 'Swedish', label: 'Swedish' },
    { value: 'Tagalog', label: 'Tagalog' },
    { value: 'Tamil', label: 'Tamil' },
    { value: 'Telugu', label: 'Telugu' },
    { value: 'Thai', label: 'Thai' },
    { value: 'Tibetan', label: 'Tibetan' },
    { value: 'Tigrinya', label: 'Tigrinya' },
    { value: 'Tsonga', label: 'Tsonga' },
    { value: 'Tswana', label: 'Tswana' },
    { value: 'Turkish', label: 'Turkish' },
    { value: 'Ukrainian', label: 'Ukrainian' },
    { value: 'Urdu', label: 'Urdu' },
    { value: 'Uyghur', label: 'Uyghur' },
    { value: 'Uzbek', label: 'Uzbek' },
    { value: 'Venda', label: 'Venda' },
    { value: 'Vietnamese', label: 'Vietnamese' },
    { value: 'Welsh', label: 'Welsh' },
    { value: 'Xhosa', label: 'Xhosa' },
    { value: 'Yiddish', label: 'Yiddish' },
    { value: 'Yoruba', label: 'Yoruba' },
    { value: 'Zulu', label: 'Zulu' }
  ]
};

export default config;
