import * as types from "./actionsTypes";
import * as API from "../utils/apiPath";
import { agent } from "../utils/agent";
import { showToaster, showErrorToaster } from "../utils/helpers";
import submittingRequestStatus from "./submittingRequestStatusAction";

export function PostResurcesHubDataSuccess(PostResourcesHubData) {
  return { type: types.SAVE_RESOURCES_DATA_SUCCESS, PostResourcesHubData };
}
export function loadResourcesHubDataSuccess(ResourcesHubData) {
  return { type: types.LOAD_RESOURCES_HUB_DATA_SUCCESS, ResourcesHubData };
}
export function loadNewsletterDataSuccess(NewsletterData) {
  return { type: types.LOAD_NEWSLETTER_DATA_SUCCESS, NewsletterData };
}
export function loadVideoDataSuccess(VideoData) {
  return { type: types.LOAD_VIDEO_DATA_SUCCESS, VideoData };
}

export function PostResurcesHubData(formData) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.SAVE_RESOURCES_HUB, formData)
      .then((response) => {
        
        if (response.status === 503) 
        {
          dispatch(submittingRequestStatus(false));
          showToaster(response.data.message);
        }
        if (response.status === 422) 
        {
          showErrorToaster(response.data.message);
        }
        if (response.status === 200) 
        {
          dispatch(submittingRequestStatus(false));
          dispatch(PostResurcesHubDataSuccess(true));
          dispatch({
            type: "REDIRECT",
            payload: "resource-hub/all-posts",
          });
          showToaster(response.data.message);
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        showToaster(error.message);
      });
  };
}

export function PostCKEditorData(formData) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.CKEDITOR_IMAGE_UPLOAD, formData)
      .then((response) => {
        console.log("CKEditor Uploaded Response ===>>> " + response);
        if (response.status === 503) 
        {
          dispatch(submittingRequestStatus(false));
          showToaster(response.data.message);
        }
        if (response.status === 200) 
        {
          dispatch(submittingRequestStatus(false));
          dispatch(PostResurcesHubDataSuccess(true));
          // dispatch({
          //   type: "REDIRECT",
          //   payload: "resource-hub/all-posts",
          // });
          showToaster(response.data.message);
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        showToaster(error.message);
      });
  };
}

export function loadResourcesHubData(request,id) {
  return async function (dispatch) {
    dispatch(loadResourcesHubDataSuccess(true));
    await agent
      .post(API.RESOURCES_HUB_DATA +
        "/" +
        request.category, {
          params: request,
        })
      .then((response) => {
        dispatch(loadResourcesHubDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadNewsletterData() {
  return async function (dispatch) {
    dispatch(loadNewsletterDataSuccess(true));
    await agent
      .post(API.NEWSLETTER_DATA , { })
      .then((response) => {
        dispatch(loadNewsletterDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadVideoData() {
  return async function (dispatch) {
    dispatch(loadVideoDataSuccess(true));
    await agent
      .post(API.VIDEO_DATA , { })
      .then((response) => {
        dispatch(loadVideoDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}


