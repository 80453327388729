import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function experienceUserReducer(
  state = initialState.userExperience,
  action
) {
  switch (action.type) {
    case types.LOADED_EXPERIENCE_DATA_SUCCESS:
        return action?.userExperience;
    default:
      return state;
  }
}