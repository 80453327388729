// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isAuth: false,
  isSubmitting: false,
  isFetching: false,
  userInfo: {},
  categoriesUser:[],
  profileData: {},
  updateProfileData: {},
  isFrogot: false,
  setting: {},
  pagination: {},
  brand: [],
  homePageData: {},
  mentorsData: [],
  leaders: [],
  testimonials: [],
  community: [],
  ResourcesHubData: [],
  faqData: [],
  filter: {},
  userRegister: {},
  userExperience: [],
  menteeRequestData: [],
  categories: [],
  forums: [],
  forumusers: [],
  reportcategories: [],
  advisors: [],
  teams: [],
  eventsData: [],
  NewsletterData: [],
  VideoData: [],
  programmes: [],
  certificateData: [],
};
