import React, { useEffect,useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../Component/Button";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import Select from "react-select";
const Index = (props) => {
  const { actions } = props;
  const { register, errors, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    let params = data;
    actions.userLoginData(params);
  };
  const [data, setData] = useState([
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
  ]);

  return (
    <>
      <section className="loginouter">
        <Container>
          
          <Row className="align-items-center">
            <Col md={12}>
              <div className="logininnerouter">
                <div>
                  <div className="loginlogo text-center mb-5">
                    <Link to="/" className="logo m-0">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_IMAGE_PATH + "logo.svg"
                        }
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="loginform program_form">
                    <h1>OLAY STEM Mentoring Registration </h1>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form_block">
                      <Row>
                      <Col md={12} sm={12}>
                          <Form.Group controlId="formBasicEmail">
                            <FormControl
                              type="fullName"
                              placeholder="Full Name"
                              className={errors.fullName ? "formError" : ""}
                              name="fullName"
                              aria-invalid={errors.fullName ? "true" : "false"}
                              ref={register({required: true,minLength: 3})}
                            />
                            {errors.fullName &&
                              errors.fullName.type === "required" && (
                                <span role="alert">
                                  Please enter your full name
                                </span>
                              )}
                            {errors.email &&
                              errors.email.type === "minLength" && (
                                <span role="alert">
                                  Your full name should contain atleast 3 characters
                                </span>
                              )}
                          </Form.Group>
                      </Col>
                      <Col md={12} sm={12}>
                        <Form.Group controlId="formBasicEmail">
                          <FormControl
                            type="email"
                            placeholder="Email Address"
                            className={errors.email ? "formError" : ""}
                            name="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            ref={register({
                              required: true,
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              },
                              maxLength: 50,
                            })}
                          />
                          {errors.email &&
                            errors.email.type === "required" && (
                              <span role="alert">
                                Please enter your email address
                              </span>
                            )}
                          {errors.email &&
                            errors.email.type === "pattern" && (
                              <span role="alert">Invalid email address</span>
                            )}
                          {errors.email &&
                            errors.email.type === "maxLength" && (
                              <span role="alert">
                                Your email should contain max 50 characters
                              </span>
                            )}
                        </Form.Group>
                      </Col>
                      <Col md={12} sm={12}>
                        <Form.Group controlId="formBasicEmail">
                          <FormControl
                            type="collegeName"
                            placeholder="College Name"
                            className={errors.collegeName ? "formError" : ""}
                            name="collegeName"
                            aria-invalid={errors.collegeName ? "true" : "false"}
                            ref={register({required: true,minLength: 3,maxLength: 50})}
                          />
                          {errors.collegeName &&
                            errors.collegeName.type === "required" && (
                              <span role="alert">
                                Please enter your full name
                              </span>
                            )}
                          {errors.collegeName &&
                            errors.collegeName.type === "minLength" && (
                              <span role="alert">
                                Your college name should contain atleast 3 characters
                              </span>
                            )}
                          
                        </Form.Group>
                      </Col>
                      </Row>
                     </div>
                     <div className="form_block">
                      <Row>
                      <Col md={12} sm={12}>
                         <h4 className="mb-2">Tell us about your academic life and achievements :</h4> 
                      </Col>
                      <Col md={12} sm={12}>
                      <Form.Group>
                          <select
                            name="role"
                            ref={register({
                              required: "Select	Highest education qualification ",
                            })}
                            className={
                              errors.role
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            
                          >
                            <option value="">Please Select	Highest education qualification*</option>
                            <option value="Mentor">B.E/B.Tech</option>
                            <option value="Mentees">ME/M.Tech</option>
                            <option value="Mentees">MCA</option>
                          </select>
                          {errors.role && (
                            <label className="invalid-feedback text-left">
                              Please select one option
                            </label>
                          )}
                          </Form.Group>
                        </Col>
                      <Col md={12} sm={12}>
                        <Form.Group controlId="formBasicEmail">
                          <FormControl
                            type="collegeName"
                            placeholder="Specialization"
                            className={errors.collegeName ? "formError" : ""}
                            name="collegeName"
                            aria-invalid={errors.collegeName ? "true" : "false"}
                            ref={register({required: true,minLength: 3,maxLength: 50})}
                          />
                          {errors.collegeName &&
                            errors.collegeName.type === "required" && (
                              <span role="alert">
                                Please enter your full name
                              </span>
                            )}
                          {errors.collegeName &&
                            errors.collegeName.type === "minLength" && (
                              <span role="alert">
                                Your college name should contain atleast 3 characters
                              </span>
                            )}
                          
                        </Form.Group>
                      </Col>
                      <Col md={12} sm={12}>
                        <Form.Group>
                          <select
                            name="role"
                            ref={register({
                              required: "Select	Regular Course",
                            })}
                            className={
                              errors.role
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            
                          >
                            <option value="">Please Select Regular Course*</option>
                            <option value="Mentor">Regular course </option>
                            <option value="Mentees">part time course </option>
                           
                          </select>
                          {errors.role && (
                            <label className="invalid-feedback text-left">
                              Please select one option
                            </label>
                          )}
                          </Form.Group>
                        </Col>
                        <Col md={12} sm={12}>
                        <Form.Group>
                          <select
                            name="role"
                            ref={register({
                              required: "Select	Graduation Year",
                            })}
                            className={
                              errors.role
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            
                          >
                            <option value="">Please Select Graduation Year*</option>
                            <option value="Mentor">2023 </option>
                            <option value="Mentees">2024 </option>
                           
                          </select>
                          {errors.role && (
                            <label className="invalid-feedback text-left">
                              Please select one option
                            </label>
                          )}
                           </Form.Group>
                        </Col>
                        <Col md={12} sm={12}>
                         <h4 className="mb-2">Please choose one of these options applicable to you :</h4> 
                      </Col>
                       </Row>   
                       </div>  
                       <div className="form_block"> 
                       <Row> 
                      <Col md={12} sm={12}>
                          <Form.Group>
                            <Form.Check
                              type="radio"
                              label="You have scored 60% and above consistently from class 10 till now"
                              name="remember_me"
                              ref={register}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} sm={12}>
                          <Form.Group>
                            <Form.Check
                              type="radio"
                              label="Your scores varied between less than and above 60%"
                              name="remember_me"
                              ref={register}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} sm={12}>
                          <Form.Group>
                            <Form.Check
                              type="radio"
                              label="You have scored above 60% always but with few backlogs in between"
                              name="remember_me"
                              ref={register}
                            />
                          </Form.Group>
                        </Col>
                        </Row>
                        </div>

                        <div className="form_block"> 
                        <Row>
                        <Col md={12} sm={12}>
                         <h4 className="mb-2">Choose 5 options from the list below, which interest you the most in terms of career options upon completing your education : </h4> 
                      </Col>
                      <Col md={12} sm={12}>
                          <Select
                          //defaultValue={[colourOptions[2], colourOptions[3]]}
                          isMulti
                          name="domains"
                          options={data}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        
                        </Col>

                        <Col md={12} sm={12}>
                          <Form.Group>
                            <Form.Check
                              type="checkbox"
                              label="I declare that I am above 18 years old "
                              name="remember_me"
                              ref={register}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} sm={12}>
                          <Form.Group>
                            <Form.Check
                              type="checkbox"
                              label="I agree to Terms and Conditions and Privacy Policy of vLookUp Pvt Ltd"
                              name="remember_me"
                              ref={register}
                            />
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <div className="form-group mt-3">
                            <Button
                              title={"Login"}
                              className={"btn w-100 loginsubmit"}
                            />
                          </div>
                        </Col> 
                      </Row>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    superUserParams: state.superUserParams,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
