import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
const queryString = require("query-string");

const Paginate = (props) => {
  const { pagination } = props;
  const [viewpage, setViewPage] = useState(0);
  const location = useLocation();
  const handlePageClick = (data) => {
    setViewPage(data.selected);
    props.onClick(data.selected);
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    if (params.get("page")) {
      var page = queryStringParsed["page"] - 1;
      setViewPage(page);
    }
  }, [location]);
  return (
    <>
      <div className="pagination_sec">
        <Row>
          <Col>
            {pagination && pagination.count > 0 && (
              <div>
                Showing 1 to {props.pagination.per_page} of{" "}
                {props.pagination.total} entries
              </div>
            )}
          </Col>
          <Col className="justify-content-end d-flex">
            {pagination && pagination.count > 0 && (
              <ReactPaginate
                forcePage={viewpage}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={props.pagination.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={props.pagination.per_page}
                onPageChange={(v) => handlePageClick(v)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                pageClassName={"page-item"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    pagination: state.pagination,
  };
}

export default connect(mapStateToProps)(Paginate);
