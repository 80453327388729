import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ResourcesHubActions from "../../actions/ResourcesHubActions";
import ViewModal from "./viewModal";
import DataLoader from "../../Component/Loader/DataLoader";

function Index(props) {
  const { actions,isAuth,VideoData} = props;
  const [getViewModal, setViewModal]=useState(false);
  const [getViewData, setViewData]=useState({});


  useEffect(() => {    

    actions.loadVideoData();

  }, [actions]);



  const resoucesPages = async (e, name, slug) => {
    e.preventDefault();
    window.location = "/resource-hub/"+ slug
  };

  const redirectNewsletter = async (e) => {
    e.preventDefault();
    window.location = "/newsletter"
  };

  const redirectVideos = async (e) => {
    e.preventDefault();
    window.location = "/videos"
  };
  
  const resoucesDrop=[{
    'name':'All posts',
    'slug':'all-posts',
    'value':'0',
    'class':'fas fa-mail-bulk',
    'key':'0'
  },
  {
    'name':'People stories',
    'slug':'people-stories',
    'value':'1',
    'class':'fas fa-user-clock',
    'key':'1'
  },
  {
    'name':'Points of view',
    'slug':'points-of-view',
    'value':'2',
    'class':'fas fa-street-view',
    'key':'2'
  },
  {
    'name':'Toolkits',
    'slug':'toolkits',
    'value':'3',
    'class':'fas fa-toolbox',
    'key':'3'
  }
  ];
  useEffect(() => {
   
    document.title = 'vLookUp | Videos';
    
    });

    const onViewModal = (e, video_id, title) => {
      e.preventDefault();
      setViewModal(true);
      setViewData({
        type: 'video',
        title: title,
        item: video_id
      })
    };
  return (
    <div>
      <section className="outer_main">
        
        <Container className="page_heading text-center">
          <h1>Resources (Videos)</h1>
        </Container>

        <div className="page_content">

          <Container>    

            <div className="linkpart">
              <ul>
                {resoucesDrop &&
                resoucesDrop.length &&
                resoucesDrop.map((item, key) => (
                <li key={key}>
                  <span onClick={(e) => {
                  resoucesPages(e, item.name, item.slug);
                  }}><i className={item.class}></i>
                  <h6>{item.name}</h6>
                  </span>
                </li>
                ))}

                <li>
                  <span onClick={(e) => {
                  redirectNewsletter(e);
                  }}>
                    <i className="fas fa-envelope-open-text"></i>
                    <h6>Newsletter</h6>
                  </span>
                </li>

                <li className="linkpart-active">
                  <span onClick={(e) => {
                  redirectVideos(e);
                  }}>
                    <i className="fas fa-video"></i><h6>Videos</h6>
                  </span>
                </li>
              </ul>              
            </div>

            <div className="page_content_inner removecol_condition p-0">
              { VideoData &&
              VideoData.length>0 && (
              <Row>
                { VideoData.map( (item, key) => 
                (
                <Col md={4} sm={6} className="news-listing video-cols" key={key}>
                  <div className="newslleter-cols">
                    <figure>
                      <span className="img-wrapper" onClick={(e) => {
                          onViewModal(e, item.video_id, item.title);
                        }}> 
                        <img className="cursor-pointer" src={item.image} alt={item.title} />
                        <i class="far fa-play-circle cursor-pointer"></i>
                      </span>

                      <figcaption>
                        <h4 className="cursor-pointer" onClick={(e) => {
                          onViewModal(e, item.video_id, item.title);
                        }} >{item.title}</h4>

                        { item.publish_date && (<span className="post-date">Posted on: {item.publish_date}</span> )}
                      </figcaption>

                    </figure>
                  </div>
                </Col>
                ) ) }
              </Row>
              )}
              {VideoData.length === 0 && (
                <div className="text-center" align="center">No data found.</div>
              )}
              
             {VideoData===true ? <DataLoader /> : null}
            </div>

          </Container>

        </div>

      </section>

      {
      getViewModal && (
        <ViewModal
        show={getViewModal}
        viewdata={getViewData}
        onHide={() => setViewModal(false)}
        centered
        size="xl"
        />
      )}

    </div>
  );
}


function mapStateToProps(state) {
  return {
    VideoData: state.VideoData,
    isAuth: state.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(ResourcesHubActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);

