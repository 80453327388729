import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function menteeRequestDataReducer(
  state = initialState.menteeRequestData,
  action
) {
  switch (action.type) {
    case types.LOADED_MENTEE_REQUEST_DATA:
      return action.menteeRequestData;
    default:
      return state;
  }
}
