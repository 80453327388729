import * as types from "../actions/actionsTypes";

let initialState = {
  scheduleListingData: [],
  popoverEvent: false,
};
export default function scheduleReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    // case types.SCHEDULE_LISTING_DATA:
    //   return  action.scheduleListingData || null;



    case types.SCHEDULE_LISTING_DATA:
      return {
        ...state,
        scheduleListingData: action.scheduleListingData
      };
    case types.SCHEDULE_CALENDAR_POPUP:
      return {
        ...state,
        popoverEvent: action.payload
      };
    default:
      return state;
  }
}
