import React from 'react'
import { css } from "@emotion/react";
import { RingLoader } from "react-spinners";

export default function DataLoader() {
const override = css`
display: block;
margin: 0 auto;
`;
  
return (
  <>
    <div className="" style={{width:'100%', textAlign:'center'}}>
        <div className="text-center" >
        <RingLoader color="#123abc" loading={true} css={override} size={50} />
        </div>
    </div>
  </>
)
}
