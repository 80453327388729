import React, { useEffect, useState, useRef } from "react";
import { Badge, Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";


const CalendarCom = ({ handleClosePopover, item, handleChange, handleCancelled, props, handleEdit, popoverEvent }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://addevent.com/libs/atc/1.6.1/atc.min.js';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    }, []);
    console.log("dasasasasasasasasasasasasas", item)
    return (
        <Modal show={popoverEvent} onHide={handleClosePopover} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>{item?.subject}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped responsive className="welcome-table">

                    <tbody>
                        <tr>
                            <td width="50%">
                                <strong>Creator Name </strong>
                            </td>
                            <td width="50%">
                                {item?.creator_name}
                            </td>
                        </tr>

                        <tr>
                            <td width="50%">
                                <strong>Receiver Name </strong>
                            </td>
                            <td width="50%">
                                {item?.receiver_name}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                <strong>Topic</strong>
                            </td>
                            <td width="50%">
                                {item?.subject}
                            </td>
                        </tr>

                        <tr>
                            <td width="50%">
                                <strong>Description </strong>
                            </td>
                            <td width="50%">
                                {item?.description}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                <strong>Meeting Start Date/Time</strong>
                            </td>
                            <td width="50%">
                                {item?.meeting_start_datetime}
                            </td>
                        </tr>

                        <tr>
                            <td width="50%">
                                <strong>Timezone</strong>
                            </td>
                            <td width="50%">
                                {item?.meeting_timezone}
                            </td>
                        </tr>

                        <tr>
                            <td width="50%">
                                <strong>Duration</strong>
                            </td>
                            <td width="50%">
                                {item?.meeting_end_datetime} hours
                            </td>
                        </tr>
                        {item.mStatus == "Accepted" && (
                            <tr>
                                <td width="50%">
                                    <strong>Join Status</strong>
                                </td>
                                <td>
                                    <Switch
                                        checked={props?.userInfo?.id === item?.creator_id ? (item.join_status_creator == "No" || item.join_status_creator == "" ? false : true) : (item.join_status_receiver == "No" || item.join_status_receiver == "" ? false : true)}
                                        onChange={(e) => { handleChange(e, item, "calendar") }}
                                        name="status"
                                        color="primary"
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td width="50%">
                                <strong>Status</strong>
                            </td>
                            <td>
                                <h5>
                                    {item.mStatus == "Created" && (
                                        <Badge variant="primary">
                                            {item?.mStatus}
                                        </Badge>
                                    )}
                                    {item.mStatus == "Accepted" && (
                                        <Badge variant="success">
                                            {item?.mStatus}
                                        </Badge>
                                    )}
                                    {item.mStatus == "Rejected" && (
                                        <Badge variant="danger">
                                            {item?.mStatus}
                                        </Badge>
                                    )}
                                    {item.mStatus == "Rescheduled" && (
                                        <Badge variant="warning">
                                            {item?.mStatus}
                                        </Badge>
                                    )}
                                    {item.mStatus == "Cancelled" && (
                                        <Badge variant="danger">
                                            {item?.mStatus}
                                        </Badge>
                                    )}
                                    {item.mStatus == "Requested_For_Reschedule" && (
                                        <Badge variant="warning">
                                            {item?.mStatus.replace(/_/g, " ")}
                                        </Badge>
                                    )}
                                </h5>
                            </td>
                        </tr>
                        {item?.mStatus == "Rescheduled" || item?.mStatus == "Requested_For_Reschedule" || item?.mStatus == "Rejected" || item?.mStatus == "Cancelled" ?
                            <tr>
                                <td width="50%">
                                    <strong>Reason</strong>
                                </td>
                                <td>
                                    <>
                                        {(item.mStatus == "Rescheduled" || item?.mStatus == "Requested_For_Reschedule") && (
                                            <>{item?.reason_of_rescheduling ? item?.reason_of_rescheduling : "N/A"}</>
                                        )}
                                        {item.mStatus == "Rejected"  && (
                                            <>{item?.reason_of_rejection ? item?.reason_of_rejection : "N/A"}</>
                                        )}
                                        {item.mStatus == "Cancelled" && (
                                            <>{item?.reason_of_cancellation ? item?.reason_of_cancellation : "N/A"}</>
                                        )}
                                    </>
                                </td>
                            </tr> : ""
                        }
                        <tr>
                            <td><strong>Action</strong></td>
                            <td scope="row">
                                {props?.userInfo?.id == item?.creator_id ? (
                                    <>
                                        {item?.mStatus == "Cancelled" || item?.mStatus == "Created" || item?.mStatus == "Rescheduled" || item?.mStatus == "Accepted" || item?.mStatus == "Requested_For_Reschedule"  ? <>{item?.mStatus != "Cancelled" ?
                                            <Button
                                                variant="primary py-1 px-2 mr-2"
                                                onClick={() => handleEdit(item, true, "calendar")}
                                            >
                                                <OverlayTrigger overlay={<Tooltip>Reschedule</Tooltip>}>
                                                    <i className="fas fa-edit mr-0"></i>
                                                </OverlayTrigger>
                                            </Button> : ""
                                        }
                                            {item?.mStatus != "Cancelled" ?
                                                < Button
                                                    variant="primary py-1 px-2"
                                                    onClick={() => handleCancelled(item, "Cancelled", "calendar")}
                                                >
                                                    <OverlayTrigger overlay={<Tooltip>Cancel</Tooltip>}>
                                                        <i className="fa fa-ban mr-0"></i>
                                                    </OverlayTrigger>
                                                </Button> : "N/A"
                                            }
                                        </>
                                            : "N/A"}
                                    </>
                                ) : (
                                    <>
                                        {item?.mStatus != "Cancelled" ? <>{item?.mStatus == "Accepted" || item?.mStatus == "Created" || item?.mStatus == "Rescheduled" ?
                                            <>
                                                {item?.mStatus != "Accepted" ?
                                                    <Button
                                                        variant="success py-1 px-2 mr-2"
                                                        onClick={() => handleCancelled(item, "Accepted", "calendar")}
                                                    >
                                                        <OverlayTrigger overlay={<Tooltip>Accept</Tooltip>}>
                                                            <i className="fa fa-thumbs-up mr-0"></i>
                                                        </OverlayTrigger>
                                                    </Button> : ""
                                                }
                                                <Button
                                                    variant="danger py-1 px-2 mr-2"
                                                    onClick={() => handleCancelled(item, "Rejected", "calendar")}
                                                >
                                                    <OverlayTrigger overlay={<Tooltip>Reject</Tooltip>}>
                                                        <i className="fa fa-thumbs-down mr-0"></i>
                                                    </OverlayTrigger>
                                                </Button>
                                                <Button
                                                    variant="danger py-1 px-2"
                                                    onClick={() => handleCancelled(item, "Requested_For_Reschedule", "calendar")}
                                                >
                                                    <OverlayTrigger overlay={<Tooltip>Request For Reschedule</Tooltip>}>
                                                        <i className="fa fa-paper-plane mr-0"></i>
                                                    </OverlayTrigger>
                                                </Button>
                                            </> : "N/A"
                                        }</> : "N/A"}
                                    </>
                                )}
                            </td>
                        </tr>
                        {item.mStatus != "Cancelled" && item.mStatus != "Rejected" && item.mStatus == "Accepted" && (
                            <tr>
                                <td><strong>Add to Calendar</strong></td>
                                <td>
                                    <div className="addeventatc mt-3">
                                        <i className="fa fa-plus"></i>
                                        Calendar
                                        <span className="start">{item?.meeting_start_datetime}</span>
                                        <span className="end">
                                            {!!item?.meeting_end_datetime
                                                ? item?.meeting_end_datetime
                                                : item?.meeting_start_datetime}
                                        </span>
                                        <span className="timezone">{item?.meeting_timezone}</span>
                                        <span className="title">{item?.subject}</span>
                                        <span className="description">{item?.description}</span>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal >
    );
};


export default CalendarCom;