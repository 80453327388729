import React from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { showToaster } from "../../utils/helpers";
import toggleNetworkRequestStatus from "../../actions/toggleNetworkRequestStatus";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import { loadData } from "../../actions/scheduleListingAction";


const ReasonModal = ({ status, reasonShow, handleClosePopover, listingData, props }) => {
    const { register, errors, handleSubmit } = useForm();
    const dispatch = useDispatch()
    const onSubmit = (data) => {
        dispatch(toggleNetworkRequestStatus(true));
        const request = {};
        request.reason = data?.reason
        request.status = status
        request.id = listingData?.id
        agent.post(API.LOAD_CUSTOMER + "/web-update-meeting-status", request).then((res) => {
            dispatch(toggleNetworkRequestStatus(false));
            dispatch(loadData({}))
            showToaster(res.data.message);
            handleClosePopover()
        }).catch((err) => {
            dispatch(toggleNetworkRequestStatus(false));
            dispatch(loadData({}))
            showToaster("Something went wrong.Please try again.");
            handleClosePopover()
        });
    };

    return (
        <Modal show={reasonShow} onHide={handleClosePopover} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {status == "Cancelled" ? "Reason for Cancel" : ""}
                    {status == "Rescheduled" ? "Reason for Reschedule" : ""}
                    {status == "Requested_For_Reschedule" ? "Reason for Reschedule" : ""}
                    {status == "Rejected" ? "Reason for Reject" : ""}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mentor_detail align-items-center">
                        <Col sm={12} className="mb-4">
                            <label>Reason *</label>
                            <Form.Control
                                as="textarea"
                                className={
                                    errors.reason ? "form-control is-invalid" : "form-control"
                                }
                                name="reason"

                                aria-invalid={errors.reason ? "true" : "false"}
                                ref={register({
                                    required: {
                                        value: true,
                                        message: "Please enter reason.",
                                    },
                                    minLength: 0,
                                    maxLength: 255,
                                    validate: (value) => {
                                        if (value.trim() == "") {
                                            return "Please enter reason.";
                                        } else {
                                            return true;
                                        }
                                    },
                                })}
                            />
                            {errors?.reason && errors?.reason?.message && (
                                <label className="customeValidationText text-left">
                                    {errors?.reason?.message}
                                </label>
                            )}
                            {errors.reason &&
                                errors.reason?.type === "minLength" && (
                                    <label className="customeValidationText text-left">
                                        Reason should contain at least 1
                                        characters.
                                    </label>
                                )}
                            {errors.reason &&
                                errors.reason?.type === "maxLength" && (
                                    <label className="customeValidationText text-left">
                                        Reason should not exceed 255 characters.
                                    </label>
                                )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="form-group mt-3 text-left">
                                <Button
                                    className="btn loginsubmit nextbtn ml-2"
                                    type="submit"
                                >
                                    Ok
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal >
    );
};


export default ReasonModal;