import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import { showToasterWithOk } from "../../utils/helpers";

const Subscribe = (props) => {

  const {register: register2, errors: errors2, handleSubmit: handleSubmit2} = useForm({});
  const [getLoader, setLoader] = useState(false);
  const [subsModalShow, setSubsModalShow] = useState(false);
  const hideModal = () => setSubsModalShow(false);
  
  const onSubmitSubsEmail = data => {
    setLoader(true);
    // console.log(data);
    agent
      .post(API.SAVESUBSCRIBTIONEMAIL, data)
      .then((res) => {
        setLoader(false);
        if (res.data.data === true) {
          setSubsModalShow(false);
          showToasterWithOk(res.data.message);
        }
        if (res.data.data === false) {
         // setSubsModalShow(false);
          showToasterWithOk(res.data.message);
        }
      })
      .catch((err) => {
        showToasterWithOk(err);
      });
  };

  // console.log('localStorage',localStorage);

  React.useEffect(()=>{
    let pop_status = localStorage.getItem('pop_status');
    // console.log('Subs Modal' + pop_status);
    if(pop_status!=1 && !localStorage.getItem('userToken')){
      setSubsModalShow(true);
      localStorage.setItem('pop_status',1);
    }
  },[])
  return (
  
    <>
    
        <section className="start-building">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <div className="title">
                    Subscribe to our newsletter{" "}
                    <span className="d-block">to stay updated with vLookUp!</span>
                  </div>
                    <span className="btn" onClick={() => setSubsModalShow(true)} >
                      Subscribe
                    </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        id="SubsModal"
        centered
        show={subsModalShow}
        onHide={hideModal}
      >
        <Modal.Header closeButton>Subscribe to our newsletter</Modal.Header>
        <Modal.Body>
          <div>
            <Form
              onSubmit={handleSubmit2(onSubmitSubsEmail)}
              className="banner_form signup_class"
              >
              <Form.Group controlId="formSubsEmail" style={{ marginTop: '1rem' }}>
              <FormControl
                  type="email"
                  placeholder="Email Address"
                  className={errors2.email ? "formError" : ""}
                  name="email"
                  aria-invalid={errors2.email ? "true" : "false"}
                  ref={register2({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                    maxLength: 50,
                  })}
                />
                {errors2.email && errors2.email.type === "required" && (
                  <span role="alert" className="err_msg">
                    Please enter your email address
                  </span>
                )}
                {errors2.email && errors2.email.type === "pattern" && (
                  <span role="alert" className="err_msg">
                    Invalid email address
                  </span>
                )}
                {errors2.email && errors2.email.type === "maxLength" && (
                  <span role="alert" className="err_msg">
                    Your email should contain max 50 characters
                  </span>
                )}                   
                <Button
                  type="submit"
                  disabled={getLoader}
                  title={"Submit"}
                  className={"btn btn-signup"}
                >{getLoader ? "Loading..." : "Submit"}</Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Subscribe;
