import React, { useEffect } from "react";
import { notice } from "react-interaction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
import MenteeDashboard from "../../Pages/MenteeDashboard";
import MentorDashboard1 from "../../Pages/MentorDashboard1";

const Index = (props) => {
  const { title, userInfo } = props;
  useEffect(() => {
    document.title = title;
  }, [title]);
  //if (userInfo.role_id != '' && typeof userInfo.role_id != typeof undefined) {
    if (userInfo.role_id === "Mentor") {
      return (
        <>
          <MentorDashboard1 />
        </>
      );
    } else {
      return (
        <>
          <MenteeDashboard />
        </>
      );
    }
  // } else {
  //   notice("Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account.", {
  //     okStyle: {
  //       display: "none"
  //     },
  //   });
  //   // if(window.location.pathname == '/login'){
  //   //   showToasterWithOk("Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account.")
  //   // }
  //   setTimeout(() => {
  //     window.location.href = '/login'
  //   }, 3000)
  // }

};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
