import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import * as settingActions from "../../actions/settingActions";
import * as homePageActions from "../../actions/homePageActions";
import Slider from "react-slick";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import { Link, useHistory } from "react-router-dom";
import { showToasterWithOk } from "../../utils/helpers";
import firebase from "../../Firebase";
import Moment from "moment";

const Index = (props) => {
  const { leaders, actions, community, isAuth, userInfo } = props;

  const [disabled, setDisabled] = useState(false);
  const [room, setRoom] = useState({ roomname: "" });
  const usersDatabase = firebase.database().ref("users/");
  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }
  useEffect(() => {
    //window.scrollTo(0, 0);
    document.title = communityDetail.meta_title ? communityDetail.meta_title : `vLookUp | ${communityDetail?.group_name}`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", communityDetail.meta_description);
  });
  const slug = props.match.params.slug;
  const history = useHistory();

  //console.log(leaders);
  var communities = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  //console.log(leaders);
  const [communityDetail, setCommunityDetail] = useState([]);

  const getOrganizaionData = (page = 1) => {
    agent
      .get(API.LOAD_COMMUNITY + "/getCommunityDetails/" + slug, {})
      .then((res) => {
        setCommunityDetail(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const joinGroupDetail = async (e, community_id, community_slug) => {
    e.preventDefault();
    window.location = "/community-details/" + community_slug;
  };

  const joinExitGroup = async (e, community_id, community_slug) => {
    e.preventDefault();
    if (isAuth === false) {
      showToasterWithOk(
        "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
      );
      history.push(`/login`);
    }

    if (!!disabled) {
      agent
        .get(
          API.LOAD_COMMUNITY + "/exitGroup/" + community_id + "/" + userInfo.id
        )
        .then((res) => {
          if (res.data.data === true) {
            showToasterWithOk(res.data.message);
          }
          if (res.data.data === false) {
            showToasterWithOk(res.data.message);
          }
          //history.push("/community-details/" + community_slug);
          //setUserCurrentRelationship(res.data.data);
          //return res.data.data;
        })
        .catch((err) => {
          throw err;
        });
    } else {
      agent
        .get(
          API.LOAD_COMMUNITY + "/joinGroup/" + community_id + "/" + userInfo.id
        )
        .then((res) => {
          if (res.data.data === true) {
            showToasterWithOk(res.data.message);
          }
          if (res.data.data === false) {
            showToasterWithOk(res.data.message);
          }
          //history.push("/community-details/" + community_slug);
          //setUserCurrentRelationship(res.data.data);
          //return res.data.data;
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  useEffect(() => {
    // console.log('group users',communityDetail.group_users);
    if (communityDetail.group_users) {
      for (var index = 0; index < communityDetail.group_users.length; ++index) {
        var users = communityDetail.group_users[index];
        // console.log(users.id +'==='+ userInfo.id);
        if (users.id === userInfo.id) {
          setDisabled(true);
          break;
        }
      }
    }
  }, [communityDetail, userInfo.id]);
  // console.log("`p` exists",disabled);

  useEffect(() => {
    getOrganizaionData();
    actions.loadLeaderData(20);
    actions.loadCommunityData(20);
  }, [actions]);

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  useEffect(() => {
    if (room.roomname) {
      history.push("/chat?roomKey=" + room.roomname);
    }
  }, [room, history]);

  const handleclickChat = (item) => {
    // console.log(userInfo);
    if (isAuth === false) {
      showToasterWithOk(
        "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
      );
      history.push(`/login`);
      return
    }
    console.log(localStorage.isConfirmed);
    if (localStorage.isConfirmed== "true") {
      if(disabled){
        if (userInfo.id > item.id) {
          var roomUser = userInfo.id + "_" + item.id;
        } else {
          var roomUser = item.id + "_" + userInfo.id;
        }
        let time = Moment.utc().valueOf();
  
        const message = {};
        message.mentor_name = item.full_name;
        message.mentor_image = item.image;
        message.mentor_id = item.id;
        message.mentee_name = userInfo.full_name;
        message.mentee_image = userInfo.image;
        message.mentee_id = userInfo.id;
        message.device_type = "Web";
        message.message = "";
        message.key = roomUser;
        message.role_id = userInfo.role_id;
        message.status = 0;
        message.time = Moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
        message.epoch = -1 * time;
  
        setRoom({ ...room, roomname: roomUser });
  
        let mentorDatabaseKey = "users/" + item.id + "/" + roomUser;
        usersDatabase.equalTo(mentorDatabaseKey).once("value", (snapshot) => {
          if (snapshot.exists()) {
          } else {
            const newUser = firebase.database().ref(mentorDatabaseKey);
            newUser.set(message);
          }
        });
  
        let menteedatabaseKey = "users/" + userInfo.id + "/" + roomUser;
        usersDatabase.equalTo(menteedatabaseKey).once("value", (snapshot) => {
          if (snapshot.exists()) {
          } else {
            const newUser = firebase.database().ref(menteedatabaseKey);
            newUser.set(message);
          }
        });
      } else{
        showToasterWithOk("Please Join The Group First.");
        return
      }
    } else {
      showToasterWithOk("Please Complete Your Profile First.");
      return
    }
  };

  return (
    <div>
      <section className="community_section">
        <div className="content_inner">
          <img
            src={communityDetail.image}
            alt="women leadership mentoring"
            className="img-fluid back_img"
          />
          {/* <img
            src="assets/img/cm_back_shape.png"
            className="img-fluid shape_img"
            alt="find a career mentor online"
          /> */}
        </div>
      </section>
      <section className="community_page_inner">
        <Container className="container">
          <div className="row_c d-flex">
            <div className="community_content">
              <h1 style={{fontSize:'24px', fontWeight:'600'}} className="community_heading">
                vLookUp {communityDetail.group_name} Community
              </h1>
              <ul>
                <li>
                  <span>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_IMAGE_PATH + "iconc1.png"
                      }
                      className="img-fluid"
                      alt="online mentoring platform"
                    />
                  </span>
                  Admin
                </li>
                <li>
                  <span>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_IMAGE_PATH + "iconc2.png"
                      }
                      className="img-fluid"
                      alt="career coaching and mentoring"
                    />
                  </span>
                  {communityDetail.member_count} Members
                </li>
                {/* <li>
                  <span>
                    <img
                      src="/assets/images/iconc3.png"
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  Open Group
                </li> */}
              </ul>
            </div>

            {disabled !== true && (
              <div className="group_c d-flex align-items-center justify-content-center ml-auto">
                <span
                  onClick={(e) => {
                    joinExitGroup(e, communityDetail.id, communityDetail.slug);
                  }}
                  className={"mr-3 join-bt-active"}
                >
                  {disabled ? "Exit Group" : "Join Group"}
                </span>
                {/* <a href="#!">
                <img src="/assets/img/share_link.png" className="img-fluid" />
              </a> */}
              </div>
            )}
          </div>

          <Row className="mt-4">
            <Col lg="8" md="7">
              <div className="whole_content">
                <div className="community_main_inner">
                  <h2 className="mb-3">About this group</h2>
                </div>

                <div className="community_main_inner mt-3 mt-md-5">
                  <div
                    dangerouslySetInnerHTML={prepareHtml(
                      communityDetail.description
                    )}
                  ></div>
                </div>
              </div>
            </Col>

            <Col lg="4" md="5" className="mt-4 mt-md-0">
              <div className="sidebar_community overflow_custom_style">
                <div className="d-flex align-items-center heading justify-content-between mb-4">
                  <h2>Group Members ({communityDetail.member_count}) </h2>
                  {/* <a href="#">View all</a> */}
                </div>

                {/* LIST */}

                {communityDetail.group_users &&
                  communityDetail.group_users.map((item, key) => (
                    <div className="member_list" key={key}>
                      <ul>
                        <li className="d-flex align-items-center mb-3">
                          <div className="member_image">
                            {item.image && (
                              <img
                                src={item.image}
                                alt="career coaching for women"
                                className="img-fluid"
                              />
                            )}
                            {!item.image && (
                              <img
                                src={
                                  process.env.REACT_APP_STATIC_IMAGE_PATH +
                                  "mentor_women_two.png"
                                }
                                className="img-fluid"
                                alt="career mentor consulting"
                              />
                            )}
                          </div>
                          <div className="member_detail ml-3">
                            <h3>{item.full_name}</h3>
                            <h4>{item.position}</h4>
                            <h4>{item.organization}</h4>
                            {userInfo.id != item.id && (
                              <span
                                className="link_btn btn btn_red"
                                onClick={() => handleclickChat(item)}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_STATIC_IMAGE_PATH +
                                    "msg.png"
                                  }
                                  alt="msg_icon"
                                  className="mr-2 bg-white"
                                />
                                Send Message
                              </span>
                            )}
                            {/* <p className="m-0">{item.main_area}</p> */}
                          </div>
                          {/* <div className="member_btn">
                            <a href="#1">Read More</a>
                          </div> */}
                        </li>
                      </ul>
                    </div>
                  ))}
                {communityDetail.group_users &&
                  communityDetail.group_users.length === 0 && (
                    <div className="text-center">No Joined User found.</div>
                  )}
                {/* LIST */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="communities_sec">
        <Container>
          <div className="title text-center space-bottom position-relative">
            {"Suggested Groups"}
          </div>
          <Slider {...communities}>
            {community &&
              community.length &&
              community.map((item, key) => (
                <div key={key}>
                  <div className="communities_info">
                    <div>
                      <img
                        src={item.thumb_image}
                        alt="career mentor consulting"
                        className="w-100"
                      />
                      <div className="p-3">
                        <h4 className="m-0 heading pb-2">{item.group_name}</h4>
                        <p className="m-0">{item.title}</p>
                      </div>

                      <div className="mx-1 p-3 border-top d-flex align-items-center">
                        <div className="members_tag">
                          <i className="fas fa-users pr-2"></i>{" "}
                          {item.member_count}
                          {" Members"}
                        </div>

                        <Link
                          className="btn link_group ml-auto"
                          to=""
                          onClick={(e) => {
                            joinGroupDetail(e, item.id, item.slug);
                          }}
                        >
                          Join Group
                        </Link>

                        {/* <a href="!#" className="">
                          Join Group
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </Container>
      </section>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    setting: state.setting,
    brand: state.brand,
    isAuth: state.isAuth,
    leaders: state.leaders,
    userInfo: state.userInfo,
    community: state.community,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(settingActions, homePageActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
