import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
const queryString = require("query-string");

const Search = (props) => {

  const { programmes } = props;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keywordData =  params.get('keyword') ? params.get('keyword') : ''
  const allMantersData =  params.get('is_available') ? params.get('is_available') : 'all'
  const mantersData =  params.get('direction') ? params.get('direction') : 'asc'
  const [keyword, setKeyword] = useState("");
  const [allManters, setAllManters] = useState("");
  const [manters, setManters] = useState("");

  
  const handleClickSearch = (e) => {
    e.preventDefault()
    params.set("keyword", keyword);
   
    if(params.get("page")>1){
      params.set("page", 1);
    }
    
    history.push({
      pathname: "/mahadewi-mentoring",
      search: "?" + params,
    });
  };

  const history = useHistory();

  const handleClickFilter = (value) => {
    setManters(value)
    const params = new URLSearchParams(location.search);
    params.set("sort", "first_name");
    params.set("direction", value);
    if(params.get("page")>1){
      params.set("page", 1);
    }
    history.push({
      pathname: `mahadewi-mentoring`,
      search: "?" + params,
    });
  };
  const handleClickFilterAvailable = (value) => {
    setAllManters(value)
    const params = new URLSearchParams(location.search);
    params.set("is_available", value);
    if(params.get("page")>1){
      params.set("page", 1);
    }
    history.push({
      pathname: `mahadewi-mentoring`,
      search: "?" + params,
    });
  };
  const handleClickFilterProgrammes = (value) => {
    const params = new URLSearchParams(location.search);
    if(value!=''){
      params.set("programmes", value);
    }
    else{
      params.delete("programmes");
    }
    if(params.get("page")>1){
      params.set("page", 1);
    }
    history.push({
      pathname: `mahadewi-mentoring`,
      search: "?" + params,
    });
  };
  

  function SidebarAddClass() {
    document.getElementById("left_sidebar").classList.toggle("sidebar_sec");
  }

  useEffect(()=>{
    const params = new URLSearchParams(location.search);
    const keywordData =  params.get('keyword') ? params.get('keyword') : ''
    setKeyword(keywordData)
  }, [])

  return (
    <div className="mentor_form mb-4">
      <span
        onClick={SidebarAddClass}
        className="d-block d-md-none sidebar_toggle_btn mb-3"
      >
        <button className="btn">
          <i className="fas fa-bars"></i> Filter
        </button>
      </span>
      <Row>
        <Col md={6}>
          <div className="search position-relative mb-2 mb-md-0">
            <Form>
            <Form.Control
              type="text"
              value={keyword}
              placeholder="Search by name, country, organization or designation"
              onChange={(event) => {
                setKeyword(event.target.value);
              }}
            ></Form.Control>
            <Button type='submit' onClick={(e) => handleClickSearch(e)}>
              <img
                src={process.env.REACT_APP_STATIC_IMAGE_PATH + "search.png"}
                alt="search"
              />
            </Button>
            </Form>
          </div>
        </Col>
        {/* <Col md={3}>
          <Form.Control
            as="select"
            name="order"
            onChange={(e) => {
              handleClickFilterProgrammes(e.target.value);
            }}
          >
            <option value="">All Programme</option>
            {
              programmes &&
              programmes.map((value)=> (
                <option value={value.id}>{value.program}</option>
              ))
            }
          </Form.Control>
        </Col> */}
        <Col md={3}>
          <Form.Control
            as="select"
            name="order"
            value={manters ? manters : mantersData}
            onChange={(e) => {
              handleClickFilter(e.target.value);
            }}
          >
            <option value="asc">Mentor (A-Z)</option>
            <option value="desc">Mentor (Z-A)</option>
          </Form.Control>
        </Col>
        <Col md={3}>
          <Form.Control
            as="select"
            name="availablity"
            value={allManters? allManters : allMantersData}
            onChange={(e) => {
              handleClickFilterAvailable(e.target.value);
            }}
          >
            <option value="all">All Mentors</option>
            <option value="yes">Available</option>
            <option value="no">Not Available</option>
          </Form.Control>
        </Col>
      </Row>
    </div>
  );
};

export default Search;