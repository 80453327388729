import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as mentorsAction from "../../actions/mentorsAction";
import { Container, Row, Col } from "react-bootstrap";
import UserSidebar from "../../Component/UserSidebar";
import Details from "./details";
import config from "../../config";
const queryString = require("query-string");
const Index = (props) => {
  const { actions, title, mentorsData, isAuth, userInfo } = props;
  const [modalShow, setModalShow] = useState(false);
  const [userData, setUserData] = useState({});
  // const [fn, setFN] = useState("");
  const location = useLocation();
  const getMentor = (e, mentorId) => {
    e.preventDefault();
    if (isAuth === false) {
      history.push(`/login`);
    }
    const userId = userInfo.id;
    const request = {};
    request.userId = userId;
    request.mentorId = mentorId;
    actions.saveSelectMentor(request);
    actions.updateUserData();
  };
  const getUserDetails = (
    first_name,
    image,
    organization,
    position,
    about,
    linkedin
  ) => {
    setModalShow(true);
    setUserData({
      first_name: first_name,
      image: image,
      organization: organization,
      position: position,
      about: about,
      linkedin: linkedin,
    });
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    const request = {};
    if (params.get("sort")) {
      var sort = queryStringParsed["sort"];
      var direction = queryStringParsed["direction"];
      request.sort = sort;
      request.direction = direction;
    }
    if (params.get("page")) {
      var page = queryStringParsed["page"];
      request.page = page;
    }
    if (params.get("keyword")) {
      var keyword = queryStringParsed["keyword"];
      request.keyword = keyword;
    }
    if (params.get("domains")) {
      var domains = queryStringParsed["domains"];
      request.domains = domains;
    }
    actions.getFilterData();
    if (isAuth === true) {
      actions.loadData(request, "Mentor", userInfo.domains);
    } else {
      actions.loadData(request, "Mentor", "");
    }

    window.scrollTo(0, 0);
    document.title = title;
  }, [actions, title, location, userInfo, isAuth]);

  const history = useHistory();
  const resetPage = () => {
    history.push(`/mentors`);
  };
  const searchData = (data) => {
    const params = new URLSearchParams(location.search);
    if (data.keyword) {
      params.set("keyword", data.keyword);
    }
    history.push({
      pathname: "/mentors",
      search: "?" + params,
    });
  };

  const handleFormClick = (item, action) => {
    actions.loadFormPop(action);
  };
  function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }
  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />

        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to="#"
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <h2 className="dash_heading">Dashboard</h2>

          <div className="box_wrap shadow-sm bg-white mentor_box">
            <h3 className="text-center mw-950">
              Based on your preferred domain and objectives these are mentors
              available. Please check mentor details and select your mentor.
            </h3>
            <div className="mentor_list dash_MentorList">
              {mentorsData &&
                mentorsData.map((item, key) => (
                  <Col xs={12} xl={3} lg={4} md={6} sm={6} key={key}>
                    <div className="outer position-relative">
                      <div className="mentor_img">
                        {item.image && (
                          <img
                            src={config.IMAGEROOTPATHLOCAL + item.image}
                            alt="mentor"
                          />
                        )}
                        {!item.image && (
                          <img
                            src="assets/images/mentor_women_two.jpg"
                            alt="business career mentor program"
                          />
                        )}
                      </div>
                      <div className="mentor_info">
                        <h3>{item.first_name}</h3>
                        <h6>{item.position}</h6>
                        <p>
                          {item.organization !== "undefined" ||
                          item.organization === ""
                            ? item.organization
                            : "N/A"}
                        </p>
                      </div>
                      <div className="mentor_btn">
                        <div>
                          <Link
                            to=""
                            onClick={(e) => {
                              getMentor(e, item.id);
                            }}
                          >
                            Select as Mentor
                          </Link>

                          <Link
                            id={key}
                            onClick={(e) => {
                              getUserDetails(
                                item.first_name,
                                item.image,
                                item.organization,
                                item.position,
                                item.about,
                                item.linkedin
                              );
                            }}
                          >
                            {" "}
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
      {modalShow && (
        <Details
          show={modalShow}
          userdata={userData}
          onHide={() => setModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    mentorsData: state.mentorsData,
    isAuth: state.isAuth,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(mentorsAction), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
