import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingActions from "../../actions/settingActions";
import * as homePageActions from "../../actions/homePageActions";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import Iframe from "react-iframe";
import { useForm } from "react-hook-form";
import Button from "../../Component/Button";

const Index = (props) => {
  const { actions, setting, title } = props;
  const { register, errors, handleSubmit, reset } = useForm();

  useEffect(() => {
    const fetchData = () => {
      actions.getSettingData();
      window.scrollTo(0, 0);
      document.title = 'vLookUp - Contact Us';
      document.querySelector('meta[name="description"]').setAttribute('content', "Would like to be a mentor or mentee with us? Queries? Concerns?  Get in touch with us and we will be happy to assist you with your inquiries.");

    };
    fetchData();
  }, [actions, title]);

  const onSubmit = (data) => {
    actions.sendContactUs(data);
    reset()
  };

  return (
    <>
      <section className="outer_main contactboxoutertab">
        <Container className="page_heading text-center">
          <h1>Contact us </h1>
        </Container>
        <div className="contactUs">
          <Container>
            <div className="contactinner">
              <Row>
                <Col md={6}>
                  <h3 className="subHeading">Quick enquiry</h3>
                  <p>Reach out to us for any inquiry</p>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="formBasicFirstName">
                      <FormControl
                        type="text"
                        placeholder="Name*"
                        className={errors.name ? "formError" : ""}
                        name="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        ref={register({
                          required: true,
                          minLength: 3,
                          maxLength: 20,
                        })}
                      />
                      {errors.name && errors.name.type === "required" && (
                        <span role="alert">Please enter your name</span>
                      )}
                      {errors.name && errors.name.type === "minLength" && (
                        <span role="alert">
                          Your name should contain atleast 3 characters
                        </span>
                      )}
                      {errors.name && errors.name.type === "maxLength" && (
                        <span role="alert">
                          Your name should contain max 20 characters
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <FormControl
                        type="email"
                        placeholder="Email Address*"
                        className={errors.email ? "formError" : ""}
                        name="email"
                        aria-invalid={errors.email ? "true" : "false"}
                        ref={register({
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          },
                          maxLength: 50,
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <span role="alert">
                          Please enter your email address
                        </span>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <span role="alert">Invalid email address</span>
                      )}
                      {errors.email && errors.email.type === "maxLength" && (
                        <span role="alert">
                          Your email should contain max 50 characters
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicMessage">
                      <FormControl
                        as="textarea"
                        rows={3}
                        type="text"
                        placeholder="Message*"
                        className={errors.message ? "formError" : ""}
                        name="message"
                        aria-invalid={errors.message ? "true" : "false"}
                        ref={register({
                          required: true,
                          minLength: 5,
                        })}
                      />
                      {errors.message && errors.message.type === "required" && (
                        <span role="alert">Please enter your message</span>
                      )}
                      {errors.message &&
                        errors.message.type === "minLength" && (
                          <span role="alert">
                            Your message should contain atleast 5 characters
                          </span>
                        )}
                    </Form.Group>
                    <Button
                      title={"Submit"}
                      type='submit'
                      className={"btn btn-primary shadow-2 mb-4 w-30"}
                    />
                  </Form>
                </Col>
                <Col md={6}>
                  <div className="map position-relative">
                    <Iframe
                      url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31909.546656528935!2d103.89509045524103!3d1.3606463900615988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da17d5be111bc5%3A0x500f7acaedaa750!2sPaya%20Lebar%2C%20Singapore!5e0!3m2!1sen!2sin!4v1611917198345!5m2!1sen!2sin"
                      width="100%"
                      height="550px"
                      id="myId"
                      className="myClassname"
                      display="initial"
                      position="relative"
                      frameBorder="0"
                    />
                  </div>
                </Col>
              </Row>
              <div className="contact_info">
                <div className="locate">
                  <img src="/assets/images/location.png" alt="Locations" />
                  <h4>
                    Locations <span>{setting.address}</span>
                  </h4>
                </div>

                <div className="locate">
                  <img src="/assets/images/email.png" alt="email" />
                  <h4>
                    Email: <span>{setting.support_email}</span>
                  </h4>
                </div>

                {/* <div className="locate">
                  <img src="/assets/images/call.png" alt="Phone" />
                  <h4>
                    Phone: <span>+{setting.number}</span>
                  </h4>
                </div> */}
              </div>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    setting: state.setting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(settingActions, homePageActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
