import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as homePageActions from "../../actions/homePageActions";
import { useLocation, useHistory } from "react-router-dom";
import {Container,Tabs,Tab,Accordion,Card,useAccordionToggle } from "react-bootstrap";
import Highlighter from "react-highlight-words";
function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    handleClick();
  });

  return (
    <Accordion.Toggle eventKey={eventKey + 1} onClick={decoratedOnClick}>{children}</Accordion.Toggle>
  );
}
const Index = (props) => {
  const { actions, faqData, title } = props;
  const [activeId, setActiveId] = useState(1);
  const [keyword, setKeyword] = useState("");
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [activeKey, setActiveKey] = useState(0);
  useEffect(() => {
    const fetchData = () => {
      actions.loadFaqData();
      window.scrollTo(0, 0);
      document.title = "vLookUp - Frequently Asked Questions";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Want to know more about our unique mentoring platform? Read the frequently asked questions and reach out to us."
        );
    };
    
    fetchData();

    

  
  }, [actions, title]);

  useEffect(() => {
    const elements = document.querySelectorAll('.faq_text_content a');
    const urls = [];
    for (const element of elements) {
      element.removeAttribute('target');
      // urls.push(element.href);
    }
    // console.log("url===>",urls);
    }, [faqData]);

  const handleClickSearch = () => {
    params.set("keyword", keyword);
    history.push({
      pathname: "/faqapp",
      search: "?" + params,
    }); 
    actions.loadFaqData({ keyword: keyword });
  };
  
  const prepareHtml = (description) => {
    if (description) {
      
      return { __html: description || "" };
      
    }
    return { __html: "" };
  };
  const resetPage = () => {
    setKeyword("");
    history.push(`/faqapp`);
    actions.loadFaqData();
  };

  

  return (
    <div>
      <section className="outer_main faq_page_main">
        <Container className="">
          <div className="faq_page">
            <div className="help_form_head">
              <h1>How can we help</h1>
              <div className="help_search">
                <button className="btn" onClick={() => handleClickSearch()}>
                  <i class="fas fa-search"></i>
                </button>
                <input
                  type="text"
                  value={keyword}
                  className="form-control"
                  placeholder="Search by question..."
                  onChange={(event) => {
                    setKeyword(event.target.value);
                  }}
                />
              </div>
            </div>
            {params.get('keyword') ?(
              <div className="faq-tabs">
              <Accordion defaultActiveKey="1">
                {/* <h2 className="title">General Queries</h2> */}
                <p> Search by <strong>"{params.get('keyword')}"</strong>&nbsp;&nbsp;
                <a href="javascript:void(0)" onClick={resetPage} className="link ml-auto">
            Reset Filters
          </a>
                </p>
                {faqData && faqData.length>0 &&
                  faqData.map((item, key) => (
                    <div>
                  
                  <div className="faq_tabs_main">
                    <CustomToggle
                      as={Card.Header}
                      eventKey={key + 1}
                      handleClick={() => {
                        if (activeKey === key + 1) {
                          setActiveKey(null);
                        } else {
                          setActiveKey(key + 1);
                        }
                      }}
                    >
                  {item.title}
                      {activeKey === key + 1 ? <span className='collapse_toggle_inner'><i class="fas fa-minus"></i></span> : <span className='collapse_toggle_inner'><i class="fas fa-plus"></i></span>}
                </CustomToggle>

                    <Accordion.Collapse eventKey={key + 1}>
                      <Card.Body>
                      <div
                        dangerouslySetInnerHTML={prepareHtml(
                          item.description
                        )}
                      ></div>
                        
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>

              
               
               </div>

               ))}
              </Accordion>
              {faqData.length === 0 && (
                  <div className="text-center">No data found.</div>
                )}
            </div>
            
          ):(
            
              
<Tabs
              defaultActiveKey="generalqueries"
              id="uncontrolled-tab-example"
            >
              {/* General-Queries */}
              <Tab eventKey="generalqueries" title="General Queries">
                {/* <div className="faq_head">
                  <div className="browse_by_topic">
                    <h3 className="">Browse by Topic</h3>
                    <div className="linkpart">
                      <ul>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/profile.svg" />
                            </figure>
                            <h6>Create Naukri Profile</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/search.svg" />
                            </figure>
                            <h6>Search</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/apply.svg" />
                            </figure>
                            <h6>Apply</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/arround.svg" />
                            </figure>
                            <h6>Gatting Around Naukri</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/setting.svg" />
                            </figure>
                            <h6>Settings</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/advice.svg" />
                            </figure>
                            <h6>Security Advice</h6>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}

                <div className="faq-tabs">
                  <Accordion defaultActiveKey="1">
                    <h2 className="title">General Queries</h2>
                    {faqData && faqData.length>0 &&
                      faqData.map((item, key) => (
                        <div>
                       {item.type==='General Queries' && (
                     
                      <div className="faq_tabs_main">
                        {/* <Accordion.Toggle eventKey={key + 1}
                         
                         >
                       
                        </Accordion.Toggle> */}

                        <CustomToggle
                          as={Card.Header}
                          eventKey={key + 1}
                          handleClick={() => {
                            if (activeKey === key + 1) {
                              setActiveKey(null);
                            } else {
                              setActiveKey(key + 1);
                            }
                          }}
                        >
                         {item.title}
                          {activeKey === key + 1 ? <span className='collapse_toggle_inner'><i class="fas fa-minus"></i></span> : <span className='collapse_toggle_inner'><i class="fas fa-plus"></i></span>}
                    </CustomToggle>

                        <Accordion.Collapse eventKey={key + 1}>
                          <Card.Body>
                          <div
                            dangerouslySetInnerHTML={prepareHtml(
                              item.description
                            )}
                          ></div>
                            
                          </Card.Body>
                        </Accordion.Collapse>
                      </div>

                   )}
                   
                   </div>

                   ))}
                  </Accordion>

                </div>
              </Tab>

              {/* Mentor Queries */}
              <Tab eventKey="mentorqueries" title="Mentor Queries">
                
              {/* <div className="faq_head">
                  <div className="browse_by_topic">
                    <h3 className="">Browse by Topic</h3>
                    <div className="linkpart">
                      <ul>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/profile.svg" />
                            </figure>
                            <h6>Create Naukri Profile</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/search.svg" />
                            </figure>
                            <h6>Search</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/apply.svg" />
                            </figure>
                            <h6>Apply</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/arround.svg" />
                            </figure>
                            <h6>Gatting Around Naukri</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/setting.svg" />
                            </figure>
                            <h6>Settings</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/advice.svg" />
                            </figure>
                            <h6>Security Advice</h6>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}

                <div className="faq-tabs">
                  <Accordion defaultActiveKey="0">
                    <h2 className="title">Mentors’ Queries</h2>

                    {faqData && faqData.length>0 &&
                      faqData.map((item, key) => (
                        <div>
                       {item.type==='Mentor Queries' && (
                     
                      <div className="faq_tabs_main">
                        <CustomToggle
                          as={Card.Header}
                          eventKey={key + 1}
                          handleClick={() => {
                            if (activeKey === key + 1) {
                              setActiveKey(null);
                            } else {
                              setActiveKey(key + 1);
                            }
                          }}
                        >
                          {item.title}
                          {activeKey === key + 1 ? <span className='collapse_toggle_inner'><i class="fas fa-minus"></i></span> : <span className='collapse_toggle_inner'><i class="fas fa-plus"></i></span>}
                    </CustomToggle>

                        <Accordion.Collapse eventKey={key + 1}>
                          <Card.Body>
                          <div
                            dangerouslySetInnerHTML={prepareHtml(
                              item.description
                            )}
                          ></div>
                            
                          </Card.Body>
                        </Accordion.Collapse>
                      </div>

                   )}
                   
                   </div>

                   ))}
                  </Accordion>
                </div>
              </Tab>

              {/* Mentee Queries */}
              <Tab eventKey="menteequeries" title="Mentee Queries">
                
              {/* <div className="faq_head">
                  <div className="browse_by_topic">
                    <h3 className="">Browse by Topic</h3>
                    <div className="linkpart">
                      <ul>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/profile.svg" />
                            </figure>
                            <h6>Create Naukri Profile</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/search.svg" />
                            </figure>
                            <h6>Search</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/apply.svg" />
                            </figure>
                            <h6>Apply</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/arround.svg" />
                            </figure>
                            <h6>Gatting Around Naukri</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/setting.svg" />
                            </figure>
                            <h6>Settings</h6>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <figure>
                              <img src="./assets/images/advice.svg" />
                            </figure>
                            <h6>Security Advice</h6>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}

                <div className="faq-tabs">
                  <Accordion defaultActiveKey="0">
                    <h2 className="title">Mentees Queries</h2>
                    {faqData &&
                      faqData.length>0 &&
                      faqData.map((item, key) => (
                        <div>
                       {item.type==='Mentee Queries' && (
                     
                      <div className="faq_tabs_main">
                        <CustomToggle
                          as={Card.Header}
                          eventKey={key + 1}
                          handleClick={() => {
                            if (activeKey === key + 1) {
                              setActiveKey(null);
                            } else {
                              setActiveKey(key + 1);
                            }
                          }}
                        >
                        {item.title}
                          {activeKey === key + 1 ? <span className='collapse_toggle_inner'><i class="fas fa-minus"></i></span> : <span className='collapse_toggle_inner'><i class="fas fa-plus"></i></span>}
                    </CustomToggle>

                        <Accordion.Collapse eventKey={key + 1}>
                          <Card.Body>
                          <div
                            dangerouslySetInnerHTML={prepareHtml(
                              item.description
                            )}
                          ></div>
                            
                          </Card.Body>
                        </Accordion.Collapse>
                      </div>

                   )}
                   
                   </div>

                   ))}
                  </Accordion>
                </div>
                
              </Tab>
    
            </Tabs>
              
            
          )}

          </div>
        </Container>
      </section>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    faqData: state.faqData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
