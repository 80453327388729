import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function programmesReducer(state = initialState.programmes, action) {
  switch (action.type) {
    case types.LOAD_PROGRAMMES_DATA_SUCCESS:
        return action.programmes;
    default:
        return state;
  }  
}
