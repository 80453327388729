import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import config from "../../config";
import rainbow from '../../assets/images/rainbow.png'

const Details = (props) => {
  const { userdata } = props;
  const inputString = userdata?.all_main_area
  const inputSubString = userdata?.all_sub_area
  const domain = inputString.split(',');
  const subDomain = inputSubString.split(',');
  const [showMentalHealthTooltip, setShowMentalHealthTooltip] = useState(false);
  const [showCoachTooltip, setShowCoachTooltip] = useState(false);
  const [showLgtbqTooltip, setShowLgtbqTooltip] = useState(false);
  const [showRelationshipTooltip, setShowRelationshipTooltip] = useState(false);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="mentor_detail">
            <Col md={4}>
              <div className="mentor_pic">
                {userdata?.is_reached_limit == 0 ? (userdata?.user_availability_check?.availability == 1 ? <span className="AvailabilityStatus m-3">Available</span> : <span className="UnavailabilityStatus m-3">Unavailable</span>) : <span className="UnavailabilityStatus m-3">Unavailable</span>}
                {userdata.image && <img src={userdata.image} alt="mentor" className="w-100" />}
                {!userdata.image && (
                  <img src="assets/images/mentor_women_two.jpg" alt="mentor" />
                )}
                <div className="mentor_name">
                  <span>
                    {userdata?.country_image_path && (
                      <img src={userdata?.country_image_path} alt="professional consultants for women" className="country_flag" />
                    )}
                  </span>
                  <h3>{userdata.full_name}</h3>
                  <h5><span className="m-0">{userdata.position} {" "}</span></h5>
                  <h5>
                    {userdata.organization === "undefined"
                      ? "N/A"
                      : userdata.organization}
                  </h5>
                  {/* <h4>
                    {userdata.main_area !== "undefined" ||
                      userdata.main_area === ""
                      ? userdata.main_area
                      : "N/A"}
                  </h4> */}
                  <div className="extar_info_link mt-2">
                    <ul className="px-0">
                      {userdata?.mf_mental_health == 1 && (
                        <li className="pl-0">

                          <Tooltip
                            size="4xl"
                            title={<h5>{config?.mf_mental_health}</h5>}
                            describeChild
                            arrow
                            open={showMentalHealthTooltip}
                            onClose={() => setShowMentalHealthTooltip(false)}
                          >
                            <img
                              src={
                                process.env.REACT_APP_STATIC_IMAGE_PATH +
                                "usermind.svg"
                              }
                              onClick={() => setShowMentalHealthTooltip(true)}
                            />
                          </Tooltip>

                        </li>
                      )}
                      {userdata?.mf_certified_coach == 1 && (
                        <li>

                          <Tooltip
                            size="4xl"
                            title={<h5>{config?.mf_certified_coach}</h5>}
                            describeChild
                            arrow
                            open={showCoachTooltip}
                            onClose={() => setShowCoachTooltip(false)}
                          >
                            <img
                              src={
                                process.env.REACT_APP_STATIC_IMAGE_PATH +
                                "useraward.svg"
                              }
                              onClick={() => setShowCoachTooltip(true)}
                            />
                          </Tooltip>

                        </li>
                      )}
                      {userdata?.lgbtq_discussion == 1 && (
                        <li>

                          <Tooltip
                            size="4xl"
                            title={<h5>{config?.LGBTQ_discussion}</h5>}
                            describeChild
                            arrow
                            open={showLgtbqTooltip}
                            onClose={() => setShowLgtbqTooltip(false)}
                          >
                            <img
                              src={rainbow}
                              style={{ width: '47px' }}
                              onClick={() => setShowLgtbqTooltip(true)}
                            />
                          </Tooltip>

                        </li>
                      )}
                      {(userdata?.total_completed_mentees > 0 || userdata?.total_ongoing_mentees > 0) && (
                        <li>

                          <Tooltip
                            size="4xl"
                            title={<h5>No. of mentoring engagements:  {parseInt(userdata?.total_completed_mentees) + parseInt(userdata?.total_ongoing_mentees)}</h5>}
                            describeChild
                            arrow
                            open={showRelationshipTooltip}
                            onClose={() => setShowRelationshipTooltip(false)}
                          >
                            <img
                              src={
                                process.env.REACT_APP_STATIC_IMAGE_PATH +
                                "completed_relationship.png"
                              }
                              onClick={() => setShowRelationshipTooltip(true)}
                            />
                          </Tooltip>

                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8}>
              {/* <h2>About me</h2> */}
              <div className="domain">
                {domain?.length > 0 && domain.map((item) => (
                  <span className="tagPoint">{item}</span>
                ))}

                {subDomain?.length > 0 && (subDomain?.length < 6 ? subDomain.map((item, index) => (
                  <span key={index} className="tagPoint">{item}</span>
                )) : subDomain.map((item, index) => (
                  index < 6 && <span key={index} className="tagPoint">{item}</span>
                )))}

              </div>
              {/* <div className="subdomain">
                {subDomain?.length > 0 && (subDomain?.length < 6 ? subDomain.map((item, index) => (
                  <span key={index} className="tagPoint">{item}</span>
                )) : subDomain.map((item, index) => (
                  index < 6 && <span key={index} className="tagPoint">{item}</span>
                )))}
              </div> */}
              <p className="mt-2">{userdata.about}</p>
              <div className="profile_btn">
                <Link
                  to={{ pathname: userdata.linkedin }}
                  className="link_btn btn m-0"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.REACT_APP_STATIC_IMAGE_PATH + "linkedin.png"
                    }
                    className="mr-2 bg-white"
                    alt="linkedin"
                  />
                  Linkedin Profile
                </Link>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Details;
