import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as menteesLogsAction from "../../actions/menteesLogsAction";
import * as baseActions from "../../actions/baseActions";
import { Col } from "react-bootstrap";
import UserSidebar from "../../Component/UserSidebar";
import Details from "./details";
import { LOAD_MENTEE_REQUEST_LOGS } from "../../utils/apiPath";
import MentoringSession from "../../Component/MentoringSession";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import { UCWords } from "../../utils/helpers";
import { Dropdown } from "react-bootstrap";
import DToolkit from "./dtoolkit";
import firebase from "../../Firebase";
import Moment from "moment";
import { useAtStart } from "react-scroll-to-bottom";
import Feedback from "./Feedback";
import ReportUser from "../Forum/reportUser";
import { Tooltip } from "@material-ui/core";
import config from "../../config";
import ReactPaginate from "react-paginate";
const queryString = require("query-string");

const Index = (props) => {
  const { title, actions, menteeRequestData, userInfo, pagination } = props;

  const [room, setRoom] = useState({ roomname: "" });
  const [sessionModel, setSessionModel] = useState(false);
  const usersDatabase = firebase.database().ref("users/");
  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  var Mentors_details = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    unslick: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          unslick: false,
        },
      },
    ],
  };
  const location = useLocation();
  const history = useHistory();
  const [menteeList, setMenteeList] = useState([]);
  const [completedMenteeList, setCompletedMenteeList] = useState([]);
  const [rejectedMenteeList, setRejectedMenteeList] = useState([]);

  var checkDateCondition = false;
  const getMenteeList = (page = 1) => {
    agent
      .get(LOAD_MENTEE_REQUEST_LOGS, {})
      .then((res) => {
        setMenteeList(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCompletedMenteeList = (page = 1) => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    if (params.get("page")) {
      page = queryStringParsed["page"];
    }
    agent
      .get(API.LOAD_CUSTOMER + `/getCompletedMenteeList?page=${page}`, {})
      .then((res) => {
        setCompletedMenteeList(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRejectedMenteeList = (page = 1) => {
    agent
      .get(API.LOAD_CUSTOMER + "/getRejectedMenteeList", {})
      .then((res) => {
        setRejectedMenteeList(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    //getMenteeList();
    getCompletedMenteeList();
    getRejectedMenteeList();
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    const request = {};
    if (params.get("sort")) {
      var sort = queryStringParsed["sort"];
      var direction = queryStringParsed["direction"];
      request.sort = sort;
      request.direction = direction;
    }
    if (params.get("page")) {
      var page = queryStringParsed["page"];
      request.page = page;
    }
    if (params.get("keyword")) {
      var keyword = queryStringParsed["keyword"];
      request.keyword = keyword;
    }
    document.title = title;
    actions.loadData(request, 4);
  }, [title, actions, location]);

  const [userData, setUserData] = useState({});

  const writeReview = (e, mentee_id, mentor_id, review) => {
    e.preventDefault();
    setModalShow(true);
    setUserData({
      mentee_id: mentee_id,
      mentor_id: mentor_id,
      mentor_review: review,
    });
  };
  const [modalShow, setModalShow] = useState(false);

  const downloadResume = (e, url) => {
    e.preventDefault();
    //window.location.href = url;
    window.open(url, "_blank");
  };
  const checkDate = (date, status) => {
    var todayDate = new Date();
    var sessionEndDate = new Date(date);
    if (todayDate > sessionEndDate) {
      checkDateCondition = true;
      return "Completed";
    }
    if (checkDateCondition === false && status === 3) {
      return "Rejected";
    }
  };

  const checkLessDate = (date) => {
    var todayDate = new Date();
    var sessionEndDate = new Date(date);
    if (todayDate > sessionEndDate) {
      checkDateCondition = true;
      return true;
    }
  };

  useEffect(() => {
    if (room.roomname) {
      history.push("/chat?roomKey=" + room.roomname);
    }
  }, [room, history]);

  const handleclickChat = (item) => {
    if (item.mentor_id > item.mentee_id) {
      var roomUser = item.mentor_id + "_" + item.mentee_id;
    } else {
      var roomUser = item.mentee_id + "_" + item.mentor_id;
    }

    //const roomUser = item.mentor_id + "_" + item.mentee_id;
    let time = Moment.utc().valueOf();
    console.log(userInfo);
    const message = {};
    message.mentor_name = item.mentor_name;
    message.mentor_image = userInfo.image;
    message.mentor_id = item.mentor_id;
    message.mentee_name = item.mentee_name;
    message.mentee_image = item.mentee_image;
    message.mentee_id = item.mentee_id;
    message.device_type = "Web";
    message.message = "";
    message.key = roomUser;
    message.role_id = userInfo.role_id;
    message.status = 0;
    message.time = Moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    message.epoch = -1 * time;

    setRoom({ ...room, roomname: roomUser });

    let mentorDatabaseKey = "users/" + item.mentor_id + "/" + roomUser;
    usersDatabase.equalTo(mentorDatabaseKey).once("value", (snapshot) => {
      if (snapshot.exists()) {
      } else {
        const newUser = firebase.database().ref(mentorDatabaseKey);
        newUser.set(message);
      }
    });

    let menteedatabaseKey = "users/" + item.mentee_id + "/" + roomUser;
    usersDatabase.equalTo(menteedatabaseKey).once("value", (snapshot) => {
      if (snapshot.exists()) {
      } else {
        const newUser = firebase.database().ref(menteedatabaseKey);
        newUser.set(message);
      }
    });
  };

  const [toolkitData, setToolkitData] = useState({});

  const viewToolkit = (
    e,
    mentee_id,
    mentor_id,
    diagnostics,
    diagnostics_sdate
  ) => {
    e.preventDefault();
    setToolkitModalShow(true);
    setToolkitData({
      mentee_id: mentee_id,
      mentor_id: mentor_id,
      mentee_diagnostics: diagnostics,
      diagnostics_sdate: diagnostics_sdate,
    });
  };
  const [modeldata, setModelData] = useState("");
  const [model, setModel] = useState(false);
  const [toolkitShow, setToolkitModalShow] = useState(false);
  const [userData2, setUserData2] = useState({});
  const [reportModalShow, setReportModalShow] = useState(false);
  const [viewpage, setViewPage] = useState(0);
  const handlePageClick = (data) => {
    setViewPage(data.selected);
    let page = data.selected + 1;
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    history.push({
      pathname: "/mentees-logs",
      search: "?" + params,
    });
    window.scrollTo(0, 0);
  };
  const viewReportModal = (e, item) => {
    e.preventDefault();
    setReportModalShow(true);
    setUserData2({ item: item, from: "user" });
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </span>
  ));
  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <div className="box_wrap shadow-sm bg-white mentor_box">
            <h2 className="dash_heading">Past Mentees</h2>

            {menteeRequestData &&
              menteeRequestData.map((item, key) => (
                <>
                  {(item.mentee_termination_status === 2 ||
                    item.mentor_termination_status === 2) && (
                      <div
                        className="mentro_details mb-2 mb-md-4 d-flex  flex-wrap"
                        key={key}
                      >
                        <div className="mentro_details_leftbar">
                          <div className="mentor_pic">
                            <img
                              src={item.mentee_image}
                              alt="career mentor near me"
                            />
                          </div>
                          <div className="profile_btn mt-3 d-none d-sm-block">
                            <Link
                              to={{ pathname: item.mentee_linkedin }}
                              className="link_btn btn m-0"
                              target="_blank"
                            >
                              <img
                                src="/assets/images/linkedin.png"
                                className="mr-2 bg-white"
                                alt="career mentor online"
                              />
                              Linkedin Profile
                            </Link>
                          </div>
                          {item.mentee_diagnostics_sdate !== "0" && (
                            <div className="profile_btn mt-3">
                              <Link
                                to=""
                                className="link_btn btn btn_red m-0"
                                onClick={(e) => {
                                  viewToolkit(
                                    e,
                                    item.mentee_id,
                                    item.mentor_id,
                                    item.mentee_diagnostics,
                                    item.mentee_diagnostics_sdate
                                  );
                                }}
                              >
                                <img
                                  src="/assets/images/notification_icon.png"
                                  alt="review_icon"
                                  className="mr-2 bg-white"
                                />
                                {"Self Assessment"}
                              </Link>
                            </div>
                          )}
                          <div className="extar_info_link">
                            <ul className="px-0 pt-2 mentor_log">
                              <li>
                                {item?.mn_open_to_talk == 1 && (
                                  <Tooltip
                                    size="4xl"
                                    title={<h5>{config?.mn_open_to_talk}</h5>}
                                    describeChild
                                    arrow
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_STATIC_IMAGE_PATH +
                                        "usermind.svg"
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </li>
                              <li>
                                {item?.mn_loking_for_coach == 1 && (
                                  <Tooltip
                                    size="4xl"
                                    title={<h5>{config?.mn_loking_for_coach}</h5>}
                                    describeChild
                                    arrow
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_STATIC_IMAGE_PATH +
                                        "useraward.svg"
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="mentro_details_sidebar">
                          <div className="row ">
                            <Col xl={4}>
                              <div className="d-flex ">
                                <div className="name_info main_area_Details">
                                  <h4 className="mb-0">{item.mentee_name}</h4>
                                  <h5>
                                    <span className="d-block">
                                      {item.mentee_organization}
                                    </span>
                                  </h5>
                                  <h5>{item.mentee_position}</h5>
                                  <h5>{UCWords(item.mantee_main_area)}</h5>
                                </div>
                              </div>
                            </Col>

                            <Col xl={8} className="">
                              <div className="current-mentees profile_btn d-sm-flex  justify-content-xl-end">
                                <span
                                  className="link_btn btn btn_red"
                                  onClick={() => {
                                    setSessionModel(true);
                                    setModelData(item);
                                  }}
                                >
                                  Mentoring Session
                                </span>

                                {item.mentee_review !== "" ? (
                                  <span
                                    className="link_btn btn btn_red"
                                    onClick={() => {
                                      setModel(true);
                                      setModelData(item);
                                    }}
                                  >
                                    {/* <img
                                    src="/assets/images/msg.png"
                                    alt="msg_icon"
                                    className="mr-2 bg-white"
                                  /> */}
                                    Feedback
                                  </span>
                                ) : (
                                  ""
                                )}
                                <span
                                  className="link_btn btn btn_red"
                                  onClick={() => handleclickChat(item)}
                                >
                                  <img
                                    src="/assets/images/msg.png"
                                    alt="msg_icon"
                                    className="mr-2 bg-white"
                                  />
                                  Send Message
                                </span>
                                <Link
                                  to=""
                                  className="link_btn btn"
                                  onClick={(e) => {
                                    writeReview(
                                      e,
                                      item.mentee_id,
                                      item.mentor_id,
                                      item.mentor_review
                                    );
                                  }}
                                >
                                  <img
                                    src="/assets/images/review_blue.png"
                                    alt="how to find a career mentor"
                                    className="mr-2 bg-white"
                                  />
                                  {item.mentor_review === "" && "Write A Review"}
                                  {item.mentor_review !== "" && "Update Review"}
                                </Link>
                                <Link
                                  to=""
                                  className="link_btn btn btn_red"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Completed
                                </Link>
                                <Dropdown>
                                  <Dropdown.Toggle as={CustomToggle} />
                                  <Dropdown.Menu
                                    style={{ marginTop: "10px" }}
                                    size="sm"
                                    title=""
                                  >
                                    {/* <Dropdown.Header>Options</Dropdown.Header> */}
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        viewReportModal(e, item);
                                      }}
                                    >
                                      Report
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </Col>
                          </div>

                          <ul className="date_list py-2 py-md-4">
                            <li className="d-flex align-items-center">
                              <div className="date_icon">
                                <img
                                  src="/assets/images/calender.png"
                                  alt="calender_image"
                                />
                              </div>
                              <div className="date_content pl-2">
                                <span className="d-block">
                                  Session start date
                                </span>
                                <span className="d-block date">
                                  {item.session_start_date}
                                </span>
                              </div>
                            </li>

                            <li className="d-flex align-items-center">
                              <div className="date_icon">
                                <img
                                  src="/assets/images/calender.png"
                                  alt="calendericon"
                                />
                              </div>
                              <div className="date_content pl-2">
                                <span className="d-block">Session end date</span>
                                <span className="d-block date">
                                  {item.session_end_date}
                                </span>
                              </div>
                            </li>
                            {item.mentee_resume && (
                              <li className="d-sm-flex align-items-center">
                                <div className="date_icon">
                                  <img
                                    src="/assets/images/pdf.png"
                                    alt="women leadership mentoring"
                                  />
                                </div>

                                {item.mentee_resume && (
                                  <div className="date_content pl-2">
                                    <Link
                                      to=""
                                      download
                                      className="d-block date color_red"
                                      onClick={(e) =>
                                        downloadResume(e, item.mentee_resume)
                                      }
                                      target="_blank"
                                    >
                                      {"Download Resume"}
                                    </Link>
                                  </div>
                                )}
                              </li>
                            )}

                            <li className="d-flex align-items-center">&nbsp;</li>
                          </ul>

                          <h3 className="mb-1">About me</h3>

                          <div className="">
                            <p>{item.mentee_about}</p>
                          </div>
                        </div>
                      </div>
                    )}
                </>
              ))}

            {/* {menteeRequestData.length === 0 && (
              <div className="text-center">No post mentees found.</div>
            )} */}
            {(menteeRequestData.length === 0 || pagination?.total === 0) && (
              <div className="text-center">No post mentees found.</div>
            )}
          {props.pagination.total_pages && !(menteeRequestData.length === 0 || pagination?.total === 0) && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              siblingCount={5}
              forcePage={viewpage}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={props.pagination.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={(v) => handlePageClick(v)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          )}
          </div>
        </div>
      </div>
      {toolkitShow && (
        <DToolkit
          show={toolkitShow}
          userdata={toolkitData}
          onHide={() => setToolkitModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {sessionModel && (
        <MentoringSession
          show={sessionModel}
          userInfo={userInfo}
          userdata={modeldata}
          onHide={() => setSessionModel(false)}
          actions={actions}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {reportModalShow && (
        <ReportUser
          show={reportModalShow}
          userdata={userData2}
          onHide={() => setReportModalShow(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {model && (
        <Feedback
          show={model}
          userdata={modeldata}
          onHide={() => setModel(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {modalShow && (
        <Details
          show={modalShow}
          userdata={userData}
          onHide={() => setModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    menteeRequestData: state.menteeRequestData,
    isAuth: state.isAuth,
    userInfo: state.userInfo,
    pagination: state.pagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(menteesLogsAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
