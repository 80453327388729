import React, { useEffect, useState } from "react";
import * as ResourcesHubActions from "../../actions/ResourcesHubActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as API from "../../utils/apiPath";
import { agent } from "../../utils/agent";
import { showToaster } from "../../utils/helpers";
import axios from "axios";

const Index = (props) => {

    // console.log(props);

    agent
      .post(API.CKEDITOR_IMAGE_UPLOAD, {})
      .then((response) => {
        console.log("CKEditor Uploaded Response ===>>> " + response);
        if (response.status === 200) 
        {
          showToaster(response.data.message);
        }
      })
      .catch((error) => {
        showToaster(error.message);
      });

  return true;
};

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(Object.assign(ResourcesHubActions), dispatch),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Index);