import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as menteesRequestAction from "../../actions/menteesRequestAction";
import * as baseActions from "../../actions/baseActions";
import UserSidebar from "../../Component/UserSidebar";
import Moment from "moment";
import { Dropdown } from "react-bootstrap";
import RoomList from "./RoomList";
import firebase from "../../Firebase";
import ScrollToBottom from "react-scroll-to-bottom";
import { useLocation, Link } from "react-router-dom";
import { Button, Form, InputGroup, InputGroupAddon } from "reactstrap";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Reportadmin from "../Forum/reportadmin";
import ReportUser from "../Forum/reportUser";

const Index = (props) => {
  const { userInfo } = props;
  const [chats, setChats] = useState([]);
  const [isMessage, setMessage] = useState(true);
  const [roomKey, setRoomKey] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [newchat, setNewchat] = useState({
    roomname: "",
    senderId: "",
    message: "",
    date: "",
    type: "",
  });

  useEffect(() => {
    document.title = "Chat";
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const room = params.get("roomKey");

  useEffect(() => {
    const fetchData = async () => {
      // manage status------------------------------
      var UserStatus = "manage_status/" + userInfo.id;
      const user_status = {};
      user_status.isonline = true;
      const newUser = firebase.database().ref(UserStatus);
      newUser.set(user_status);

      var ref = firebase.database().ref("manage_status/" + userInfo.id);
      ref.update({
        isonline: true,
        status: "I'm online.",
      });
      ref.onDisconnect().update({
        isonline: false,
        status: "I'm offline.",
      });
      //----------------------

      setRoomKey(room);

      firebase
        .database()
        .ref("chats/" + roomKey)
        .on("value", (resp) => {
          setChats([]);
          setChats(snapshotToArray(resp));
        });
    };

    window.scrollTo(0, 0);
    fetchData();
  }, [room, userInfo, roomKey]);

  const snapshotToArray = (snapshot) => {
    const returnArr = [];

    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
    });

    return returnArr;
  };

  useEffect(() => {
    const fetchData = async () => {
      const userKey = "users/" + userInfo.id;
      firebase
        .database()
        .ref(userKey)
        .on("value", (resp) => {
          setCurrentUser({});
          if (snapshotToArray(resp)) {
            snapshotToArray(resp).forEach((item) => {
              if (item.key === room) {
                setCurrentUser(item);
              }
            });
          }
        });
    };
    fetchData();
  }, [userInfo, room]);

  const onChange = (e) => {
    e.persist();
    setMessage(false);
    setNewchat({ ...newchat, [e.target.name]: e.target.value });
  };

  const snapshotToArrayRoom = (snapshot) => {
    const returnArr = [];
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      returnArr.push(item);
    });
    return returnArr;
  };

  const submitMessage = (e) => {
    e.preventDefault();
    if (newchat.message.trim() !== "") {
      const chat = newchat;
      chat.roomname = roomKey;
      chat.senderId = userInfo.id.toString();
      chat.date = Moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      chat.type = "message";
      const newMessage = firebase
        .database()
        .ref("chats/" + roomKey)
        .push();
      newMessage.set(chat);
      setNewchat({
        roomname: "",
        senderId: "",
        message: "",
        date: "",
        type: "",
        image: "",
      });

      if (userInfo.role_id === "Mentees") {
        var databaseKey = "users/" + currentUser.mentor_id + "/" + roomKey;
      } else {
        databaseKey = "users/" + currentUser.mentee_id + "/" + roomKey;
      }

      let time = Moment.utc().valueOf();
      const lastMessage = {};
      lastMessage.epoch = -1 * time;
      lastMessage.message = newchat.message;
      const newUser = firebase.database().ref(databaseKey);
      newUser.update(lastMessage);
      //console.log(roomKey);
      //send mail when user not online---------------------
      let removed_ids_array = roomKey.split("_");
      let recevier_array = removed_ids_array.filter(function (f) {
        return f !== userInfo.id.toString();
      });
      firebase
        .database()
        .ref("manage_status/" + recevier_array[0])
        .on("value", (resp) => {
          let receiver_status = snapshotToArrayRoom(resp);
          if (receiver_status[0] !== true) {
            let data = {};
            data.msg = newchat.message;
            data.sender_name = userInfo.full_name;
            data.receiver_id = recevier_array[0];
            data.roomKey = roomKey;

            agent
              .post(API.SEND_CHAT_MSG, data)
              .then((res) => {
                console.log("success");
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });

      //------------------------------------

      setMessage(true);
    }
  };
  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  // function SidebarRemoveClass() {
  //   document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  // }
  const [userData, setUserData] = useState({});
  const [reportModalShow, setReportModalShow] = useState(false);
  const viewReportModal = (e, item) => {
    e.preventDefault();
    setReportModalShow(true);
    setUserData({ item: item, from: "chat" });
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </span>
  ));
  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner chetboxouter">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <div className="d-flex flex-wrap mb-3 mb-md-0 mb-md-none toggle_btn_outer chat_main">
            <RoomList userInfo={userInfo} roomKey={room} />
            <div
              className="message-bar-head mt-0 p-0"
              style={{ position: "relative" }}
            >
              {currentUser.mentee_image ? (
                <div className="jumbotron_rightsec ">
                  <div className="usr-msg-details">
                    <>
                      <div className="usr-ms-img">
                        {userInfo.id === currentUser.mentor_id ? (
                          <img
                            src={currentUser.mentee_image}
                            alt="ListingImage"
                          />
                        ) : (
                          <img
                            src={currentUser.mentor_image}
                            alt="ListingImage"
                          />
                        )}
                      </div>
                      <div className="usr-mg-info">
                        {userInfo.id === currentUser.mentor_id ? (
                          <h3>{currentUser.mentee_name}</h3>
                        ) : (
                          <h3>{currentUser.mentor_name}</h3>
                        )}
                      </div>
                    </>
                    {/* <i
                      class="fas fa-flag"
                      style={{
                        color: "red",
                        cursor: "pointer",
                        fontSize: "20px",
                        zIndex: 999,
                        position: "absolute",
                        top: "10%",
                        right: "0",
                      }}
                      onClick={(e) => {
                        viewReportModal(e, currentUser);
                      }}
                      title="Report User"
                    ></i> */}
                    <Dropdown
                      style={{
                        zIndex: 999,
                        position: "absolute",
                        top: "10%",
                        right: "0",
                      }}
                    >
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        style={{ marginTop: "10px" }}
                        size="sm"
                        title=""
                      >
                        {/* <Dropdown.Header>Options</Dropdown.Header> */}
                        <Dropdown.Item
                          onClick={(e) => {
                            viewReportModal(e, currentUser);
                          }}
                        >
                          Report
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <ScrollToBottom
                    className="ChatContent"
                    scrollViewClassName="scrollViewClassName"
                    followButtonClassName="followButtonClassName"
                  >
                    {chats.map((item, idx) => (
                      <div key={idx} className="MessageBox">
                        {item.type === "join" || item.type === "exit" ? (
                          <div className="ChatStatus">
                            <span className="ChatDate">{item.date}</span>
                            <span className="ChatContentCenter">
                              {item.message}
                            </span>
                          </div>
                        ) : (
                          <div className="ChatMessage">
                            <div
                              className={`${
                                item.senderId === userInfo.id.toString()
                                  ? "RightBubble"
                                  : "LeftBubble"
                              }`}
                            >
                              <span className="MsgDate">{item.date}</span>
                              <div className="chat_message">{item.message}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </ScrollToBottom>
                  <footer className="StickyFooter p-0">
                    <Form className="MessageForm" onSubmit={submitMessage}>
                      <InputGroup>
                        {/* <Input
                          type="textarea"
                          name="message"
                          id="message"
                          minRows={1}
                          maxRows={5}
                          placeholder="Enter message here"
                          value={newchat.message}
                          onChange={onChange}
                        /> */}
                        <TextareaAutosize
                          aria-label="empty textarea"
                          name="message"
                          id="message"
                          minRows={1}
                          maxRows={5}
                          placeholder="Enter message here"
                          value={newchat.message}
                          onChange={onChange}
                        />

                        <InputGroupAddon addonType="append">
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={isMessage}
                          >
                            <i className="fas fa-paper-plane"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Form>
                  </footer>
                </div>
              ) : (
                <div className="no-chat">
                  <div>
                    <span className="chat_icon">
                      <i className="far fa-comments"></i>
                    </span>
                    {/* <div className="w-100 heading pt-4 pb-3">
                      Lorem Ipsum is
                    </div>
                    <p className="0">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {reportModalShow && (
        <ReportUser
          show={reportModalShow}
          userdata={userData}
          onHide={() => setReportModalShow(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    menteeRequestData: state.menteeRequestData,
    isAuth: state.isAuth,
    userInfo: state.userInfo,
    updateProfileData: state.updateProfileData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(menteesRequestAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
