import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as scheduleListingAction from "../../actions/scheduleListingAction";
import * as baseActions from "../../actions/baseActions";
import UserSidebar from "../../Component/UserSidebar";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import ScheduleMeetingModal from "../MentorDashboard1/scheduleMeetingModal";
import CalendarCom from "./CalendarModel";
import ReasonModal from "./ReasonModal";
const queryString = require("query-string");

const Index = (props) => {
    const { title, actions, scheduleListingData, popoverEvent } = props;
    const [reasonShow, setReasonShow] = useState(false)
    const [item, setItem] = useState({})
    const [mstatus, setMstatus] = useState('')
    const location = useLocation();
    const history = useHistory();
    function SidebarAddClass(e) {
        e.preventDefault();
        document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
    }

    // useEffect(() => {
    //     document.title = title;
    //     if (props?.userInfo?.id) {
    //         actions.loadData({ user_id: props?.userInfo?.id });
    //     }
    // }, [title]);


    const handleChange = (event, item, page) => {
        let params = item;
        if (props?.userInfo?.id === item?.creator_id) {
            params.status = event.target.checked ? "Yes" : "No";
        } else {
            params.status = event.target.checked ? "Yes" : "No";
        }
        actions.updateJoinStatus(params, page);
    }

    const handleCancelled = (item, status, page) => {
        if(status == "Cancelled" || status == "Requested_For_Reschedule" || status == "Rescheduled" || status == "Rejected"){
            actions.setPopoverEvent(false);
            setMstatus(status)
            setReasonShow(true)
            setItem(item)
        }else{
            actions.updateMeetingStatus(item, status, page);
        }
    }

    const [modalShow, setModalShow] = useState(false)
    const [scheduleData, setScheduleData] = useState(false)
    const [updatePage, setUpdatePage] = useState('')


    const handleEdit = (item, ModalStatus, page) => {
        setScheduleData(item)
        setModalShow(ModalStatus)
        if (page == "calendar") {
            actions.setPopoverEvent(false);
            actions.loadData({})
        }
        setUpdatePage(page)
    }


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const queryStringParsed = queryString.parse(location.search);
        const request = {};
        if (params.get("sort")) {
            var sort = queryStringParsed["sort"];
            var direction = queryStringParsed["direction"];
            request.sort = sort;
            request.direction = direction;
        }
        if (params.get("page")) {
            var page = queryStringParsed["page"];
            request.page = page;
        }
        if (params?.get("keyword")) {
            var keyword = queryStringParsed["keyword"];
            request.keyword = keyword;
        }

        if (params.get("from")) {
            var from = queryStringParsed["from"];
            request.from = from;
        }

        if (params.get("to")) {
            var to = queryStringParsed["to"];
            request.to = to;
        }

        document.title = title;
        if (props?.userInfo?.id) {
            actions.loadData(request);
        }
    }, [title, actions, location]);

    const returnEvent = async () => {
        const events = [];
        scheduleListingData.map((data) => {
            let obj = {
                id: data?.id,
                title: data?.subject,
                start: data?.meeting_start_datetime
            }
            events.push(obj)
        })
        return events;
    }

    const handleEventClick = (eventInfo) => {
        actions.setPopoverEvent(true);
        setMstatus('')
        setReasonShow(false)
        const data = scheduleListingData.filter(item => item.id == eventInfo.event.id);
        setItem(data[0])

    }

    const handleClosePopover = () => {
        actions.setPopoverEvent(false);
        setMstatus('')
        setReasonShow(false)
    };



    return (
        <div>
            <div className="dashboard_outer">
                <UserSidebar />
                <div className="dashboard_inner">
                    <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                        <Link
                            to=""
                            onClick={SidebarAddClass}
                            className="toggle_btn d-lg-none"
                        >
                            <i className="fas fa-bars"></i>
                        </Link>
                    </div>
                    <h1 className="dash_heading">Calendar/Meetings</h1>
                    <FullCalendar
                        plugins={[dayGridPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth'
                        }}
                        initialView='dayGridMonth'
                        events={() => returnEvent()}
                        eventClick={handleEventClick}
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                    />
                    {modalShow && (
                        <ScheduleMeetingModal
                            show={modalShow}
                            scheduleData={scheduleData}
                            type="edit"
                            updatePage={updatePage}
                            onHide={() => setModalShow(false)}
                            centered
                            size="xl"
                            className="mentor_modal"
                        />
                    )}
                    {popoverEvent && (
                        <CalendarCom
                            handleClosePopover={handleClosePopover}
                            item={item}
                            handleChange={handleChange}
                            handleCancelled={handleCancelled}
                            props={props}
                            handleEdit={handleEdit}
                            popoverEvent={popoverEvent}
                        />
                    )}
                    {mstatus == "Cancelled" || mstatus == "Requested_For_Reschedule" || mstatus == "Rejected" && reasonShow ?
                        <ReasonModal
                            status={mstatus}
                            handleClosePopover={handleClosePopover}
                            reasonShow={reasonShow}
                            listingData={item}
                            props={props}
                        /> : ""
                    }
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        scheduleListingData: state.scheduleListingReducer.scheduleListingData,
        userInfo: state.userInfo,
        isAuth: state.isAuth,
        popoverEvent: state.scheduleListingReducer.popoverEvent
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            Object.assign(scheduleListingAction, baseActions),
            dispatch
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);