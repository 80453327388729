import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Jumbotron } from "reactstrap";
import firebase from "../../Firebase";
import Loader from "../../Component/Loader";
import { Button } from "react-bootstrap";
import userProfile from '../../assets/images/noImage.png'
import axios from "axios";
function RoomList(props) {
  const { userInfo, roomKey } = props;
  const [userRoom, setUserRoom] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const history = useHistory();

  const snapshotToArrayRoom = (snapshot) => {
    const returnArr = [];
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      returnArr.push(item);
    });
    return returnArr;
  };

  useEffect(() => {
    const fetchData = async () => {
      const userRoomKey = "users/" + userInfo.id;
      firebase
        .database()
        .ref(userRoomKey)
        .orderByChild("epoch")
        .on("value", (resp) => {
          setUserRoom([]);
          setUserRoom(snapshotToArrayRoom(resp));
          setShowLoading(false);
        });
        
    };
    fetchData();
  }, [userInfo]);

  const enterChatRoom = (key) => {
    document.getElementById("OnscrollBody").classList.add("chatbodyclass");
    history.push("/chat");
    history.push("/chat?roomKey=" + key);
  };
  const bodyClassFalse = (e) => {
    document.getElementById("OnscrollBody").classList.remove("chatbodyclass");
  };

  const renderChatList = () => {
    return userRoom.map((item, idx) => {
      return (
        <li
          key={idx}
          onClick={() => {
            enterChatRoom(item.key);
          }}
          className={item.key === roomKey ? "chatActive" : ""}
          >
          <div className="usr-msg-details">
            <div className="usr-ms-img">
              {userInfo.id === item.mentee_id ? (
                <img src={item?.mentor_image || userProfile} alt="ListingImage" />
              ) : (
                <img src={item?.mentee_image } alt="ListingImage" />
              )}
            </div>
            {userInfo.id === item.mentee_id ? (
              <div className="usr-mg-info">
                <h3>{item.mentor_name}</h3>
                <p>{item.message}</p>
              </div>
            ) : (
              <div className="usr-mg-info">
                <h3>{item.mentee_name}</h3>
                <p>{item.message}</p>
              </div>
            )}
            <span className="posted_time">{item.time}</span>
          </div>
        </li>
      );
    });
  };

  return (
    <>
      <div className="form-group mt-3 text-left">
        <Button
          className="btn  backbtn outline mr-2"
          onClick={(e) => {
            e.preventDefault();
            bodyClassFalse(e);
          }}
        >
          <i className="fas fa-times"></i>
        </Button>
      </div>

      <div className="jumbotron_leftsec">
        {showLoading && <Loader />}
        <Jumbotron>
          <ul>{renderChatList()}</ul>
        </Jumbotron>
      </div>
    </>
  );
}

export default RoomList;
