import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import submittingRequestStatus from "./submittingRequestStatusAction";
import * as API from "../utils/apiPath";
import { setToaster } from "../utils/helpers";
import { showErrorToaster } from "../utils/helpers";

export function loadCategoryDataSuccess(categories) {
  return { type: types.LOAD_CATEGORY_DATA_SUCCESS, categories };
}

export function loadReportCategoryDataSuccess(reportcategories) {
  return { type: types.LOAD_REPORT_CATEGORY_DATA_SUCCESS, reportcategories };
}

export function loadForumDataSuccess(forums) {
  return { type: types.LOAD_FORUM_DATA_SUCCESS, forums };
}

export function loadForumUserDataSuccess(forumusers) {
  return { type: types.LOAD_FORUM_USER_DATA_SUCCESS, forumusers };
}
export function loadReportCategoryDataUserSuccess(categoriesUser) {
  return {
    type: types.LOAD_REPORT_CATEGORY_DATA_USER_SUCCESS,
    categoriesUser,
  };
}

export function loadCategoryData() {
  return async function (dispatch) {
    await agent
      .get(API.FORUM_CATEGORY, {})
      .then((response) => {
        // console.log(response.data.data);
        dispatch(loadCategoryDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadForumData(id) {
  return async function (dispatch) {
    await agent
      .get(API.FORUM_DATA_FETCH + "/" + id, {})
      .then((response) => {
        // console.log(response.data.data);
        dispatch(loadForumDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function saveForumQuestion(params, props, setLoader) {
  return async function (dispatch) {
    await agent
      .post(API.SUBMIT_FORUM_QUESTION, params)
      .then((response) => {
        setLoader(true);
        setToaster(response.data.message);
        dispatch(loadForumDataSuccess(response.data.data));
        props.onHide();
      })
      .catch((error) => {
        // console.log('eroorrrrr',error);
        setLoader(false);
        if (error.status === 422) {
          showErrorToaster(error.message);
        }
        throw error;
      });
  };
}

export function saveForumUser(id) {
  return async function (dispatch) {
    await agent
      .post(API.FORUM_USER_SET, id)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function loadForumUserData(user_role) {
  return async function (dispatch) {
    await agent
      .get(API.FORUM_USER_GET + "/" + user_role, {})
      .then((response) => {
        // console.log(response.data.data);
        dispatch(loadForumUserDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function saveForumAnswer(params, props) {
  return async function (dispatch) {
    await agent
      .post(API.SUBMIT_FORUM_ANSWER, params)
      .then((response) => {
        setToaster(response.data.message);
        dispatch(loadForumDataSuccess(response.data.data));
        props.onHide();
      })
      .catch((error) => {
        if (error.status === 422) {
          showErrorToaster(error.message);
        }
        throw error;
      });
  };
}

export function saveForumAnswerLike(params) {
  return async function (dispatch) {
    await agent
      .post(API.FORUM_ANS_LIKE_SET, params)
      .then((response) => {
        dispatch(loadForumDataSuccess(response.data.data));
        if (response.data.message != "") {
          setToaster(response.data.message);
        }
      })
      .catch((error) => {
        if (error.status === 422) {
          showErrorToaster(error.message);
        }
        throw error;
      });
  };
}

export function loadReportCategoryData() {
  return async function (dispatch) {
    await agent
      .get(API.FORUM_REPORT_CATEGORY, {})
      .then((response) => {
        // console.log(response.data.data);
        dispatch(loadReportCategoryDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadReportCategoryDataUser() {
  return async function (dispatch) {
    await agent
      .get(API.FORUM_REPORT_CATEGORY_USER, {})
      .then((response) => {
        console.log(response.data.data, "----------");
        dispatch(loadReportCategoryDataUserSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function saveForumReportAdmin(params, props, setLoader) {
  return async function (dispatch) {
    await agent
      .post(API.SUBMIT_FORUM_REPORT_ADMIN, params)
      .then((response) => {
        setToaster(response.data.message);
        props.onHide();
      })
      .catch((error) => {
        setLoader(false);
        if (error.status === 422) {
          showErrorToaster(error.message);
        }
        throw error;
      });
  };
}

export function saveForumReportUser(params, props, setLoader) {
  return async function (dispatch) {
    await agent
      .post(API.SUBMIT_FORUM_REPORT_USER, params)
      .then((response) => {
        setToaster(response.data.message);
        props.onHide();
      })
      .catch((error) => {
        setLoader(false);
        if (error.status === 422) {
          showErrorToaster(error.message);
        }
        throw error;
      });
  };
}
