import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function communityReducer(
  state = initialState.community,
  action
) {
  switch (action.type) {
    case types.LOAD_COMMUNITY_DATA_SUCCESS:
      return action.community;
    default:
      return state;
  }
}
