import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import * as API from "../utils/apiPath";
import { showToaster } from "../utils/helpers";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import { userLoginData } from "./userActions";
export function loadDataSuccess(scheduleListingData) {
  return {
    type: types?.SCHEDULE_LISTING_DATA,
    scheduleListingData,
  };
}

export function loadNotFoundDataSuccess(isData) {
  return { type: types.SCHEDULE_LISTING_DATA, isData };
}

export function loadpaginationDataSuccess(pagination) {
  return { type: types.LOAD_PAGINATION_DATA_SUCCESS, pagination };
}

export const setPopoverEvent = (status) => async (dispatch) => {
  dispatch({ type: types.SCHEDULE_CALENDAR_POPUP, payload:status });
};

export const loadData = (request, status) => async (dispatch) => {
  try {
    console.log("reuqest",request)  
    request.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const response = await agent.get(API.LOAD_CUSTOMER + '/web-schedule-meetings', {
      params: request,
    });
    if (response.data.data.data.length <= 0) {
      dispatch(loadNotFoundDataSuccess(false));
    }
    if (response.data.data.data) {
      dispatch(loadDataSuccess(response.data.data.data));
      dispatch(loadpaginationDataSuccess(response.data.data.pagination));
      //dispatch(toggleNetworkRequestStatus(false));
    }
  } catch (error) {
    showToaster(error.message);
    //dispatch(toggleNetworkRequestStatus(false));
    dispatch(loadNotFoundDataSuccess(false));
  }
};

export const updateJoinStatus = (params, page) => async (dispatch) => {
  dispatch(toggleNetworkRequestStatus(true));
  const request = {}
  request.id = params.id
  request.status = params.status
  const response = await agent.post(
    API.LOAD_CUSTOMER + "/web-update-meeting-joined-status",
    request
  );
  if (response.status === 200) {
    dispatch({ type: types.SCHEDULE_CALENDAR_POPUP, payload:false })
    dispatch(toggleNetworkRequestStatus(false));
    if (page == "calendar") {
      showToaster(response.data.message, page);
      dispatch({
        type: "REDIRECT",
        payload: "/schedule-meeting-calendar",
      });
    } else {
      dispatch({
        type: "REDIRECT",
        payload: "/schedule-meeting",
      });
    }

  }
};

export const updateMeetingStatus = (params, status, page) => async (dispatch) => {
  dispatch(toggleNetworkRequestStatus(true));
  const request = {}
  request.id = params.id
  request.status = status
  const response = await agent.post(
    API.LOAD_CUSTOMER + "/web-update-meeting-status",
    request
  );
  if (response.status === 200) {
    dispatch({ type: types.SCHEDULE_CALENDAR_POPUP, payload:false })
    dispatch(toggleNetworkRequestStatus(false));
    showToaster(response.data.message);
    if (page == "calendar") {
      dispatch({
        type: "REDIRECT",
        payload: "/schedule-meeting-calendar",
      });
    } else {
      dispatch({
        type: "REDIRECT",
        payload: "/schedule-meeting",
      });
    }
  }
};
