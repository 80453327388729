import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as eventActions from "../../actions/eventActions";
import { Col, Row, Modal, Container, Form, Button } from "react-bootstrap";
import { useLocation, useHistory, Link } from "react-router-dom";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useForm } from "react-hook-form";
import { decode } from "html-entities";

const Index = (props) => {
  const { actions, eventsData } = props;
  const event_type = props?.match?.params?.type;

  const dispatch = useDispatch();
  const [getSlug, setSlug] = useState("");
  const [show, setShow] = useState(false);
  const [showOrganization, setShowOrganization] = useState(false);
  const [getLoader, setLoader] = useState(false);
  const { register, handleSubmit, errors, getValues } = useForm({ mode: 'onChange' });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEventShow = async (e, slug) => {
    //   e.preventDefault();
    setSlug(slug);
    setShow(true);
  };
  useEffect(() => {
    const fetchData = () => {
      actions.loadEventData(event_type);
    };
    fetchData();
  }, [event_type]);

  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //     document.title = "Event";
  //     document.querySelector('meta[name="description"]').setAttribute('content', eventsData.meta_description);

  //   });

  const onSubmit = (data) => {
    setLoader(true);
    const request = {};
    if (!!data.full_name && !!data.email) {
      request.full_name = data.full_name;
      request.email = data.email;
      request.user_type = data.user_type;
      request.subscribe_to_newsletter = data.subscribe_to_newsletter ? 'Yes' : 'No';
      if (data?.organization) {
        request.organisation = data?.organization;
      }
      request.slug = data.slug;
      // setFormError(false);
      actions.handleSubmitRSVP(request, setShow, setLoader);
    } else {
      // setFormError(true);
    }
  };

  return (
    <>
      {/* <Container className="outer_main text-center">
          <h1 style={{fontSize:'24px', fontWeight:'600'}}>Resources </h1>
        </Container> */}
      <section className="outer_main about_page page-content event-page">

        <h1 className=" text-center" style={{ fontSize: '24px', fontWeight: '600', marginBottom: 10 }}>Events </h1>
        <Container>
          <div className="event-wrapper">
            <div className="event-tabs-wr">
              <a
                href="/event/upcoming"
                className={
                  event_type === "upcoming" ? "active event-tabs" : "event-tabs"
                }
              >
                Upcoming Events
              </a>
              <Link
                to="/event/past"
                className={
                  event_type === "past" ? "active event-tabs" : "event-tabs"
                }
              >
                Past Events
              </Link>
            </div>

            <Row className="events_listing-outer">
              {eventsData && eventsData.length > 0 ? (
                eventsData.map((item, key) => (
                  // console.log(item?.short_description?.substring(0, 150),"=========="),
                  <Col lg={4} md={6} key={key}>
                    <div className="event_listing verticle_grid">
                      {item.image && (
                        <a href={"/event-detail/" + item.slug}>
                          <figure>
                            <img src={item.image} />
                          </figure>
                        </a>
                      )}
                      <div className="list_info">
                        {item.title && (
                          <h3>
                            <a
                              style={{ fontWeight: "800" }}
                              href={"/event-detail/" + item.slug}
                            >
                              {item.title}
                            </a>
                          </h3>
                        )}
                        {item.publish_date && (
                          <span className="date">
                            <img
                              src={
                                process.env.REACT_APP_STATIC_IMAGE_PATH +
                                "time.svg"
                              }
                            />
                            {`${moment(new Date(item?.publish_date)).format(
                              "Do MMM YY"
                            )} ${item?.calender_start_date?.split("+")[1]} ${item?.calender_start_date?.split("+")[2]
                              } - ${item?.calender_end_date?.split("+")[1]} ${item?.calender_end_date?.split("+")[2]
                              } SGT`}
                          </span>
                        )}
                        {item.address && (
                          <span className="locationfix">
                            <img
                              src={
                                process.env.REACT_APP_STATIC_IMAGE_PATH +
                                "location.svg"
                              }
                            />
                            {item.address}
                          </span>
                        )}

                        {event_type === "upcoming" && (
                        <>
                          <div
                            title="Add to Calendar"
                            className="addeventatc mt-3"
                          >
                            <i class="fa fa-plus"></i> Calendar
                            <span className="start">{item.start_date}</span>
                            <span className="end">
                              {!!item.end_date
                                ? item.end_date
                                : item.start_date}
                            </span>
                            <span className="timezone">{item.timezone}</span>
                            <span className="title">{item.title}</span>
                            <span className="description">
                              {decode(item.addevent_description, {
                                level: "html5",
                              })}
                            </span>
                            {/* <span className="description">{ decode( item.short_description + '<br><br>For more details view this ' + window.origin + "/event-detail/" + item.slug , {level: 'html5'}) }</span> */}
                            <span className="location">{item.address}</span>
                          </div>
                          <span
                            onClick={(e) => {
                              handleEventShow(e, item.slug);
                            }}
                            className="ms-auto btn btn_login outline_btn btn_white rsvp_btn"
                          >
                            RSVP
                          </span>
                        </>
                       )}

                        {!!item.short_description && (
                          <p>{item?.short_description?.substring(0, 150)}</p>
                        )}
                        <a
                          href={"/event-detail/" + item.slug}
                          className="readmore"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <h3 className="m-auto">We are working on few exciting events and will post details soon. Check out our past events</h3>
              )}
            </Row>
          </div>
        </Container>

        <Modal show={show} onHide={handleClose} className="rsvp_modal" centered>
          <Modal.Header closeButton>RSVP </Modal.Header>
          <Modal.Body className="arrow_icon thumbnail">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="rsvp_full_name" className="mb-3">
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  ref={register({
                    minLength: 3,
                    required: true,
                    maxLength: 50,
                  })}
                  name="full_name"
                />
              </Form.Group>
              {errors.full_name &&
                errors.full_name.type === "required" && (
                  <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                    Please enter Name
                  </label>
                )}
              {errors.full_name &&
                errors.full_name.type === "minLength" && (
                  <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                    Minimum length should be 3
                  </label>
                )}
              {errors.full_name && errors.full_name.type === "maxLength" && (
                <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                  Your name should contain max 50 characters
                </span>
              )}
              <Form.Group controlId="rsvp_email" className="mb-3">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                    maxLength: 50,
                  })}
                  name="email"
                />
              </Form.Group>
              {errors.email && errors.email.type === "required" && (
                <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                  Please enter your email address
                </span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                  Invalid email address
                </span>
              )}
              {errors.email && errors.email.type === "maxLength" && (
                <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                  Your email should contain max 50 characters
                </span>
              )}
              <Form.Group controlId="rsvp_user_type" className="mb-3">
                <Form.Label>Are you a vLookUp user?*</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="user_type"
                  ref={register({
                    required: true,
                  })}
                  onChange={(e) => {
                    e.target.value === 'None' ? setShowOrganization(true) : setShowOrganization(false);
                  }}
                >
                  <option value="">Please select the category</option>
                  <option value="None">No</option>
                  <option value="Mentee">Mentee</option>
                  <option value="Mentor">Mentor</option>
                </Form.Control>
                {errors.user_type && errors.user_type.type === "required" && (
                  <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                    Please select category
                  </span>
                )}
                {showOrganization && <Form.Group controlId="rsvp_organization" className="mb-3">
                  <Form.Label>Organization*</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    maxLength={150}
                    ref={register({
                      minLength: 3,
                      required: true,
                      maxLength: 150,
                    })}
                    name="organization"
                  />
                </Form.Group>}

                {errors.organization &&
                  errors.organization.type === "required" && (
                    <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                      Please enter Organization
                    </label>
                  )}
                {errors.organization &&
                  errors.organization.type === "minLength" && (
                    <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                      Minimum length should be 3
                    </label>
                  )}
                {errors.organization && errors.organization.type === "maxLength" && (
                  <span role="alert" className="err_msg" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                    Organization name should contain max 50 characters
                  </span>
                )}

                <Form.Group controlId="rsvp_organization" className="mb-3">
                  {/* <Form.Label>Organization*</Form.Label> */}
                  <span className="custom_check">
                    Subscribe to our newsletter to stay updated
                    <input
                      // checked
                      name='subscribe_to_newsletter'
                      type="checkbox"
                      defaultChecked={true}
                      // onClick={(e) => setSelectedDomainArray(e)}
                      ref={register({
                      })}
                    ></input>
                    <span className="check_indicator">&nbsp;</span>
                  </span>
                </Form.Group>


                <Form.Control
                  type="hidden"
                  defaultValue={getSlug}
                  ref={register({})}
                  name="slug"
                />
              </Form.Group>
              <Button
                type="submit"
                className="btn btn_submit ml-2"
                align="center"
                disabled={getLoader}
              >
                {getLoader ? "Submitting.." : "Submit"}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    eventsData: state.eventsData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(eventActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
