import React, { useState, useEffect } from "react";
import UserSidebar from "../../Component/UserSidebar";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
import { useForm } from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import countryList from "react-select-country-list";
import { LOAD_ORGANIZATION_LIST } from "../../utils/apiPath";
import { agent } from "../../utils/agent";
import { showErrorToaster, blobToFile } from "../../utils/helpers";
import config from "../../config";
import Select from "react-select";

import {
  Container,
  Row,
  Col,
  input,
  Input,
  Form,
  select,
  Button,
  Dropdown,
} from "react-bootstrap";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import placeholder from "../../assets/images/userprofile.png";
import ImageCropper from "../../Component/ImageCropper";

const UpdateProfile1 = (props) => {
  const { title, actions, updateProfileData, userInfo, programmes, isAuth, userExperience } =
    props;

  const [mantees, setMantees] = useState(
    !updateProfileData.number_of_mantees
      ? updateProfileData.number_of_mantees
      : ""
  );
  const [consentChecked, setConsentChecked] = useState(0)
  const [text, setText] = useState('');
  const [cropImg, setCropImg] = useState("");
  const [cropImgModalShow, setCropImgModalShow] = useState(false);
  const getUserId = localStorage.getItem("userId");
  const [checkBox, setCheckBox] = useState(false);
  const [checkBox2, setCheckBox2] = useState(false);
  const [experience, setExperience] = useState([]);

  const [defaultLanguages, setDefaultLanguages] = useState([]);
  const [languages, setLanguages] = useState([]);
  const languageOptions = config.languages
  useEffect(() => {
    if (updateProfileData.language) {
      setDefaultLanguages(
        languageOptions.filter((item) => {
          return updateProfileData.language
            .split(",")
            .includes(item.value.toString());
        })
      );
    }
  }, [updateProfileData.language]);

  useEffect(() => {
    if (updateProfileData.number_of_mantees) {
      setMantees(updateProfileData.number_of_mantees);
    }
  }, [updateProfileData.number_of_mantees]);
  const [{ alt, src }, setImg] = useState({
    src: placeholder,
    alt: "Upload an Image",
  });
  useEffect(() => {
    document.title = title;
    actions.getUserProfile(userInfo.id);
  }, [title, actions, userInfo]);
  useEffect(() => {
    actions.getAllProgrammes();
  }, []);
  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }
  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }
  const history = useHistory();
  var todayDate = new Date();
  todayDate.setMonth(todayDate.getMonth());
  todayDate = todayDate.toLocaleDateString();
  var d = new Date();
  d.setMonth(d.getMonth() + 3);
  const nextThreeMonthData = d.toLocaleDateString();
  const [available, setAvailable] = useState();
  const showDatePicker = (e) => {
    if (e.target.checked === true) {
      setAvailable(2);
      setShowDateDiv("block");
    } else {
      setAvailable(1);
      setShowDateDiv("none");
    }
  };

  const [resumeText, setResumeText] = useState('');
  const [showDateDiv, setShowDateDiv] = useState("none");
  const [fromDate, handleFromDateChange] = useState(todayDate);
  const [toDate, handleToDateChange] = useState(nextThreeMonthData);
  const [formObject, setFormObject] = useState(false);
  const checkFromDate = (date) => {
    handleFromDateChange(moment(new Date(date)).format("YYYY-MM-DD"));
    if (moment(new Date(date)).isValid() === false) {
      setFormObject(true);
    } else if (moment(new Date(date)).isValid()) {
      setFormObject(false);
    }
  };
  const checkToDate = (date) => {
    handleToDateChange(moment(new Date(date)).format("YYYY-MM-DD"));
    if (moment(new Date(date)).isValid() === false) {
      setFormObject(true);
    } else if (moment(new Date(date)).isValid()) {
      setFormObject(false);
    }
  };
  /* Form Method Handling */
  const [privacy, setPrivacy] = useState({
    organization_privacy: false,
    position_privacy: false,
    country_privacy: false,
    linkedin_privacy: false,
    employement_privacy: false,
    language_privacy: false,
    about_privacy: false,
    domain_privacy: false,
    is_featured: false,
  });
  const handleChange = (event) => {
    setPrivacy({ ...privacy, [event.target.name]: event.target.checked });
  };

  const [employee, setEmployee] = useState('');
  const [country1, setCountry] = useState(updateProfileData.country);
  const [getProgrammes, setProgrammes] = useState(
    updateProfileData.programmes ? updateProfileData.programmes : ""
  );
  const options = countryList().getData();
  const [country] = useState(options);

  const [organizationText, setOrganizationText] = useState(
    "Name of the organization/institute"
  );

  const [positionText, setpositionText] = useState(
    "Position title in the current organization/institute"
  );

  const setOrganizationPlaceholder = (e) => {
    var value = e.target.value;
    setEmployee(value);
    if (value === "yes") {
      setOrganizationText("Name of the current organization/institute");
      setpositionText("Position title in the current organization/institute");
    }
    if (value === "no") {
      setOrganizationText("Name of the previous organization/institute");
      setpositionText("Position title in the previous organization/institute");
    }
  };
  const [selectedResume, setSelectedResume] = useState("");
  const onChangeResume = (e) => {
    if (e.target.files[0]) {
      var size = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2);

      if (!e.target.files[0].name.match(/\.(pdf)$/)) {
        showErrorToaster("Please select valid file.");
      } else if (size > 1) {
        showErrorToaster("Please select resume size less than 1 MB");
      } else {
        const selectedResume = e.target.files[0];
        setResumeText(selectedResume.name);
        setSelectedResume(selectedResume);
      }
    }
  };
  const downloadResume = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank");
    //window.location.href = url;
  };
  const [termsVal, setTermsVal] = useState(updateProfileData.is_terms);
  const handleTermChange = (e) => {
    var checked = e.target.checked;
    if (checked) {
      setTermsVal(e.target.value);
    } else {
      setTermsVal(0);
    }
  };
  const [privacyVal, setPrivacyVal] = useState(updateProfileData.is_privacy);
  const handlePrivacyChange = (e) => {
    var checked = e.target.checked;
    if (checked) {
      setPrivacyVal(e.target.value);
    } else {
      setPrivacyVal(0);
    }
  };

  useEffect(() => {
    if (updateProfileData) {
      setPrivacy({
        organization_privacy: updateProfileData.organization_privacy,
        position_privacy: updateProfileData.position_privacy,
        country_privacy: updateProfileData.country_privacy,
        linkedin_privacy: updateProfileData.linkedin_privacy,
        employement_privacy: updateProfileData.employement_privacy,
        language_privacy: updateProfileData.language_privacy,
        about_privacy: updateProfileData.about_privacy,
        is_featured: updateProfileData.is_featured,
        domain_privacy: updateProfileData.domain_privacy,
      });
      setAvailable(updateProfileData?.user_availability)
      setEmployee(updateProfileData.employee);

      setExperience(typeof (updateProfileData?.experience_levels) === 'object' ? updateProfileData?.experience_levels?.map(item => item.id) : [])
      setCountry(updateProfileData.country);
      if (updateProfileData.language) {
        setLanguages(updateProfileData.language.split(","));
      }
      if (updateProfileData.avail_start_time) {
        handleFromDateChange(updateProfileData.avail_start_time);
      }
      if (updateProfileData.avail_end_time) {
        handleToDateChange(updateProfileData.avail_end_time);
      }
      if (updateProfileData.user_availability === 1) {
        setShowDateDiv("none");
      }
      else if (updateProfileData.user_availability === 2) {
        setShowDateDiv("block");
      }
      if (updateProfileData.is_terms === 1) {
        //document.getElementById("is_terms").disabled = true;
        setTermsVal(1);
      } else {
        setTermsVal(0);
      }
      if (updateProfileData.is_privacy === 1) {
        //document.getElementById("is_privacy").disabled = true;

        setPrivacyVal(1);
      } else {
        setPrivacyVal(0);
      }
      if (updateProfileData?.my_consent == 1) {
        setConsentChecked(1)
      } else if (updateProfileData?.my_consent === '') {
        setConsentChecked(1)
      } else {  
        setConsentChecked(0)
      }

    }
    // if (updateProfileData.image) {
    setImg({
      src: updateProfileData?.image || placeholder,
      alt: "upload image",
    });
    // }

    if (updateProfileData.employee === "no") {
      setpositionText("Position title in the previous organization/institute");
      setOrganizationText("Name of the previous organization/institute");
    }
    if (updateProfileData.employee === "yes") {
      setOrganizationText("Name of the current organization/institute");
      setpositionText("Position title in the current organization/institute");
    }
    // if (updateProfileData.employee === "no") {
    //   setpositionText("Position in organization/institute");
    //   setOrganizationText("Name of organization/institute");
    // }
    // if (updateProfileData.employee === "yes") {
    //   setOrganizationText("Name of organization/institute");
    //   setpositionText("Position in organization/institute");
    // }
  }, [updateProfileData]);

  useEffect(() => {
    if (updateProfileData.role_id == "Mentor") {
      setCheckBox(updateProfileData.mf_mental_health === 1 ? true : false);
      setCheckBox2(updateProfileData.mf_certified_coach === 1 ? true : false);
    } else if (updateProfileData.role_id == "Mentees") {
      setCheckBox(updateProfileData.mn_open_to_talk === 1 ? true : false);
      setCheckBox2(updateProfileData.mn_loking_for_coach === 1 ? true : false);
    }
  }, [updateProfileData?.role_id]);

  const setLanguagesArray = (e) => {
    const val = e.target.value;
    let tempLanguage = [...languages];

    if (tempLanguage.indexOf(val) > -1) {
      tempLanguage.splice(tempLanguage.indexOf(val), 1);
    } else {
      tempLanguage.push(val);
    }
    setLanguages(tempLanguage);
  };
  const { register, setValue, reset, errors, watch, handleSubmit } = useForm();
  const [getLoader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      var size = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2);

      if (size > 30) {
        alert("Please select image size less than 30 MB");
        setImg({
          src: placeholder,
          alt: "Upload an Image",
        });
        setLoader(true);
      } else if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
        showErrorToaster("Please select valid image.");
        setImg({
          src: placeholder,
          alt: "Upload an Image",
        });
        setLoader(false);
      } else {
        const selectedFile = e.target.files[0];
        // setImg({
        //   src: URL.createObjectURL(e.target.files[0]),
        //   alt: e.target.files[0].name,
        // });
        // setSelectedFile(selectedFile);
        setCropImg(URL.createObjectURL(e.target.files[0]));
        setCropImgModalShow(true);
        setLoader(false);
      }
    } else {
      setImg({
        src: placeholder,
        alt: "Upload an Image",
      });
      setLoader(false);
    }
  };
  const getOrganizaionData = (page = 1) => {
    agent
      .get(LOAD_ORGANIZATION_LIST + "/organization", {})
      .then((res) => {
        setOrganization(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [organization, setOrganization] = useState([]);
  useEffect(() => {
    getOrganizaionData();
  }, []);
  const onSubmit = (data) => {
    if (languages.length == 0) {
      return
    }
    setLoader(true);
    if (formObject === true) {
      return false;
    }
    const experience_levels = updateProfileData?.role_id === "Mentor" ? Object.values(experience) : Array.from(data.experience_levels)


    const formData = new FormData();
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("my_consent", consentChecked);
    formData.append("organization", data.organization);
    formData.append("position", data.position);
    formData.append("country", country1);
    formData.append("from_date", data.from_date);
    formData.append("to_date", data.to_date);
    formData.append("availability", available);
    formData.append("number_of_mantees", mantees);
    formData.append("linkedin", data.linkedin);
    formData.append("employee", data.employee);
    formData.append("language", languages);
    formData.append("about", data.about);
    experience_levels.map((item, index) => {
      formData.append(`experience_levels[${index}]`, item);
    });
    // formData.append("experience_levels", JSON.stringify(experience_levels));

    if (privacyVal == true) {
      formData.append("is_privacy", 1);
    }
    if (termsVal == true) {
      formData.append("is_terms", "1");
    }
    if (updateProfileData.role_id == "Mentor") {
      formData.append("mf_mental_health", checkBox ? 1 : 0);
      formData.append("lgbtq_discussion", data?.lgbtq_discussion ? 1 : 0);
      formData.append("mf_certified_coach", checkBox2 ? 1 : 0);
    } else if (updateProfileData.role_id == "Mentees") {
      formData.append("mn_open_to_talk", checkBox ? 1 : 0);
      formData.append("mn_loking_for_coach", checkBox2 ? 1 : 0);
    }

    // formData.append("domains", domains);
    formData.append("organization_privacy", privacy.organization_privacy);
    formData.append("position_privacy", privacy.position_privacy);
    formData.append("linkedin_privacy", privacy.linkedin_privacy);
    formData.append("employement_privacy", privacy.employement_privacy);
    formData.append("language_privacy", privacy.language_privacy);
    formData.append("about_privacy", privacy.about_privacy);
    formData.append("is_featured", privacy.is_featured);
    formData.append("country_privacy", privacy.country_privacy);
    formData.append("domain_privacy", privacy.domain_privacy);
    formData.append("id", data.id);
    formData.append("role_id", updateProfileData.role_id);
    formData.append("programmes", getProgrammes);
    if (selectedFile.name) {
      const imageSizeInBytes = selectedFile.size;
      const imageSizeInKB = (imageSizeInBytes / 1024).toFixed(2);
      const imageSizeInMB = (imageSizeInBytes / (1024 * 1024)).toFixed(2);
      console.log(imageSizeInKB, "🚀 ~ onSubmit ~ imageSizeInMB:", imageSizeInMB)
      formData.append("image", selectedFile, selectedFile.name);
    }
    if (selectedResume.name) {
      formData.append("resume", selectedResume, selectedResume.name);
    }
    actions.updateStep1(formData, updateProfileData.id, setLoader);
    //history.push("/manage-profile");
  };
  const redirectToNextPage = () => {
    if (privacyVal == true && termsVal == true) {
      history.push("/manage-profile-step3");
    } else if (termsVal == false) {
      showErrorToaster("Please agree on Terms & Conditions and save");
    } else if (privacyVal == false) {
      showErrorToaster("Please agree on Privacy Policy and save");
    }
  };
  const fileToImage = (childdata) => {
    setSelectedFile(blobToFile(childdata, getUserId + ".png"));
    setImg({
      src: URL.createObjectURL(childdata),
      alt: "",
    });
  };

  const getExperienceData = (userType) => {
    let params = userType;
    actions.getExperienceUser(params);
  };

  const handleCheckboxChange = (event) => {
    if (event?.target?.value === 'all_of_above' && event?.target?.checked) {
      setExperience(userExperience.map((obj) => obj.id))
    }
    else if (!event?.target?.checked && event?.target?.value === 'all_of_above') {
      setExperience([])
    }
    else {
      if (experience.find((obj) => obj === event?.id)) {
        setExperience(experience.filter((item) => item !== event?.id));
      } else {
        setExperience([...experience, event?.id]);
      }
    }

  };

  useEffect(() => {
    let defaultValues = {};
    defaultValues.id = updateProfileData.id;
    defaultValues.first_name = updateProfileData.first_name;
    defaultValues.last_name = updateProfileData.last_name;
    defaultValues.email = updateProfileData.email;
    defaultValues.organization = updateProfileData.organization;
    defaultValues.position = updateProfileData.position;
    defaultValues.linkedin = updateProfileData.linkedin;
    defaultValues.about = updateProfileData.about;
    defaultValues.lgbtq_discussion = updateProfileData?.lgbtq_discussion;
    defaultValues.availability = updateProfileData.user_availability;
    defaultValues.resumeName = updateProfileData?.resume?.slice(0, 30)
    setText(updateProfileData.about)
    if (updateProfileData?.role_id === "Mentees") {
      defaultValues.experience_levels = updateProfileData?.experience_levels?.[0]?.id;
    }
    reset({ ...defaultValues });
  }, [updateProfileData]);

  useEffect(() => {
    getExperienceData(updateProfileData?.role_id)
  }, [])

  const countWords = (text) => {
    return text?.length;
  };


  const onConsentChange = (e) => {
    setConsentChecked(e?.target?.checked ? 1 : 0)
  }

  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>

          <h1 className="dash_heading mb-3">Edit Profile</h1>

          <div className="box shadow bg-white p-4">
            <div className="changepassword text-right">
              <Link to="/change-password" className="btn">
                Change Password
              </Link>
            </div>

            <div className="studentformdashboard">
              <div className="userprofile pt-3 pb-5 text-center">
                <label className="customeValidationText text-center">
                  Image size should be less than 30 MB*
                </label>
                <span>
                  <img
                    className="img-fluid rounded-circle userprofile-img"
                    src={src}
                    alt={alt}
                  />
                </span>
                <div>
                  <span className="inputfileouter">
                    Change image{" "}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={onChangePicture}
                      className="form-control"
                    />
                  </span>
                </div>

                {/* && updateProfileData?.ai_shu_user===1 */}
                <label className="mt-3">{(updateProfileData?.role_id === "Mentees" && updateProfileData?.programmes_data?.id === 17) && `Programme: ${updateProfileData?.programmes_name !== 0 && updateProfileData?.programmes_name}`}</label>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Control
                  type="hidden"
                  name="id"
                  ref={register}
                />
                <Row>

                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col sm={9}>
                        <Row>
                          <Col sm={6} className="pr-md-2">
                            <div className="form-group">
                              <label>First Name*</label>
                              <Form.Control
                                type="text"
                                // value={updateProfileData.first_name}
                                className={
                                  errors.first_name
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="first_name"
                                aria-invalid={
                                  errors.first_name ? "true" : "false"
                                }
                                ref={register({
                                  required: true,
                                  minLength: 2,
                                  maxLength: 20,
                                })}
                              />
                              {errors.first_name &&
                                errors.first_name.type === "required" && (
                                  <label className="invalid-feedback text-left">
                                    Please enter your first name
                                  </label>
                                )}
                              {errors.first_name &&
                                errors.first_name.type === "minLength" && (
                                  <label className="invalid-feedback text-left">
                                    Your platform first name should contain
                                    atleast 2 characters
                                  </label>
                                )}
                              {errors.first_name &&
                                errors.first_name.type === "maxLength" && (
                                  <label className="invalid-feedback text-left">
                                    Your first name should contain max 20
                                    characters
                                  </label>
                                )}
                            </div>
                          </Col>
                          <Col sm={6} className="pl-md-2">
                            <div className="form-group">
                              <label>Last Name*</label>
                              <Form.Control
                                type="text"
                                // defaultValue={updateProfileData.last_name}
                                className={
                                  errors.last_name
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="last_name"
                                aria-invalid={
                                  errors.last_name ? "true" : "false"
                                }
                                ref={register({
                                  required: true,
                                  minLength: 2,
                                  maxLength: 20,
                                })}
                              />
                              {errors.last_name &&
                                errors.last_name.type === "required" && (
                                  <label className="invalid-feedback text-left">
                                    Please enter your last name
                                  </label>
                                )}
                              {errors.last_name &&
                                errors.last_name.type === "minLength" && (
                                  <label className="invalid-feedback text-left">
                                    Your platform last name should contain
                                    atleast 2 characters
                                  </label>
                                )}
                              {errors.last_name &&
                                errors.last_name.type === "maxLength" && (
                                  <label className="invalid-feedback text-left">
                                    Your last name should contain max 20
                                    characters
                                  </label>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col sm={9}>
                        <Row>
                          <Col sm={12}>
                            <div className="form-group">
                              <label className="d-block">
                                I am currently employed*
                              </label>
                              <span className="ml-md-3">
                                <span className="custom_radio ml-0">
                                  Yes &nbsp;{" "}
                                  <input
                                    type="radio"
                                    name="employee"
                                    checked={
                                      employee === "yes" ? "checked" : ""
                                    }
                                    value="yes"
                                    ref={register}
                                    onClick={setOrganizationPlaceholder}
                                  />
                                  <span className="radio_indicator">
                                    &nbsp;
                                  </span>
                                </span>
                                <span className="custom_radio">
                                  No &nbsp;{" "}
                                  <input
                                    type="radio"
                                    name="employee"
                                    checked={employee === "no" ? "checked" : ""}
                                    value="no"
                                    ref={register({
                                      required: true,
                                    })}
                                    onClick={setOrganizationPlaceholder}
                                  />
                                  <span className="radio_indicator">
                                    &nbsp;
                                  </span>

                                </span>
                              </span>
                              {errors.employee &&
                                errors.employee.type === "required" && (
                                  <label className=" text-left" style={{ color: '#dc3545', fontSize: '0.875em' }}>
                                    Please enter your organization/institute
                                    address
                                  </label>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col sm={3} className="pl-md-0">
                        <div className="dropdownouter">
                          <Switch
                            checked={privacy.employement_privacy}
                            onChange={handleChange}
                            name="employement_privacy"
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </div>
                      </Col> */}
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col sm={9}>
                        <Row>
                          <Col sm={12}>
                            <div className="form-group">
                              <label>Email Address</label>

                              <Form.Control
                                type="text"
                                // defaultValue={updateProfileData.email}
                                className={
                                  errors.email
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="email"
                                aria-invalid={errors.email ? "true" : "false"}
                                ref={register({
                                  required: true,
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  },
                                  maxLength: 50,
                                })}
                              />
                              {errors.email &&
                                errors.email.type === "required" && (
                                  <label className="invalid-feedback text-left">
                                    Please enter your email address
                                  </label>
                                )}
                              {errors.email &&
                                errors.email.type === "pattern" && (
                                  <label className="invalid-feedback text-left">
                                    Invalid email address
                                  </label>
                                )}
                              {errors.email &&
                                errors.email.type === "maxLength" && (
                                  <label className="invalid-feedback text-left">
                                    Your email should contain max 50 characters
                                  </label>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {updateProfileData.role_id != "Mentees" && (
                    <Col md="6">
                      <Row className="align-items-center">
                        <Col sm={9}>
                          <Row>
                            <Col sm={12}>
                              <Form.Label>No. of mentees</Form.Label>
                              <Form.Control
                                as="select"
                                className="mb-3"
                                value={mantees}
                                name="number_of_mantees"
                                onChange={(e) => {
                                  setMantees(e.target.value);
                                }}
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </Form.Control>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col sm={9}>
                        <Row>
                          <Col sm={12}>
                            <div className="form-group">
                              <label>
                                {organizationText}
                                <span className="alert-dange">*</span>
                              </label>
                              <Form.Control
                                type="text"
                                // defaultValue={updateProfileData.organization}
                                className={
                                  errors.organization
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="organization"
                                aria-invalid={
                                  errors.organization ? "true" : "false"
                                }
                                ref={register({ required: true, maxLength: 255 })}
                              />
                              {errors.organization &&
                                errors.organization.type === "required" && (
                                  <label className="invalid-feedback text-left">
                                    Please enter your organization/institute
                                  </label>
                                )}
                              {errors.organization &&
                                errors.organization.type === "maxLength" && (
                                  <label className="customeValidationText text-left">
                                    Organization/institute should not be more then 255 characters
                                  </label>
                                )}

                              <Form.Text className="text-muted"></Form.Text>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col sm={3} className="pl-md-0">
                        <div className="dropdownouter">
                          <Switch
                            checked={privacy.organization_privacy}
                            onChange={handleChange}
                            name="organization_privacy"
                            color="primary"
                            input
                            props={{ "aria-label": "secondary checkbox" }}
                          />
                        </div>
                      </Col> */}
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col sm={9}>
                        <Row>
                          <Col sm={12}>
                            <div className="form-group">
                              <label>Your country*</label>
                              <Form.Control
                                as="select"
                                className="mb-3"
                                name="country"
                                value={country1 || updateProfileData.country}
                                onChange={(e) => {
                                  setCountry(e.target.value);
                                }}
                                ref={register({
                                  required: true,
                                })}
                              >
                                <option value="">Select Country</option>

                                {country &&
                                  country.map((item, key) => (
                                    <option value={item.label} key={key}>
                                      {item.label}
                                    </option>
                                  ))}


                              </Form.Control>
                            </div>
                            {errors.country &&
                              errors.country.type === "required" && (
                                <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                                  Please select country
                                </label>
                              )}
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col sm={3} className="pl-md-0">
                        <div className="dropdownouter">
                          <Switch
                            checked={privacy.country_privacy}
                            onChange={handleChange}
                            name="country_privacy"
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </div>
                      </Col> */}
                    </Row>
                  </Col>
                  {/* {updateProfileData.role_id != "Mentees" && (
                    <Col md={6}>
                      <Row className="align-items-center">
                        <Col sm={9}>
                          <Row>
                            <Col sm={12}>
                              <div className="form-group">
                                <label>Your programmes*</label>
                                <Form.Control
                                  as="select"
                                  className="mb-3"
                                  name="programmes"
                                  value={
                                    getProgrammes ||
                                    updateProfileData.programmes
                                  }
                                  onChange={(e) => {
                                    setProgrammes(e.target.value);
                                  }}
                                  ref={register}
                                >
                                  <option value="">
                                    --Select programmes--
                                  </option>
                                  {programmes &&
                                    programmes.map((updateProfileData, key) => (
                                      <option
                                        value={updateProfileData.id}
                                        key={key}
                                      >
                                        {updateProfileData.program}
                                      </option>
                                    ))}
                                </Form.Control>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )} */}
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col sm={9}>
                        <Row>
                          <Col sm={12}>
                            <div className="form-group">
                              <label>{positionText}</label>
                              <span className="alert-dange">*</span>
                              <Form.Control
                                type="text"
                                // defaultValue={updateProfileData.position}
                                className={
                                  errors.position
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="position"
                                aria-invalid={
                                  errors.position ? "true" : "false"
                                }
                                ref={register({ required: true, maxLength: 255 })}
                              />
                              {errors.position &&
                                errors.position.type === "required" && (
                                  <label className="invalid-feedback text-left">
                                    Please enter your position in
                                    organization/institute
                                  </label>
                                )}
                              {errors.position &&
                                errors.position.type === "maxLength" && (
                                  <label className="customeValidationText text-left">
                                    Organization position should not be more then 255 characters
                                  </label>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col sm={3} className="pl-md-0">
                        <div className="dropdownouter">
                          <Switch
                            checked={privacy.position_privacy}
                            onChange={handleChange}
                            name="position_privacy"
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </div>
                      </Col> */}
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col sm={9}>
                        <Row>
                          <Col sm={12}>
                            <label>Linkedin profile link*</label>
                            <div className="form-group position-relative">
                              <span>
                                <Link
                                  to={{ pathname: updateProfileData.linkedin }}
                                  className="link_btn  m-0"
                                  target="_blank"
                                >
                                  <i className="fab fa-linkedin-in"></i>
                                </Link>
                              </span>
                              <Form.Control
                                type="text"
                                placeholder="Linkedin profile link"
                                className={
                                  errors.linkedin
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                // defaultValue={updateProfileData.linkedin}
                                name="linkedin"
                                aria-invalid={
                                  errors.linkedin ? "true" : "false"
                                }
                                ref={register({
                                  required: true,
                                  pattern: {
                                    value:
                                      /^(ftp|http|https):\/\/?(?:[a-z]*\.)?linkedin\.com(\S*)$/i,
                                  },
                                })}
                              />
                              {errors.linkedin &&
                                errors.linkedin.type === "required" && (
                                  <label className="invalid-feedback text-left">
                                    Please enter your linkedin URL
                                  </label>
                                )}
                              {errors.linkedin &&
                                errors.linkedin.type === "pattern" && (
                                  <label className="invalid-feedback text-left">
                                    Please enter full linked in url with https
                                    and user name.
                                  </label>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {updateProfileData.role_id === "Mentees" && (
                    <Col md={6}>
                      <Row>
                        <Col md={9}>
                          <div className="form-group inputfileupload">
                            <label>Resume</label>
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              value={resumeText || userInfo?.resume?.slice(0, 30)}
                              name='resumeName'
                              ref={register}
                            ></input>
                            <span className="uploadfile">
                              <input
                                type="file"
                                accept=".pdf"
                                name="resume"
                                onChange={onChangeResume}
                                ref={register}
                                title=""
                              />
                              Choose File
                            </span>
                            <sub class=" pl-2">
                              Please upload only in PDF and upto 1 MB in size
                            </sub>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Row className="align-items-center">
                            <Col sm={9}>
                              <Row>
                                <Col sm={12}>
                                  {updateProfileData.resume !== "" && (
                                    <Link
                                      to=""
                                      download
                                      className="d-block date color_red"
                                      onClick={(e) =>
                                        downloadResume(
                                          e,
                                          updateProfileData.resume
                                        )
                                      }
                                      target="_blank"
                                    >
                                      {"Download Resume"}
                                    </Link>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col md={6}>
                    <Row className="align-items-center">
                      <Col sm={9}>
                        <Row>
                          <Col sm={12}>
                            <label>
                              Tell us a little more about yourself (Min 300 and Max 1,500 characters)*
                            </label>
                            <Form.Control
                              as="textarea"
                              onChange={(e) => setText(e.target.value)}
                              defaultValue={updateProfileData.about}
                              // placeholder="Tell us more about yourself (Min 300 and Max 1,500 characters) *"
                              className={
                                errors.about
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="Tell us a little more about yourself (Min 300 and Max 1,500 characters)"
                              name="about"
                              aria-invalid={errors.about ? "true" : "false"}
                              ref={register({
                                required: "Please enter yourself.",
                                minLength: 300,
                                maxLength: 1500,
                              })}
                            />
                            <p>Word count: {countWords(text)}/1500</p>
                            {errors.about &&
                              errors.about.type === "required" && (
                                <label className="customeValidationText text-left">
                                  Please enter about yourself
                                </label>
                              )}
                            {errors.about &&
                              errors.about.type === "maxLength" && (
                                <label className="customeValidationText text-left">
                                  Maximum 1500 characters are allowed
                                </label>
                              )}
                            {errors.about &&
                              errors.about.type === "minLength" && (
                                <label className="customeValidationText text-left">
                                  Minimum 300 characters are allowed
                                </label>
                              )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="mt-4">
                    <Row className="align-items-center">
                      <Col sm={9}>
                        <Row>
                          <Col sm={12}>

                            <Form.Group controlId="formBasicLanguage">
                              <Form.Label>Language proficiency and preferences*</Form.Label>
                              <Select
                                isMulti
                                name="language"
                                value={defaultLanguages}
                                options={languageOptions}
                                onChange={(e) => {
                                  setLanguages(e.map((item) => item.value));
                                  setDefaultLanguages(e)
                                }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                ref={register}
                              />
                              {languages.length == 0 &&
                                (
                                  <label
                                    className="customeValidationText text-left"
                                    style={{ color: "#dc3545" }}
                                  >
                                    Please choose languages
                                  </label>
                                )}
                            </Form.Group>
                            {/* <div className="form-group proficiency">
                              <h4 className="mb-3">
                                Language proficiency and preferences*
                              </h4>
                              <span className="custom_check mr-2 mb-2">
                                English &nbsp;{" "}
                                <input
                                  type="checkbox"
                                  value="English"
                                  checked={languages.indexOf("English") > -1}
                                  name="language"
                                  onChange={(e) => setLanguagesArray(e)}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-check-input ${errors.language ? "is-invalid" : ""
                                    }`}
                                />
                                <span className="check_indicator">&nbsp;</span>
                              </span>
                              <span className="custom_check mr-2 mb-2">
                                Chinese &nbsp;{" "}
                                <input
                                  type="checkbox"
                                  value="Chinese"
                                  checked={languages.indexOf("Chinese") > -1}
                                  onChange={(e) => setLanguagesArray(e)}
                                  name="language"
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-check-input ${errors.language ? "is-invalid" : ""
                                    }`}
                                />
                                <span className="check_indicator">&nbsp;</span>
                              </span>
                              <span className="custom_check mb-2">
                                Spanish
                                <input
                                  type="checkbox"
                                  value="Spanish"
                                  checked={languages.indexOf("Spanish") > -1}
                                  onChange={(e) => setLanguagesArray(e)}
                                  name="language"
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-check-input ${errors.language ? "is-invalid" : ""
                                    }`}
                                />
                                <span className="check_indicator">&nbsp;</span>
                              </span>
                              <span className="custom_check mb-2">
                                Hindi
                                <input
                                  type="checkbox"
                                  value="Hindi"
                                  checked={languages.indexOf("Hindi") > -1}
                                  onChange={(e) => setLanguagesArray(e)}
                                  name="language"
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-check-input ${errors.language ? "is-invalid" : ""
                                    }`}
                                />
                                <span className="check_indicator">&nbsp;</span>
                              </span>
                              {errors.language &&
                                errors.language.type === "required" && (
                                  <label
                                    className="customeValidationText text-left"
                                    style={{ color: "#dc3545" }}
                                  >
                                    Please select proficiency and preferences
                                  </label>
                                )}
                            </div> */}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={9}>
                        <Row>
                          {updateProfileData?.role_id === 'Mentees' && <Col sm={12}>
                            <div className="form-group">
                              <label>Indicate your working experience level*</label>
                              <Form.Control
                                as="select"
                                name="experience_levels"
                                // value={experience?.length>0 ? experience : updateProfileData?.experience_levels?.[0]?.title}
                                className={
                                  errors.experience_levels
                                    ? "form-control mb-3 is-invalid"
                                    : "form-control mb-3"
                                }
                                ref={register({
                                  required: true,
                                })}
                              // onChange={(e) => {
                              //   setExperience([e.target.value]);
                              // }}
                              >
                                <option value="">
                                  Indicate your working experience level
                                </option>
                                {userExperience?.map((val, ind) => <option value={val?.id}>{val?.title}</option>)}

                              </Form.Control>
                            </div>
                            {errors.experience_levels &&
                              errors.experience_levels.type === "required" && (
                                <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                                  Please select experience level
                                </label>
                              )}
                          </Col>}

                          {updateProfileData?.role_id === "Mentor" ? (<Col
                            md="12"
                            className="mt-3"
                          >
                            <div className="form-group">
                              <label>Indicate the experience level of mentee you would like to mentor*</label><br />
                              {userExperience?.map((val, ind) => <span className="custom_check mr-2 mb-2">
                                {val?.title}
                                <input
                                  type="checkbox"
                                  name="experience_levels"
                                  checked={experience.find((obj) => obj === val?.id)}
                                  value={experience}
                                  onChange={(e) =>
                                    handleCheckboxChange(val)
                                  }
                                  ref={register({
                                    required: true,
                                  })}
                                />
                                <span className="check_indicator">&nbsp;</span>
                              </span>)}
                              <span className="custom_check mr-2 mb-2">
                                All of above
                                <input
                                  type="checkbox"
                                  name="availability"
                                  value='all_of_above'
                                  checked={experience.length === userExperience.length}
                                  onChange={(e) =>
                                    handleCheckboxChange(e)
                                  }
                                />
                                <span className="check_indicator">&nbsp;</span>
                              </span></div>
                            {errors.experience_levels &&
                              errors.experience_levels.type === "required" && (
                                <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                                  Please select experience level
                                </label>
                              )}
                          </Col>) : (
                            ""
                          )}



                        </Row>
                      </Col>
                      <Col sm={9}>
                        <Row>
                          {<Col sm={12}>
                            <div className="form-group">
                              <label>Programme</label>
                              <br />
                              <div>{updateProfileData?.programmes_name} </div>
                            </div>

                          </Col>}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="">
                    <Row className="align-items-center">
                      {updateProfileData.role_id === "Mentor" && (
                        <Col md="12" className="">
                          <span className="custom_check mr-2 mb-2">
                            {"Please indicate in case you are not available"}
                            <input
                              type="checkbox"
                              name="availability"
                              // value={available}
                              defaultChecked={
                                updateProfileData.user_availability === 2
                              }
                              // className={`form-check-input ${
                              //   errors.language ? "is-invalid" : ""
                              // }`}
                              onChange={showDatePicker}
                            // ref={register}
                            />

                            <span className="check_indicator">&nbsp;</span>
                          </span>
                        </Col>
                      )}
                      <Col md={6} className="" style={{ display: showDateDiv }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            format="yyyy-MM-dd"
                            id="date-picker-from"
                            label="From"
                            minDate={new Date()}
                            // value={todayDate}
                            className={
                              errors.fromDate ? "w-100 is-invalid" : "w-100"
                            }
                            name="from_date"
                            aria-invalid={errors.fromDate ? "true" : "false"}
                            value={fromDate}
                            inputRef={register}
                            onChange={(date) => checkFromDate(date)}
                          />
                        </MuiPickersUtilsProvider>
                      </Col>
                      <Col md={6} className="" style={{ display: showDateDiv }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            format="yyyy-MM-dd"
                            id="date-picker-to"
                            label="To"
                            name="to_date"
                            className="w-100"
                            minDate={new Date(fromDate)}
                            value={toDate}
                            inputRef={register}
                            onChange={(date) => checkToDate(date)}
                          />
                        </MuiPickersUtilsProvider>
                        {errors.fromDate &&
                          errors.fromDate.type === "required" && (
                            <label className="customeValidationText text-left">
                              Please enter fromDate
                            </label>
                          )}
                      </Col>
                      {updateProfileData.is_terms === 0 && (
                        <Col md={12}>
                          <div className="form-group termsconditions mt-4">
                            <span style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row-reverse' }}>
                              <span>
                                I agree to vLookUp{" "}
                                <Link to="/page/terms-and-conditions" target="_blank">Terms and conditions*</Link>{" "}</span>
                              <span className="custom_check" id="check_terms">
                                &nbsp;{" "}
                                <input
                                  type="checkbox"
                                  id="is_terms"
                                  name="terms"
                                  value="1"
                                  defaultChecked={termsVal}
                                  className={`form-check-input ${errors.terms ? "is-invalid" : ""
                                    }`}
                                  ref={register({
                                    required: true,
                                  })}
                                  onChange={(e) => handleTermChange(e)}
                                />

                                <span
                                  className="check_indicator"
                                  id="term_check_indicator"
                                >
                                  &nbsp;
                                </span>
                              </span>
                            </span>

                            {errors.terms &&
                              errors.terms.type === "required" && (
                                <label className="text-left error-class ml-5">
                                  Please agree on terms & conditions
                                </label>
                              )}
                          </div>
                        </Col>
                      )}
                      {updateProfileData.is_privacy === 0 && (
                        <Col md={12}>
                          <div className="form-group">
                            <span style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row-reverse' }}>
                              <span>
                                I agree to vLookUp{" "}
                                <Link to="/page/privacy-policy" target="_blank">
                                  {" "}
                                  Privacy policy*
                                </Link>{" "}
                              </span>
                              <span className="custom_check">
                                &nbsp;{" "}
                                <input
                                  type="checkbox"
                                  name="privacy"
                                  id="is_privacy"
                                  value="1"
                                  defaultChecked={privacyVal}
                                  className={`form-check-input ${errors.privacy ? "is-invalid" : ""
                                    }`}
                                  ref={register({
                                    required: true,
                                  })}
                                  onChange={(e) => handlePrivacyChange(e)}
                                />

                                <span className="check_indicator">&nbsp;</span>
                              </span>
                            </span>
                            {errors.privacy &&
                              errors.privacy.type === "required" && (
                                <label className="error-class ml-5">
                                  Please agree on Privacy Policy
                                </label>
                              )}
                          </div>
                        </Col>
                      )}
                      <Col md={12}>
                        <div className="form-group">
                          <span className="custom_check">
                            I give my consent to be featured (Name, Image and Text) on SM pages and collaterals of vLookUp
                            <input
                              type="checkbox"
                              name="my_consent"
                              id="is_my_consent"
                              value={consentChecked}
                              checked={consentChecked}
                              className={`form-check-input ${errors?.my_consent ? "is-invalid" : ""
                                }`}

                              onChange={onConsentChange}
                            />
                            {errors.my_consent &&
                              errors.my_consent.type === "required" && (
                                <label className="invalid-feedback text-left">
                                  Please agree on consent
                                </label>
                              )}
                            <span className="check_indicator">
                              &nbsp;
                            </span>
                          </span>
                        </div>
                      </Col>


                      <Col md="12">
                        <div className="form-group">
                          <label className="mb-3">Indicate your preference for below</label><br />
                          <span className="custom_check">
                            {updateProfileData?.role_id == "Mentor"
                              ? config?.mf_mental_health
                              : config?.mn_open_to_talk}
                            <input
                              type="checkbox"
                              className={`form-check-input ${errors.privacy ? "is-invalid" : ""
                                }`}
                              checked={checkBox}
                              onChange={(e) => setCheckBox(e.target.checked)}
                            />
                            <span className="check_indicator">&nbsp;</span>
                          </span>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <span className="custom_check">
                            {updateProfileData?.role_id == "Mentor"
                              ? config?.mf_certified_coach
                              : config?.mn_loking_for_coach}
                            <input
                              type="checkbox"
                              className={`form-check-input ${errors.privacy ? "is-invalid" : ""
                                }`}
                              checked={checkBox2}
                              onChange={(e) => setCheckBox2(e.target.checked)}
                            />
                            <span className="check_indicator">&nbsp;</span>
                          </span>
                        </div>
                      </Col>


                      {updateProfileData?.role_id === "Mentor" && <Col md="12">
                        <div className="form-group">
                          <span className="custom_check">
                            {config?.LGBTQ_discussion}
                            <input
                              type="checkbox"
                              name='lgbtq_discussion'
                              value='lgbtq_discussion'
                              className={`form-check-input ${errors.privacy ? "is-invalid" : ""
                                }`}
                              ref={register({
                                required: false,
                              })}
                            />
                            <span className="check_indicator">&nbsp;</span>
                          </span>
                        </div>
                      </Col>}

                    </Row>
                  </Col>
                  <Col sm={12}>
                    <Row>
                      <Col md={12}>
                        <div className="form-group mt-3 text-left">
                          <Button
                            className="btn loginsubmit nextbtn mr-2"
                            type="submit"
                            disabled={getLoader}
                            id="submitStep1"
                          >
                            {getLoader ? " Saving... " : "Save & Next"}
                          </Button>

                          {/* <Button
                          className="btn loginsubmit nextbtn ml-2"
                          type="button"
                          onClick={redirectToNextPage}
                          >
                          Next
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>

      {cropImgModalShow && (
        <ImageCropper
          show={cropImgModalShow}
          cropImg={cropImg}
          fileToImage={fileToImage}
          onHide={() => setCropImgModalShow(false)}
          centered
          size="xl"
          className="cropper-modal"
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isData: state.isData,
    updateProfileData: state.updateProfileData,
    isLoad: state.isLoad,
    userInfo: state.userInfo,
    isAuth: state.isAuth,
    programmes: state.programmes,
    userExperience: state.userExperience
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile1);
