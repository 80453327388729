import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function categoryReducer(state = initialState.reportcategories, action) {
  switch (action.type) {
    case types.LOAD_REPORT_CATEGORY_DATA_SUCCESS:
        return action.reportcategories;
    default:
        return state;
  }  
}
