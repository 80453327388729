import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as homePageActions from "../../actions/homePageActions";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Details from "./details";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Index = (props) => {
  const { actions, homePageData, leaders, advisors, teams } = props;
  // console.warn(advisors);
  console.log(advisors)
  var Members = {
    dots: false,
    infinite: true,
    centerpadding: 0,
    speed: 500,
    slidesToShow: advisors?.length <= 4 ? advisors?.length : 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          dots: true,
          arrows: false,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },

      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },

    ],
  };

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  //   var advisors = [
  //     { 
  //       full_name: "Damayanti Shahani",
  //     image: "/assets/images/Damayanti_Shahani.jpg",
  //     alt:"women's career consultation",
  //     position:"CEO | Entrepreneur",
  //     organization:"",
  //     about:"Damayanti Shahani is a lawyer and compliance professional with over 18 years of experience in blue-chip law and consulting firms across Singapore, New York, Washington DC and Mumbai. She is currently the Founder and CEO of a compliance consulting firm in Singapore and is regularly featured at industry thought-leadership forums in the financial services industry. Damayanti has also been active in the social impact space as the current Chair of the Impact Committee of 100Women in Finance, Singapore and Co-Founder of BridgeAble, a Singapore based philanthropy and volunteerism consulting initiative. She has a Law degree from National Law School, India and a Masters in Law from Georgetown University (Dean’s List and with Distinction). Damayanti also mentors girls and young women looking to enter the workforce and believes that female professionals like herself need to pay it forward to the next generation of working women. That’s why she is excited about the possibilities that vLookUp aims to bring through mentorship and role modeling, to young women in the workforce!", 
  //     linkedin:"https://www.linkedin.com/in/damayanti-shahani-8152264/" ,main_area: '',
  //   },
  //   { 
  //     full_name: "Manish Verma",
  //   image: "/assets/images/Manish_Verma.jpg",
  //   alt:'business career mentor program',
  //   position:"Global Head of Talent",
  //   organization:"Cargill",
  //   about:"Strategic Global Talent Leader. Commercial and Transformation oriented HR Leader. Experience in leading large scale change and Transformation. Proven success in HR Transformation. Currently heading Global Talent acquisition and Talent Management part of CoE leadership team. Strong regional HR leadership experience in Asia and Europe Passion for people management and leadership.Strategic Workforce Planning, HR ops, Career Management, Talent Strategy & Management, OD & Employee Engagement, Talent Acquisition, M&A and Proven Business HR. Specialties: 24+years of HR experience in APAC, UK & Europe, Global role. HR Generalist and Specialist experience focusing on Careers, Workforce strategy, Talent Management, OD and Employee Engagement. Strong expertise in HR Business Partnering and stakeholder management. Proven experience of heading the HR functions in APAC and in UK & Europe. PG from Univ. of London in Org Psychology and a MBA in HR from India. Certified OPQ Psychometric assessor from SHL, London. Able to work across cultures and geo boundaries.", 
  //   linkedin:"https://www.linkedin.com/in/manishverma29/" ,main_area: '',


  // },
  // { 
  //   full_name: "Yvonne Siow",
  // image: "/assets/images/Yvonne_Siow.jpg",
  // alt:'career mentor online',
  // position:"Head",
  // organization:"Essilor Vision Foundation",
  // about:"Yvonne Siow has more than 20 years of experience as an entrepreneur, corporate warrior and philanthropist. She believes that in today's VUCA world, where things are volatile, complex, uncertain and ambiguous, businesses which focus on both profit and purpose will thrive. The strong connection to community and societal good will create both a giving corporate culture as well as loyal consumers. She is a firm believer that everyone has the potential to be the best version of themselves. She serves on the boards of a number of charity foundations and wishes to continuously increase the scale and impact of her works.", 
  // linkedin:"https://www.linkedin.com/in/yvonnesiow/" ,main_area: '',


  // },

  //   ];
  //   var teams = [
  //     { 
  //       full_name: "Kanchana Gupta",
  //       image: "/assets/images/Kanchana_Gupta.jpg",
  //       alt:'how can a mentor help your career',
  //       position:"Founder",
  //       organization:"vLookUp",
  //       about:"Kanchana Gupta is a multi-hyphenate personality. In addition to being the founder of vLookUp, she is also a  part-time Consultant and Project Manager as well as a contemporary artist. She made a pivot into contemporary art as a multi-disciplinary artist after more than a decade-long career in HR, Project Management, Consulting, Data and Analytics etc. with organizations like Tata Consultancy Services, Accenture and Telstra, where she managed cross-country teams. Her  art practice ranges from painting, sculptures and installations to performance-based video works and she has  exhibited her art works in multiple solo and group exhibitions in Singapore and outside.She has been able to manage disparate careers of being a consultant and an artist because of few mentors that she found along her path - leaders who stood behind her, understood her unique requirements and provided her with opportunities. vLookUp is her way of facilitating the same for other aspiring women leaders and professionals by creating a platform that helps them access women and men mentors. ", 
  //       linkedin:"https://www.linkedin.com/in/kanchana-gupta-6041071/" ,
  //       main_area: '',
  //   },
  //   { 
  //     full_name: "Keziah Quek",
  //     image: "/assets/images/Keziah_Quek.jpg",
  //     alt:'how to find a good career mentor',
  //     position:"Mentee engagement and development lead",
  //     organization:"vLookUp",
  //     about:"Keziah is the Ecosystem & Sustainability Lead at Applied Innovation Exchange (Manager) at Capgemini, Southeast Asia. She leads growth and alliances between corporates building the next, in collaboration with the ecosystem of technology startups. In addition, as Sustainability Lead for the AIE in the region, she also leads a team that works with clients to understand, create and redesign business models that have sustainability at their core. She is passionate about promoting gender diversity in the workforce and leads the mentee engagement and development programme at vLookUp.",
  //     linkedin:"https://www.linkedin.com/in/keziah-quek/" ,
  //     main_area: '',
  // },
  // { 
  //   full_name: "Avni Khajanchi",
  //   image: "/assets/images/avni.jpg",
  //   alt:'how to find a good career mentor',
  //   position:"Intern, vLookUp",
  //   organization:"vLookUp",
  //   about:"Avni loves people, creativity, challenges and is on a pursuit to learn more about new perspectives of looking at the world. A student of Psychology stepping into the business world with an MSc in Management, she is a copywriter in her spare time. An aspiring marketer, she is curious about the world of branding, advertising and digital marketing.",
  //   linkedin:"https://www.linkedin.com/in/keziah-quek/" ,
  //   main_area: '',
  // }



  //   ];


  useEffect(() => {
    const fetchData = () => {
      actions.loadHomePageData("aboutus");
      actions.loadLeaderData(4);
    };
    fetchData();
  }, []);

  useEffect(() => {
    //window.scrollTo(0, 0);
    document.title = homePageData.meta_title;
    document.querySelector('meta[name="description"]').setAttribute('content', homePageData.meta_description);
  });

  const [modalShow, setModalShow] = useState(false);

  const [userData, setUserData] = useState({});

  const getUserDetails = (
    e,
    full_name,
    image,
    organization,
    position,
    about,
    linkedin,
    main_area
  ) => {
    e.preventDefault();
    setModalShow(true);
    setUserData({
      full_name: full_name,
      image: image,
      organization: organization,
      position: position,
      about: about,
      linkedin: linkedin,
      main_area: main_area,
    });
  };

  return (
    <>
      <section className="about_content_mian aboutclass">
        <Container>
          <div className="about_content">
            <h1 className="title">Passionate about diversity by helping women grow and lead </h1>
            <p>We’re a group of experienced diversity champions across domains united by one mission: nurturing more women talent pools across businesses by breaking access barriers. vLookUp started in July 2021 and we’ve seen it grow significantly in a short time. Our mentors come from across 13 industries, nearly 70 organizations, over 10 countries, and currently 35% of them are men. Our mentees come from 12 countries and 60+ organizations. We have enabled 1000+ hours of meaningful mentoring engagement for our members in one year.<br />Our offerings have expanded and now we provide an AI enabled integrated mentoring process and platform to complement the D&I initiatives of organizations. <a href="/partnerships">Read about how vLookUp can support yours. </a></p>

          </div>
        </Container>
        <section className="about_content_mian">
          <div className="container">
            <div className="about_content">
              <div className="title">Our Dream</div>
              <p className="m-0">Many of us experience having a mentor for the first time almost mid-way through our careers, realize the positive impact and wonder why we didn’t look for one earlier. Many others don’t know where to look, especially if they want to speak to someone outside their organization. There are fewer women in the labor force, and in leadership. And with the many transitions we go through in our professional careers and lives, the right role models, right access, and domain-specific mentoring can make a difference. We are passionate about creating this support structure, right from the early days of one’s career, so women can stay, grow, and lead. </p>

            </div>
          </div>
        </section>
      </section>
      <div
        dangerouslySetInnerHTML={prepareHtml(homePageData.description)}
      ></div>

      <section className="about_who_sec">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="about_content">
                <div className="title">Meet the team</div>
                {/* <span className="sub_lead d-block pt-2">
                 
                </span> */}
                <p className="m-0">
                  We are committed diversity champions who truly believe in the power of diverse workplaces
                </p>
              </div>
            </Col>

            <Col lg={6}>
              <Row className="who-thumb-outer">
                {teams &&
                  teams.length &&
                  teams.map((item, key) => (
                    <Col
                      sm={6}
                      className={(key === 1 || key === 3) && "mt-md-n5"}
                    >
                      <div className="who_box" key={key}>
                        <img className="w-100" src={item.image} alt={item.full_name} />
                        <div className="who_info text-left">
                          <div className="hover-part">
                            <p className="positionsh">{item.position}</p>
                            <span className="profile">{item.full_name}</span>
                            <div className="hvrcontant">

                              <p>{item.organization}</p>
                              <Link
                                to={{ pathname: item.linkedin_url }}

                                className="newcustomelinked text-white m-0"

                                target="_blank"
                              >
                                Linkedin Profile
                              </Link>
                              <div>
                                <span className="readmore" onClick={(e) => {
                                  getUserDetails(
                                    e,
                                    item.full_name,
                                    item.image,
                                    item.organization,
                                    item.position,
                                    item.about,
                                    item.linkedin_url,
                                    item.main_area
                                  );
                                }}>Read More<i className="fas fa-long-arrow-alt-right"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="members_sec">
        <Container>
          <div className="title text-center space-bottom">
            Our Advisors
          </div>
          <Slider {...Members}>
            {advisors &&
              advisors.length &&
              advisors.map((item, key) => (
                <div key={key}>
                  <div className="member_box text-center">
                    <div className="member_img">
                      <img src={item.image} alt={item.full_name} />
                      <div className="advisor-contnt">
                        <p className="positionsh">{item.position}</p>
                        <span className="name d-block pt-2">{item.full_name}</span>
                        <div className="hvrcontant">

                          <p>{item.organization}</p>
                          <Link
                            to={{ pathname: item.linkedin_url }}

                            className="newcustomelinked text-white m-0"

                            target="_blank"
                          >
                            Linkedin Profile
                          </Link>
                          <div>
                            <span className="readmore" onClick={(e) => {
                              getUserDetails(
                                e,
                                item.full_name,
                                item.image,
                                item.organization,
                                item.position,
                                item.about,
                                item.linkedin_url,
                                item.main_area
                              );
                            }} >Read More<i className="fas fa-long-arrow-alt-right"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </Container>
        {modalShow && (
          <Details
            show={modalShow}
            userdata={userData}
            onHide={() => setModalShow(false)}
            centered
            size="xl"
            className="mentor_modal"
          />
        )}
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    homePageData: state.homePageData,
    leaders: state.leaders,
    teams: state.teams,
    advisors: state.advisors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
