import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import countryList from "react-select-country-list";
import { useForm } from "react-hook-form";
import * as API from "../../utils/apiPath";
import { agent } from "../../utils/agent";
import {
  showToasterWithOk,
  blobToFile,
  showErrorToaster,
} from "../../utils/helpers";
import placeholder from "../../assets/images/userprofile.png";
import ImageCropper from "../../Component/ImageCropper";
import Select from "react-select";
import config from "../../config";

const MentorSignUp = (props) => {
  const { title } = props;
  const [{ alt, src }, setImg] = useState({
    src: placeholder,
    alt: "Upload an Image",
  });
  // Country dropdowns vars
  const getUserId = localStorage.getItem("userId");
  const [cropImg, setCropImg] = useState("");
  const [cropImgModalShow, setCropImgModalShow] = useState(false);
  const [imageError, setImageError] = useState(false)
  const [userData, setUserData] = useState({});
  const [employee, setEmployee] = useState("yes");
  const [gender, setGender] = useState("female");

  const [languages, setLanguages] = useState([]);
  const [defaultLanguages, setDefaultLanguages] = useState([]);
  const languageOptions = config.languages
  
  const handleGender = (value) => {
    setGender(value);
  };

  useEffect(() => {
    document.title = title;

    const getAreasData = (page = 1) => {
      agent
        .get(API.GETUSERDATA + "/" + getUserId)
        .then((res) => {
          setUserData(res.data.data);
          res.data.data?.image ? setImageError(false) : setImageError(true)
          setImg({
            src: res.data.data?.image ? res.data.data?.image : placeholder,
            alt: "Upload an Image",
          })

          // setSelectedFile(res.data.data?.image)
          // blobToFile(childdata, getUserId + ".png")
          // const fileContent = localStorage.getItem('profile_img');
          // const retrievedBlob = new Blob([fileContent]);
          setValue('image1', res.data.data?.image)
          // setSelectedFile(blobToFile(retrievedBlob, getUserId + ".png"));
          // if (res.data.data.language) {
          //   setLanguages(res.data.data.language.split(","));
          // }

          if (res.data.data.language) {
            setDefaultLanguages(
              languageOptions.filter((item) => {
                return res.data.data.language
                  .split(",")
                  .includes(item.value.toString());
              })
            );
            setLanguages(res.data.data.language.split(","));
          }

          if (res.data.data.employee) {
            setEmployee(res.data.data.employee);
          }
          if (res.data.data.gender) {
            setGender(res.data.data.gender);
          }
          if (res.data.data.country) {
            setCountry(res.data.data.country);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getAreasData();
  }, [title, getUserId]);

  const setLanguagesArray = (e) => {
    const val = e.target.value;
    let tempLanguage = [...languages];

    if (tempLanguage.indexOf(val) > -1) {
      tempLanguage.splice(tempLanguage.indexOf(val), 1);
    } else {
      tempLanguage.push(val);
    }
    setLanguages(tempLanguage);
  };

  const options = countryList().getData();
  const [country] = useState(options);
  const [country1, setCountry] = useState("");

  const [organizationText, setOrganizationText] = useState(
    "Name of organization/institute*"
  );


  const [positionText, setpositionText] = useState(
    "Position in organization/institute*"
  );

  const setOrganizationPlaceholder = (e) => {
    var value = e.target.value;
    setEmployee(value);
    if (value === "yes") {
      setOrganizationText("Name of the current organization/institute*");
      setpositionText("Position title in the current organization/institute*");
    }
    if (value === "no") {
      setOrganizationText("Name of the previous organization/institute*");
      setpositionText("Position title in the previous organization/institute*");
    }
    // if (value === "yes") {
    //   setOrganizationText("Name of organization/institute*");
    //   setpositionText("Position in organization/institute*");
    // }
    // if (value === "no") {
    //   setOrganizationText("Name of organization/institute*");
    //   setpositionText("Position in organization/institute*");
    // }
  };

  /* For form refs */
  const { register, errors, handleSubmit, watch, setValue, clearErrors } = useForm();
  const history = useHistory();
  const [getLoader, setLoader] = useState(false);
  /* File upload */
  const [selectedFile, setSelectedFile] = useState("");

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      var size = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2);
      if (size > 30) {
        alert("Please select image size less than 30 MB");
        setImg({
          src: placeholder,
          alt: "Upload an Image",
        });
        setLoader(true);
      } else if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
        showErrorToaster("Please select valid image.");
        setImg({
          src: placeholder,
          alt: "Upload an Image",
        });
        setLoader(false);
      } else {
        const selectedFile = e.target.files[0];
        // setImg({
        //   src: URL.createObjectURL(e.target.files[0]),
        //   alt: e.target.files[0].name,
        // });
        // setSelectedFile(selectedFile);
        setCropImg(URL.createObjectURL(e.target.files[0]));
        setCropImgModalShow(true);
        setLoader(false);
      }
    } else {
      setImg({
        src: placeholder,
        alt: "Upload an Image",
      });
      setLoader(true);
    }
  };

  const onSubmit = (data) => {
    if (languages.length == 0) {
      return
    }

    if (!country1) {
      showErrorToaster("Country is required");
    }
    else if (!imageError) {
      data.country = country1;
      data.stepNumber = 1;
      data.userId = localStorage.getItem("userId");
      const formData = new FormData();
      formData.append("stepNumber", 1);
      formData.append("userId", localStorage.getItem("userId"));
      formData.append("employee", data.employee);
      formData.append("organization", data.organization);
      formData.append("position", data.position);
      formData.append("linkedin", data.linkedin);
      formData.append("country", country1);
      formData.append("gender", data.gender);
      formData.append("language", languages);
      formData.append("roleId", "Mentor");

      if(selectedFile){
        formData.append("image", selectedFile, selectedFile.name);
      }

      // if (localStorage.getItem('profile_img')) {
      //   // formData.append("image", selectedFile, selectedFile.name);

      // }
      // else {
      //   localStorage.setItem('profile_img', selectedFile);
      //   formData.append("image", selectedFile, selectedFile.name);
      // }


      let base64String = "";

      //if (userData) {
      // base64String = "asasdasd";
      //} else {
      // base64String = data.linkedin;
      base64String = data.linkedin + "-" + localStorage.getItem("userId");
      //}

      let buff = new Buffer(base64String);
      let base64data = buff.toString("base64");
      setLoader(true);

      agent
        .get(API.checkLinkedInURL + "/" + base64data)
        .then((res) => {
          if (res.data.data === true) {
            agent
              .post(API.SAVEUSERDETAILS, formData)
              .then((res1) => {
                if (res1.data.data === true) {
                  setLoader(false);
                  history.push(`/mentor-signup-step-2`);
                }
                if (res1.data.data === false) {
                  showToasterWithOk(res1.data.message);
                  setLoader(false);
                }
                if (res1.data.status === 422) {
                  showErrorToaster(res1.data.message);
                  setLoader(false);
                }
              })
              .catch((err) => {
                showToasterWithOk(err);
                setLoader(false);
              });
          }
          if (res.data.data === false) {
            showToasterWithOk(res.data.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          showToasterWithOk(err);
          setLoader(false);
        });
    }
    // history.push(`/mentor-signup-step-2`);
    // Http("POST", API.SAVEUSERDETAILS, data)
    //   .then((response) => {
    //     response = response.data;
    //     console.log(response);
    //     if (response.status) {
    //       showToaster(response.data.message);
    //       props.history.replace("/mentee-signup-step-2");
    //     } else {
    //       showToaster(response.data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     showToaster(err.message);
    //   });
  };

  const fileToImage = (childdata) => {
    setSelectedFile(blobToFile(childdata, getUserId + ".png"));
    setImg({
      src: URL.createObjectURL(childdata),
      alt: "",
    });

    setImageError(false)
  };



  return (
    <>
      <div>
        <section className="loginouter studentsteps">
          <Container>
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
              <Row className="align-items-center ">
                <Col md={12}>
                  <div className="logininnerouter">
                    <div className="w-100">
                      {" "}
                      <div className="loginlogo text-center mb-5 pb-4">
                        <Link to="/">
                          <img src="/assets/images/logo.svg" alt="logo" />
                        </Link>
                      </div>
                      <div className="loginform">
                        <h1>Tell us about yourself</h1>
                        <div className="steps_signup">
                          <div>
                            <span className="stepcount active">1</span>
                            <span className="stepcount">2</span>
                            <span className="stepcount">3</span>
                            <span className="stepcount">4</span>
                          </div>
                        </div>
                        <div className="profilescreen">
                          <h4 className="text-center">Your profile</h4>
                          <div className="userprofile text-center">
                            <label className="customeValidationText text-center">
                              Image size should be less than 30 MB*
                            </label>
                            <span>
                              <img
                                className="img-fluid rounded-circle userprofile-img"
                                src={src}
                                alt={alt}
                              />
                            </span>
                            <div>
                              <span className="inputfileouter">
                                Upload image{" "}
                                <input
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                  onChange={onChangePicture}
                                  className="form-control"
                                  name="image1"
                                // ref={register('image1',{ 
                                //   required: true,      
                                // })}
                                />
                              </span>
                              {imageError && (
                                <label className="customeValidationText text-center">
                                  Please select image size less than 30 MB
                                </label>
                              )}
                            </div>
                          </div>

                          <div className="whoamform">
                            <h5>Who Am I? </h5>
                            <div
                              className="mt-3"
                              style={{
                                marginTop: "0em",
                                display: "block",
                              }}
                            >
                              <Row className="align-items-center">
                                <Col sm={6}>
                                  <div className="form-group">
                                    <p className="mb-0 form-text">
                                      I am currently employed*
                                    </p>
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <div className="form-group">
                                    <span className="ml-md-3">
                                      <span className="custom_radio ml-0">
                                        Yes &nbsp;{" "}
                                        <input
                                          type="radio"
                                          name="employee"
                                          checked={
                                            employee === "yes" ? "checked" : ""
                                          }
                                          value="yes"
                                          ref={register}
                                          onChange={setOrganizationPlaceholder}
                                        />
                                        <span className="radio_indicator">
                                          &nbsp;
                                        </span>
                                      </span>
                                      <span className="custom_radio">
                                        No &nbsp;{" "}
                                        <input
                                          type="radio"
                                          name="employee"
                                          checked={
                                            employee === "no" ? "checked" : ""
                                          }
                                          value="no"
                                          ref={register({
                                            required: true,
                                          })}
                                          onChange={setOrganizationPlaceholder}
                                        />
                                        <span className="radio_indicator">
                                          &nbsp;
                                        </span>
                                        {errors.organization &&
                                          errors.organization.type ===
                                          "required" && (
                                            <label className="invalid-feedback text-left">
                                              Please enter your
                                              organization/institute address
                                            </label>
                                          )}
                                      </span>
                                    </span>
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <Form.Group
                                    controlId="formBasicOrganization"
                                    className="form-group"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder={organizationText}
                                      defaultValue={userData.organization}
                                      className={
                                        errors.organization
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="organization"
                                      aria-invalid={
                                        errors.organization ? "true" : "false"
                                      }
                                      ref={register({
                                        required: true,
                                        maxLength: 255
                                      })}
                                    />
                                    {errors.organization &&
                                      errors.organization.type ===
                                      "required" && (
                                        <label className="invalid-feedback text-left">
                                          Please enter your
                                          organization/institute address
                                        </label>
                                      )}
                                    {errors.organization &&
                                      errors.organization.type === "maxLength" && (
                                        <label className="customeValidationText text-left">
                                          Organization/institute should not be more then 255 characters
                                        </label>
                                      )}
                                    <Form.Text className="text-muted"></Form.Text>
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group
                                    controlId="formBasicposition"
                                    className="form-group"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder={positionText}
                                      defaultValue={userData.position}
                                      className={
                                        errors.position
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="position"
                                      aria-invalid={
                                        errors.position ? "true" : "false"
                                      }
                                      ref={register({
                                        required: true,
                                        maxLength: 255
                                      })}
                                    />
                                    {errors.position &&
                                      errors.position.type === "required" && (
                                        <label className="invalid-feedback text-left">
                                          Please enter your
                                          organization/institute position
                                        </label>
                                      )}
                                    {errors.position &&
                                      errors.position.type === "maxLength" && (
                                        <label className="customeValidationText text-left">
                                          Organization position should not be more then 255 characters
                                        </label>
                                      )}
                                    <Form.Text className="text-muted"></Form.Text>
                                  </Form.Group>
                                </Col>

                                <Col md={12}>
                                  <div className="form-group position-relative">
                                    <span>
                                      <i className="fab fa-linkedin-in"></i>
                                    </span>

                                    <Form.Control
                                      type="text"
                                      placeholder="Linkedin profile link*"
                                      defaultValue={userData.linkedin}
                                      className={
                                        errors.linkedin
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="linkedin"
                                      aria-invalid={
                                        errors.linkedin ? "true" : "false"
                                      }
                                      ref={register({
                                        required: true,
                                        pattern: {
                                          value:
                                            /^(ftp|http|https):\/\/?(?:[a-z]*\.)?linkedin\.com(\S*)$/i
                                          ,
                                        },
                                      })}
                                    />

                                    {errors.linkedin &&
                                      errors.linkedin.type === "required" && (
                                        <label className="invalid-feedback text-left">
                                          Please enter your linkedin address
                                        </label>
                                      )}
                                    {errors.linkedin &&
                                      errors.linkedin.type === "pattern" && (
                                        <label className="invalid-feedback text-left">
                                          Please enter full linked in url with
                                          https and user name.
                                        </label>
                                      )}
                                  </div>
                                </Col>

                                <Col md={12}>
                                  <Form.Control
                                    as="select"
                                    value={country1 || userData.country}
                                    className="mb-1"
                                    name="country"
                                    onChange={(e) => {
                                      setCountry(e.target.value);
                                    }}
                                    ref={register({
                                      required: true,
                                    })}
                                  >
                                    <option value="">Select Country*</option>

                                    {country &&
                                      country.map((item, key) => (
                                        <option value={item.label} key={key}>
                                          {item.label}
                                        </option>
                                      ))}

                                    {errors.position &&
                                      errors.position.type === "required" && (
                                        <label className="invalid-feedback text-left">
                                          Please enter your position
                                        </label>
                                      )}
                                  </Form.Control>
                                  {errors.country &&
                                    errors.country.type == "required" && (
                                      <p
                                        style={{
                                          color: "#dc3545",
                                          fontSize: "14.56px",
                                        }}
                                      >
                                        Please select country.
                                      </p>
                                    )}
                                </Col>
                                <Col sm={6}>
                                  <div className="form-group">
                                    <p className="mb-0">Gender*</p>
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <div className="form-group">
                                    <span className="ml-md-3">
                                      <span className="custom_radio ml-0">
                                        Male &nbsp;
                                        <input
                                          type="radio"
                                          name="gender"
                                          value="male"
                                          checked={
                                            gender === "male" ? "checked" : ""
                                          }
                                          ref={register}
                                          onClick={(e) =>
                                            handleGender(e.target.value)
                                          }
                                        />
                                        <span className="radio_indicator">
                                          &nbsp;
                                        </span>
                                      </span>
                                      <span className="custom_radio">
                                        Female &nbsp;
                                        <input
                                          type="radio"
                                          name="gender"
                                          value="female"
                                          checked={
                                            gender === "female" ? "checked" : ""
                                          }
                                          ref={register}
                                          onClick={(e) =>
                                            handleGender(e.target.value)
                                          }
                                        />
                                        <span className="radio_indicator">
                                          &nbsp;
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </Col>

                                <Col md={12}>
                                  <Form.Group controlId="formBasicLanguage">
                                    <Form.Label>Language proficiency and preferences*</Form.Label>
                                    <Select
                                      isMulti
                                      name="language"
                                      value={defaultLanguages}
                                      options={languageOptions}
                                      onChange={(e) => {
                                        setLanguages(e.map((item) => item.value));
                                        setDefaultLanguages(e)
                                      }}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      ref={register}
                                    />
                                    {languages.length == 0 &&
                                      (
                                        <label
                                          className="customeValidationText text-left"
                                          style={{ color: "#dc3545" }}
                                        >
                                          Please choose languages
                                        </label>
                                      )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mt-3 text-center">
                          <Button
                            type="submit"
                            className="btn loginsubmit nextbtn ml-2"
                            disabled={getLoader}
                          >
                            {"Save & Next"}
                          </Button>
                          {/* <Button
                            className="btn loginsubmit nextbtn outline mr-2 ml-2"
                            onClick={(e) => {
                              e.preventDefault();

                              history.push("/mentor-signup-step-2");
                            }}
                          >
                            Next
                          </Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </section>
      </div>

      {cropImgModalShow && (
        <ImageCropper
          show={cropImgModalShow}
          cropImg={cropImg}
          fileToImage={fileToImage}
          onHide={() => setCropImgModalShow(false)}
          centered
          size="xl"
          className="cropper-modal"
        />
      )}
    </>
  );
};

export default MentorSignUp;
