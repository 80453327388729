import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Accordion,
  Card,
} from "react-bootstrap";
import * as settingActions from "../../actions/settingActions";
import * as homePageActions from "../../actions/homePageActions";
import * as forumActions from "../../actions/forumActions";
import Question from "./question";
import Answer from "./answer";
import ReportAdmin from "./reportadmin";
import { Link, useHistory } from "react-router-dom";
import { showToasterWithOk } from "../../utils/helpers";
import firebase from "../../Firebase";
import Moment from "moment";

const Index = (props) => {
  const { categories, forums, forumusers, actions, userInfo, isAuth } = props;
  const history = useHistory();
  const [room, setRoom] = useState({ roomname: "" });
  const usersDatabase = firebase.database().ref("users/");
  var forum_heading = "";

  if (userInfo.role_id == "Mentor") {
    forum_heading = "Mentors";
  } else if (userInfo.role_id == "Mentees") {
    forum_heading = "Mentees";
  }

  useEffect(() => {
    document.title = "vLookUp Forum";
    actions.loadCategoryData(); // Question Category
    actions.loadForumData(userInfo.id); // Forum Details
    actions.saveForumUser(userInfo.id); // Forum user add
    actions.loadForumUserData(userInfo.role_id); // Forum user get for Forum Members

    if (room.roomname) {
      history.push("/chat?roomKey=" + room.roomname);
    }
  }, [room, history, actions]);
  // console.log(forums);

  const [disbaled, setDisbaled] = useState("join-bt-active");
  const [modalShow, setModalShow] = useState(false);
  const [userData, setUserData] = useState({});
  const [answerShow, setAnswerShow] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);

  const askQuestionModal = (e) => {
    if (localStorage.isConfirmed == "true") {
      actions.loadCategoryData();
      e.preventDefault();
      setModalShow(true);
      setUserData({
        user_id: userInfo.id,
        categories: categories,
      });
    } else{
      showToasterWithOk("Please Complete Your Profile First.");
      return
    }
  };

  const replyAnswerModal = (e, ques_id, user_ans) => {
    e.preventDefault();
    setAnswerShow(true);
    setUserData({
      user_id: userInfo.id,
      ques_id: ques_id,
      user_ans: user_ans,
    });
  };

  const viewReportModal = (e, ques_id, ans_id, report_type) => {
    e.preventDefault();
    setReportModalShow(true);
    setUserData({
      user_id: userInfo.id,
      ques_id: ques_id,
      ans_id: ans_id,
      report_type: report_type,
    });
  };

  const likeAnswer = (ques_id, ans_id) => {
    const ans_params = {};
    ans_params.user_id = userInfo.id;
    ans_params.ques_id = ques_id;
    ans_params.ans_id = ans_id;
    actions.saveForumAnswerLike(ans_params);
  };

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const handleclickChat = (item) => {
    if (isAuth === false) {
      showToasterWithOk(
        "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
      );
      history.push(`/login`);
    }
    if(localStorage.isConfirmed == "true"){
      if (userInfo.id > item.user_id) {
        var roomUser = userInfo.id + "_" + item.user_id;
      } else {
        var roomUser = item.user_id + "_" + userInfo.id;
      }
      let time = Moment.utc().valueOf();
  
      const message = {};
      message.mentor_name = item.full_name;
      message.mentor_image = item.image;
      message.mentor_id = item.user_id;
      message.mentee_name = userInfo.full_name;
      message.mentee_image = userInfo.image;
      message.mentee_id = userInfo.id;
      message.device_type = "Web";
      message.message = "";
      message.key = roomUser;
      message.role_id = userInfo.role_id;
      message.status = 0;
      message.time = Moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      message.epoch = -1 * time;
  
      setRoom({ ...room, roomname: roomUser });
  
      let mentorDatabaseKey = "users/" + item.user_id + "/" + roomUser;
      usersDatabase.equalTo(mentorDatabaseKey).once("value", (snapshot) => {
        if (snapshot.exists()) {
        } else {
          const newUser = firebase.database().ref(mentorDatabaseKey);
          newUser.set(message);
        }
      });
  
      let menteedatabaseKey = "users/" + userInfo.id + "/" + roomUser;
      usersDatabase.equalTo(menteedatabaseKey).once("value", (snapshot) => {
        if (snapshot.exists()) {
        } else {
          const newUser = firebase.database().ref(menteedatabaseKey);
          newUser.set(message);
        }
      });
    } else{
      showToasterWithOk("Please Complete Your Profile First.");
      return
    }
  };

  return (
    <div>
      <section className="community_section">
        <div className="content_inner">
          <img
            src={process.env.REACT_APP_STATIC_IMAGE_PATH + "cm_back.jpg"}
            alt="women leadership mentoring"
            className="img-fluid back_img"
          />
        </div>
      </section>
      <section className="community_page_inner">
        <Container className="container">
          <div className="row_c d-flex">
            <div className="community_content">
              <h1 className="dash_heading">{forum_heading} Forum</h1>
            </div>

            <div className="group_c d-flex align-items-center justify-content-center ml-auto">
              <span
                onClick={(e) => {
                  askQuestionModal(e);
                }}
                className={"mr-3 " + disbaled}
              >
                Ask a Question
              </span>
            </div>
          </div>

          <Row className="mt-4">
            <Col lg="8" md="7">
              <div className="whole_content">
                <div className="forum_page_contant faqouter">
                  <Accordion defaultActiveKey={1}>
                    {forums &&
                      forums.map((item, key) => (
                        <Card key={key + 1}>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              eventKey={key + 1}
                              className="faq_open"
                            >
                              {item.question}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={key + 1}>
                            <Card.Body>
                              <div className="forum_contant_body">
                                <div className="answer_top_info">
                                  <div className="answer_list_left">
                                    <div className="answer_count">
                                      {" "}
                                      <img
                                        src={
                                          process.env
                                            .REACT_APP_STATIC_IMAGE_PATH +
                                          "message.png"
                                        }
                                        alt="Answers"
                                      />{" "}
                                      {item.answers.length} Answers
                                    </div>
                                    <Button
                                      className="btn reply_btn"
                                      onClick={(e) => {
                                        replyAnswerModal(
                                          e,
                                          item.id,
                                          item.user_ans
                                        );
                                      }}
                                    >
                                      <i className="fas fa-reply"></i>Reply
                                    </Button>
                                    <Button
                                      className="btn reply_btn report_to_admin w-md-100 "
                                      onClick={(e) => {
                                        viewReportModal(
                                          e,
                                          item.id,
                                          "",
                                          "question"
                                        );
                                      }}
                                      title="Report to Admin"
                                    >
                                      {" "}
                                      Report to Admin
                                    </Button>
                                  </div>
                                  <span className="timeing">
                                    Asked{" "}
                                    <span className="asked_timing">
                                      {item.asked_ago}
                                    </span>
                                  </span>
                                </div>
                                {item.answers && item.answers.length > 0 && (
                                  <>
                                    <h4>Answers</h4>
                                    {item.answers &&
                                      item.answers.length > 0 &&
                                      item.answers.map((aitem, akey) => (
                                        <div className="answer_list" key={akey}>
                                          <div
                                            dangerouslySetInnerHTML={prepareHtml(
                                              aitem.answer
                                            )}
                                          ></div>
                                          <div className="answer_bottom">
                                            <div
                                              className={"answer_list_left "}
                                            >
                                              <Button
                                                className="btn reply_btn  report_to_admin answer_report_to_admin"
                                                onClick={(e) => {
                                                  viewReportModal(
                                                    e,
                                                    item.id,
                                                    aitem.id,
                                                    "answer"
                                                  );
                                                }}
                                                title="Report to Admin"
                                              >
                                                {" "}
                                                Report to Admin
                                              </Button>
                                              <Button
                                                className="likes_count"
                                                style={{}}
                                                onClick={(e) => {
                                                  likeAnswer(item.id, aitem.id);
                                                }}
                                              >
                                                <i className="fas fa-thumbs-up"></i>
                                                {aitem.likes} Likes
                                              </Button>
                                              <div className="posted_by">
                                                <span>Posted By : </span>
                                                {aitem.posted_by}
                                              </div>
                                            </div>
                                            <span className="timeing">
                                              Posted On :{" "}
                                              <span className="posted_timing">
                                                {aitem.posted_ago}
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                  </>
                                )}
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ))}
                  </Accordion>
                </div>
              </div>
            </Col>

            <Col lg="4" md="5" className="mt-4 mt-md-0">
              <div className="sidebar_community overflow_custom_style">
                <div className="d-flex align-items-center heading justify-content-between mb-4">
                  <h2>Forum Members ({forumusers.length}) </h2>
                </div>

                {/* LIST */}

                {forumusers &&
                  forumusers.map((item, key) => (
                    <div className="member_list" key={key}>
                      <ul>
                        <li className="d-flex align-items-center mb-3">
                          <div className="member_image">
                            {item.image && (
                              <img
                                src={item.image}
                                alt="career coaching for women"
                                className="img-fluid"
                              />
                            )}
                            {!item.image && (
                              <img
                                src={
                                  process.env.REACT_APP_STATIC_IMAGE_PATH +
                                  "mentor_women_two.png"
                                }
                                className="img-fluid"
                                alt="career mentor consulting"
                              />
                            )}
                          </div>
                          <div className="member_detail ml-3">
                            <h3>{item.full_name}</h3>
                            <h4>{item.position}</h4>
                            <h4>{item.organization}</h4>
                            {userInfo.id !== item.user_id && (
                              <span
                                className="link_btn btn btn_red"
                                onClick={() => handleclickChat(item)}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_STATIC_IMAGE_PATH +
                                    "msg.png"
                                  }
                                  alt="msg_icon"
                                  className="mr-2 bg-white"
                                />
                                Send Message
                              </span>
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  ))}
                {forumusers && forumusers.length === 0 && (
                  <div className="text-center">No Joined User found.</div>
                )}
                {/* LIST */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {modalShow && (
        <Question
          show={modalShow}
          userdata={userData}
          onHide={() => setModalShow(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}

      {answerShow && (
        <Answer
          show={answerShow}
          userdata={userData}
          onHide={() => setAnswerShow(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}

      {reportModalShow && (
        <ReportAdmin
          show={reportModalShow}
          userdata={userData}
          onHide={() => setReportModalShow(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    setting: state.setting,
    brand: state.brand,
    isAuth: state.isAuth,
    userInfo: state.userInfo,
    categories: state.categories,
    forums: state.forums,
    forumusers: state.forumusers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(settingActions, homePageActions, forumActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
