import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as menteesRequestAction from "../../actions/menteesRequestAction";
import * as baseActions from "../../actions/baseActions";
import { Col, Button } from "react-bootstrap";
import UserSidebar from "../../Component/UserSidebar";
import { agent } from "../../utils/agent";
import { ASSIGN_MENTOR_REQUEST } from "../../utils/apiPath";
import { setToaster } from "../../utils/helpers";
import Slider from "react-slick";
import Relationship from "./relationship";
import { Tooltip } from "@material-ui/core";
import config from "../../config";
const queryString = require("query-string");

const Index = (props) => {
  const { title, actions, menteeRequestData, pagination } = props;
  function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  const [relationModalShow, setRelationModalShow] = useState(false);
  const [endRelationship, setEndRelationship] = useState({});
  const [getAcceptLoader, setAcceptLoader] = useState(false);
  const [getRejectLoader, setRejectLoader] = useState(false);

  var Mentors_details = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    unslick: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          unslick: false,
        },
      },
    ],
  };

  const downloadResume = (e, url) => {
    e.preventDefault();
    //window.location.href = url;
    window.open(url, "_blank");
  };
  const AcceptReject = (menteeId, mentorId, status, actionid) => {
    setAcceptLoader(true);
    var data = {};
    data.mentee_id = menteeId;
    data.mentor_id = mentorId;
    data.status = status;
    data.actionid = actionid;

    agent
      .post(ASSIGN_MENTOR_REQUEST, data)
      .then((res) => {
        setAcceptLoader(false);
        setToaster(res.data.message);
        history.push(`/mantees-request`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const rejectWithReason = (menteeId, mentorId, status, actionid) => {
    setRejectLoader(true);
    setRelationModalShow(true);
    setEndRelationship({
      mentee_id: menteeId,
      mentor_id: mentorId,
      status: status,
      actionid: actionid,
    });
  };
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    const request = {};
    if (params.get("sort")) {
      var sort = queryStringParsed["sort"];
      var direction = queryStringParsed["direction"];
      request.sort = sort;
      request.direction = direction;
    }
    if (params.get("page")) {
      var page = queryStringParsed["page"];
      request.page = page;
    }
    if (params.get("keyword")) {
      var keyword = queryStringParsed["keyword"];
      request.keyword = keyword;
    }
    document.title = title;
    actions.loadPendingRequestData(request, 1);
  }, [title, actions, location]);
  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link onClick={SidebarAddClass} className="toggle_btn d-lg-none">
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <h2 className="dash_heading">Pending Request</h2>
          <div className="box_wrap shadow-sm bg-white mentor_box">
            <Slider {...Mentors_details}>
              {menteeRequestData &&
                menteeRequestData.map((item, key) => (
                  <div
                    className="mentro_details mb-2 mb-md-4 d-flex  flex-wrap"
                    key={key}
                  >
                    <div className="mentro_details_leftbar">
                      <div className="mentor_pic">
                        <img
                          src={item.mentee_image}
                          alt="women's career consultation"
                        />
                      </div>
                      <div className="profile_btn mt-3">
                        <Link
                          to={{ pathname: item.mentee_linkedin }}
                          className="link_btn btn m-0"
                          target="_blank"
                        >
                          <img
                            src={
                              process.env.REACT_APP_STATIC_IMAGE_PATH +
                              "linkedin.png"
                            }
                            className="mr-2 bg-white"
                            alt="linkedin"
                          />
                          Linkedin Profile
                        </Link>
                      </div>
                      <div className="extar_info_link">
                        <ul className="px-0 pt-2 mentor_log">
                          <li>
                            {item?.mn_open_to_talk == 1 && (
                              <Tooltip
                                size="4xl"
                                title={<h5>{config?.mn_open_to_talk}</h5>}
                                describeChild
                                arrow
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_STATIC_IMAGE_PATH +
                                    "usermind.svg"
                                  }
                                />
                              </Tooltip>
                            )}
                          </li>
                          <li>
                            {item?.mn_loking_for_coach == 1 && (
                              <Tooltip
                                size="4xl"
                                title={<h5>{config?.mn_loking_for_coach}</h5>}
                                describeChild
                                arrow
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_STATIC_IMAGE_PATH +
                                    "useraward.svg"
                                  }
                                />
                              </Tooltip>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mentro_details_sidebar">
                      <div className="row">
                        <Col xl={4}>
                          <div className="d-flex ">
                            <div className="name_info">
                              <h4 className="mb-0">{item.mentee_name}</h4>
                              <h5>
                                <span className="d-block">
                                  {item.mentee_organization}
                                </span>
                                {item.mentee_position}
                              </h5>
                            </div>
                          </div>
                        </Col>
                        <Col xl={8} className="">
                          <div className="profile_btn d-sm-flex  justify-content-xl-end">
                            {item.mentee_resume && (
                              <div className="d-sm-flex align-items-center">
                                <div className="date_icon">
                                  <img
                                    src="/assets/images/pdf.png"
                                    alt="women leadership mentoring"
                                  />
                                </div>

                                {item.mentee_resume && (
                                  <div className="date_content pl-2">
                                    <Link
                                      to=""
                                      download
                                      className="d-block date color_red"
                                      onClick={(e) =>
                                        downloadResume(e, item.mentee_resume)
                                      }
                                      target="_blank"
                                    >
                                      {"Download Resume"}
                                    </Link>
                                  </div>
                                )}
                              </div>
                            )}
                            <Button
                              className="link_btn btn"
                              onClick={() =>
                                AcceptReject(
                                  item.mentee_id,
                                  item.mentor_id,
                                  2,
                                  item.id
                                )
                              }
                              disabled={getAcceptLoader}
                            >
                              {getAcceptLoader ? "Accepting.." : "Accept"}
                            </Button>
                            <Link
                              className="link_btn btn btn_red"
                              onClick={() =>
                                rejectWithReason(
                                  item.mentee_id,
                                  item.mentor_id,
                                  3,
                                  item.id
                                )
                              }
                            >
                              {getRejectLoader ? "Rejecting.." : "Reject"}
                            </Link>
                          </div>
                        </Col>
                      </div>

                      <div className="mentro_text">
                        <h3 className="mb-1">Objectives</h3>
                        <ul>
                          {item.mentee_objective &&
                            item.mentee_objective.map((item, key) => (
                              <li>{item}</li>
                            ))}
                        </ul>
                      </div>
                      <h3 className="mb-1">About me</h3>
                      <div className="about-text">
                        <p>{item.mentee_about}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
            {/* {menteeRequestData.length === 0 && (
              <div className="text-center">No pending request found.</div>
            )} */}
             {(menteeRequestData.length === 0 || pagination?.total ===0)  && (
              <div className="text-center">No pending request found.</div>
            )}
          </div>
        </div>
      </div>
      {relationModalShow && (
        <Relationship
          show={relationModalShow}
          endrelationship={endRelationship}
          actions={actions}
          onHide={() => setRelationModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    menteeRequestData: state.menteeRequestData,
    isAuth: state.isAuth,
    pagination: state.pagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(menteesRequestAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
