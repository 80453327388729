import React, { useEffect, useState } from "react";
import { Modal, Button, Form, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import { setToaster } from "../../utils/helpers";

import { useLocation } from "react-router-dom";
const queryString = require("query-string");
const MentoringSession = (props) => {
  const { userdata, userInfo,actions } = props;
  const { register, errors, handleSubmit } = useForm();
  const [roleSession, setRoleSession] = useState()
  const location = useLocation();

  useEffect(() => {
    if (userInfo?.role_id == 'Mentor') {
      setRoleSession(userdata?.mentor_sessions)
    } else {
      setRoleSession(userdata?.mentee_sessions)
    }

  }, [userdata,userInfo])

  const onSubmit = (data) => {
    const request = {};
    request.sessions =  Number(data.sessions);
    agent
      .post(API.LOAD_CUSTOMER + "/update-session/" + userInfo?.id + "/" + userdata?.id, request)
      .then((res) => {
        setToaster(res.data.message);

        const params = new URLSearchParams(location.search);
        const queryStringParsed = queryString.parse(location.search);
        const request = {};

        if (params.get("sort")) {
          var sort = queryStringParsed["sort"];
          var direction = queryStringParsed["direction"];
          request.sort = sort;
          request.direction = direction;
        }
        if (params.get("page")) {
          var page = queryStringParsed["page"];
          request.page = page;
        }
        if (params.get("keyword")) {
          var keyword = queryStringParsed["keyword"];
          request.keyword = keyword;
        }
        userInfo?.role_id == 'Mentor'? actions.loadData(request, 4): actions.myMentors(request, userInfo.id)
        props.onHide();
      })
      .catch((err) => {
        setToaster("Something went wrong.Please try again.");
      });
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Mentoring Sessions</Modal.Header>


        <Modal.Body>
          <div>
            <Form onSubmit={handleSubmit(onSubmit)} className="banner_form2 signup_class">
              <Form.Group controlId="formSessions" style={{ marginTop: '1rem' }}>
                <FormControl
                  type="number"
                  min={0}
                  max={10000}
                  placeholder="No. of mentoring sessions completed"
                  className={errors.sessions ? "formError" : ""}
                  name="sessions"
                  value={roleSession}
                  aria-invalid={errors.sessions ? "true" : "false"}
                  onChange={(e)=>{setRoleSession(e?.target?.value)}}
                  ref={register({
                    required: true,
                  })}
                />
                {errors.sessions && errors.sessions.type === "required" && (
                  <span role="alert" className="err_msg">
                    Please enter no. of sessions
                  </span>
                )}
              </Form.Group>
              <div className="text-right">
                <Button type={"submit"} className={"btn btn-signup px-4 ml-auto"}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body >
      </Modal >
    </>
  );
};

export default MentoringSession;
