import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col, Form, Button, Modal, Accordion ,Card} from "react-bootstrap";
import * as settingActions from "../../actions/settingActions";
import * as homePageActions from "../../actions/homePageActions";
import * as forumActions from "../../actions/forumActions";
import Slider from "react-slick";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import Question from "./question";
import Answer from "./answer";
import ReportAdmin from "./reportadmin";
import { Link, useLocation, useHistory } from "react-router-dom";
import { showToasterWithOk } from "../../utils/helpers";
import firebase from "../../Firebase";
import Moment from "moment";
import Paginate from "../../Component/Paginate";
const queryString = require("query-string");


const Index = (props) => {
  const { categories, forums, forumusers, actions, userInfo, isAuth } = props;
  const history = useHistory();
  const location = useLocation();
  const [room, setRoom] = useState({ roomname: "" });
  const usersDatabase = firebase.database().ref("users/");
  var forum_heading = '';

  if(userInfo.role_id=='Mentor'){
    forum_heading='Mentors';
  }
  else if(userInfo.role_id=='Mentees'){
    forum_heading='Mentees';
  }

  // Useffect for all data
  useEffect(() => {
    
    document.title = 'vLookUp Forum';
    actions.loadCategoryData(); // Question Category
    actions.saveForumUser(userInfo.id); // Forum user add
    actions.loadForumUserData(userInfo.role_id); // Forum user get for Forum Members

    if (room.roomname) {
      history.push("/chat?roomKey=" + room.roomname);
    }

  }, [room, history, actions]);
// console.log(forums);

  const [disbaled, setDisbaled] = useState('join-bt-active');  
  const [modalShow, setModalShow] = useState(false);
  const [userData, setUserData] = useState({});
  const [answerShow, setAnswerShow] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);

  const askQuestionModal = (e) => {
    actions.loadCategoryData();
    e.preventDefault();
    setModalShow(true);
    setUserData({
      user_id: userInfo.id,
      categories: categories
    });
  };

  const replyAnswerModal = (e, ques_id) => {
    e.preventDefault();
    setAnswerShow(true);
    setUserData({
      user_id: userInfo.id,
      ques_id: ques_id
    });
  };
  
  const viewReportModal = (e, ques_id, ans_id, report_type) => {
    e.preventDefault();
    setReportModalShow(true);
    setUserData({
      user_id: userInfo.id,
      ques_id: ques_id,
      ans_id: ans_id,
      report_type: report_type
    });
  };

  const likeAnswer = (ques_id, ans_id) => {
    const ans_params = {};
    ans_params.user_id=userInfo.id;
    ans_params.ques_id=ques_id;
    ans_params.ans_id=ans_id;
    actions.saveForumAnswerLike(ans_params);
  };
  
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const handleclickChat = (item) => {
    if (isAuth === false) {
      showToasterWithOk(
        "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
      );
      history.push(`/login`);
    }
    if(userInfo.id>item.user_id){
      var roomUser = userInfo.id + "_" + item.user_id;

    }else{

      var roomUser = item.user_id + "_" + userInfo.id;

    }
    let time = Moment.utc().valueOf();
    
    const message = {};
    message.mentor_name = item.full_name;
    message.mentor_image = item.image;
    message.mentor_id = item.user_id;
    message.mentee_name = userInfo.full_name;
    message.mentee_image = userInfo.image;
    message.mentee_id = userInfo.id;
    message.device_type = "Web";
    message.message = "";
    message.key = roomUser;
    message.role_id = userInfo.role_id;
    message.status = 0;
    message.time = Moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    message.epoch = -1 * time;

    setRoom({ ...room, roomname: roomUser });

    let mentorDatabaseKey = "users/" + item.user_id + "/" + roomUser;
    usersDatabase.equalTo(mentorDatabaseKey).once("value", (snapshot) => {
      if (snapshot.exists()) {
      } else {
        const newUser = firebase.database().ref(mentorDatabaseKey);
        newUser.set(message);
      }
    });

    let menteedatabaseKey = "users/" + userInfo.id + "/" + roomUser;
    usersDatabase.equalTo(menteedatabaseKey).once("value", (snapshot) => {
      if (snapshot.exists()) {
      } else {
        const newUser = firebase.database().ref(menteedatabaseKey);
        newUser.set(message);
      }
    });
  };

  const handlePageClick = (data) => {
    let page = data + 1;
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    history.push({
      pathname: "/forumtest",
      search: "?" + params,
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    const request = {};
    if (params.get("page")) {
      var page = queryStringParsed["page"];
      request.page = page;
    }
    actions.loadForumData(userInfo.id, request); // Forum Details
    
  }, [actions, location]);

  return (
    <div>
      <section className="community_section">
        <div className="content_inner">
          <img
            src='http://localhost/vlookup/api/public/upload/community/1622722440.jpg'
            alt="women leadership mentoring"
            className="img-fluid back_img"
          />
          <img
            src="assets/img/cm_back_shape.png"
            className="img-fluid shape_img"
            alt="find a career mentor online"
          />
        </div>
      </section>
      <section className="community_page_inner">
        <Container className="container">
          <div className="row_c d-flex">
            <div className="community_content">
              <h2 className="community_heading">{forum_heading} Forum</h2>
              
            </div>

            <div className="group_c d-flex align-items-center justify-content-center ml-auto">
              <span 
              onClick={(e) => {
                askQuestionModal(
                  e
                );
              }} 
              className={"mr-3 " + disbaled}  >
               Ask a Question
              </span>
            </div>
          </div>
          

          <Row className="mt-4">
            <Col lg="8" md="7">
                <Paginate onClick={handlePageClick} />
              <div className="whole_content">
                <div className="forum_page_contant faqouter">
                  <Accordion defaultActiveKey={1}>
                    
                  {forums &&
                        forums.map((item, key) => (
                    <Card key={key + 1}>
                      <Card.Header>
                        <Accordion.Toggle as={Button} eventKey={key + 1}  className= "faq_open">Q. {item.question}</Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={key + 1}>
                        <Card.Body>
                        
                          <div className="forum_contant_body">
                            <div className="answer_top_info">
                              <div className="answer_list_left">
                                <div className="answer_count"><i className="far fa-comment-dots"></i>{item.answers.length} Answer(s)</div>
                                <Button className="btn reply_btn" onClick={(e) => {
                                replyAnswerModal(
                                  e,
                                  item.id,
                                );
                              }} ><i className="fas fa-reply"></i>Reply</Button>
                                <Button className="btn reply_btn" onClick={(e) => {
                                viewReportModal(
                                  e,
                                  item.id,
                                  '',
                                  'question'
                                );
                              }} title="Report to Admin"><i className="fas fa-flag"></i> Report</Button>
                              </div>
                              <span className="timeing">Asked {item.asked_ago}</span>
                            </div>
                            
                            { item.answers && item.answers.length > 0 &&
                              item.answers.map((aitem, akey) => (
                            <div className="answer_list" key={akey}>
                              <h4>Answers</h4>
                              <div
                                dangerouslySetInnerHTML={prepareHtml(
                                  aitem.answer
                                )}
                              ></div>
                              <div className="answer_bottom">
                                <div className={"answer_list_left "}>
                                  <Button className="btn reply_btn" onClick={(e) => {
                                  viewReportModal(
                                    e,
                                    item.id,
                                    aitem.id,
                                    'answer'
                                  );
                                }} title="Report to Admin"><i className="fas fa-flag"></i> Report</Button>
                                  <Button className="likes_count" style={{}} onClick={(e) => {
                                    likeAnswer(
                                      item.id,
                                      aitem.id,
                                    );
                                  }} ><i className="fas fa-thumbs-up"></i>{aitem.likes} Likes</Button>
                                  <div className="posted_by"><span>Posted By</span>{aitem.posted_by}</div>
                                </div>
                                <span className="timeing">Posted On {aitem.posted_ago}</span>
                              </div>
                            </div>
                              ))}

                          </div>

                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                  ))}

                  </Accordion>
 
                </div>

              </div>
            </Col>

            <Col lg="4" md="5" className="mt-4 mt-md-0">
              <div className="sidebar_community overflow_custom_style">
                <div className="d-flex align-items-center heading justify-content-between mb-4">
                  <h2>Forum Members ({forumusers.length}) </h2>
                </div>

                {/* LIST */}

                {forumusers &&
                 
                  forumusers.map((item, key) => (
                    <div className="member_list" key={key}>
                      <ul>
                        <li className="d-flex align-items-center mb-3">
                          <div className="member_image">
                            {item.image && (
                              <img
                                src={item.image}
                                alt="career coaching for women"
                                className="img-fluid"
                              />
                            )}
                            {!item.image && (
                              <img
                                src={
                                  process.env.REACT_APP_STATIC_IMAGE_PATH +
                                  "mentor_women_two.png"
                                }
                                className="img-fluid"
                                alt="career mentor consulting"
                              />
                            )}
                          </div>
                          <div className="member_detail ml-3">
                            <h3>{item.full_name}</h3>
                            <h4>{item.position}</h4>
                            <h4>{item.organization}</h4>
                            {userInfo.id!=item.user_id &&(
                            <span
                                className="link_btn btn btn_red"
                                onClick={() => handleclickChat(item)}
                              >
                                <img
                                  src={
                                  process.env.REACT_APP_STATIC_IMAGE_PATH + "msg.png"
                                  }
                                  
                                  alt="msg_icon"
                                  className="mr-2 bg-white"
                                />
                              Send Message
                            </span>)}
                          </div>
                        </li>
                      </ul>
                    </div>
                  ))}
              { forumusers &&
                      forumusers.length===0 && (
                  <div className="text-center">No Joined User found.</div>
                )}
                {/* LIST */}
              </div> 
            </Col>
          </Row>
        </Container>
      </section>

      {modalShow && (
        <Question
          show={modalShow}
          userdata={userData}
          onHide={() => setModalShow(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}

      {answerShow && (
        <Answer
          show={answerShow}
          userdata={userData}
          onHide={() => setAnswerShow(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}

      {reportModalShow && (
        <ReportAdmin
          show={reportModalShow}
          userdata={userData}
          onHide={() => setReportModalShow(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
     
    </div>
  );
};

function mapStateToProps(state) {
  return {

    setting: state.setting,
    brand: state.brand,
    isAuth: state.isAuth,
    userInfo: state.userInfo,
    categories: state.categories,
    forums: state.forums,
    forumusers: state.forumusers    

  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(settingActions, homePageActions, forumActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
