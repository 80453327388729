import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";

const ViewModal = (props) => {

  const { viewdata } = props;
  // console.log('viewdata',viewdata);
  
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const modalStyle = {
    maxHeight:'70vh', 
    overflowX:'hidden', 
    overflowY:'scroll'
  }



  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {viewdata.title}
        </Modal.Header>
        
        <Modal.Body className="newsletter-html" style={ modalStyle}>
          { viewdata.type==='newsletter' ? (
            // <html>{viewdata.item}</html>
            <div
              dangerouslySetInnerHTML={prepareHtml(
                viewdata.item
              )}
            ></div>
          ) : (
            <div style={{ margin: "-13px", marginBottom: "-17px" }}>
              {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/AoIw_mdiO0g?si=UAYPqVMXYPFMHDKn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
            <iframe width="100%" height="500" src={"https://www.youtube.com/embed/" + viewdata?.item +"?autoplay=1&start=0"} title={viewdata.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          ) }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ViewModal;
