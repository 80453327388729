import React, { useState, useEffect,Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { agent } from "../../utils/agent";
import * as menteesRequestAction from "../../actions/menteesRequestAction";
import * as baseActions from "../../actions/baseActions";
import { Container, Form, Col, Button, Accordion, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as API from "../../utils/apiPath";
import { showToasterWithOk } from "../../utils/helpers";
import {  useHistory } from "react-router-dom";
// const queryString = require("query-string");

const AddDiagnostics = (props) => {

  const { title, actions, menteeRequestData, userInfo } = props;
  const { register, errors, handleSubmit } = useForm();
  const [getLoader, setLoader] = useState(false);
  const [getShareLoader, setShareLoader] = useState(false);
  const [activeId, setActiveId] = useState(1);
  const [objQuestions, setObjQuestions] = useState([]);
  const [checkDiagnosticToolkit, setCheckDiagnosticToolkit] = useState({
    is_save: 0,
    is_save_share: 0,
    is_update: 0
  });
  const submit_state = {
    button: 1
  };
  
  var mentor_status, mentor_is_completed, mentor_mentee_termination_status, mentor_mentee_diagnostics_leng, mentee_diagnostics_sdate = '';
  const total_mentor = menteeRequestData?.length;

  menteeRequestData.map((rank, i) => {
    if (i === 0) {
      mentor_status = rank.status;
      mentor_is_completed = rank.is_completed;
      mentor_mentee_termination_status = rank.mentee_termination_status;
      mentor_mentee_diagnostics_leng = rank.mentee_diagnostics?.length;
      mentee_diagnostics_sdate = rank.mentee_diagnostics_sdate;
    } 
  })
  
  useEffect(() => {
    const request = {};
    document.title = title;
    actions.myMentors(request, userInfo.id);
    const getObjQuestionsData = () => {
      agent
        .get(API.GET_OBJ_QUEST + '/' + userInfo.id, {})
        .then((res) => {
          setObjQuestions(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getObjQuestionsData();

    const checkrequest = {user_id:userInfo.id};
    
    const getcheckDiagnosticToolkit = () => {
      agent
        .post(API.CHECK_DIAGNOSTIC, checkrequest)
        .then((res) => {
          setCheckDiagnosticToolkit(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getcheckDiagnosticToolkit();
    // console.log(total_mentor);
  }, [title, actions, userInfo]);

  const history = useHistory();
  const onDiagnoSubmit = (data) => {

    data.mentee_id=userInfo.id;
    if (submit_state.button === 1) {
      data.action='save';
      setLoader(true);
    }
    if (submit_state.button === 2) {
      data.action='save_share';
      setShareLoader(true);
    }
    if (submit_state.button === 3) {
      data.action='update_share';
      setShareLoader(true);
    }
    // console.log(data);

    agent.post(API.SET_OBJ_QUEST, data).then((res1) => 
    {
      // console.log(res1);
      if (res1.data.data === true) 
      {
      
        setLoader(false);
        setShareLoader(false);
        showToasterWithOk(res1.data.message);
       
        window.location.href = "/my-diagnostic-toolkit";
      
        
      }
      if (res1.data.data === false) {
        showToasterWithOk(res1.data.message);

        setLoader(false);
        setShareLoader(false);
      }
    })
    .catch((err) => {
      showToasterWithOk(err);
      setLoader(false);
    });
    
  }

  // console.log('checkDiagnosticToolkit',checkDiagnosticToolkit);
  
  return (
    <Fragment>
      
      <Form onSubmit={handleSubmit(onDiagnoSubmit)}>
        <Container>
          <div className="faqouter">
            <div
              className="mb-2 mb-md-4 d-flex  flex-wrap"
              key={total_mentor}
            >
            <div className="mentro_details_sidebar" style={{width:'100%'}}>
              <div className="row py-2 py-md-12">
                <Col xl={12}>
                  <div className="d-flex ">
                    <div style={{width:'100%'}}>
                      { (checkDiagnosticToolkit.is_save===0 && checkDiagnosticToolkit.is_save_share===0 && checkDiagnosticToolkit.is_update===0) ? ( 
                          <div className="text-center">Detailed diagnostic tool will be available once you are assigned a mentor</div>
                      ) : (
                        <Fragment> 
                        {objQuestions &&
                        objQuestions.map((item, key) => (
                          <Fragment>
                          
                            {/* Open Starting 2 accordin */}
                            { (key === 0 || key === 1) && (
                            <Accordion defaultActiveKey={key+1} className="myaccourdin">
                              
                              <Card key={key + 1}>
                                <Card.Header>
                                  <Accordion.Toggle
                                    as={Button}
                                    className={activeId !== key + 1 && "faq_open"}
                                    variant="link"
                                    eventKey={key + 1}
                                    onClick={() => setActiveId(key + 1)}
                                  >
                                  {item.ques}
                                  </Accordion.Toggle>
                                </Card.Header>

                                <Accordion.Collapse eventKey={key + 1}>
                                  <Card.Body>
                                    <div><i><sup>{item.ques_desc}</sup></i></div>
                                    <Form.Control
                                      as="textarea"
                                      className={
                                      errors.answer && errors.answer[key] ? "form-control is-invalid " : "form-control"
                                      }
                                      style={{width:'100%'}}
                                      name={`answer.${key}`}
                                      id={`answer.${key}`}
                                      aria-invalid={errors.answer && errors.answer[key] ? "true" : "false"}
                                      defaultValue={item.answer}
                                      ref={register({
                                      maxLength: 600,
                                      })}
                                    />
                                    {errors.answer && errors.answer[key] && errors.answer[key].type === "required" && (
                                      
                                        <label className="customeValidationText text-left">
                                        Please enter answer
                                        </label>
                                    )}
                                    {errors.answer && errors.answer[key] && errors.answer[key].type === "maxLength" && (
                                      <label className="customeValidationText text-left">
                                        Answer should not more than 600 characters
                                      </label>
                                    )}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>

                            </Accordion>
                            ) }

                            {/* Close Other Starting 2 accordin */}
                            { (key !== 0 && key !== 1) && (
                              <Accordion className="myaccourdin">
                                
                                <Card key={key + 1}>
                                  <Card.Header>
                                    <Accordion.Toggle
                                      as={Button}
                                      className={activeId !== key + 1 && "faq_open"}
                                      variant="link"
                                      eventKey={key + 1}
                                      onClick={() => setActiveId(key + 1)}
                                    >
                                    {item.ques}
                                    </Accordion.Toggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey={key + 1}>
                                    <Card.Body>
                                      <div><i><sup>{item.ques_desc}</sup></i></div>
                                      <Form.Control
                                        as="textarea"
                                        className={
                                        errors.answer && errors.answer[key] ? "form-control is-invalid " : "form-control"
                                        }
                                        style={{width:'100%'}}
                                        name={`answer.${key}`}
                                        id={`answer.${key}`}
                                        aria-invalid={errors.answer && errors.answer[key] ? "true" : "false"}
                                        defaultValue={item.answer}
                                        ref={register({
                                        maxLength: 600,
                                        })}
                                      />
                                      {errors.answer && errors.answer[key] && errors.answer[key].type === "required" && (
                                        
                                        <label className="customeValidationText text-left">
                                        Please enter answer
                                        </label>

                                      )}
                                      {errors.answer && errors.answer[key] && errors.answer[key].type === "maxLength" && (
                                        <label className="customeValidationText text-left">
                                          Answer should not more than 600 characters
                                        </label>
                                      )}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            ) }

                          </Fragment>
                        ))}

                        <div className="mt-3 mt-md-3 mt-lg-4" align="center">
                        { checkDiagnosticToolkit.is_save===1 && (
                          <Button
                              type="submit"
                              className="btn loginsubmit ml-2"
                              onClick={() => (submit_state.button = 1)}
                              disabled={getLoader}
                          >
                          { getLoader ? 'Saving...' : 'SAVE' }
                          
                          </Button>
                          )}
                        { checkDiagnosticToolkit.is_save_share===1 && (
                          <Button
                              type="submit"
                              className="btn loginsubmit outline mr-2 ml-2"
                              onClick={() => (submit_state.button = 2)}
                              disabled={getShareLoader}
                          >
                            
                          { getShareLoader ? 'Sharing...' : "SHARE WITH YOUR MENTOR" }
                          </Button>
                        )}
                        </div>

                        { checkDiagnosticToolkit.is_update===1 && (
                        <div className="mt-3 mt-md-3 mt-lg-4" align="center">
                          {/* <Button
                              type="submit"
                              className="btn loginsubmit ml-2"
                              onClick={() => (submit_state.button = 1)}
                              disabled={getLoader}
                          >
                          { getLoader ? 'Updating...' : 'Update' }
                          
                          </Button> */}
                          <Button
                              type="submit"
                              className="btn loginsubmit outline mr-2 ml-2"
                              onClick={() => (submit_state.button = 3)}
                              disabled={getShareLoader}
                          >
                          
                          { getShareLoader ? 'Updating...' : "UPDATE" }
                          </Button>
                        </div>
                        )}
                        </Fragment>
                      )}

                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
          </div>
        </Container>
      </Form>
      
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    menteeRequestData: state.menteeRequestData,
    userInfo: state.userInfo,
    isAuth: state.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(menteesRequestAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDiagnostics);
