import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../../Component/Button";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../actions/userActions";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import { useScrollTo } from "react-scroll-to-bottom";
import { useState } from "react";

const Index = (props) => {
  const history = useHistory();
  // const checkAuthToken = localStorage.getItem("userToken");
  const { title, superUserParams, userInfo, actions } = props;
  
  if (userInfo.id) {
    history.push(`/dashboard`);
  }else{
    window.addEventListener('popstate', function(event) {
      history.push("/")
      //window.history.go(-2)
    });
    
  }

  const { register, reset, errors, handleSubmit } = useForm({
    defaultValues: superUserParams,
  });
  const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe'))


  useEffect(() => {
    document.title = 'vLookUp - Login to Your Account';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Login to your vLookUp account to access one-to-one, domain-specific career mentoring between aspiring women leaders and experienced industry leaders.');
    window.scrollTo(0, 0);
  }, [title]);

  const isToken = localStorage.getItem("deviceToken");
  const onSubmit = async (data) => {
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("userId");
    let params = data;
    params.device_type = "web";
    params.device_token = isToken;
    params.role_id = ["Mentor", "Mentees"];
    actions.userLoginData(params);
    if (rememberMe) {
      localStorage.setItem('email', data?.email)
      localStorage.setItem('password', data?.password)
    }
    localStorage.setItem('rememberMe', rememberMe)
  };

  useEffect(() => {
    if (rememberMe == 'true') {
      reset({
        email: localStorage.getItem('email'),
        password: localStorage.getItem('password')
      })
    } else {
      localStorage.removeItem('email')
      localStorage.removeItem('password')
    }

  }, [])

  const handleCheckboxChange = (e) => {
    if (e.target.checked === true) {
      setRememberMe('true')
    }
    else {
      setRememberMe('false')
    }
  }



  return (
    <>
      <section className="loginouter">
        <Container>
          <Row className="align-items-center ">
            <Col md={12}>
              <div className="logininnerouter">
                <div>
                  <div className="loginlogo text-center mb-5">
                    <Link to="/" className="logo m-0">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_IMAGE_PATH + "logo.svg"
                        }
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="loginform">
                    <h1>Login</h1>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={12} sm={12}>
                          <Form.Group controlId="formBasicEmail">
                            <FormControl
                              type="email"
                              placeholder="Email Address"
                              className={errors.email ? "formError" : ""}
                              name="email"
                              aria-invalid={errors.email ? "true" : "false"}
                              ref={register({
                                required: true,
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                },
                                maxLength: 50,
                              })}
                            />
                            {errors.email &&
                              errors.email.type === "required" && (
                                <span role="alert">
                                  Please enter your email address
                                </span>
                              )}
                            {errors.email &&
                              errors.email.type === "pattern" && (
                                <span role="alert">Invalid email address</span>
                              )}
                            {errors.email &&
                              errors.email.type === "maxLength" && (
                                <span role="alert">
                                  Your email should contain max 50 characters
                                </span>
                              )}
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <Form.Group controlId="formBasicPassword">
                            <FormControl
                              type="password"
                              className={errors.password ? "formError" : ""}
                              placeholder="Password"
                              name="password"
                              aria-invalid={errors.password ? "true" : "false"}
                              ref={register({
                                required: true,

                              })}
                            />
                            {errors.password &&
                              errors.password.type === "required" && (
                                <span role="alert">
                                  Please enter your password
                                </span>
                              )}

                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Check
                              type="checkbox"
                              label="Remember me"
                              name="remember_me"
                              checked={rememberMe == 'true' ? true : false}
                              onChange={(e) => handleCheckboxChange(e)}
                              ref={register}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6} className="text-right">
                          <Form.Group>
                            <Link to="/forgotPassword" className="text-danger">
                              Forgot Password
                            </Link>
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <div className="form-group mt-3">
                            <Button
                              title={"Login"}
                              className={"btn w-100 loginsubmit"}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="text-center">
                          <Form.Group>
                            Create a new account?&nbsp;
                            <Link to="/register" className="text-danger">
                            Sign Up
                            </Link>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    superUserParams: state.superUserParams,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
