import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ResourcesHubActions from "../../actions/ResourcesHubActions";
import placeholder from "../../assets/images/resourceshub-1.png";
import { showToasterWithOk } from "../../utils/helpers";
import { useLocation, useHistory, Link } from "react-router-dom";
import DataLoader from "../../Component/Loader/DataLoader";
import Subscribe from "../../Component/Subscribe";
import SubscribeResource from "../../Component/Subscribe/SubscribeResource";

const queryString = require("query-string");

function Index(props) {
  const { actions,isAuth,ResourcesHubData, userInfo} = props; 
  const params = props.match.params.slug; 
  const [title, setTitle] = useState('');
  const history = useHistory();
  const location = useLocation();
  const PostResourceHub = async (e) => {
    e.preventDefault();
    if (isAuth === false) {
      showToasterWithOk(
        "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
      );
      history.push(`/login`);
    }else{
      if(localStorage.isConfirmed == "true"){
        window.location = "/post-resource-hub" ;
      } else{
        showToasterWithOk("Please Complete Your Profile First.");
        return
      }

    }

    
  };
  

  useEffect(() => {
    // const params = new URLSearchParams(location.search);
    // const queryStringParsed = queryString.parse(location.search);
    const request = {};
    if (params) {
      request.category = params;
    }else{
      request.category = 'all-posts';

    }
    

    actions.loadResourcesHubData(request);

  }, [actions,params]);



  function setClass(key) 
  {
    var class1= 'pl-lg-0 col-lg-4';
    var class2='col-lg-8';
    
      if(key%4==0 || key%4==3){
        return class2;
      }
      else if(key%4 == 1 || key%4==2 ){
        return class1;
      }  
  }

  const resoucesPages = async (e, name, slug) => {
    e.preventDefault();
    // setTitle(name);
    window.location = "/resource-hub/"+ slug
  };

  const redirectNewsletter = async (e) => {
    e.preventDefault();
    // setTitle('Newsletter');
    window.location = "/newsletter"
  };

  const redirectVideos = async (e) => {
    e.preventDefault();
    // setTitle('Videos');
    window.location = "/videos"
  };

  const ResourceHubDetail = async (e, resource_id, slug) => {
    e.preventDefault();
    window.location = "/resources-details/" + slug
  };

  const resoucesDrop=[{
    'name':'All posts',
    'slug':'all-posts',
    'value':'0',
    'class':'fas fa-mail-bulk',
    'key':'0'
  },
  {
    'name':'People stories',
    'slug':'people-stories',
    'value':'1',
    'class':'fas fa-user-clock',
    'key':'1'
  },
  {
    'name':'Points of view',
    'slug':'points-of-view',
    'value':'2',
    'class':'fas fa-street-view',
    'key':'2'
  },
  {
    'name':'Toolkits',
    'slug':'toolkits',
    'value':'3',
    'class':'fas fa-toolbox',
    'key':'3'
  }
  ];
  useEffect(() => {
    //window.scrollTo(0, 0);
    const resource_slug = props?.match.params?.slug;
    const resoucre_data= resoucesDrop.find((flower)=>flower?.slug === resource_slug);

    if(resource_slug!=='all-posts'){
      setTitle(resoucre_data?.name);
    }
   
    document.title = 'vLookUp | '+resoucre_data?.name;
    document.querySelector('meta[name="description"]').setAttribute('content', resoucre_data?.name);

    
    });
  return (
    <div>
      <section className="outer_main">
        <Container className="page_heading text-center">
          <h1 style={{fontSize:'24px', fontWeight:'600'}}>Resources { title ? ' (' + title + ')' : '' }</h1>
          <div className="d-flex mt-4 justify-content-center align-items-center">
          <span className="btn btn_login " onClick={(e) => {
            PostResourceHub(e);
          }}>
            Submit your write up
          </span>
              <SubscribeResource />
            </div>
        </Container>
 
        <div className="page_content">
          <Container>   

            <div className="linkpart">
              <ul>

                {resoucesDrop &&
                resoucesDrop.length &&
                resoucesDrop.map((item, key) => (
                  <li key={key} className={ (item.slug===params) ? 'linkpart-active' : '' }><span onClick={(e) => {
                    resoucesPages(e, item.name, item.slug);
                    }}>
                      <i className={item.class}></i>
                      <h6>{item.name}</h6></span>
                  </li>
                
                ))}

                <li>
                  <span onClick={(e) => {
                  redirectNewsletter(e);
                  }}>
                    <i className="fas fa-envelope-open-text"></i>
                    <h6>Newsletter</h6>
                  </span>
                </li>

                <li>
                  <span onClick={(e) => {
                    redirectVideos(e);
                    }}>
                    <i className="fas fa-video"></i><h6>Videos</h6>
                  </span>
                </li>
                
              </ul>                   
            </div>

            <div className="page_content_inner removecol_condition p-0">
              <Row>

                { ResourcesHubData.length>0 &&
                ResourcesHubData?.map((item, key) => (
                  <Col className={setClass(key)} style={{'padding-left':'0'}}>
                    <div className="resourcestab">
                      <a href={"/resources-details/"+item.slug}>
                        <div
                          className="resourcestabinnercontent"
                          style={{
                            backgroundImage: 'url('+item.image+')',
                          }}
                        >
                          <div>
                            <h3>{item.short_title}</h3>
                            <div className="d-flex align-items-center">
                              <div className="resourecimg">
                                <img
                                  src={item.user_image}
                                  className="img-fluid"
                                  alt="business career mentor program"
                                />
                              </div>
                              <div className="resourcecontent">
                                <p className="m-0">
                                  {item.user_name}
                                  <small className="d-block mt-1">
                                    {item.date}
                                  </small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </Col>

                ))}
                
                {ResourcesHubData?.length === 0 && (
                  <div className="text-center">No data found.</div>
                )}
                {ResourcesHubData===true ? <DataLoader /> : null
                }

              </Row>
            </div>

          </Container>
        </div>
        
      </section>
    </div>
  );
}


function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    ResourcesHubData: state.ResourcesHubData,
    isAuth: state.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(ResourcesHubActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);

