import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingActions from "../../actions/settingActions";
import * as homePageActions from "../../actions/homePageActions";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import * as userActions from "../../actions/userActions";

document.getElementById("OnscrollBody").onscroll = function () {
  var element = document.getElementById("navbar_scroll_fixed");
  var scroll = window.scrollY;
  if (scroll <= 20 && element) {
    element.classList.remove("fixed-top");
  } else if (element) {
    element.classList.add("fixed-top");
  }
};

const Index = (props) => {
  const { actions, community, isAuth } = props;

  const location = useLocation();
  const userInfo = useSelector((state) => state.userInfo);
  let history = useHistory();
  const checkAuthToken = localStorage.getItem("userToken");
  //console.log(userInfo);
  // const checkAuthToken = () => {
  //   if (localStorage.getItem("authToken")) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  // console.log("userInfo",userInfo)
  function handleLogOut() {
    localStorage.setItem("authToken", "");
    localStorage.clear();
    let params = {};
    actions.userLogout(params);
    history.push("/login"); // whichever component you want it to route to
  }
  const [communityDetail, setCommunityDetail] = useState([]);

  const joinGroupDetail = async (e, community_id, community_slug) => {
    e.preventDefault();
    window.location = "/community-details/" + community_slug;
  };
  // const resoucesPages = async (e, name, value) => {
  //   e.preventDefault();
  //   window.location = "/resource-hub?category="+ value
  // };

  useEffect(() => {
    const fetchData = () => {
      actions.loadCommunityData(20);
    };
    fetchData();
  }, [actions]);

  let headerPrevent = ["/login", "/forgotPassword", "/register", "/ignite-women-mentoring", "/mentee-signup-step-1", "/mentee-signup-step-2", "/mentee-signup-step-3", "/reset-password"]

  
  return (<>
    {['/partnerships']?.includes(location.pathname)&&['?is_mobile=yes'].includes(location.search)?null:
    <header id="navbar_scroll_fixed" className="navigation">
     
      {!headerPrevent?.includes(location.pathname) && (
          <Navbar expand="lg">
            <Container>
              <Link to="/" className="logo m-0">
                <img
                  src={process.env.REACT_APP_STATIC_IMAGE_PATH + "logo.svg"}
                  alt="career mentoring services"
                />
              </Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-md-auto align-items-lg-center d-flex">
                  <NavLink
                    className="nav-link"
                    to="/mentors"
                    // title="Home"
                    exact
                    activeClassName="active"
                  >
                    Mentors
                  </NavLink>

                  {/* <NavLink
                    className="nav-link"
                    to="#!"
                    exact
                    activeClassName=""
                  >
                    Communities
                  </NavLink> */}

                  <NavDropdown
                    title="Communities"
                    id="basic-nav-dropdown"
                    className="nav-link"
                  >
                    {community &&
                      community.length &&
                      community.map((item, key) => (
                        <NavDropdown.Item
                          href={"/community-details/" + item.slug}
                          // onClick={(e) => {
                          //   joinGroupDetail(e, item.id, item.slug);
                          // }}
                        >
                          {item.group_name}
                        </NavDropdown.Item>
                      ))}
                  </NavDropdown>

                  <NavLink
                    className="nav-link"
                    to="/resource-hub/all-posts"
                    exact
                    activeClassName="active"
                  >
                    Resources
                  </NavLink>

                  {/* { (userInfo.id===191 || userInfo.id===137 || userInfo.id===236) && ( */}
                  <a
                    className="nav-link"
                    href="/partnerships"
                    exact
                    activeClassName="active"
                  >
                    Partnerships
                  </a>
                  <a
                    className="nav-link"
                    href="/event/upcoming"
                    exact
                    activeClassName="active"
                  >
                    Events
                  </a>
                  {/* ) } */}

                  <NavLink
                    className="nav-link"
                    to="/aboutus"
                    exact
                    activeClassName="active"
                  >
                    About Us
                  </NavLink>
                </Nav>

                <div className="header_login d-flex ">
                  {!userInfo.id && (
                    <Link to="/login" className="btn btn_login mr-2">
                      Login
                    </Link>
                  )}

                  {userInfo.id && (
                    <>
                      <Link to="/manage-profile" className="btn btn_join mr-2">
                        My Dashboard
                      </Link>
                      <Link
                        to="/forum"
                        className="btn btn_join mr-2"
                        style={{ minWidth: "auto" }}
                      >
                        Forum
                      </Link>
                    </>
                  )}
                  {/* {userInfo.id && (
                    <button
                      type="button"
                      onClick={handleLogOut}
                      className={"btn btn_login mr-2"}
                    >
                      Logout
                    </button>
                  )} */}
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        )}
    </header>
  }
    </>
  );
};
function mapStateToProps(state) {
  return {
    setting: state.setting,
    community: state.community,
    isAuth: state.isAuth,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userActions, settingActions, homePageActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
