export const SUBMITTING_REQUEST_STATUS = "SUBMITTING_REQUEST_STATUS";
export const LOADED_USER_AUTH_SUCCESS = "LOADED_USER_AUTH_SUCCESS";
export const LOADED_USER_PARAM_SUCCESS = "LOADED_USER_PARAM_SUCCESS";
export const LOADED_USER_INFO_SUCCESS = "LOADED_USER_INFO_SUCCESS";
export const LOADED_USER_FORGOT_SUCCESS = "LOADED_USER_FORGOT_SUCCESS";
export const LOADED_CUSTOMER_DATA_SUCCESS = "LOADED_CUSTOMER_DATA_SUCCESS";
export const LOADED_DATA_SUCCESS = "LOADED_DATA_SUCCESS";
export const LOADED_SETTING_INFO_SUCCESS = "LOADED_SETTING_INFO_SUCCESS";
export const LOADED_BRAND_DATA_SUCCESS = "LOADED_BRAND_DATA_SUCCESS";
export const LOAD_FAQ_DATA_SUCCESS = "LOAD_FAQ_DATA_SUCCESS";
export const LOAD_HOME_PAGE_DATA_SUCCESS = "LOAD_HOME_PAGE_DATA_SUCCESS";
export const NETWORK_REQUEST_STATUS = "NETWORK_REQUEST_STATUS";
export const LOAD_LEADER_DATA_SUCCESS = "LOAD_LEADER_DATA_SUCCESS";
export const LOAD_TESTIMONIALS_DATA_SUCCESS = "LOAD_TESTIMONIALS_DATA_SUCCESS";

export const LOAD_COMMUNITY_DATA_SUCCESS = "LOAD_COMMUNITY_DATA_SUCCESS";

export const SAVE_RESOURCES_DATA_SUCCESS = "SAVE_RESOURCES_DATA_SUCCESS";
export const LOAD_RESOURCES_HUB_DATA_SUCCESS =
  "LOAD_RESOURCES_HUB_DATA_SUCCESS";

export const LOAD_FILTER_DATA_SUCCESS = "LOAD_FILTER_DATA_SUCCESS";
export const LOADED_USER_REGISTER_SUCCESS = "LOADED_USER_REGISTER_SUCCESS";
export const LOADED_UPDATE_PROFILE_SUCCESS = "LOADED_UPDATE_PROFILE_SUCCESS";
export const LOADED_EXPERIENCE_DATA_SUCCESS = "LOADED_EXPERIENCE_DATA_SUCCESS";
export const LOAD_MENTORS_DATA_SUCCESS = "LOAD_MENTORS_DATA_SUCCESS";
export const LOAD_FORM_POP = "LOAD_FORM_POP";
export const LOAD_PAGINATION_DATA_SUCCESS = "LOAD_PAGINATION_DATA_SUCCESS";
export const LOAD_REGISTER_DATA_SUCCESS = "LOAD_REGISTER_DATA_SUCCESS";
export const LOADED_MENTEE_REQUEST_DATA = "LOADED_MENTEE_REQUEST_DATA";
export const CERTIFICATE_DATA = "CERTIFICATE_DATA";
export const LOAD_CATEGORY_DATA_SUCCESS = "LOAD_CATEGORY_DATA_SUCCESS";
export const LOAD_FORUM_DATA_SUCCESS = "LOAD_FORUM_DATA_SUCCESS";
export const LOAD_FORUM_USER_DATA_SUCCESS = "LOAD_FORUM_USER_DATA_SUCCESS";
export const LOAD_REPORT_CATEGORY_DATA_SUCCESS =
  "LOAD_REPORT_CATEGORY_DATA_SUCCESS";
export const LOAD_REPORT_CATEGORY_DATA_USER_SUCCESS =
  "LOAD_REPORT_CATEGORY_DATA_USER_SUCCESS";
export const LOAD_TEAMS_DATA_SUCCESS = "LOAD_TEAMS_DATA_SUCCESS";
export const LOAD_ADVISORS_DATA_SUCCESS = "LOAD_ADVISORS_DATA_SUCCESS";

// Event
export const LOAD_EVENTS_DATA_SUCCESS = "LOAD_EVENTS_DATA_SUCCESS";

// Newsletter & Videos
export const LOAD_NEWSLETTER_DATA_SUCCESS = "LOAD_NEWSLETTER_DATA_SUCCESS";
export const LOAD_VIDEO_DATA_SUCCESS = "LOAD_VIDEO_DATA_SUCCESS";
export const LOAD_PROGRAMMES_DATA_SUCCESS = "LOAD_PROGRAMMES_DATA_SUCCESS";


export const SCHEDULE_LISTING_DATA = "SCHEDULE_LISTING_DATA";
export const SCHEDULE_CALENDAR_POPUP = "SCHEDULE_CALENDAR_POPUP";
