import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ResourcesHubActions from "../../actions/ResourcesHubActions";
import ViewModal from "./viewModal";
import DataLoader from "../../Component/Loader/DataLoader";

function Index(props) {
  const { actions,isAuth,NewsletterData} = props; 
  const [getViewModal, setViewModal]=useState(false);
  const [getViewData, setViewData]=useState({});

  useEffect(() => {

    actions.loadNewsletterData();

  }, [actions]);


  const resoucesPages = async (e, name, slug) => {
    e.preventDefault();
    window.location = "/resource-hub/"+ slug
  };
  
  const redirectNewsletter = async (e) => {
    e.preventDefault();
    window.location = "/newsletter"
  };

  const redirectVideos = async (e) => {
    e.preventDefault();
    window.location = "/videos"
  };

  const resoucesDrop=[{
    'name':'All posts',
    'slug':'all-posts',
    'value':'0',
    'class':'fas fa-mail-bulk',
    'key':'0'
  },
  {
    'name':'People stories',
    'slug':'people-stories',
    'value':'1',
    'class':'fas fa-user-clock',
    'key':'1'
  },
  {
    'name':'Points of view',
    'slug':'points-of-view',
    'value':'2',
    'class':'fas fa-street-view',
    'key':'2'
  },
  {
    'name':'Toolkits',
    'slug':'toolkits',
    'value':'3',
    'class':'fas fa-toolbox',
    'key':'3'
  }
  ];
  useEffect(() => {
    
    document.title = 'vLookUp | Newsletter';
    
  });

  const onViewModal = (e, description, title) => {
    e.preventDefault();
    setViewModal(true);
    setViewData({
      type: 'newsletter',
      title: title + ' Newsletter' ,
      item: description
    })
  };

  return (
    <div>
      <section className="outer_main">
        
        <Container className="page_heading text-center">
          <h1>Resources (Newsletter)</h1>
        </Container>
 
        <div className="page_content">
          <Container>  
                      
            <div className="linkpart">
              <ul>
                {resoucesDrop &&
                resoucesDrop.length &&
                resoucesDrop.map((item, key) => (

                <li key={key}><span onClick={(e) => {
                    resoucesPages(e, item.name, item.slug);
                  }}>
                    <i className={item.class}></i><h6>{item.name}</h6>
                  </span>
                </li>
          
                ))}

                <li key='newsletter' className="linkpart-active">
                  <span onClick={(e) => {
                    redirectNewsletter(e);
                  }}>
                    <i className="fas fa-envelope-open-text"></i>
                    <h6>Newsletter</h6>
                  </span>
                </li>

                <li key='videos'>
                  <span onClick={(e) => {
                    redirectVideos(e);
                  }}>
                    <i className="fas fa-video"></i>
                    <h6>Videos</h6>
                  </span>
                </li>
                
              </ul>                   
            </div>

            <div className="page_content_inner removecol_condition p-0">
              { NewsletterData &&
              NewsletterData.length>0 && (
                <Row>
                { NewsletterData.map( (item, key) => 
                (
                  <Col md={4} sm={6} className="news-listing" key={key}>
                    <div className="newslleter-cols">
                      <figure>
                        <img src={item.image} alt={item.title} onClick={(e) => {
                          onViewModal(e, item.description, item.publish_date_formatted);
                        }} className="cursor-pointer" />

                        <figcaption>
                          {/* <h4 className="cursor-pointer" onClick={(e) => {
                            onViewModal(e, item.description, item.title);
                          }}>{item.title}</h4> */}
                          { item.publish_date_formatted && (<span className="post-date">Edition: {item.publish_date_formatted}</span>) }
                        </figcaption>
                      </figure>
                    
                    </div>
                  </Col>
                ) ) }
                </Row>
              )}
              
              {NewsletterData.length === 0 && (
                <div className="text-center" align="center">No data found.</div>
              )}

             {NewsletterData===true ? <DataLoader /> : null}
            </div>

          </Container>
        </div>

      </section>
      {
        getViewModal && (
          <ViewModal
          show={getViewModal}
          viewdata={getViewData}
          onHide={() => setViewModal(false)}
          centered
          size="xl"
          />
        )
      }
    </div>
  );
}


function mapStateToProps(state) {
  return {
    NewsletterData: state.NewsletterData,
    isAuth: state.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(ResourcesHubActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);

