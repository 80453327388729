import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingActions from "../../actions/settingActions";
import * as homePageActions from "../../actions/homePageActions";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import Iframe from "react-iframe";
import { useForm } from "react-hook-form";
import Button from "../../Component/Button";
import Slider from "react-slick";

const Index = (props) => {
  const { actions, brand, setting, title } = props;
  const { register, errors, handleSubmit } = useForm();

  useEffect(() => {
    const fetchData = () => {
      actions.getSettingData();
      actions.getBrandData();
      window.scrollTo(0, 0);
      document.title = "vLookUp - Contact Us";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Would like to be a mentor or mentee with us? Queries? Concerns?  Get in touch with us and we will be happy to assist you with your inquiries."
        );
    };
    fetchData();
  }, [actions, title]);

  const onSubmit = (data) => {
    actions.sendContactUs(data);
  };

  const patner_slider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],

  };

  var patner_slider2 = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <section className="community_section partner-community">
        <div className="content_inner">
          <img
            src="./assets/images/partner-banner.jpg"
            alt="women leadership mentoring"
            className="img-fluid back_img"
          />
          <img
            src="./assets/images/cm_back_shape.png"
            className="img-fluid shape_img w-100"
            alt="find a career mentor online"
          />
        </div>
      </section>

      <section className="community_page_inner">
        <Container>
          <Row className="partnership-page-inner">
            <Col md={12} className="mb-3">
              <div>
                <h1 className="inner-partner">
                  Enabling relevant mentoring connections
                </h1>
                <p className="text-dark">
                  <strong>vLookUp offers a proprietary AI-driven, digitally integrated and customizable platform and product for organizations, educational institutions, and professional bodies to implement an integrated, real-time, user-driven, scalable and global mentoring program. Our B2C platform and apps support aspiring women leaders (mentees) to get access to mentors beyond barriers of organizations and locations. We help organizations create bespoke mentoring solutions to complement their diversity and inclusion (D&I) initiatives and to solve specific challenges.</strong>
                </p>
              </div>
            </Col>
          </Row>
          <div className="we-offer">
            <h3>We offer:</h3>
            <Row>
              <Col md={6}>
                <div className="we-offer-point">
                  <ul>
                    <li>
                      An integrated and customizable platform and app to implement and scale up a unique, real-time and user driven global mentoring program.</li>
                    <li>
                      A proprietary ‘Mentor request’ workflow and an end-to-end integrated mentoring process supported by user engagement and communications.
                    </li>
                    <li>
                      AI-driven matching algorithm to support relevant and quality mentor-mentee matching.
                    </li>
                    <li>
                      User driven mentoring process with self-assessment, feedback, and continuity of connection between mentors and mentees.
                    </li>

                  </ul>
                </div>
              </Col>

              <Col md={6}>
                <div className="we-offer-point">
                  <ul>
                    <li> Organizations to create bespoke mentoring solutions for diversity, talent & career growth, recruitment, bringing women back to work, and more.
                    </li>
                    <li>Access to a cross-organizational and global community of passionate mentors and role models, who believe in helping women grow and lead. </li>
                    <li>Opportunity to join our growing pool of global leaders as mentors.</li>
                    <li>Mentor onboarding and development frameworks with masterclasses, resources, tips, tools.</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>

          <div className="connect_with_us">
            <figure>
              <img src="/assets/images/connecting_call.png" />
            </figure>
            <figcaption>
              We would love to have a chat with you to help you meet your objectives. <a href="mailto:kanchana@vlookup.ai">Connect with us</a>
            </figcaption>
          </div>
        </Container>
      </section>

      {/* smriti */}
      <section className="we_offer partner_ship">
        <Container>
          <h2 class="title text-center position-relative">
            How can you benefit?
          </h2>
          <div className="offer_main">
            <div className="d-flex position-relative offer_1 pt-0 benifits_list">
              <div className="offer_img  position-relative">
                <figure className="position-relative">
                  <img src="/assets/images/saas_based.png" />
                  <span className="top_dotted">
                    <img src="/assets/images/dotted.png" />
                  </span>
                  <span className="bottom_dotted">
                    <img src="/assets/images/dotted.png" />
                  </span>
                </figure>
              </div>

              <div class="offer_content">
                <h3 className="mb-4">
                  B2B platform to create and scale up your unique global mentoring program.
                </h3>
                <p>
                  ESTABLISH, AUTOMATE, SCALE-UP a global, user-driven, custom mentoring program – internally as well as externally. Our customizable front-end, user interface and workflow solutions can be tailored to support your unique goals.
                </p>
                <p>
                  <span className="text-dark">Partner story : </span>P&G's Olay has partnered with vLookUp since 2022 to implement and scale their STEM mentoring program in India. vLookUp is the selected technology and program partner, supporting Olay's unique and ambitious mentoring efforts to grow Indian women talent in STEM fields.
                </p>
                <p>
                  Dell Technologies partnered with vLookUp in 2022 for their initiative of mentoring women at Polytechnics in Singapore.
                </p>
              </div>
            </div>

            <div className="d-flex position-relative offer_1 pt-0 benifits_list">
              <div className="offer_img  position-relative">
                <figure className="position-relative">
                  <img src="/assets/images/baspoke.png" />
                  <span className="top_dotted">
                    <img src="/assets/images/dotted.png" />
                  </span>
                  <span className="bottom_dotted">
                    <img src="/assets/images/dotted.png" />
                  </span>
                </figure>
              </div>

              <div class="offer_content">
                <h3 className="mb-4">
                  Bespoke mentoring program, process and platform to support your D&I interventions
                </h3>
                <p>
                  Partner with us to create a customized mentoring programme to support career development of your women leaders and to supplement your D&I interventions with career-based mentoring.
                </p>
                <p>
                  <span className="text-dark">Partner story : </span>We collaborated with Unioleo, Indonesia, to create the unique ‘Oleo Femme Mentorship Program’. Their high potential and aspiring women leaders were matched with a group of global vLookUp mentors for a mentoring engagement that supported their growth and career development.
                </p>
              </div>
            </div>

            <div className="d-flex position-relative offer_1 pt-0 benifits_list">
              <div className="offer_img  position-relative">
                <figure className="position-relative">
                  <img src="/assets/images/accesssthre.png" />
                  <span className="top_dotted">
                    <img src="/assets/images/dotted.png" />
                  </span>
                  <span className="bottom_dotted">
                    <img src="/assets/images/dotted.png" />
                  </span>
                </figure>
              </div>

              <div class="offer_content">
                <h3 className="mb-4">
                  Access to a cross-organizational and global community of passionate mentors
                </h3>
                <p>
                  We provide access to a diverse global community of mentors and role models from across organizations. Our unique workflow empowers women who are in the workforce, or planning to enter it, to select from our pool of amazing mentors and stay connected.
                </p>
                <p>
                  <span className="text-dark">Partner story : </span>Nanyang and Republic Polytechnics in Singapore partnered with us to offer their students and graduates access to industry mentors and role models to help them enter the workforce with confidence and the right set of skills.
                </p>
              </div>
            </div>

            <div className="d-flex position-relative offer_1 pt-0 benifits_list">
              <div className="offer_img  position-relative">
                <figure className="position-relative">
                  <img src="/assets/images/opportunity.png" />
                  <span className="top_dotted">
                    <img src="/assets/images/dotted.png" />
                  </span>
                  <span className="bottom_dotted">
                    <img src="/assets/images/dotted.png" />
                  </span>
                </figure>
              </div>

              <div class="offer_content">
                <h3 className="mb-4">
                  Opportunity to join a global pool of leaders as mentors and learn skills
                </h3>
                <p>
                  Encourage your leaders to sign up as mentors on our platform and be part of someone’s success story! Empower them to benefit from our custom frameworks of mentor onboarding and development.
                </p>
                <p>
                  <span className="text-dark">Partner story : </span>As part of the Olay STEM mentoring programs, we facilitate end-to-end mentor onboarding and development frameworks with interventions, masterclasses, resources, tips, tools.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="programs_are_driven">
        <Container>
          <Row>
            <Col md={7}>
              <figure className="programs_are_driven_pic">
                <img src="/assets/images/driven.png" />
              </figure>
            </Col>
            <Col md={5}>
              <figcaption className="programs_are_driven_content">
                <h2 className="title pb-3 pb-md-4 position-relative">
                  What drives our solutions :
                </h2>

                <div className="programs_are_driven_point">
                  <ul>
                    <li>
                      <a href="/faq">
                        <span>
                          <img src="/assets/images/drivenicon1.png" />
                        </span>
                        Al driven relevant mentor-mentee matching.
                      </a>
                    </li>

                    <li>
                      <a href="/faq">
                        <span>
                          <img src="/assets/images/drivenicon2.png" />
                        </span>
                        User driven process and communications
                      </a>
                    </li>

                    <li>
                      <a href="/faq">
                        <span>
                          <img src="/assets/images/drivenicon3.png" />
                        </span>
                        Custom user interface and experience
                      </a>
                    </li>

                    <li>
                      <a href="/mentors">
                        <span>
                          <img src="/assets/images/drivenicon4.png" />
                        </span>
                        A strong network of global mentors
                      </a>
                    </li>
                    <li>
                      <a href="/faq">
                        <span>
                          <img src="/assets/images/flexible2.png" />
                        </span>
                        A flexible and scalable process
                      </a>
                    </li>
                    <li>
                      <a href="/faq">
                        <span>
                          <img src="/assets/images/Continued2.png" />
                        </span>
                        Continued connections post mentoring
                      </a>
                    </li>
                  </ul>
                </div>
              </figcaption>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="brand_sec partner_brand">
        <Container>
        <div className="title text-center space-bottom secondaryTitle pb-3 pb-md-5 pr-0">
            Partnering Organisations and Institutions
          </div>

          <Slider {...patner_slider2}>
            {brand &&
              brand.length &&
              brand.map((item, key) => (
                <div key={key}>
                  <div className="brand">
                    <img src={item.image} alt="professional consultants for women" />
                  </div>
                </div>
              ))}
          </Slider>

          {/* <Slider {...patner_slider2}>
            
                <div>
                  <div className="brand">
                    <img src="../assets/images/partners/partner_olay.png" alt="OLAY" />
                  </div>
                </div>
                <div>
                  <div className="brand">
                    <img src="../assets/images/partners/partner_lti.png" alt="LTI Mindtree" />
                  </div>
                </div>          <div>
                  <div className="brand">
                    <img src="../assets/images/partners/partner_dell.png" alt="Dell Technologies" />
                  </div>
                </div>          <div>
                  <div className="brand">
                    <img src="../assets/images/partners/women_in_action.png" alt="Nanyang Technological University" />
                  </div>
                </div>
                <div>
                  <div className="brand">
                    <img src="../assets/images/partners/republic_pol.png" alt="NCS" />
                  </div>
                </div> 
                <div>
                  <div className="brand">
                    <img src="../assets/images/partners/partner_ncs.png" alt="" />
                  </div>
                </div>
                <div>
                  <div className="brand">
                    <img src="../assets/images/partners/git.png" alt="professional consultants for women" />
                  </div>
                </div> 
                <div>
                  <div className="brand">
                    <img src="../assets/images/partners/unioleo_logo.png" alt="GIT" />
                  </div>
                </div> 
          
          </Slider> */}

        </Container>
      </section>

      <section className="parter-slider py-4">
        <Container>
          <h2 className="title text-center space-bottom position-relative">
            Client Stories
          </h2>
          <Slider {...patner_slider}>
            {/* <div>
              <Row className="align-items-center">
                <Col md={5}>
                  <div className="partner-img">
                    <img
                      src="./assets/images/slide1.jpg"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </Col>

                <Col md={7}>
                  <div className="slider-content">
                    <h2>Carol Dong Kiew Yoon</h2>
                    <h3>
                      <strong>Organization: NCS</strong>
                    </h3>
                    <h3>
                      <strong>Event: NCS x GIT x vLookUp Hackathon</strong>
                    </h3>
                    <p>
                      “Good experience and I enjoyed the journey of mentoring”
                    </p>
                  </div>
                </Col>
              </Row>
            </div> */}



            {/* new */}


            <div className="client_slider">
              <div className="client_slider_inner">

                <div className="partner-img">
                  <img
                    src="./assets/images/testimonial/olay.png"
                    alt=""
                    className="w-100"
                  />
                </div>

                <div className="slider-content">
                  <h3>
                    <strong>Event: Olay STEM Mentoring Programme 2.0</strong>
                  </h3>
                </div>

              </div>
            </div>



            <div className="client_slider">
              <div className="client_slider_inner">
                <div className="partner-img">
                  <img
                    src="./assets/images/testimonial/olay_1.png"
                    alt=""
                    className="w-100"
                  />
                </div>

                <div className="slider-content">
                  <h3>
                    <strong>Event: Olay STEM Mentoring Programme 2.0</strong>
                  </h3>
                </div>
              </div>

            </div>

            <div className="client_slider">
              <div className="client_slider_inner">
                <div className="partner-img">
                  <img
                    src="./assets/images/testimonial/republic.jpg"
                    alt=""
                    className="w-100"
                  />
                </div>

                <div className="slider-content">
                  <h3>
                    <strong>Event: Republic Polytechnic x vLookUp</strong>
                  </h3>
                </div>

              </div>
            </div>

            <div className="client_slider">
              <div className="client_slider_inner">
                <div className="partner-img">
                  <img
                    src="./assets/images/testimonial/dell.jpg"
                    alt=""
                    className="w-100"
                  />
                </div>



                <div className="slider-content">
                  <h3>
                    <strong>Event: Dell, Women in Action, Girls in Tech Mentoring Programme</strong>
                  </h3>
                </div>
              </div>

            </div>

            <div className="client_slider">
              <div className="client_slider_inner">
                <div className="partner-img">
                  <img
                    src="./assets/images/testimonial/gitpic.png"
                    alt=""
                    className="w-100"
                  />
                </div>

                <div className="slider-content">
                  <h3>
                    <strong>Event: GIT x NCS x vLookUp Hackathon Empowering </strong>
                  </h3>
                </div>
              </div>

            </div>






          </Slider>
        </Container>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    setting: state.setting,
    brand: state.brand,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(settingActions, homePageActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
