import { combineReducers } from "redux";
import initialState from "./initialState";
import isAuth from "./isAuthRequest";
import isSubmitting from "./isSubmittingRequest";
import isFrogot from "./isSubmittingRequest";
import setting from "./settingReducer";
import brand from "./brandReducer";
import homePageData from "./homePageReducers";
import leaders from "./leaderReducer";
import testimonials from "./testimonialsReducer";
import community from "./communityReducer";
import ResourcesHubData from "./resourceHubReducer";
import pagination from "./paginationReducers";

import isFetching from "./networkRequest";
import faqData from "./faqDataReducer";
import userExperience from "./experienceUserReducer";
import filter from "./filterReducer";
import redirectTo from "./redirectTo";
import userInfo from "./userInfoReducer";
import updateProfileData from "./updateProfileDataReducer";
import userRegister from "./registerDataReducer";
import mentorsData from "./mentorDataReducer";
import menteeRequestData from "./menteeRequestDataReducer";
import categories from "./categoriesReducer";
import forums from "./forumReducer";
import forumusers from "./forumUserReducer";
import reportcategories from "./reportCategoriesReducer";
import advisors from "./advisorsReducer";
import teams from "./teamsReducer";
import certificateData from "./certificateReducer";
import scheduleListingReducer from "./scheduleReducer";

import eventsData from "./eventsReducer";
import NewsletterData from "./newsletterReducer";
import VideoData from "./videoReducer";
import programmes from "./programmesReducer";
import categoryUserReducer from "./categoryUserReducers"
const rootReducer = combineReducers({
  isAuth,
  isSubmitting,
  isFrogot,
  pagination,
  setting,
  brand,
  homePageData,
  leaders,
  testimonials,
  ResourcesHubData,
  community,
  isFetching,
  faqData,
  userExperience,
  filter,
  redirectTo,
  userInfo,
  updateProfileData,
  userRegister,
  certificateData,
  mentorsData,
  menteeRequestData,
  categories,
  forums,
  forumusers,
  reportcategories,
  advisors,
  teams,
  eventsData,
  NewsletterData,
  VideoData,
  programmes,
  categoryUserReducer,
  scheduleListingReducer
});

export default function combinedRootReducer(state, action) {
  return action.type === "LOG_OUT"
    ? rootReducer(initialState, action)
    : rootReducer(state, action);
}
