import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as homePageActions from "../../actions/homePageActions";
import { Container } from "react-bootstrap";
import {useLocation, useHistory, Link} from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Index = (props) => {
  const { actions, homePageData } = props;
  const slug = props.match.params.slug;

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  useEffect(() => {
   
    const fetchData = () => {
      actions.loadHomePageData(slug);
    };
    fetchData();
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = homePageData.meta_title;
    document.querySelector('meta[name="description"]').setAttribute('content', homePageData.meta_description);
    
  });

  return (
    <>
      <section className="about_content_mian">
        <Container>
          <div className="cms_content">
            <h1 className="title pb-5">{homePageData.title}</h1>
            <div
              dangerouslySetInnerHTML={prepareHtml(homePageData.description)}
            ></div>
          </div>
        </Container>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    homePageData: state.homePageData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
