import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import * as forumActions from "../../actions/forumActions";
import { useForm } from "react-hook-form";

const ReportAdmin = (props) => {
  const { userdata, reportcategories, actions } = props;
  const { register, errors, handleSubmit } = useForm();
  const [getLoader, setLoader] = useState(false);
  useEffect(() => {
    actions.loadReportCategoryData();
  }, [actions]);

  const onSubmit = (data) => {
    setLoader(true);
    const request = {};
    request.user_id = userdata.user_id;
    request.ques_id = userdata.ques_id;
    request.ans_id = userdata.ans_id;
    request.report_type = userdata.report_type;
    request.description = data.description;
    request.category = data.category;
    actions.saveForumReportAdmin(request, props, setLoader);
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h3>
            <b>Report to Admin</b>
          </h3>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mentor_detail align-items-center">
              <Col sm={12}>
                <div className="form-group">
                  <label>Category</label>
                  <Form.Control
                    as="select"
                    className="mb-3"
                    name="category"
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value="" key="">
                      Select Option
                    </option>

                    {reportcategories &&
                      reportcategories.map((item, key) => (
                        <option value={item.id} key={key}>
                          {item.category}
                        </option>
                      ))}
                  </Form.Control>
                  {errors.category && errors.category.type === "required" && (
                    <label className="customeValidationText text-left">
                      Please select category
                    </label>
                  )}
                </div>
              </Col>
              <Col sm={12}>
                <label>Reason (Optional)</label>
                <Form.Control
                  as="textarea"
                  className={"form-control"}
                  name="description"
                  defaultValue=""
                  aria-invalid={"false"}
                  ref={register({
                    maxLength: 6000,
                  })}
                />
                {/* {errors.description &&
                  errors.description.type === "required" && (
                    <label className="customeValidationText text-left">
                      Please enter description
                    </label>
                  )}
                {errors.description &&
                  errors.description.type === "maxLength" && (
                    <label className="customeValidationText text-left">
                      Description should not more then 6000 characters
                    </label>
                  )} */}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="form-group mt-3 text-left">
                  <Button
                    className="btn nextbtn ml-2"
                    type="submit"
                    disabled={getLoader}
                  >
                    {getLoader ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    reportcategories: state.reportcategories,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(forumActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportAdmin);
