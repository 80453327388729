// sessionStorageManager.js

// Create a BroadcastChannel instance
const channel = new BroadcastChannel('session_channel');

// Function to set data in sessionStorage and notify other tabs
export function setData(key, value) {
  sessionStorage.setItem(key, value);
  channel.postMessage({ type: 'update', key, value });
}

// Function to get data from sessionStorage
export function getData(key) {
  return sessionStorage.getItem(key);
}

// Listen for messages from other tabs
channel.onmessage = (event) => {
  if (event.data.type === 'update') {
    sessionStorage.setItem(event.data.key, event.data.value);
  }
};

// Optional: Close the channel when it's no longer needed (e.g., on window unload)
window.addEventListener('unload', () => {
  channel.close();
});
